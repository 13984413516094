import React, { useState, useEffect, createRef } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import { toast } from 'react-toastify'
import Loader from '../components/loader'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 0px;
  font-weight: 600;
  background-color: ${vars.colors.teal};

  a {
    color: ${vars.colors.white};
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;
    transition: transform .3s ease-out;

    &:hover {
      @media (hover: hover) {
        transform: scale(1.05);
      }
    }
  }
  span {
    font-weight: 300;
  }
  img {
    width: 55px;
    margin-right: 14px;
  }
`
const InnerWrapper = styled.div`
  width: 90%;
  max-width: 800px;
  min-height: calc(100vh - 100px);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    max-width: 650px;
    text-align: center;
    margin-bottom: 30px;
  }
`
const ApiDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`
const ApiStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;
  background-color: ${vars.colors.white};
  box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
  will-change: box-shadow;
  transition: box-shadow .3s ease-out;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    margin: 0 20px 0 0;
    width: 30px;
  }
  p {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  span {
    color: ${vars.colors.red};
    font-weight: 600;
  }
  button {
    font-weight: 600;
    color: ${vars.colors.red};
    will-change: transform;
    transition: transform .3s ease-out;

    &:hover {
      @media (hover: hover) {
        transform: scale(1.1);
      }
    }
  }

  &:hover {
    @media (hover: hover) {
      box-shadow: 0px 1px 3px ${vars.colors.lightShadow};
    }
  }
`
const ApiConnections = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;

  > div {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
    will-change: box-shadow;
    transition: box-shadow .3s ease-out;

    &:hover {
      @media (hover: hover) {
        box-shadow: 0px 1px 3px ${vars.colors.lightShadow};
      }
    }
    &:nth-last-of-type(2) {
      margin: 0 10px;
    }
    > span {
      width: calc(100% - 40px);
      padding: 15px 0;
      margin-bottom: 10px;
      box-sizing: border-box;
      border-bottom: solid 2px ${vars.colors.green};
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 400px;
      overflow: auto;
    }
    li {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      min-height: 65px;
      padding: 10px 20px;
      box-sizing: border-box;

      &.locked {
        pointer-events: none;
        background-color: ${vars.colors.offWhite} !important;
      }
      &.account {
        padding-right: 40px;
      }
      &:not(.disabled), &:not(.active) {
        cursor: pointer;
        will-change: background-color;
        transition: background-color .3s ease-out;

        &:hover {
          @media (hover: hover) {
            background-color: ${vars.colors.lightShadow};
          }
        }
      }

      &.disabled {
        pointer-events: none;
        padding: 0 20px;

        > div {
          height: 60px;
        }
      }
      &.active {
        pointer-events: none;
        background-color: ${vars.colors.green};
        color: ${vars.colors.white};
      }
      p {
        margin: 0 0 5px 0;
        text-align: left;
      }
      span {
        font-size: 14px;
        font-weight: 600;
      }
      img {
        position: absolute;
        right: 20px;
        width: 12px;
        cursor: default;
        pointer-events: auto;
      }
    }
  }
`
const AccountLockPop = styled.div`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  border-radius: 5px;
  padding: 10px;
  width: 450px;
  top: ${props => props.position.y}px;
  left: ${props => props.position.x}px;
  background-color: ${vars.colors.white};
  opacity: ${props => (props.show ? 1 : 0)};
  box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
  will-change: opacity;
  transition: opacity .3s ease-out;
  transform: translateY(-110%);
`
const ApiButton = styled.button`
  height: 45px;
  min-width: 200px;
  line-height: 25px;
  padding: 0 20px;
  border-radius: 40px;
  color: ${vars.colors.white};
  font-size: 0.9em;
  font-weight: 300;
  text-align: center;
  background-color: ${props => (props.delete ? vars.colors.red : props.disabled ? vars.colors.lightGrey : vars.colors.green)};
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  will-change: background-color;
  transition: background-color .3s ease-out;

  &:hover {
    @media (hover: hover) {
      background-color: ${props => (props.delete ? darken(0.1, vars.colors.red) : darken(0.1, vars.colors.green))};
    }
  }
`
const Modal = styled.div`
  position: fixed;
  z-index: 9;
  top: 0; left: 0;
  width: 100%; height: 100vh;
  background-color: ${vars.colors.darkShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  will-change: opacity;
  transition: opacity .3s ease-out;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none !important')};

  * {
    pointer-events: ${props => (props.show ? 'auto' : 'none !important')};
  }
  a, button {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    max-width: 390px;
    margin: 32px auto 0;
    padding: 0 15px;
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-size: 1.3em;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:focus {
      outline: 0;
    }
    &:disabled {
      cursor: auto;
    }
  }
`
const ModalInner = styled.div`
  width: 80%;
  min-height: 100px;
  max-width: 550px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: ${vars.colors.offWhite};

  p {
    width: 80%;
    margin: 0 auto 20px;
    font-size: 18px;
    line-height: 22px;

    &.success {
      margin: 0;
    }
  }
  span {
    font-weight: 600;
    color: ${vars.colors.red};
  }
`
const ModalLoader = styled.div`
  width: 70%; height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  > div:first-of-type {
    height: 100%;
  }
`
const ModalButtons = styled.div`
  width: 70%; height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;

  button {
    width: 40%; height: 40px;
    line-height: 40px;
    max-width: 150px;
    margin: 0;
    padding: 0 8px;
    font-weight: 300;
    font-size: 18px;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:first-of-type {
      background-color: ${vars.colors.red};

      &:hover {
        @media (hover: hover) {
          background-color: ${darken(0.3, vars.colors.red)};
        }
      }
    }
  }
`

const ApiConnect = ({ history, match }) => {
  const initialSettings = {
    type: 'google_analytics',
    domain: '',
    company_id: null,
    google_account_id: null,
    google_account_name: null,
    google_account_connect_token: null,
    loading_accounts: true,
    accounts: null,
    analytics_account_id: null,
    loading_properties: true,
    properties: null,
    analytics_property_id: null,
    loading_profiles: true,
    profiles: null,
    analytics_profile_id: null,
    saving_settings: false,
    save_enabled: false,
    removing_connection: false,
    remove_successful: false,
    variant_string: '',
    existing_details: false,
    access_revoked: false
  }
  const [apiState, setApiState] = useState(initialSettings)
  const [popupLoading, setPopupLoading] = useState(false)
  const [checkExisting, setCheckExisting] = useState(true)
  const [showIntro, setIntroVisibility] = useState(false)
  const [showOutro, setOutroVisibility] = useState(false)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [showApiDetails, setApiDetailsVisibility] = useState(false)
  const [showApiStatus, setApiStatusVisibility] = useState(false)
  const [showSaveButton, setSaveButtonVisibility] = useState(false)
  const [accountRefs, setAccountRefs] = useState(null)
  const [propertyRefs, setPropertyRefs] = useState(null)
  const [profileRefs, setProfileRefs] = useState(null)
  const [showModal, setModalVisibility] = useState(false)
  const [showAccountLockPop, setAccountLockPopVisibility] = useState(false)
  const [accountPopPosition, setAccountPopPosition] = useState({ x: 0, y: 0 })

  useEffect(() => {
    fetch(`${__API__}apisettings/sl/${match.params.shortlink}`)
      .then(handleResponse)
      .then(result => {
        if (result.status && result.status === 'ok') {
          getApiDetails(result)
        } else {
          setIntroVisibility(true)
          setCheckExisting(false)
        }
      })
      .catch(error => {
        console.error(error)
        return error
      })
  }, [])

  const handleResponse = response => {
    if (!response.ok) {
      const error = new Error(response.statusText)
      toast.error(`There was an error while processing your request. ${response.status === 500 ? '' : `${response.statusText}.`}`)
      error.response = response
      throw error
    }
    const contentType = response.headers.get('content-type')
    if (contentType.includes('application/json')) {
      return response.json().then(json => {
        if (response.ok) return json
        return Promise.reject({
          ...json,
          status: response.status,
          statusText: response.statusText
        })
      })
    }
    if (contentType.includes('text/html')) {
      return response.text().then(text => {
        if (response.ok) return text
        return Promise.reject({
          status: response.status,
          statusText: response.statusText
        })
      })
    }
  }

  const getApiDetails = apiData => {
    const accountsPayload = {
      method: 'post',
      body: JSON.stringify({ google_account_connect_token: apiData.setting_data.google_account_connect_token }),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    }
    fetch(`${__API__}google-api/analytics/accounts`, accountsPayload)
      .then(handleResponse)
      .then(accountsResult => {
        const accounts = accountsResult.sort((a, b) => ((a.name > b.name) ? 1 : -1))
        let accRefs = null
        accountsResult.forEach(acc => {
          accRefs = { ...accRefs, ...{ [acc.id]: createRef() } }
        })
        setAccountRefs(accRefs)

        const propertiesPayload = {
          method: 'post',
          body: JSON.stringify({
            account_id: apiData.setting_data.analytics_account_id,
            google_account_connect_token: apiData.setting_data.google_account_connect_token
          }),
          headers: { 'Content-Type': 'application/json; charset=UTF-8' }
        }
        fetch(`${__API__}google-api/analytics/properties`, propertiesPayload)
          .then(handleResponse)
          .then(propertiesResult => {
            const properties = propertiesResult.sort((a, b) => ((a.name > b.name) ? 1 : -1))
            let propRefs = null
            propertiesResult.forEach(acc => {
              propRefs = { ...propRefs, ...{ [acc.id]: createRef() } }
            })
            setPropertyRefs(propRefs)

            const profilesPayload = {
              method: 'post',
              body: JSON.stringify({
                account_id: apiData.setting_data.analytics_account_id,
                property_id: apiData.setting_data.analytics_property_id,
                google_account_connect_token: apiData.setting_data.google_account_connect_token
              }),
              headers: { 'Content-Type': 'application/json; charset=UTF-8' }
            }
            fetch(`${__API__}google-api/analytics/profiles`, profilesPayload)
              .then(handleResponse)
              .then(profilesResult => {
                const profiles = profilesResult.sort((a, b) => ((a.name > b.name) ? 1 : -1))
                let profRefs = null
                profilesResult.forEach(acc => {
                  profRefs = { ...profRefs, ...{ [acc.id]: createRef() } }
                })
                setProfileRefs(profRefs)

                setApiState({
                  ...apiState,
                  type: apiData.type,
                  domain: apiData.domain,
                  accounts,
                  loading_accounts: false,
                  properties,
                  loading_properties: false,
                  profiles,
                  loading_profiles: false,
                  api_setting_id: apiData.id,
                  google_account_name: apiData.setting_data.google_account_info.name,
                  google_account_id: apiData.setting_data.google_account_id,
                  google_account_connect_token: apiData.setting_data.google_account_connect_token,
                  analytics_account_id: apiData.setting_data.analytics_account_id,
                  analytics_property_id: apiData.setting_data.analytics_property_id,
                  analytics_profile_id: apiData.setting_data.analytics_profile_id,
                  variant_string: apiData.variant_string,
                  save_enabled: true,
                  existing_details: true,
                  access_revoked: false
                })
                setApiStatusVisibility(true)
                setApiDetailsVisibility(true)
                setSaveButtonVisibility(true)
                setCheckExisting(false)

                if (accounts.length > 5) {
                  accRefs[apiData.setting_data.analytics_account_id].current.scrollIntoView()
                }
                if (properties.length > 5) {
                  propRefs[apiData.setting_data.analytics_property_id].current.scrollIntoView()
                }
                if (profiles.length > 5) {
                  profRefs[apiData.setting_data.analytics_profile_id].current.scrollIntoView()
                }
              })
              .catch(error => {
                console.error(error)
                handleRevokedAccount(apiData)
                return error
              })
          })
          .catch(error => {
            console.error(error)
            handleRevokedAccount(apiData)
            return error
          })
      })
      .catch(error => {
        console.error(error)
        handleRevokedAccount(apiData)
        return error
      })
  }
  const handleRevokedAccount = apiData => {
    setApiState({
      ...apiState,
      type: apiData.type,
      domain: apiData.domain,
      api_setting_id: apiData.id,
      google_account_name: apiData.setting_data.google_account_info.name,
      google_account_id: apiData.setting_data.google_account_id,
      google_account_connect_token: apiData.setting_data.google_account_connect_token,
      access_revoked: true
    })
    setLoadingDetails(false)
    setApiStatusVisibility(true)
    setApiDetailsVisibility(false)
    setCheckExisting(false)
  }
  const connectApi = () => {
    setPopupLoading(true)
    fetch(`${__API__}google-api/authentication-url`)
      .then(handleResponse)
      .then(result => {
        showGoogleAuthPopup(result)
        setPopupLoading(false)
      })
      .catch(error => {
        console.error(error)
        return error
      })
  }
  const showGoogleAuthPopup = url => {
    let windowObjectReference = null
    let previousUrl = null
    setIntroVisibility(false)
    setLoadingDetails(true)
    setApiDetailsVisibility(true)
    setSaveButtonVisibility(false)
    window.removeEventListener('message', handleGoogleMessage)

    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, 'Google', strWindowFeatures)
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, 'Google', strWindowFeatures)
      windowObjectReference.focus()
    } else {
      windowObjectReference.focus()
    }

    window.addEventListener('message', handleGoogleMessage, false)
    previousUrl = url
  }
  const handleGoogleMessage = e => {
    if (`${e.origin}/` !== __BASEURL__) {
      setLoadingDetails(false)
      setApiStatusVisibility(true)
      return
    }
    if (e.data?.kliprGoogleAuthCode) {
      window.removeEventListener('message', handleGoogleMessage)
      setApiState({
        ...apiState,
        access_revoked: false
      })
      const payload = {
        method: 'post',
        body: JSON.stringify({ access_code: e.data.kliprGoogleAuthCode }),
        headers: { 'Content-Type': 'application/json; charset=UTF-8' }
      }
      fetch(`${__API__}google-api/authenticate`, payload)
        .then(handleResponse)
        .then(result => {
          const { google_account_connect_token, google_account_id, google_account_name } = result
          setApiState({
            ...apiState,
            loading_accounts: true,
            google_account_connect_token,
            google_account_id,
            google_account_name,
            access_revoked: false
          })
          setSaveButtonVisibility(true)
          setLoadingDetails(false)
          setApiStatusVisibility(true)

          fetch(`${__API__}apisettings/sl/${match.params.shortlink}`)
            .then(handleResponse)
            .then(settings => {
              const connectPayload = {
                method: 'post',
                body: JSON.stringify({ google_account_connect_token }),
                headers: { 'Content-Type': 'application/json; charset=UTF-8' }
              }
              fetch(`${__API__}google-api/analytics/accounts`, connectPayload)
                .then(handleResponse)
                .then(accounts => {
                  setApiState({
                    ...apiState,
                    accounts: accounts.sort((a, b) => ((a.name > b.name) ? 1 : -1)),
                    type: settings.type,
                    domain: settings.domain,
                    company_id: settings.company_id,
                    loading_accounts: false,
                    google_account_connect_token,
                    google_account_id,
                    google_account_name,
                    variant_string: `Linked by ${settings.email_request_to_name}`,
                    access_revoked: false
                  })
                  setLoadingDetails(false)
                })
                .catch(error => {
                  setApiDetailsVisibility(false)
                  setApiStatusVisibility(false)
                  setLoadingDetails(false)
                  setSaveButtonVisibility(false)
                  setIntroVisibility(true)
                  console.error(error)
                  return error
                })
            })
            .catch(error => {
              console.error(error)
              return error
            })
        })
        .catch(error => {
          console.error(error)
          return error
        })
    }
  }
  const setCurrentGoogleAccount = e => {
    const id = e.currentTarget.getAttribute('data-id')
    setApiState({
      ...apiState,
      analytics_account_id: id,
      loading_properties: true
    })
    const payload = {
      method: 'post',
      body: JSON.stringify({
        account_id: id,
        google_account_connect_token: apiState.google_account_connect_token
      }),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    }
    fetch(`${__API__}google-api/analytics/properties`, payload)
      .then(handleResponse)
      .then(properties => {
        setApiState({
          ...apiState,
          properties: properties.sort((a, b) => ((a.name > b.name) ? 1 : -1)),
          analytics_account_id: id,
          loading_properties: false
        })
      })
      .catch(error => {
        console.error(error)
        return error
      })
  }
  const setCurrentGoogleProperty = e => {
    const id = e.currentTarget.getAttribute('data-id')
    setApiState({
      ...apiState,
      analytics_property_id: id,
      loading_profiles: true
    })
    const payload = {
      method: 'post',
      body: JSON.stringify({
        account_id: apiState.analytics_account_id,
        property_id: id,
        google_account_connect_token: apiState.google_account_connect_token
      }),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    }
    fetch(`${__API__}google-api/analytics/profiles`, payload)
      .then(handleResponse)
      .then(profiles => {
        setApiState({
          ...apiState,
          profiles: profiles.sort((a, b) => ((a.name > b.name) ? 1 : -1)),
          analytics_property_id: id,
          loading_profiles: false
        })
      })
      .catch(error => {
        console.error(error)
        return error
      })
  }
  const setCurrentGoogleProfile = e => {
    const id = e.currentTarget.getAttribute('data-id')
    setApiState({
      ...apiState,
      analytics_profile_id: id,
      save_enabled: true
    })
  }
  const handleSave = () => {
    setApiState({ ...apiState, saving_settings: true })
    const payload = {
      method: 'put',
      body: JSON.stringify({
        variant_string: apiState.variant_string,
        setting_data: {
          google_account_id: apiState.google_account_id,
          google_account_connect_token: apiState.google_account_connect_token,
          analytics_account_id: apiState.analytics_account_id,
          analytics_property_id: apiState.analytics_property_id,
          analytics_profile_id: apiState.analytics_profile_id
        }
      }),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    }
    fetch(`${__API__}apisettings/sl/${match.params.shortlink}`, payload)
      .then(handleResponse)
      .then(result => {
        if (result.success) {
          toast.success(`Google account connection successfully ${apiState.existing_details ? 'updated' : 'saved'}.`)
          setApiState({ ...apiState, saving_settings: false })
          setLoadingDetails(false)
          setApiStatusVisibility(false)
          setApiDetailsVisibility(false)
          setSaveButtonVisibility(false)
          setOutroVisibility(true)
        }
      })
      .catch(error => {
        console.error(error)
        return error
      })
  }
  const handleRemove = () => {
    setApiState({ ...apiState, removing_connection: true })
    const payload = {
      method: 'post',
      body: JSON.stringify({ google_account_connect_token: apiState.google_account_connect_token }),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    }
    fetch(`${__API__}google-api/remove`, payload)
      .then(handleResponse)
      .then(result => {
        if (result.success) {
          toast.success(`${apiState.google_account_name} Google Analytics connection was removed.`)
          setApiState({
            ...apiState,
            removing_connection: false,
            remove_successful: false
          })
          setTimeout(() => setApiState({ ...apiState, remove_successful: false }), 500)
          setModalVisibility(false)
          setApiStatusVisibility(false)
          setApiDetailsVisibility(false)
          setLoadingDetails(false)
          setSaveButtonVisibility(false)
          setIntroVisibility(true)
        }
      })
      .catch(error => {
        console.error(error)
        return error
      })
  }
  const handleAccountLockHover = e => {
    const rect = e.target.getBoundingClientRect()
    const offset = document.documentElement.scrollTop || document.body.scrollTop
    setAccountPopPosition({ x: rect.x - 20, y: rect.y + offset })
    setAccountLockPopVisibility(true)
  }

  return (
    <Wrapper>
      <Title>
        <Link to='/'>
          <img src={vars.icons.logo} alt='Klipr logo'/>Klipr.io
        </Link>
      </Title>
      <InnerWrapper>
        {checkExisting &&
          <Loader/>
        }
        {showIntro && (
          <>
            <h3>Connect your Google Analytics account</h3>
            <p>By connecting your Google Analytics account, your team will be able to view relevant Analytics statistics through the Klipr platform for insight, analysis and reporting. They will not be able to make edits to your Google Analytics account settings or view any data that you do not specifically select, and you are able to withdraw Google Analytics access at any time.</p>
            <ApiButton disabled={popupLoading} onClick={connectApi}>
              {popupLoading ? (
                <Loader small/>
              ) : 'Connect'}
            </ApiButton>
          </>
        )}
        {showApiStatus && (
          <>
            <ApiStatus>
              <div>
                <img src={apiState.access_revoked ? vars.icons.crossRed : vars.icons.google} alt='API type'/>
                <p>{apiState.google_account_name}</p>
              </div>
              {apiState.access_revoked ? (
                <span>Google Account access revoked</span>
              ) : (
                <>
                  {apiState.google_account_connect_token &&
                    <button type='button' onClick={() => setModalVisibility(true)}>Remove</button>
                  }
                </>
              )}
            </ApiStatus>
            {(apiState.access_revoked && !loadingDetails) && (
              <ApiButton disabled={popupLoading} onClick={connectApi}>
                {popupLoading ? (
                  <Loader small/>
                ) : 'Reconnect'}
              </ApiButton>
            )}
          </>
        )}
        {showApiDetails && (
          <ApiDetails>
            {loadingDetails ? (
              <Loader small/>
            ) : (
              <ApiConnections>
                <div>
                  <span>Analytics Accounts</span>
                  <ul>
                    {(apiState.loading_accounts || !apiState.accounts.length) ? (
                      <li className='disabled'><Loader small/></li>
                    ) : (
                      apiState.accounts.map(account => {
                        const perms = account.effective_permissions
                        const unlocked = perms && perms.length > 0 && perms.find(x => x === 'READ_AND_ANALYZE')
                        return (
                          <li key={account.id} onClick={unlocked ? setCurrentGoogleAccount : null}
                            className={account.id === apiState.analytics_account_id ? unlocked ? 'active account' : 'active account locked' : unlocked ? 'account' : 'account locked'}
                            data-id={account.id} data-name={account.name}
                            ref={accountRefs ? accountRefs[account.id] : null}
                          >
                            <p>{account.name}</p>
                            <span>ID: {account.id}</span>
                            {!unlocked &&
                              <img src={vars.icons.padlock} alt='account lock' onMouseEnter={handleAccountLockHover} onMouseLeave={() => setAccountLockPopVisibility(false)}/>
                            }
                          </li>
                        )
                      })
                    )}
                  </ul>
                  <AccountLockPop show={showAccountLockPop} position={accountPopPosition}>Your access to this Analytics profile does not permit Klipr to use its data. In order to connect the account, please instead use the “Request Access via Email” option on the API List setting.</AccountLockPop>
                </div>
                <div>
                  <span>Properties & Apps</span>
                  {apiState.analytics_account_id ? (
                    <ul>
                      {(apiState.loading_properties || !apiState.properties.length) ? (
                        <li className='disabled'><Loader small/></li>
                      ) : (
                        apiState.properties.map(property => (
                          <li key={property.id} onClick={setCurrentGoogleProperty} data-id={property.id}
                            className={property.id === apiState.analytics_property_id ? 'active' : ''}
                            ref={propertyRefs ? propertyRefs[property.id] : null}
                          >
                            <p>{property.name}</p>
                            <span>{property.id}</span>
                          </li>
                        ))
                      )}
                    </ul>
                  ) : (
                    <ul><li className='disabled'>Select an account</li></ul>
                  )}
                </div>
                <div>
                  <span>Views</span>
                  {apiState.analytics_property_id ? (
                    <ul>
                      {(apiState.loading_profiles || !apiState.profiles.length) ? (
                        <li className='disabled'><Loader small/></li>
                      ) : (
                        apiState.profiles.map(profile => (
                          <li key={profile.id} onClick={setCurrentGoogleProfile} data-id={profile.id}
                            className={profile.id === apiState.analytics_profile_id ? 'active' : ''}
                            ref={profileRefs ? profileRefs[profile.id] : null}
                          >
                            <p>{profile.name}</p>
                            <span>{profile.id}</span>
                          </li>
                        ))
                      )}
                    </ul>
                  ) : (
                    <ul><li className='disabled'>Select a property</li></ul>
                  )}
                </div>
              </ApiConnections>
            )}
          </ApiDetails>
        )}
        {showSaveButton && (
          <ApiButton disabled={apiState.saving_settings || !apiState.save_enabled} onClick={handleSave}>
            {apiState.saving_settings ? (
              <Loader small/>
            ) : apiState.existing_details ? 'Update' : 'Save'}
          </ApiButton>
        )}

        {showOutro && (
          <>
            <h3>Your Google account has been connected.</h3>
            <ApiButton onClick={() => history.push('/')}>Go to Dashboard</ApiButton>
          </>
        )}
      </InnerWrapper>
      <Modal show={showModal}>
        <ModalInner>
          {apiState.removing_connection ? (
            <p>Removing <span>{apiState.google_account_name}</span> API connection</p>
          ) : apiState.remove_successful ? (
            <p className='success'>Successfully removed <span>{apiState.google_account_name}</span> API connection</p>
          ) : (
            <div>
              <p>Are you sure you want to remove <span>{apiState.google_account_name}</span> API connection?</p>
            </div>
          )}
          {apiState.removing_connection ? (
            <ModalLoader><Loader small/></ModalLoader>
          ) : apiState.remove_successful ? (
            <span/>
          ) : (
            <ModalButtons>
              <button type='button' onClick={handleRemove}>Remove</button>
              <button type='button' onClick={() => setModalVisibility(false)}>Cancel</button>
            </ModalButtons>
          )}
        </ModalInner>
      </Modal>
    </Wrapper>
  )
}

export default withRouter(ApiConnect)
