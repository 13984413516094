import React, { useState, useEffect, useRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { darken } from 'polished'
import posed from 'react-pose'
import { CSVLink } from 'react-csv'
import { CSVReader } from 'react-papaparse'
import moment from 'moment'
import { Column, SortDirection, SortIndicator } from 'react-virtualized'
import isURL from 'validator/es/lib/isURL'
import Form from '../components/form'
import VirtualizedTable from '../components/virtualizedTable'
import Loader from '../components/loader'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  text-align: center;
  flex-direction: column;
  text-align: left;

  h3 {
    font-size: 2em;
    font-weight: 300;
    position: relative;
    padding-left: 60px;
    margin: 10px 0;
    padding-bottom: 20px;
    color: ${vars.colors.teal};

    &:before {
      content: '';
      width: 50px; height: 50px;
      position: absolute;
      top: 45%; left: 0;
      transform: translateY(-50%);
      background-image: url(${vars.icons.emailFinder});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
  .ReactVirtualized__Table__row {
    overflow: visible !important;
  }
`
const TabsWrapper = styled.ul`
  width: 100%;
  border-bottom: solid 2px ${vars.colors.green};
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 150px;
    color: ${vars.colors.white};
    padding: 10px 15px 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:first-of-type {
      margin-left: 10px;
      background-color: ${({ searchMode }) => (searchMode ? vars.colors.green : vars.colors.lightGrey)};
      font-weight: ${({ searchMode }) => (searchMode ? 600 : 300)};

      span {
        background-image: url(${vars.icons.searchWhite});
      }
      &:hover {
        @media (hover: hover) {
          background-color: ${({ searchMode }) => (searchMode ? darken(0.1, vars.colors.green) : darken(0.1, vars.colors.lightGrey))};
        }
      }
    }
    &:last-of-type {
      margin-left: 1px;
      background-color: ${({ searchMode }) => (searchMode ? vars.colors.lightGrey : vars.colors.green)};
      font-weight: ${({ searchMode }) => (searchMode ? 300 : 600)};

      span {
        background-image: url(${vars.icons.download});
        transform: rotate(180deg);
      }
      &:hover {
        @media (hover: hover) {
          background-color: ${({ searchMode }) => (searchMode ? darken(0.1, vars.colors.lightGrey) : darken(0.1, vars.colors.green))};
        }
      }
    }
    span {
      width: 15px; height: 15px;
      margin-right: 5px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
`
const AnimatedWrapper = posed.div({
  show: { opacity: 1 },
  hide: { opacity: 0 }
})
const HiddenWrapper = styled.div`
  width: 100%;
  height: ${props => (props.show ? 'auto' : 0)};
  overflow: ${props => (props.show ? 'visible' : 'hidden')};
`
const URLFormWrapper = styled.div`
  width: 100%;

  form {
    flex-direction: column;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }
  p {
    margin: 0;
  }
  textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`
const NoResults = styled.div`
  margin-top: 50px;
`
const NameCell = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  img {
    margin-right: 10px !important;
  }
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
const NumberCell = styled.div`
  font-weight: 600;
`
const UrlCell = styled.div`
  display: flex;
  align-items: center;

  span {
    position: relative;
    margin-right: 5px;
    width: 14px;
    height: 14px;

    svg {
      width: 14px;
      height: auto;
    }
    p {
      position: absolute;
      z-index: 1;
      width: max-content;
      max-width: 400px;
      bottom: -4px; right: 20px;
      background-color: ${vars.colors.white};
      box-shadow: 0 3px 6px ${vars.colors.lightShadow};
      font-size: .9rem;
      line-height: 1.1;
      padding: 7px 10px;
      margin: 0;
      pointer-events: none;
      opacity: 0;
      will-change: opacity;
      transition: opacity .3s ease-out;
    }
    &:hover {
      @media (hover: hover) {
        p {
          opacity: 1;
        }
      }
    }
  }
  a {
    line-height: 1.2em;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${vars.colors.teal};
    will-change: color;
    transition: color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        color: ${vars.colors.blue};
      }
    }
  }
`
const Toolbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 10px;

  h4 {
    margin: 0;
    font-size: 1.3em;
    color: ${vars.colors.teal};
  }
  a span {
    display: inline-block;
    width: 16px; height: 16px;
    margin-right: 6px;
    position: relative;
    top: 3px;
    background-image: url(${vars.icons.download});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`
const grow = keyframes`
  0% { transform: scale(0.96); }
  70% { transform: scale(1); }
  100% { transform: scale(0.96); }
`
const pulse = keyframes`
  0% { box-shadow: 0 0 5px 2px ${vars.colors.lightShadow}; }
  70% { box-shadow: 0 0 3px 20px rgba(0,0,0,0); }
  100% { box-shadow: 0 0 rgba(0,0,0,0); }
`
const LoadingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;

  > div {
    margin-right: 20px;
    height: 60px;
    width: 60px;
    padding: 10px;
    border-radius: 50%;
    background-color: ${vars.colors.white};
    position: relative;
    animation: ${grow} 2.2s infinite ease-out;

    > span {
      border-radius: 50%;
      width: 100%; height: 100%;
      position: absolute;
      top: 50%; left: 50%;
      box-shadow: 0 0 5px 2px ${vars.colors.lightShadow};
      transform: translate(-50%, -50%);

      &:before {
        content: '';
        border-radius: 50%;
        width: 100%; height: 100%;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 5px 2px ${vars.colors.darkShadow};
        transform: translate(-50%, -50%);
        animation: ${pulse} 2.2s infinite ease-out;
      }
    }
  }
  p {
    font-size: 1.3em;
    font-weight: 700;
  }
`
const ErrorCell = styled.div`
  width: 18px; height: 18px;
  position: relative;
  margin-right: 10px;

  svg {
    width: 18px;
    height: auto;

    > path {
      fill: ${({ severity }) => (severity === 3 ? vars.colors.red : severity === 2 ? vars.colors.amber : vars.colors.blue)};
    }
  }
  ul {
    position: absolute;
    z-index: 1;
    width: max-content;
    max-width: 400px;
    bottom: -10px; right: 20px;
    background-color: ${vars.colors.white};
    box-shadow: 0 3px 6px ${vars.colors.lightShadow};
    font-size: .9rem;
    line-height: 1.1;
    padding: 7px 5px 7px 25px;
    margin: 0;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    transition: opacity .3s ease-out;

    li {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &:hover {
      @media (hover: hover) {
        ul {
          opacity: 1;
        }
      }
    }
  }
  &:hover {
    @media (hover: hover) {
      ul {
        pointer-events: auto;
        opacity: 1;
      }
    }
  }
`
const ResetSort = styled.button`
  font-size: .9rem;
  margin: 0 5px;

  &:hover {
    @media (hover: hover) {
      text-decoration: underline;
    }
  }
`
const UploadWrapper = styled.div`
  width: 100%;
  min-height: 80px;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  button {
    width: 200px;
  }
  a {
    color: ${vars.colors.blue};
    text-decoration: underline;
    font-size: .8rem;
    margin-top: 10px;
    width: max-content !important;
  }
`
const CSVUpload = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 20px;
  text-decoration: none;
  color: ${vars.colors.white};
  font-size: 0.9em;
  text-align: center;
  background-color: ${vars.colors.blue};
  will-change: background-color;
  transition: background-color .3s ease-out;

  &:before {
    content: '';
    display: inline-flex;
    width: 15px; height: 15px;
    margin-right: 5px;
    background-image: url(${vars.icons.download});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    transform: rotate(180deg);
  }
  &:hover {
    @media (hover: hover) {
      background-color: ${darken(0.1, vars.colors.blue)};
    }
  }
`

const Home = ({ user, setLoading, callAPI, toast }) => {
  useEffect(() => setLoading(false), [setLoading])
  const [searchMode, setSearchMode] = useState(true)
  const [links, setLinks] = useState([])
  const [inputUrls, setInputUrls] = useState('')
  const [tallInput, setTallInput] = useState(false)
  const [loadingResults, setLoadingResults] = useState(false)
  const [originalData, setOriginalData] = useState(null)
  const [results, setResults] = useState(null)
  const [csvData, setCsvData] = useState(null)
  const [limitExceeded, setLimitExceeded] = useState(false)
  const [csvProcessing, setCsvProcessing] = useState(false)
  const [bulkCsvData, setBulkCsvData] = useState(null)
  const csvUploadRef = useRef(null)
  useEffect(() => {
    if (limitExceeded) {
      toast.warning('Results limited to 60 URLs per search.')
      setTimeout(() => setLimitExceeded(false), 5000)
    }
  }, [limitExceeded])

  const addLinks = e => {
    setInputUrls(e.target.value)
    const newLinks = e.target.value.split(/[\n\s,]+/)
    setTallInput(newLinks.length > 1)
    if (newLinks.length > 60) {
      setLimitExceeded(true)
    }
    setLinks([...new Set(newLinks.slice(0, 60))])
  }
  const onSubmit = e => {
    e.preventDefault()
    updateCsv(null)
    setOriginalData(null)
    setResults(null)
    let invalidUrls = []
    links.forEach((link, i) => {
      if (!/http:\/\/|https:\/\//.test(link) || (link.match(/http/g) || []).length > 1 || !isURL(link)) {
        invalidUrls = [...invalidUrls, i]
      }
    })
    const validLinks = [...links]
    if (invalidUrls.length) {
      if (links.length > 1) {
        toast.warning(`${invalidUrls.length} of the URLs entered ${invalidUrls.length > 1 ? 'are' : 'is'} not valid.`)
      } else {
        toast.error('Please enter a valid URL.')
      }
      invalidUrls.reverse().forEach(i => validLinks.splice(i, 1))
    }
    const payload = { urls: validLinks }
    if (validLinks.length) {
      setLoadingResults(true)
      callAPI('journalistfinder/search', undefined, 'post', JSON.stringify(payload)).then(result => {
        if (result.length) {
          const data = result.map(x => {
            if (x.error > 0 && user.is_super_user) {
              /* eslint-disable */
              console.groupCollapsed(`%cError encountered for URL ${x.url}. Expand for more details.`, `color: ${vars.colors.red};`)
              console.group('%cError message', `color: ${vars.colors.red}; font-weight: normal;`)
              console.log(`%c${x.error_message}`, `background-color: ${vars.colors.red}; color: ${vars.colors.white}; padding: 2px 4px;`)
              console.groupEnd()
              console.group('%cError backtrace', `color: ${vars.colors.red}; font-weight: normal;`)
              console.table(x.error_backtrace)
              console.groupEnd()
              console.groupEnd()
              /* eslint-enable */
            }

            const noAccess = x.http_status !== 200
            const missingEmail = !x.email && !x.alt_email
            const unsureEmail = (x.email_veracity && x.email_veracity <= 50) || (!x.email_veracity && x.alt_email_veracity && x.alt_email_veracity <= 50)
            const missingData = [
              ...(!x.publisher_name ? ['Website Name'] : []),
              ...(!x.domain ? ['Website Domain'] : []),
              ...(!x.headline ? ['Article Headline'] : []),
              ...(!x.domain_rating ? ['Domain Rating'] : []),
              ...(!x.date_published ? ['Published Date'] : []),
              ...(!x.author_first ? ['Author Name'] : [])
            ]
            const oldData = x.date_published && moment(new Date()).diff(moment(x.date_published), 'years') >= 1
            const domain = x.domain?.includes('www.') ? x.domain.split('www.')[1] : x.domain
            const emailType = x.email_type ? x.email_type : (!x.email && x.alt_email) ? 'generic' : null
            return ({
              status: x.http_status,
              websiteName: x.publisher_name,
              websiteDomain: domain,
              articleUrl: x.url,
              articleHeadline: x.headline,
              domainRating: x.domain_rating,
              publishedDate: x.date_published,
              firstName: x.author_first,
              lastName: x.author_last,
              email: x.email || x.alt_email,
              validated: x.email_veracity !== null ? x.email_veracity : x.alt_email_veracity,
              errors: { domain, code: x.error, noAccess, missingEmail, unsureEmail, emailType, missingData, oldData }
            })
          })
          let indexesToMove = []
          data.forEach((x, i) => {
            data.slice(i + 1, data.length).forEach((y, j) => {
              indexesToMove = y.articleUrl === x.articleUrl ? [...new Set([...indexesToMove, i + 1 + j])] : indexesToMove
            })
          })
          indexesToMove.forEach(i => data.push(data[i]))
          indexesToMove.reverse().forEach(i => data.splice(i, 1))
          updateCsv(data)
          setOriginalData(data)
          setResults(data)
        } else {
          setOriginalData([])
          setResults([])
        }
        setLoadingResults(false)
      }).catch(() => {
        setOriginalData([])
        setResults([])
        setLoadingResults(false)
      })
    }
  }

  // Table
  const [tableSortBy, setTableSortBy] = useState('')
  const [tableSortDir, setTableSortDir] = useState(SortDirection.ASC)
  const [prevSortBy, setPrevSortBy] = useState(null)
  const sort = ({ sortBy, sortDirection }) => {
    let sortDir = sortDirection
    let sortOptions = ['websiteName']
    sortOptions = sortOptions.filter(item => item !== sortBy)
    sortOptions.unshift(sortBy)

    const prevSort = prevSortBy
    if (sortBy !== 'websiteName' && (!prevSort || (prevSort && prevSort !== sortBy))) {
      sortDir = 'DESC'
    }
    const applySort = options => {
      const fields = options.map(option => {
        const cmp = (a, b) => {
          if (a == b) return 0 // eslint-disable-line
          return a < b ? -1 : 1
        }
        const invert = sortDir === SortDirection.DESC || (option !== sortBy && option !== 'name')
        const cmpFunc = invert ? (a, b) => -1 * cmp(a, b) : cmp
        return { option, cmpFunc }
      })

      return (A, B) => {
        let a, b, name, cmp, result
        for (let i = 0; i < fields.length; i += 1) {
          result = 0
          name = fields[i].option
          a = A[name] === 'TBC' ? -1 : typeof A[name] === 'string' ? A[name].toLowerCase() : A[name]
          b = B[name] === 'TBC' ? -1 : typeof B[name] === 'string' ? B[name].toLowerCase() : B[name]
          cmp = fields[i].cmpFunc
          result = cmp(a, b)
          if (result !== 0) break
        }
        return result
      }
    }

    setTableSortBy(sortBy)
    setTableSortDir(sortDir)
    setPrevSortBy(sortBy)
    const newResults = [...results].sort(applySort(sortOptions))
    updateCsv(newResults)
    setResults(newResults)
  }
  const resetTableSort = () => {
    setResults(originalData)
    setTableSortBy('')
    setTableSortDir(SortDirection.ASC)
    setPrevSortBy(null)
  }
  const updateCsv = data => {
    let newCsvData = null
    const columns = [{ label: 'Website Name', key: 'websiteName' },
      { label: 'Website Domain', key: 'websiteDomain' },
      { label: 'Article URL', key: 'articleUrl' },
      { label: 'Article Headline', key: 'articleHeadline' },
      { label: 'Domain Rating', key: 'domainRating' },
      { label: 'Published Date', key: 'publishedDate' },
      { label: 'First Name', key: 'firstName' },
      { label: 'Last Name', key: 'lastName' },
      { label: 'Email', key: 'email' },
      { label: 'Validated', key: 'validated' }]
    newCsvData = (data && data.length > 0) ? data.filter(x => x.status === 200).map(x => {
      const obj = {}
      columns.forEach(column => {
        obj[column.label] = column.key === 'publishedDate' ? moment(x[column.key]).format('DD MMMM YYYY') : x[column.key]
      })
      return obj
    }) : null
    setCsvData(newCsvData)
  }
  const bulkCsvTemplate = [{ 'Website Domain': '', 'First Name': '', 'Last Name': '' }]
  const handleOpenDialog = e => {
    if (csvUploadRef.current) {
      csvUploadRef.current.open(e)
    }
  }
  const handleCSVUpload = rawData => {
    const data = rawData.map(x => ({
      domain: x.data['Website Domain'],
      author_first: x.data['First Name'],
      author_last: x.data['Last Name']
    }))
    setCsvProcessing(true)
    callAPI('journalistfinder/name_search', undefined, 'post', JSON.stringify({ data })).then(result => {
      if (result.length) {
        const bulkData = result.map(x => ({
          'Website Name': x.publisher_name,
          'Website Domain': x.domain,
          'First Name': x.author_first,
          'Last Name': x.author_last,
          Email: x.email ? x.email : x.alt_email,
          Validated: x.email && x.email_veracity ? x.email_veracity : x.alt_email_veracity,
          'Email Type': x.email_type ? x.email_type : (!x.email && x.alt_email) ? 'generic' : null,
          Error: (x.error_message && x.error_message.includes(': ')) ? x.error_message.split(': ')[1] : null
        }))
        setBulkCsvData(bulkData)
      } else {
        toast.error('There was an error while loading the CSV file. Please make sure all CSV template fields are filled in.')
      }
      setCsvProcessing(false)
    }).catch(() => {
      resetBulkCsv()
    })
  }
  const handleCSVError = error => {
    toast.error('There was an error while loading the CSV file. Please make sure all CSV template fields are filled in.')
    console.error(error)
  }
  const resetBulkCsv = () => {
    setCsvProcessing(false)
    setBulkCsvData(null)
  }

  const headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => (
    <>
      {label}{sortBy === dataKey && <SortIndicator sortDirection={sortDirection}/>}
    </>
  )
  const nameCellRenderer = ({ cellData }) => (
    cellData ? (
      <NameCell>
        <p>{cellData}</p>
      </NameCell>
    ) : '-'
  )
  const dateCellRenderer = ({ cellData }) => (
    cellData ? (
      <>{moment(cellData).format('DD MMMM YYYY')}</>
    ) : '-'
  )
  const iconCellRenderer = ({ cellData }) => (
    cellData ? (
      <NameCell>
        {/* <img src={rowData.logo} alt={rowData.name}/> */}
        <p>{cellData}</p>
      </NameCell>
    ) : '-'
  )
  const emailCellRenderer = ({ cellData, rowData }) => (
    cellData ? (
      <UrlCell>
        {rowData.errors?.emailType === 'guess' && (
          <span>
            <svg height='24' width='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><circle cx='12' cy='12' fill='#1e66c9' r='12'/><path d='M13.685 12.195a3.723 3.723 0 0 0 1.635-2.91c0-1.845-1.38-2.97-3.48-2.97a4.549 4.549 0 0 0-3.435 1.41L9.8 9.21a2.291 2.291 0 0 1 1.77-.945 1.106 1.106 0 0 1 1.245 1.155 2.1 2.1 0 0 1-.9 1.575c-1.1 1.035-1.5 1.47-1.5 3.1v.435h2.16v-.22c0-1.02.255-1.35 1.11-2.115zM10.22 18h2.535v-2.505H10.22z' fill='#fff'/></svg>
            <p>This is a guessed email.</p>
          </span>
        )}
        <a href={`mailto:${cellData}`} title={cellData}>{cellData}</a>
      </UrlCell>
    ) : '-'
  )
  const urlCellRenderer = ({ cellData, rowData }) => (
    <UrlCell>
      <a href={cellData} target='_blank' rel='noopener noreferrer' title={rowData.articleHeadline ? rowData.articleHeadline : cellData}>{cellData}</a>
    </UrlCell>
  )
  const numberCellRenderer = ({ cellData }) => (
    cellData ? (
      <NumberCell>{typeof cellData === 'number' ? cellData.toLocaleString() : cellData}</NumberCell>
    ) : '-'
  )
  const percentCellRenderer = ({ cellData }) => (
    cellData && cellData >= 0 ? (
      <NumberCell>{cellData}%</NumberCell>
    ) : '-'
  )
  const errorCellRenderer = ({ cellData }) => {
    let errorMessages = []
    let errorSeverity = [1]
    if (cellData.noAccess) {
      errorMessages = ['Access to data refused by site.']
      errorSeverity = [...errorSeverity, 3]
    } else if (cellData.missingEmail) {
      if (cellData.code === 2 && cellData.domain) {
        errorMessages = [...errorMessages, `Hunter.io does not collect email addresses from ${cellData.domain}.`]
      } else {
        errorMessages = ['Email unavailable from Hunter.io.']
      }
      errorSeverity = [...errorSeverity, 3]
    } else if (cellData.unsureEmail) {
      errorMessages = ['Email quality not fully assured; it is advised to review the email before use.']
    }
    if (cellData.code === 3) {
      errorMessages = [...errorMessages, 'The domain cannot receive emails.']
      errorSeverity = [...errorSeverity, 3]
    }
    if (!cellData.noAccess && cellData.oldData) {
      errorMessages = [...errorMessages, 'Published Date older than a year; contact details may no longer be relevant.']
    }
    if (!cellData.noAccess && cellData.missingData?.length > 0) {
      errorMessages = [...errorMessages, `${cellData.missingData.map(entry => ` ${entry}`)} not provided by site.`]
      errorSeverity = [...errorSeverity, 2]
    }
    if (!cellData.noAccess && !cellData.email && cellData.alt_email) {
      errorMessages = [...errorMessages, 'An email could not be found for this journalist. A generic email has been supplied instead.']
      errorSeverity = [...errorSeverity, 2]
    }
    errorSeverity = Math.max(...errorSeverity)

    return errorMessages.length > 0 ? (
      <ErrorCell severity={errorSeverity}>
        <svg height='512pt' viewBox='0 0 512 512.92258' width='512pt' xmlns='http://www.w3.org/2000/svg'><path d='m433.347656 512.921875h-352.898437c-27.71875-.003906-53.460938-14.355469-68.039063-37.9375-14.574218-23.578125-15.902344-53.023437-3.511718-77.820313l176.433593-352.914062c13.542969-27.117188 41.253907-44.25 71.566407-44.25s58.023437 17.132812 71.566406 44.25l176.433594 352.914062c12.390624 24.796876 11.0625 54.242188-3.511719 77.820313-14.574219 23.582031-40.320313 37.933594-68.039063 37.9375zm0 0' fill='#FDB002'/><g fill='#fff'><path d='m256.898438 128.203125c8.835937 0 16 7.164063 16 16v192c0 8.835937-7.164063 16-16 16-8.835938 0-16-7.164063-16-16v-192c0-8.835937 7.164062-16 16-16zm0 0'/><path d='m240.898438 384.203125h32v32h-32zm0 0'/></g></svg>
        <ul>
          {errorMessages.map(error => (
            <li key={error}>{error}</li>
          ))}
        </ul>
      </ErrorCell>
    ) : null
  }

  const headerHeight = 55
  const rowHeight = 50
  const rowCount = results?.length
  const height = rowHeight * Math.min(10, rowCount) + headerHeight

  return (
    <Wrapper>
      <h3>Journalist Email Finder</h3>
      <TabsWrapper searchMode={searchMode}>
        <li onClick={() => setSearchMode(true)}><span/>Search</li>
        <li onClick={() => setSearchMode(false)}><span/>Upload CSV</li>
      </TabsWrapper>
      <AnimatedWrapper pose={searchMode ? 'show' : 'hide'}>
        <HiddenWrapper show={searchMode}>
          <URLFormWrapper>
            <Form onSubmit={onSubmit} isInline>
              <div>
                <p>Search the URLs of articles to find site information and the authors’ contact details.</p>
                <button type='submit' disabled={inputUrls.length === 0 || loadingResults}><span/>Search</button>
              </div>
              <textarea rows={tallInput ? 6 : 1} value={inputUrls} onPaste={addLinks} onChange={addLinks} required/>
            </Form>
          </URLFormWrapper>
          {(loadingResults || results) && (
            <>
              {loadingResults ? (
                <LoadingWrapper>
                  <div><span/><Loader small/></div>
                  <p>Searching URLs...</p>
                </LoadingWrapper>
              ) : (
                <>
                  {results.length > 0 ? (
                    <>
                      <Toolbar>
                        <h4>Search Results: {results.filter(x => x.status === 200).length}</h4>
                        <div>
                          {tableSortBy &&
                            <ResetSort onClick={resetTableSort}>Reset Table Sorting</ResetSort>
                          }
                          {csvData &&
                            <CSVLink data={csvData} filename={`Klipr_Journalist_Email_Finder ${moment(new Date()).format('DD.MM.YYYY')}.csv`} className='button blue' target='_blank'><span/>Export Results via CSV</CSVLink>
                          }
                        </div>
                      </Toolbar>
                      <VirtualizedTable
                        rowHeight={rowHeight} headerHeight={headerHeight} height={height} rowCount={rowCount}
                        rows={results} sort={sort} sortBy={tableSortBy} sortDirection={tableSortDir} leftAligned
                      >
                        <Column label='Website Name' dataKey='websiteName' width={150} flexGrow={1} headerRenderer={headerRenderer} cellRenderer={iconCellRenderer}/>
                        <Column label='Website Domain' dataKey='websiteDomain' width={150} headerRenderer={headerRenderer} cellRenderer={nameCellRenderer}/>
                        <Column label='Article URL' dataKey='articleUrl' width={250} flexGrow={1} headerRenderer={headerRenderer} cellRenderer={urlCellRenderer}/>
                        <Column label='Domain Rating' dataKey='domainRating' width={70} headerRenderer={headerRenderer} cellRenderer={numberCellRenderer}/>
                        <Column label='Published Date' dataKey='publishedDate' width={170} headerRenderer={headerRenderer} cellRenderer={dateCellRenderer}/>
                        <Column label='First Name' dataKey='firstName' width={100} headerRenderer={headerRenderer} cellRenderer={nameCellRenderer}/>
                        <Column label='Last Name' dataKey='lastName' width={100} headerRenderer={headerRenderer} cellRenderer={nameCellRenderer}/>
                        <Column label='Email' dataKey='email' width={200} flexGrow={1} headerRenderer={headerRenderer} cellRenderer={emailCellRenderer}/>
                        <Column label='Validated' dataKey='validated' width={70} headerRenderer={headerRenderer} cellRenderer={percentCellRenderer}/>
                        <Column label='' dataKey='errors' width={30} disableSort cellRenderer={errorCellRenderer}/>
                      </VirtualizedTable>
                    </>
                  ) : (
                    <NoResults>No results found</NoResults>
                  )}
                </>
              )}
            </>
          )}
        </HiddenWrapper>
      </AnimatedWrapper>
      <AnimatedWrapper pose={!searchMode ? 'show' : 'hide'}>
        <HiddenWrapper show={!searchMode}>
          <UploadWrapper>
            {csvProcessing ? (
              <LoadingWrapper>
                <div><span/><Loader small/></div>
                <p>Searching Emails...</p>
              </LoadingWrapper>
            ) : (
              <>
                {bulkCsvData ? (
                  <CSVLink data={bulkCsvData} filename={`Klipr_Journalist_Email_Finder ${moment(new Date()).format('DD.MM.YYYY')}.csv`} className='button green' target='_blank' onClick={resetBulkCsv}><span/>Download Results</CSVLink>
                ) : (
                  <>
                    <CSVReader noDrag noProgressBar ref={csvUploadRef} onFileLoad={handleCSVUpload} onError={handleCSVError} style={{ dropArea: { border: 0, padding: 0 }, dropFile: { display: 'none' } }} config={{ header: true }}>
                      <CSVUpload type='button' onClick={handleOpenDialog}>Upload CSV</CSVUpload>
                    </CSVReader>
                    <CSVLink data={bulkCsvTemplate} filename='Klipr_Journalist_Email_Finder_Template.csv' target='_blank' onClick={resetBulkCsv}><span/>Download CSV Template</CSVLink>
                  </>
                )}
              </>
            )}
          </UploadWrapper>
        </HiddenWrapper>
      </AnimatedWrapper>
    </Wrapper>
  )
}

export default Home
