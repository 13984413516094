/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import moment from 'moment'
import DeleteModal from './deleteModal'
import Header from './header'
import Footer from './footer'
import TourComponent from './tour'
import NewCampaign from '../api/newCampaign'
import NewCompany from '../api/newCompany'
import NewTeam from '../api/newTeam'
import NewUser from '../api/newUser'
import NewUrls from '../api/newUrls'
import Campaigns from '../pages/campaigns'
import CampaignDetails from '../pages/campaignDetails'
import Companies from '../pages/companies'
import Coverage from '../pages/coverage'
import Users from '../pages/users'
import Teams from '../pages/teams'
import Lookbook from '../pages/lookbook'
import Plan from '../pages/plan'
import EmailFinder from '../pages/emailFinder'
import searchDatabase from '../pages/searchDatabase'
import NotFound from '../pages/404'
import tours from '../assets/tours'
import * as vars from '../assets/vars'
import SearchDatabase from '../pages/searchDatabase'

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 180px);

  > div:not(.limit) {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2em 0;

    &.narrow {
      max-width: 800px;
    }
  }

  .panelHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px 0 20px;

    h2 {
      margin: 0 0 12px;
      font-size: 1.4em;

      @media (min-width: 768px) {
        margin: 0;
        font-size: 1.9em;
      }

      a {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        padding: 0;
        margin: 0 0 4px 12px;

        &:after {
          content: '';
          position: absolute;
          top: 50%; left: 5px;
          width: 16px; height: 16px;
          transform: translateY(-50%);
          background-image: url(${vars.icons.edit});
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          will-change: transform;
          transition: transform .3s ease-out;
        }
        &:hover:after {
          @media (hover: hover) {
            transform: translateY(-50%) scale(1.2);
          }
        }
      }
    }
  }
  .link {
    text-decoration: none;
    font-size: 1em;
    font-weight: 600;
    color: ${vars.colors.teal};
    background-color: transparent;
    white-space: nowrap;
    border: 0;
    outline: 0;
    padding: 0;
    appearance: none;
    will-change: color;
    transition: color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: transparent;

        span {
          transform: scale(1.15);
        }
        img {
          transform: translateX(-3px) rotate(90deg);
        }
      }
    }
    img {
      max-width: 12px;
      vertical-align: middle;
      transform: rotate(90deg);
      will-change: transform;
      transition: transform .3s ease-out;
    }
  }
  .button {
    padding: 10px 15px;
    border: 0;
    outline: 0;
    border-radius: 20px;
    text-decoration: none;
    color: ${vars.colors.white};
    font-size: 0.9em;
    font-weight: 300;
    cursor: pointer;
    text-align: center;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &.blue {
      background-color: ${vars.colors.blue};

      &:hover {
        @media (hover: hover) {
          background-color: ${darken(0.1, vars.colors.blue)};
        }
      }
    }
    &.amber {
      background-color: ${vars.colors.amber};

      &:hover {
        @media (hover: hover) {
          background-color: ${darken(0.1, vars.colors.amber)};
        }
      }
    }
  }
`
const LimitMessage = styled.div`
  background-color: ${vars.colors.red};
  width: 80%;
  max-width: 700px;
  margin: 30px auto 0;
  padding: 10px 20px;
  text-align: center;
  color: ${vars.colors.white};

  a {
    color: ${vars.colors.white};
  }
`

const MainWrapper = ({ setLoading, history, location, logout, user, teams, companies, setDeleteModal, deleteInputChange, deleteDetails, deleteItem, callAPI, currentTeam, setCurrentTeamId, toast, defaultFilter }) => {
  const isSuperUser = user.is_super_user
  const isTeamAdmin = isSuperUser || user.is_team_admin
  const noSURedir = isSuperUser ? currentTeam : true
  const limitReached = (currentTeam?.linkLimitReached || currentTeam?.companyLimitReached || currentTeam?.campaignLimitReached) && !currentTeam?.planEnded && !currentTeam?.planNotStarted && !location.pathname.includes('/plan')
  const planEnded = currentTeam?.planEnded && !location.pathname.includes('/plan')
  const planNotStarted = currentTeam?.planNotStarted && !location.pathname.includes('/plan')

  const [completedTours, setCompletedTours] = useState([])
  const [currentPage, setCurrentPage] = useState(location.pathname.split('/')[1])
  const [showHelp, setHelpVisibility] = useState(false)
  useEffect(() => {
    const page = location.pathname.split('/')[1]
    setCurrentPage(page)
    setHelpVisibility(!isSuperUser && page && completedTours.includes(page))
  }, [completedTours])
  const [tourSteps, setTourSteps] = useState([])
  const [tourOpen, setTourOpen] = useState(false)

  const startTour = () => {
    const tour = tours.find(x => x.page === currentPage)
    if (tour) {
      if (!tour.adminsOnly || (tour.adminsOnly && isTeamAdmin)) {
        setTourSteps(tour.steps)
        setTourOpen(true)
      }
    }
  }
  const endTour = () => {
    const payload = [...new Set([...completedTours, currentPage])]
    callAPI('settings/user/completed_tours', '', 'post', JSON.stringify(payload))
    setCompletedTours(payload)
    setTourOpen(false)
  }
  const setLoadingAndTour = () => {
    const page = location.pathname.split('/')[1]
    const toursAllowed = !isSuperUser && !user.is_client && (page === 'company' || (currentTeam?.id !== 3 && currentTeam?.id !== 29))
    if (toursAllowed) {
      if (completedTours.length < 1) {
        callAPI('settings/user/completed_tours').then(result => {
          if (result.value) {
            setCompletedTours(result.value)
            setLoading(false)
            if (result.value.length < 1 || !result.value.includes(page)) {
              startTour()
            }
          } else {
            setLoading(false)
            startTour()
          }
        }).catch(() => {
          setLoading(false)
          startTour()
        })
      } else {
        setLoading(false)
        if (!completedTours.includes(page)) {
          startTour()
        }
      }
    } else {
      setLoading(false)
    }
  }

  return (
    <>
      <Header history={history} location={location} logout={logout} user={user} currentTeam={currentTeam} teams={teams} companies={companies} callAPI={callAPI} setLoading={setLoading} toast={toast} showHelp={showHelp} startTour={startTour}/>
      <Wrapper>
        {limitReached &&
          <LimitMessage className='limit'>You have reached an account limit. View the <Link to='/plan'>plan</Link> page for more information.</LimitMessage>
        }
        {planEnded &&
          <LimitMessage className='limit'>Your plan has ended. View the <Link to='/plan'>plan</Link> page for more information.</LimitMessage>
        }
        {planNotStarted &&
          <LimitMessage className='limit'>Your plan is set to start on {moment(currentTeam.allowance_date_start).format('MMMM Do YYYY')}. View the <Link to='/plan'>plan</Link> page for more information.</LimitMessage>
        }
        <Switch>
          <Route exact path='/' render={() => (isSuperUser ?
            <Redirect to='/teams'/>
            : <Redirect to='/companies'/>)}
          />

          <Route path='/add-campaign/:company?' render={props => (noSURedir ?
            (currentTeam.campaignLimitReached || (planEnded || planNotStarted)) ?
              <Redirect to='/plan'/>
              : <NewCampaign {...props} companies={companies} callAPI={callAPI} setLoading={setLoadingAndTour} toast={toast} buzzstreamEnabled={currentTeam.buzzstream_enabled}/>
            : <Redirect to='/teams'/>)}
          />
          <Route exact path='/add-company' render={props => (noSURedir ?
            (currentTeam.companyLimitReached || (planEnded || planNotStarted)) ?
              <Redirect to='/plan'/>
              : <NewCompany {...props} user={user} teams={teams} callAPI={callAPI} setLoading={setLoadingAndTour} toast={toast}/>
            : <Redirect to='/teams'/>)}
          />
          <Route exact path='/add-user' render={props => (
            <NewUser {...props} user={user} currentTeam={currentTeam} teams={teams} companies={companies} callAPI={callAPI} setLoading={setLoading} toast={toast}/>)}
          />
          <Route exact path='/add-urls/:company/:campaign' render={props => (noSURedir ?
            <NewUrls {...props} user={user} callAPI={callAPI} setLoading={setLoading} toast={toast}/>
            : <Redirect to='/teams'/>)}
          />

          <Route path='/campaigns/:company?' render={props => (noSURedir ?
            <Campaigns {...props} user={user} currentTeam={currentTeam} delete={setDeleteModal} deleteDetails={deleteDetails} callAPI={callAPI} setLoading={setLoadingAndTour} toast={toast} defaultFilter={defaultFilter}/>
            : <Redirect to='/teams'/>)}
          />
          <Route path='/campaign/:company/:campaign/:page?' render={props => (noSURedir ?
            <CampaignDetails {...props} user={user} currentTeam={currentTeam} delete={setDeleteModal} deleteDetails={deleteDetails} callAPI={callAPI} setLoading={setLoading} toast={toast} defaultFilter={defaultFilter}/>
            : <Redirect to='/teams'/>)}
          />
          <Route exact path='/edit-campaign/:company/:campaign' render={props => (noSURedir ?
            <NewCampaign {...props} companies={companies} callAPI={callAPI} setLoading={setLoading} toast={toast} buzzstreamEnabled={currentTeam.buzzstream_enabled}/>
            : <Redirect to='/teams'/>)}
          />

          <Route exact path='/companies' render={props => (noSURedir ?
            <Companies {...props} delete={setDeleteModal} deleteDetails={deleteDetails} user={user} currentTeam={currentTeam} companies={companies} callAPI={callAPI} setLoading={setLoadingAndTour} toast={toast}/>
            : <Redirect to='/teams'/>)}
          />
          <Route exact path='/company/:company' render={props => (noSURedir ?
            <NewCompany {...props} user={user} currentTeam={currentTeam} callAPI={callAPI} setLoading={setLoadingAndTour} tourOpen={tourOpen} toast={toast}/>
            : <Redirect to='/teams'/>)}
          />

          <Route exact path='/teams' render={props => (isSuperUser ?
            <Teams {...props} teams={teams} delete={setDeleteModal} deleteDetails={deleteDetails} user={user} callAPI={callAPI} setLoading={setLoading} toast={toast} setCurrentTeamId={setCurrentTeamId}/>
            : <Redirect to='/'/>)}
          />
          <Route exact path='/add-team' render={props => (isSuperUser ?
            <NewTeam {...props} callAPI={callAPI} setLoading={setLoading} setCurrentTeamId={setCurrentTeamId} toast={toast}/>
            : <Redirect to='/'/>)}
          />
          <Route exact path='/edit-team/:team' render={props => (isSuperUser ? <NewTeam {...props} callAPI={callAPI} setLoading={setLoading} setCurrentTeamId={setCurrentTeamId} toast={toast}/> : <Redirect to='/'/>)}/>

          <Route exact path='/users' render={props => (isTeamAdmin ?
            <Users {...props} user={user} currentTeam={currentTeam} delete={setDeleteModal} deleteDetails={deleteDetails} callAPI={callAPI} setLoading={setLoading} toast={toast}/>
            : <Redirect to='/'/>)}
          />
          <Route exact path='/user/:user' render={props => (
            <NewUser {...props} user={user} currentTeam={currentTeam} teams={teams} companies={companies} callAPI={callAPI} setLoading={setLoading} toast={toast}/>)}
          />

          <Route exact path='/lookbook/:company/:campaign' render={props => (noSURedir ?
            <Lookbook {...props} user={user} callAPI={callAPI} setLoading={setLoading} toast={toast}/>
            : <Redirect to='/teams'/>)}
          />
          <Route path='/coverage' render={props => (noSURedir ?
            <Coverage {...props} user={user} currentTeam={currentTeam} delete={setDeleteModal} deleteDetails={deleteDetails} callAPI={callAPI} setLoading={setLoading} toast={toast}/>
            : <Redirect to='/teams'/>)}
          />
          <Route exact path='/plan' render={props => (noSURedir ?
            <Plan {...props} user={user} currentTeam={currentTeam} callAPI={callAPI} setLoading={setLoading} toast={toast}/>
            : <Redirect to='/teams'/>)}
          />

          <Route exact path='/journalist-email-finder' render={props => (currentTeam?.tool_access_journalist_finder ?
            <EmailFinder {...props} user={user} setLoading={setLoading} callAPI={callAPI} toast={toast}/>
            : <Redirect to='/'/>)}
          />

          <Route exact path='/search-database' render={props => (currentTeam?.tool_access_search_database ?
            <SearchDatabase {...props} user={user} setLoading={setLoading} callAPI={callAPI} toast={toast}/>
            : <Redirect to='/'/>)}
          />

          <Route render={props => (
            <NotFound {...props} setLoading={setLoading}/>)}
          />
        </Switch>
      </Wrapper>
      <Footer/>
      <DeleteModal deleteDetails={deleteDetails} deleteInputChange={deleteInputChange} deleteItem={deleteItem} setDeleteModal={setDeleteModal}/>
      <TourComponent steps={tourSteps} isOpen={tourOpen} endTour={endTour}/>
    </>
  )
}

export default MainWrapper
//
