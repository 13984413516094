import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  input {
    display: none;

    &, &:after, & *, & *:after {
      box-sizing: border-box;

      &:selection {
        background-color: ${darken(0.3, vars.colors.red)};
      }
    }
    &:checked + label {
      background-color: ${darken(0.2, vars.colors.green)};

      &:after {
        left: 50%;
        background-color: ${vars.colors.green};
      }
      &:active:after {
        margin-left: -5px;
      }
    }
  }

  label {
    outline: 0;
    display: block;
    width: ${props => (props.small ? '30px' : '50px')};
    height: ${props => (props.small ? '15px' : '25px')};
    border-radius: ${props => (props.small ? '15px' : '25px')};
    position: relative;
    cursor: pointer;
    user-select: none;
    background-color: ${darken(0.3, vars.colors.red)};
    opacity: ${props => (props.disabled ? 0.3 : 1)};
    pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:after {
      content: '';
      display: block;
      width: 50%; height: 100%;
      position: relative;
      left: -1px;
      border-radius: 50%;
      background-color: ${vars.colors.red};
      will-change: transform, padding, margin, left;
      transition: transform .3s ease-out, padding .3s ease-out, margin .3s ease-out, left .3s cubic-bezier(0.175, 0.885, 0.320, 1.275);
    }
    &:hover:after {
      @media (hover: hover) {
        transform: scale(1.1);
      }
    }
    &:active:after {
      padding-right: 5px;
    }
  }
`

const Toggle = ({ small, disabled, id, name, onChange, isChecked }) => (
  <Wrapper small={small} disabled={disabled}>
    <input id={id} name={name} onChange={onChange} type='checkbox' checked={isChecked}/>
    <label htmlFor={id}/>
  </Wrapper>
)

export default Toggle
