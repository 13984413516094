import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { darken } from 'polished'
import * as vars from '../assets/vars'

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-space-between;
  align-items: center;
  width: 100%;
  height: 80px;
`
const Copyright = styled.p`
  width: calc(100% - 170px);
  padding: 20px;
  margin: 0;
  color: ${vars.colors.lightGrey};
  text-align: center;
  font-size: 14px;
`
const Button = styled.a`
  position: fixed;
  right: 20px; bottom: 20px;
  background-color: ${vars.colors.red};
  color: ${vars.colors.white};
  padding: 10px 15px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 14px;
  will-change: background-color;
  transition: background-color .3s ease-out;

  &:hover {
    @media (hover: hover) {
      background-color: ${darken(0.3, vars.colors.red)};
    }
  }
`

const Footer = () => (
  <Wrapper>
    <Copyright>{`Copyright © 2019 - ${moment().format('YYYY')} Klipr - trading name of Kaizen Search Marketing Ltd`}</Copyright>
    <Button href='https://docs.google.com/forms/d/e/1FAIpQLSdBYufrpwAwBujupdRVUZ-fyEX5280QuPoFtNt47Gq1_ATbOA/viewform' target='_blank' rel='noopener noreferrer'>Report a Bug</Button>
  </Wrapper>
)

export default Footer
