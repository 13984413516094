import React from 'react'
import { toast } from 'react-toastify'
import LoginForm from '../components/loginForm'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)

    const st = this.props.location.state
    this.state = {
      error: st?.error && st?.error !== 'null' ? st.error : null,
      user: {
        username: '',
        password: '',
        loading: false,
        loggedIn: false
      }
    }
  }

  handleResponse = response => {
    if (!response.ok) {
      const { user } = this.state
      user.loading = false
      this.setState({ user })

      toast.error(<div><span>Invalid username or password.</span><br/><span>Please try again.</span></div>)
      const error = new Error(response.statusText)
      error.response = response
      throw error
    }

    return response.json().then(json => {
      if (response.ok) {
        return json
      }
      return Promise.reject({
        ...json,
        status: response.status,
        statusText: response.statusText
      })
    })
  }
  login = payload => {
    const request = {
      method: 'post',
      body: payload,
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    }

    return fetch(__OAUTH__, request)
      .then(this.handleResponse)
      .then(result => result)
      .catch(error => error)
  }
  processForm = e => {
    e.preventDefault()

    const { user } = this.state
    const payload = {
      username: user.username,
      password: user.password
    }
    user.loading = true
    this.setState({ user })

    this.login(JSON.stringify(payload)).then(result => {
      if (result.access_token && result.token_type === 'bearer') {
        localStorage.setItem('klipr_auth_token', result.access_token)
        localStorage.removeItem('klipr_user_email')
        user.loggedIn = true
        this.setState({ user })
      }
    })
  }
  changeUser = e => {
    const field = e.target.name
    const { user } = this.state
    if (field === 'username') {
      localStorage.setItem('klipr_user_email', e.target.value)
    }
    user[field] = e.target.value
    this.setState({ user })
  }

  render() {
    return <LoginForm onSubmit={this.processForm} onChange={this.changeUser} error={this.state.error} user={this.state.user}/>
  }
}

export default LoginPage
