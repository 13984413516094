import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import { darken } from 'polished'
import Loader from './loader'
import * as vars from '../assets/vars'

const AnimatedWrapper = posed.div({
  show: { opacity: 1 },
  hide: { opacity: 0 }
})
const Wrapper = styled(AnimatedWrapper)`
  position: fixed;
  z-index: 9;
  top: 0; left: 0;
  width: 100%; height: 100vh;
  background-color: ${vars.colors.darkShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  pointer-events: ${props => (props.pose === 'show' ? 'auto' : 'none !important')};

  * {
    pointer-events: ${props => (props.pose === 'show' ? 'auto' : 'none !important')};
  }
  a, button {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    max-width: 390px;
    margin: 32px auto 0;
    padding: 0 15px;
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-size: 1.3em;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:focus {
      outline: 0;
    }
    &:disabled {
      cursor: auto;
    }
  }
`
const InnerWrapper = styled.div`
  width: 80%;
  min-height: 100px;
  max-width: 550px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: ${vars.colors.offWhite};

  p {
    width: 80%;
    margin: 0 auto 20px;
    font-size: 18px;
    line-height: 22px;

    &.success {
      margin: 0;
    }
  }
  span {
    font-weight: 600;
    color: ${vars.colors.red};
  }
  input {
    width: 70%;
    max-width: 300px;
    margin-bottom: 20px;
    padding: 14px 20px;
    color: ${vars.colors.teal};
    border-radius: 3px;
    border: none;
    box-sizing: border-box;
    text-align: center;

    &:focus {
      outline: none;
    }
    &::placeholder {
      font-size: 14px;
    }
  }
`
const LoaderWrapper = styled.div`
  width: 70%; height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  > div:first-of-type {
    height: 100%;
  }
`
const ButtonsWrapper = styled.div`
  width: 70%; height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;

  button {
    width: 40%; height: 40px;
    line-height: 40px;
    max-width: 150px;
    margin: 0;
    padding: 0 8px;
    font-weight: 300;
    font-size: 18px;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:first-of-type {
      opacity: ${props => (props.disableDelete ? 0.3 : 1)};
      pointer-events: ${props => (props.disableDelete ? 'none' : 'auto')};
      background-color: ${vars.colors.red};

      &:hover {
        @media (hover: hover) {
          background-color: ${darken(0.3, vars.colors.red)};
        }
      }
    }
  }
`

const DeleteModal = ({ deleteDetails, deleteInputChange, deleteItem, setDeleteModal }) => (
  <Wrapper pose={deleteDetails.id ? 'show' : 'hide'}>
    <InnerWrapper>
      {deleteDetails.deleting ? (
        <p>Deleting {deleteDetails.type} <span>{deleteDetails.name}</span></p>
      ) : deleteDetails.success ? (
        <p className='success'>Successfully deleted {deleteDetails.type} <span>{deleteDetails.name}</span></p>
      ) : (
        <div>
          <p>Are you sure you want to delete {deleteDetails.type} <span>{deleteDetails.name}</span>?</p>
          {deleteDetails.type === 'team' &&
            <input type='text' placeholder='Type "delete" here to confirm' onChange={deleteInputChange}/>
          }
        </div>
      )}
      {deleteDetails.deleting ? (
        <LoaderWrapper><Loader small/></LoaderWrapper>
      ) : deleteDetails.success ? (
        <span/>
      ) : (
        <ButtonsWrapper disableDelete={deleteDetails.type === 'team' && !deleteDetails.allowed}>
          <button type='button' onClick={deleteItem}>Delete</button>
          <button type='button' onClick={setDeleteModal}>Cancel</button>
        </ButtonsWrapper>
      )}
    </InnerWrapper>
  </Wrapper>
)

export default DeleteModal
