import React from 'react'
import { Redirect, withRouter } from 'react-router'
import AddUser from '../pages/addUser'

class NewUser extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {
        currentUser: props.user,
        team_admin: false,
        super: false,
        fname: '',
        lname: '',
        uname: '',
        email: '',
        avatar: '',
        updatePassword: true,
        pword1: '',
        pword2: '',
        prevpword: '',
        passwordStrength: 0,
        passwordsEqual: false,
        loading: false,
        title: +props.match.params.user === +props.user.id ? 'Edit Profile' : props.match.params.user ? 'Edit User' : 'Add User'
      }
    }
  }

  componentDidMount() {
    if (this.props.match.params.user) {
      this.getUser(this.props.match.params.user)
    } else {
      this.props.setLoading(false)
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.user && this.props.match.params.user !== nextProps.match.params.user) {
      this.getUser(this.props.match.params.user)
    }

    if (this.props.match.path === '/user/:user' && nextProps.match.path === '/add-user') {
      const { user } = this.state
      user.title = 'Add User'
      this.setState({ user })
    }
  }

  getUser = id => {
    const { user } = this.state
    const currentUser = this.props.user
    const sameId = currentUser.id && +currentUser.id === +id
    const notAdmin = !currentUser.is_super_user && !currentUser.is_team_admin
    user.updatePassword = false

    if (sameId || notAdmin) {
      user.fname = currentUser.first_name
      user.lname = currentUser.last_name
      user.uname = currentUser.username
      user.email = currentUser.email
      user.team_admin = currentUser.is_team_admin
      user.super = currentUser.is_super_user
      user.avatar = currentUser.image
      user.emailVerified = currentUser.is_email_verified
      user.pword1 = ''
      user.pword2 = ''
      user.prevpword = ''
      user.title = 'Edit Profile'
      this.setState({ user }, () => this.props.setLoading(false))
    } else {
      this.props.callAPI(`users/${id}`).then(result => {
        user.fname = result.first_name
        user.lname = result.last_name
        user.uname = result.username
        user.email = result.email
        user.team_admin = result.is_team_admin
        user.super = result.is_super_user
        user.avatar = result.image
        user.emailVerified = result.is_email_verified
        user.pword1 = ''
        user.pword2 = ''
        user.prevpword = ''
        user.title = 'Edit User'
        this.setState({ user }, () => this.props.setLoading(false))
      })
    }
  }

  addUser = e => {
    e.preventDefault()

    const { user } = this.state
    user.loading = true
    this.setState({ user })

    const firstName = this.state.user.fname
    const lastName = this.state.user.lname
    const { email } = this.state.user
    const password = this.state.user.pword1
    const password2 = this.state.user.pword2
    const prevpassword = this.state.user.prevpword

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email,
      is_team_admin: this.state.user.team_admin,
      image: this.state.user.avatar,
      is_active: true
    }

    if (user.updatePassword) {
      if (password !== password2) {
        this.props.toast.warn('Passwords not matching.')
        user.loading = false
        this.setState({ user })
        return
      }
      payload.password = password

      if (prevpassword.length) {
        payload.old_password = prevpassword
      }
    }

    const path = this.props.match.params.user ? 'users/' : 'users/new'
    const method = this.props.match.params.user ? 'put' : 'post'
    const id = this.props.match.params.user

    this.props.callAPI(path, id, method, JSON.stringify(payload)).then(result => {
      user.loading = false
      this.setState({ user })

      if (result.success) {
        if (method === 'post') {
          this.props.toast.success(`New user ${this.state.user.fname} ${this.state.user.lname} was successfully added.`)
        } else {
          this.props.toast.success('User updated successfully.')
        }
        this.props.history.push('/users')
      }
    })
  }
  checkPassword = () => {
    const password = this.state.user.pword1
    const hasSpecial = new RegExp(/[^A-Za-z0-9]/).test(password)
    const hasNumber = new RegExp(/[0-9]/).test(password)
    const hasMixed = new RegExp(/[a-z]/).test(password) && new RegExp(/[A-Z]/).test(password)

    let passwordStrength = 0
    if (password.length >= 5) passwordStrength += 1
    if (password.length >= 8 && hasSpecial) passwordStrength += 1
    if (password.length >= 8 && hasSpecial && (hasNumber || hasMixed)) passwordStrength += 1

    const { user } = this.state
    user.passwordStrength = passwordStrength
    this.setState({ user }, () => this.checkPasswordsAreEqual())
  }
  checkPasswordsAreEqual = () => {
    const { user } = this.state
    user.passwordsEqual = (user.pword1 === user.pword2) && user.passwordStrength > 1
    this.setState({ user })
  }
  changeUser = e => {
    const { target } = e
    const { user } = this.state
    user[e.target.name] = e.target.value
    this.setState({ user }, () => {
      if (target.name === 'pword1') {
        this.checkPassword()
      }
      if (target.name === 'pword2') {
        this.checkPasswordsAreEqual()
      }
    })
  }
  previewFile = e => {
    const reader = new FileReader()
    const { user } = this.state
    const file = e.target.files[0]

    if (file) {
      const size = ((file.size / 1024) / 1024).toFixed(4)
      if (size < 0.5) {
        reader.addEventListener('load', () => {
          user.avatar = reader.result.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '')
          this.setState({ user })
        }, false)
        reader.readAsDataURL(file)
      } else {
        this.props.toast.error('The file is too big, please try again with an image smaller than 5kb.')
      }
    }
  }
  togglePasswordFields = () => {
    const { user } = this.state
    user.updatePassword = !user.updatePassword
    this.setState({ user })
  }

  render() {
    if (+this.props.match.params.user !== +this.props.user.id && !this.props.user.is_super_user && !this.props.user.is_team_admin) {
      return <Redirect to={{ pathname: `/user/${this.props.user.id}` }}/>
    }

    return <AddUser onSubmit={this.addUser} onChange={this.changeUser} togglePasswordFields={this.togglePasswordFields} preview={this.previewFile} user={this.state.user} currentTeam={this.props.currentTeam} updateUser={this.props.match.params.user}/>
  }
}

export default withRouter(NewUser)
