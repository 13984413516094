import React from 'react'
import styled from 'styled-components'
import { Link, withRouter, matchPath } from 'react-router-dom'
import Select from 'react-select'
import Avatar from './avatar'
import * as vars from '../assets/vars'

const Wrapper = styled.header`
  box-sizing: border-box;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 5%;
  color: ${vars.colors.white};
  background: ${vars.colors.teal};

  > div {
    position: relative;
  }
  &#companyList {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    text-align: center;
  }
  > .name {
    display: inline-flex;
    vertical-align: middle;
  }

  select {
    max-width: 165px;
  }
  p {
    display: inline-block;
    vertical-align: middle;
  }
  a {
    color: ${vars.colors.white};
    text-decoration: none;
  }
`
const Overlay = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: ${vars.colors.lightShadow};

  &.active {
    display: block;
  }
`
const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    margin-left: 10px;
  }
`
const MainMenuTrigger = styled.span`
  display: flex;
  align-items: center;
  height: 19px;
  cursor: pointer;
  will-change: transform;
  transition: transform .3s ease-out;

  span {
    position: relative;
    display: block;
    width: 30px;
    height: 3px;
    border-radius: 10px;
    background-color: ${vars.colors.white};

    &:after, &:before {
      content: '';
      position: absolute;
      background-color: ${vars.colors.white};
      height: 3px;
      border-radius: 10px;

    }
    &:before {
      top: -8px;
      width: 100%;
    }
    &:after {
      top: 8px;
      width: calc(100% - 6px);
      will-change: width;
      transition: width .3s ease-out;
    }
  }

  &:hover {
    @media (hover: hover) {
      transform: scale(1.08);

      span {
        &:after {
          width: 100%;
        }
      }
    }
  }
`
const MainMenu = styled.div`
  position: fixed;
  top: 0; bottom: 0; left: 0;
  width: 300px;
  max-width: 90%;
  padding: 25px;
  background: ${vars.colors.teal};
  box-shadow: 4px 0 8px ${vars.colors.lightShadow};
  transform: translate3d(calc(-100% - 5px), 0, 0);
  will-change: transform;
  transition: transform .3s cubic-bezier(0.215, 0.610, 0.355, 1.000);
  box-sizing: border-box;
  z-index: 99;

  &.active {
    transform: translate3d(0, 0, 0);
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    list-style: none;
  }
  li {
    margin: 10px 0;
    padding: 0;
    border-left: 3px solid rgba(255,255,255,0.5);
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 50%; left: 0;
      border-left: 5px solid;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      transform: translate3d(-100%, -50%, 0);
      will-change: transform;
      transition: transform .3s ease-out;
    }
    &:after {
      content: '';
      display: block;
    }
    &:hover {
      @media (hover: hover) {
        border-left: 3px solid rgba(255,255,255,0.9);

        &:before {
          transform: translate3d(0, -50%, 0);
        }
        a:after {
          width: 100%;
        }
      }
    }
  }
  a {
    display: block;
    text-decoration: none;
    font-size: 1.2em;
    margin-left: 15px;
    position: relative;

    &:after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      border-radius: 4px;
      background-color: ${vars.colors.offWhite};
      left: 0; bottom: 0;
      will-change: width;
      transition: width .3s ease-out;
    }
  }
`
const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const UserMenuTrigger = styled.span`
  text-decoration: none;
  cursor: pointer;
  user-select: none;

  &.active {
    img {
      transform: rotate(180deg);
    }
  }

  > div {
    will-change: transform;
    transition: transform .3s ease-out;

    &:hover {
      @media (hover: hover) {
        transform: scale(1.08);
      }
    }
  }
  img {
    width: 12px; height: 12px;
    margin: 2px 0 0 10px;
    vertical-align: middle;
    transform: rotate(0);
    will-change: transform;
    transition: transform .3s cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
`
const UserMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 200px;
  background-color: ${vars.colors.white};
  box-shadow: 0 1px 20px ${vars.colors.lightShadow};
  border: 1px solid ${vars.colors.lightGrey};
  transform: translate3d(0, 0, 0);
  opacity: 0.001;
  pointer-events: none;
  z-index: 99;
  will-change: transform, opacity, background-color;
  transition: transform .27s cubic-bezier(0.215, 0.610, 0.355, 1.000), opacity .3s ease-out, background-color .3s ease-out;

  &.active {
    opacity: 0.999;
    transform: translate3d(0, 10px, 0);
    pointer-events: auto;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    &:first-child {
      padding-bottom: 0;
    }
    &:last-child {
      padding-top: 0;

      a {
        font-weight: 300;
      }
    }
  }

  a {
    display: block;
    padding: 10px 12px;
    font-weight: 300;
    font-size: 0.9em;
    color: ${vars.colors.teal};
    text-decoration: none;
    background-color: none;
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${vars.colors.offWhite};
      }
    }
  }
`
const DropSelect = styled(Select)`
  width: 200px;
  max-height: 40px;
  text-align: center;
  cursor: pointer;
`
const HelpButton = styled.button`
  appearance: none;
  font-size: 18px;
  font-weight: 600;
  color: ${vars.colors.teal};
  width: 26px;
  height: 26px;
  margin-right: 20px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  background-color: ${vars.colors.white};
  box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
  will-change: transform;
  transition: transform .3s ease-out;

  &:hover {
    transform: scale(1.08);
  }
`
const UpgradeButton = styled.button`
  appearance: none;
  margin-right: 20px;
  will-change: transform;
  transition: transform .3s ease-out;

  &:hover {
    @media (hover: hover) {
      transform: scale(1.1);
    }
  }
`

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      matchedId: null,
      currentTeam: null,
      logo: null,
      companyName: '',
      selectedOption: null,
      options: []
    }

    this.mainMenuTriggerRef = React.createRef()
    this.mainMenuRef = React.createRef()
    this.mainOverlayRef = React.createRef()
    this.userMenuTriggerRef = React.createRef()
    this.userMenuRef = React.createRef()
    this.userOverlayRef = React.createRef()
  }

  componentDidMount() {
    this.getMatches()
    if (!this.props.user.is_client && this.props.currentTeam) {
      if (this.props.user.is_super_user) {
        if (localStorage.getItem('klipr_team_override')) {
          this.setState({ currentTeam: this.props.currentTeam })
        }
      } else {
        this.setState({ currentTeam: this.props.currentTeam })
      }
    }
  }
  UNSAFE_componentWillReceiveProps() {
    this.getMatches()
  }
  componentDidUpdate() {
    this.toggleMenu()
  }

  getData = matchedId => {
    if (this.props.companies) {
      this.getOptions(this.props.companies, 'All Companies', matchedId)
      if (this.props.user.is_client) {
        this.getCompanyData()
      }
    } else if ((this.props.user.is_super_user && this.props.currentTeam) || (!this.props.user.is_super_user)) {
      this.props.callAPI('companies').then(result => {
        const companies = Array.isArray(result) ? result : [...[], result]
        this.getOptions(companies, 'All Companies', matchedId)
        if (this.props.user.is_client) {
          this.getCompanyData()
        }
      })
    }
  }
  getMatches = () => {
    const getCompany1 = matchPath(this.props.location.pathname, { path: '/*/:company' })
    const getCompany2 = matchPath(this.props.location.pathname, { path: '/campaign/edit/:company/:campaign', exact: true })
    const getCompany3 = matchPath(this.props.location.pathname, { path: '/campaign/:company/:campaign/:page?' })
    const getCompany4 = matchPath(this.props.location.pathname, { path: '/lookbook/:company/:campaign', exact: true })
    let matchedCompany = null

    if (getCompany1?.params.company && !getCompany2 && !getCompany3) {
      matchedCompany = getCompany1.params.company
    }
    if (getCompany2?.params.company) {
      matchedCompany = getCompany2.params.company
    }
    if (getCompany3?.params.company && !getCompany2) {
      matchedCompany = getCompany3.params.company
    }
    if (getCompany4?.params.company) {
      matchedCompany = getCompany4.params.company
    }

    this.getData(matchedCompany)
    this.setState({ matchedId: matchedCompany })
  }
  getCompanyData = () => {
    if (this.state.matchedId && this.props.companies) {
      const company = this.props.companies.find(cn => cn.id === parseInt(this.state.matchedId, 10))
      if (company) {
        this.setState({
          logo: company.image,
          companyName: company.name
        })
      }
    }
  }
  getOptions = (data, label, matchedId) => {
    const formattedData = data ? data.map(entry => ({ value: entry.id, label: entry.name })) : []
    const orderedData = formattedData.sort((x, y) => x.label.localeCompare(y.label))
    const options = [{ value: '', label }, ...orderedData]
    const selectedOption = options.length ? options.find(entry => +entry.value === +matchedId) : null
    this.setState({ options, selectedOption })
  }
  toggleMenu = menu => {
    if (menu) {
      if (menu === 'main') {
        this.mainMenuTriggerRef.current.classList.toggle('active')
        this.mainOverlayRef.current.classList.toggle('active')
        this.mainMenuRef.current.classList.toggle('active')
      } else {
        this.userMenuTriggerRef.current.classList.toggle('active')
        this.userOverlayRef.current.classList.toggle('active')
        this.userMenuRef.current.classList.toggle('active')
      }
    } else if (this.mainMenuTriggerRef.current) {
      this.mainMenuTriggerRef.current.classList.remove('active')
      this.mainOverlayRef.current.classList.remove('active')
      this.mainMenuRef.current.classList.remove('active')
      this.userMenuTriggerRef.current.classList.remove('active')
      this.userOverlayRef.current.classList.remove('active')
      this.userMenuRef.current.classList.remove('active')
    }
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption })

    const path = selectedOption.label === 'All Companies' ? 'companies' : 'campaigns'
    this.props.history.push(`/${path}/${selectedOption.value}`)
  }

  render() {
    const dropdownStyles = {
      control: styles => ({
        ...styles,
        color: vars.colors.white,
        backgroundColor: 'transparent',
        border: 'none !important',
        boxShadow: 'none',
        cursor: 'pointer',
        willChange: 'transform',
        transition: 'transform .3s ease-out',
        '&:hover': { transform: 'scale(1.03)' }
      }),
      placeholder: styles => ({ ...styles, color: vars.colors.white }),
      indicatorSeparator: () => ({ display: 'none' }),
      singleValue: styles => ({ ...styles, color: vars.colors.white }),
      dropdownIndicator: styles => ({ ...styles, color: `${vars.colors.white} !important` }),
      option: (styles, { isSelected, isFocused }) => ({
        ...styles,
        backgroundColor: isSelected ? vars.colors.teal : isFocused ? vars.colors.offWhite : vars.colors.white,
        color: isSelected ? vars.colors.white : vars.colors.teal,
        fontweight: 300,
        cursor: 'pointer',
        textAlign: 'left',
        willChange: 'background-color',
        transition: 'background-color .3s ease-out'
      }),
      input: styles => ({ ...styles, color: vars.colors.white }),
      menu: styles => ({
        ...styles,
        width: '300px',
        borderRadius: 0,
        border: `solid 1px ${vars.colors.lightGrey}`,
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: `0 3px 10px ${vars.colors.lightShadow}`
      }),
      menuList: styles => ({ ...styles, padding: 0 }),
      noOptionsMessage: styles => ({
        ...styles,
        color: vars.colors.teal,
        fontweight: 300,
        textAlign: 'left'
      })
    }
    const { user } = this.props
    const { currentTeam } = this.state

    return (
      <Wrapper>
        {!user.is_client ? (
          <div>
            <MainMenuTrigger id='mainMenuTrigger' ref={this.mainMenuTriggerRef} onClick={() => this.toggleMenu('main')}>
              <span/>
            </MainMenuTrigger>
            <Overlay ref={this.mainOverlayRef} onClick={() => this.toggleMenu('main')}/>
            <MainMenu id='mainMenu' ref={this.mainMenuRef}>
              {user.is_super_user ? (
                <div>
                  {currentTeam && (
                    <AvatarWrapper>
                      <Avatar image={currentTeam.avatar ? this.state.logo : null} name={currentTeam.name}/>
                      <span className='name'>{currentTeam.name}</span>
                    </AvatarWrapper>
                  )}
                </div>
              ) : (
                <AvatarWrapper>
                  <Avatar image={this.state.user?.avatar ? this.state.logo : null} name={`${user.first_name} ${user.last_name}`}/>
                  <p className='name'>{`${user.first_name}${currentTeam ? ` (${currentTeam.name})` : ''}`}</p>
                </AvatarWrapper>
              )}
              <ul>
                {/* <li><Link to='/'>Dashboard</Link></li> */}
                {user.is_super_user &&
                  <li><Link to='/teams'>{`${currentTeam ? 'Change' : 'Select'} Team`}</Link></li>
                }
                {((user.is_super_user && currentTeam) || !user.is_super_user) &&
                  <li><Link to='/companies'>Companies</Link></li>
                }
                {((user.is_super_user && currentTeam) || !user.is_super_user) &&
                  <li><Link to='/campaigns'>Campaigns</Link></li>
                }
                {((user.is_super_user && currentTeam) || !user.is_super_user) &&
                  <li><Link to='/coverage'>Coverage Database</Link></li>
                }
                {(user.is_super_user || user.is_team_admin) && <li><Link to='/users'>{`${user.is_super_user && !currentTeam ? 'Super ' : ''}Users`}</Link></li>}
                {((user.is_super_user && currentTeam) || !user.is_super_user) &&
                  <li><Link to='/plan'>Plan</Link></li>
                }
                {currentTeam?.tool_access_journalist_finder && (
                  <li><Link to='/journalist-email-finder'>Journalist Email Finder</Link></li>
                )}
                {currentTeam?.tool_access_search_database && (
                  <li><Link to='/search-database'>Search Database</Link></li>
                )}
                <li><a href='https://docs.google.com/document/d/1k3aZ7mIOWcf_0aD917YJP8IZ_MUiZC8wPADC_RWWHLo/edit?usp=sharing' target='_blank' rel='noopener noreferrer'>Changelog</a></li>
              </ul>
            </MainMenu>
          </div>
        ) : (
          <div>
            {/* <a href='https://docs.google.com/document/d/1k3aZ7mIOWcf_0aD917YJP8IZ_MUiZC8wPADC_RWWHLo/edit?usp=sharing' target='_blank' rel='noopener noreferrer'>Changelog</a> */}
          </div>
        )}

        {((user.is_super_user && currentTeam) || (!user.is_super_user && !user.is_client)) &&
          <DropSelect styles={dropdownStyles} value={this.state.selectedOption} onChange={this.handleChange} options={this.state.options}/>
        }
        {user.is_client && (
          <AvatarWrapper id='companyList'>
            <Avatar image={this.state.logo} name={this.state.companyName}/>
            <p className='name'>{this.state.companyName}</p>
          </AvatarWrapper>
        )}

        {!user.is_client ? (
          <FlexWrapper>
            {currentTeam?.type === 'free' && (
              <UpgradeButton>
                <a href='https://klipr.io/pricing/' target='_blank' rel='noreferrer noopener'>Upgrade</a>
              </UpgradeButton>
            )}
            {this.props.showHelp &&
              <HelpButton onClick={this.props.startTour}>?</HelpButton>
            }
            <UserMenuTrigger id='userMenuTrigger' ref={this.userMenuTriggerRef} onClick={() => this.toggleMenu('user')}>
              {user.avatar?.length ? (
                <AvatarWrapper>
                  <Avatar image={user.avatar} name={`${user.first_name} ${user.last_name}`}/>
                  <img src={vars.icons.downChevron} alt='Show/hide user menu'/>
                </AvatarWrapper>
              ) : (
                <AvatarWrapper>
                  <Avatar name={`${user.first_name} ${user.last_name}`}/>
                  <img src={vars.icons.downChevron} alt='Show/hide user menu'/>
                </AvatarWrapper>
              )}
            </UserMenuTrigger>
            <Overlay ref={this.userOverlayRef} onClick={() => this.toggleMenu('user')}/>
            <UserMenu id='userMenu' ref={this.userMenuRef}>
              <ul>
                <li><Link to={`/user/${user.id}`}>Edit profile</Link></li>
                <li><Link to='/login' onClick={() => this.props.logout('full')}>Logout</Link></li>
              </ul>
            </UserMenu>
          </FlexWrapper>
        ) : (
          <div>
            <Link to='/login' onClick={() => this.props.logout('full')}>Logout</Link>
          </div>
        )}
      </Wrapper>
    )
  }
}

export default withRouter(Header)
