import React from 'react'
import { Redirect, withRouter } from 'react-router'
import AddTeam from '../pages/addTeam'

class NewTeam extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      team: {
        name: '',
        email: '',
        logo: '',
        max_clients: '',
        max_campaigns: '',
        max_urls: '',
        type: 'free',
        tool_access_journalist_finder: false,
        allowance_unlimited: true,
        initial_allowance_date_start: '',
        allowance_date_start: new Date().toISOString().split('T')[0],
        initial_allowance_date_end: '',
        allowance_date_end: '',
        initial_allowance_period_duration: '',
        allowance_period_duration: '',
        loading: false,
        title: this.props.match.params.team ? 'Edit Team' : 'Add Team'
      }
    }
  }

  componentDidMount() {
    if (this.props.match.params.team) {
      this.getTeam(this.props.match.params.team)
    } else {
      this.props.setLoading(false)
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.team && this.props.match.params.team !== nextProps.match.params.team) {
      this.getTeam(nextProps.match.params.team)
    }

    if (this.props.match.path === '/edit-team/:team' && nextProps.match.path === '/add-team') {
      const { team } = this.state
      this.clearFields()
      team.title = 'Add Team'
      this.setState({ team })
    }
  }

  getTeam = id => {
    const team = { ...this.state.team }
    this.props.callAPI(`teams/${id}`).then(result => {
      team.id = result.id
      team.name = result.name
      team.email = result.email
      team.type = result.type
      team.max_clients = result.companies_max
      team.max_campaigns = result.campaign_max
      team.logo = result.logo
      team.max_urls = result.url_max
      team.title = 'Edit Team'
      const startDate = result.allowance_date_start ? result.allowance_date_start.split(' ')[0] : ''
      const endDate = result.allowance_date_end ? result.allowance_date_end.split(' ')[0] : ''
      const duration = result.allowance_period_duration ? result.allowance_period_duration : ''
      team.tool_access_journalist_finder = result.tool_access_journalist_finder
      team.allowance_unlimited = result.allowance_unlimited
      team.initial_allowance_date_start = startDate
      team.allowance_date_start = startDate
      team.initial_allowance_date_end = endDate
      team.allowance_date_end = endDate
      team.initial_allowance_period_duration = duration
      team.allowance_period_duration = duration

      this.clearFields()
      this.setState({ team }, () => this.props.setLoading(false))
    })
  }
  addTeam = e => {
    e.preventDefault()

    const { team } = this.state
    team.loading = true
    this.setState({ team })

    const dateStart = team.allowance_date_start.length ? `${team.allowance_date_start} 00:00:00` : null
    const dateEnd = team.allowance_date_end.length ? `${team.allowance_date_end} 23:59:59` : null
    const period = team.allowance_period_duration.length ? team.allowance_period_duration : null

    const payload = {
      name: team.name,
      logo: team.logo,
      email: team.email,
      type: team.type,
      max_clients: team.max_clients,
      max_campaigns: team.max_campaigns,
      max_urls: dateStart && team.max_urls ? team.max_urls : 0,
      tool_access_journalist_finder: team.tool_access_journalist_finder,
      allowance_unlimited: team.allowance_unlimited,
      allowance_date_start: dateStart,
      allowance_date_end: dateEnd,
      allowance_period_duration: period,
      allowance_period_changed: this.checkIfChangedAllowance(),
      active: true
    }

    const path = this.props.match.params.team ? 'teams/' : 'teams/new'
    const method = this.props.match.params.team ? 'put' : 'post'
    const id = this.props.match.params.team

    this.props.callAPI(path, id, method, JSON.stringify(payload)).then(result => {
      team.loading = false
      this.setState({ team })

      if (result.success) {
        if (method === 'post') {
          this.props.toast.success(`Team ${team.name} was successfully added.`)
          this.props.setCurrentTeamId(result.team_id)
          setTimeout(() => this.props.history.push('/companies'), 250)
        } else {
          this.props.toast.success('Team updated successfully.')
          this.updateInitialAllowance()
        }
      }
    })
  }
  checkIfChangedAllowance = () => {
    const { team } = this.state
    return team.allowance_date_start !== team.initial_allowance_date_start || team.allowance_date_end !== team.initial_allowance_date_end || team.allowance_period_duration !== team.initial_allowance_period_duration
  }
  updateInitialAllowance = () => {
    const { team } = this.state
    team.initial_allowance_date_start = team.allowance_date_start
    team.initial_allowance_date_end = team.allowance_date_end
    team.initial_allowance_period_duration = team.allowance_period_duration
    this.setState({ team })
  }
  clearFields = () => {
    const { team } = this.state
    team.name = ''
    team.logo = ''
    team.email = ''
    team.type = 'free'
    team.max_clients = ''
    team.max_campaigns = ''
    team.max_urls = ''
    team.tool_access_journalist_finder = false
    team.allowance_unlimited = true
    team.allowance_date_start = ''
    team.allowance_date_end = ''
    team.allowance_period_duration = ''
    team.active = false
    this.setState({ team })
  }
  changeTeam = e => {
    const field = e.target.name
    const { team } = this.state
    const value = e.target.type === 'checkbox' ? e.target.name === 'allowance_unlimited' ? !e.target.checked : e.target.checked : e.target.value
    team[field] = value
    this.setState({ team })
  }
  previewFile = e => {
    const reader = new FileReader()
    const { team } = this.state
    const file = e.target.files[0]

    if (file) {
      const size = ((file.size / 1024) / 1024).toFixed(4)
      if (size < 0.5) {
        reader.addEventListener('load', () => {
          team.logo = reader.result.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '')
          this.setState({ team })
        }, false)
        reader.readAsDataURL(file)
      } else {
        this.props.toast.error('The file is too big, please try again with an image smaller than 500kb.')
      }
    }
  }

  render() {
    if (+this.props.match.params.team === 1) {
      return <Redirect to='/teams'/>
    }

    if (this.props.match.path.includes('/edit-team') || this.props.match.path === '/add-team') {
      return <AddTeam onSubmit={this.addTeam} onChange={this.changeTeam} preview={this.previewFile} team={this.state.team} match={this.props.match}/>
    }
  }
}

export default withRouter(NewTeam)
