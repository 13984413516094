import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import * as vars from '../assets/vars'

const Wrapper = styled.form`
  margin: 18px 0 0;
  display: flex;
  flex-wrap: ${({ isInline }) => (isInline ? 'nowrap' : 'wrap')};
  justify-content: space-between;
  align-items: center;

  fieldset {
    width: 100%;
    margin: 0 0 28px;
    position: relative;

    &.fullWidth {
      margin-left: 0 !important;

      fieldset {
        display: inline-block;
        vertical-align: middle;

        &:last-of-type {
          margin-left: 40px;
        }
      }
    }
    &.halfWidth {
      @media (min-width: 768px) {
        width: calc(50% - 20px);
      }
    }
  }

  label {
    display: block;
    color: ${vars.colors.grey};
    margin-bottom: 12px;
  }
  input, select, textarea {
    display: block;
    width: ${({ isInline }) => (isInline ? 'calc(100% - 100px)' : '100%')};
    margin: 0;
    padding: ${({ isInline }) => (isInline ? '11px 15px' : '18px 24px')};
    color: ${vars.colors.teal};
    box-sizing: border-box;
    outline: none;
    border: none;
    border-radius: 0;
    background-color: ${vars.colors.white} !important;
    box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
    will-change: box-shadow;
    transition: box-shadow .3s ease-out;

    &:hover {
      @media (hover: hover) {
        box-shadow: 0px 1px 3px ${vars.colors.lightShadow};
      }
    }
    &:disabled {
      background-color: ${vars.colors.offWhite} !important;

      &:hover {
        @media (hover: hover) {
          box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
        }
      }
    }
  }
  input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  textarea {
    min-height: ${({ isInline }) => (isInline ? 'auto' : '130px')};
  }

  button {
    display: block;
    width: ${({ isInline }) => (isInline ? '150px' : '100%')};
    height: ${({ isInline }) => (isInline ? '40px' : '60px')};
    line-height: ${({ isInline }) => (isInline ? '40px' : '60px')};
    max-width: 390px;
    margin: ${({ isInline }) => (isInline ? '0 0 0 20px' : '32px auto 0')};
    padding: ${({ isInline }) => (isInline ? '0 5px' : '0 15px')};
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-size: ${({ isInline }) => (isInline ? '1.1em' : '1.3em')};
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:focus {
      outline: 0;
    }
    &:disabled {
      cursor: auto;
      background-color: ${vars.colors.grey};
    }
    span {
      display: inline-block;
      width: 18px; height: 18px;
      margin-right: 8px;
      position: relative;
      top: 3px;
      background-image: url(${vars.icons.searchWhite});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
`

const Form = ({ action, onSubmit, children, isInline }) => (
  <Wrapper action={action} onSubmit={onSubmit} isInline={isInline}>
    {children}
  </Wrapper>
)

export default Form
