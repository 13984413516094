import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import { toast } from 'react-toastify'
import Loader from '../components/loader'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  width: 100%;
  text-align: center;

  span {
    color: ${vars.colors.green};
    font-weight: 600;
  }
`
const InnerWrapper = styled.div`
  margin-top: 100px;
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1.4em;
  font-weight: 600;
  padding: 20px 0px;
  background-color: ${vars.colors.teal};

  a {
    color: ${vars.colors.white};
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;
    transition: transform .3s ease-out;

    &:hover {
      @media (hover: hover) {
        transform: scale(1.05);
      }
    }
  }
  span {
    font-weight: 300;
  }
  img {
    width: 55px;
    margin-right: 14px;
  }
`
const Reasons = styled.ul`
  margin: 20px 0;
  padding: 0;
  list-style-type: none;

  p {
    margin: 0;
  }
`
const Contact = styled.p`
  font-size: 18px;

  a {
    color: ${vars.colors.blue};
    font-weight: 300;
    font-size: 20px;
    text-decoration: none;
    will-change: color;
    transition: color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        color: ${vars.colors.black};
        text-decoration: underline;
      }
    }
  }
`
const ResetForm = styled.form`
  margin: 0 auto 30px;

  input {
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 26px;
    outline: none;
    border: 0;
    font-size: 1.2em;
    background-color: ${vars.colors.white};
    box-sizing: border-box;
  }

  button {
    display: block;
    width: 80%;
    max-width: 400px;
    height: 60px;
    line-height: 60px;
    margin: 32px auto 0;
    padding: 0 15px;
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-size: 1.3em;
    font-weight: 600;
    background-color: ${vars.colors.green};
    text-align: center;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    will-change: background-color, color;
    transition: background-color .3s ease-out, color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:disabled {
      background-color: ${vars.colors.lightGrey};
      color: ${vars.colors.darkGrey};
      cursor: auto;
    }

    > div {
      height: 80%;
    }
  }
`
const HiddenInput = styled.input`
  display: none !important;
`
const PasswordMeterWrapper = styled.div`
  position: relative;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    bottom: 0; left: 0;
    height: 4px;
    width: ${props => `${props.strength * (100 / 3)}%`};
    background-color: ${props => (props.strength > 2 ? vars.colors.green : props.strength > 1 ? vars.colors.amber : vars.colors.red)};
    will-change: width, color;
    transition: width .3s ease-out, color .3s ease-out;
  }
`
const PasswordEqualWrapper = styled.div`
  position: relative;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;

  &:after {
    content: '';
    position: absolute;
    bottom: 0; left: 0;
    width: 100%; height: 4px;
    background-color: ${props => (props.equal ? vars.colors.green : vars.colors.red)};
    will-change: color;
    transition: color .3s ease-out;
  }
`
const Back = styled(Link)`
  display: table;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 600;
  color: ${vars.colors.black};
  will-change: color;
  transition: color .3s ease-out;

  &:hover {
    @media (hover: hover) {
      color: ${vars.colors.blue};
    }
  }
`

class PasswordReset extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      username: '',
      pword1: '',
      pword2: '',
      passwordStrength: 0,
      passwordsEqual: false,
      loading: true,
      testPassed: false,
      resetting: false
    }
  }

  componentDidMount() {
    this.testRequest().then(result => {
      if (result.success) {
        this.setState({ loading: false, testPassed: true, username: result.username })
      } else {
        this.setState({ loading: false, testPassed: false })
      }
    })
  }

  handleResponse = response => {
    if (!response.ok) {
      this.setState({ loading: false })
      const error = new Error(response.statusText)
      toast.error('There was an error while updating your password. Please try again.')
      error.response = response
      throw error
    }

    return response.json().then(json => {
      if (response.ok) {
        return json
      }
      return Promise.reject({
        ...json,
        status: response.status,
        statusText: response.statusText
      })
    })
  }
  testRequest = () => fetch(`${__API__}forgotten-password/test/${this.props.match.params.token}`, { method: 'get' })
    .then(this.handleResponse)
    .then(result => result)
    .catch(error => {
      console.error(error)
      return error
    })
  resetRequest = () => {
    const payload = {
      method: 'post',
      body: JSON.stringify({ password: this.state.pword1 }),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    }
    return fetch(`${__API__}forgotten-password/reset/${this.props.match.params.token}`, payload)
      .then(this.handleResponse)
      .then(result => result)
      .catch(error => {
        console.error(error)
        return error
      })
  }
  update = e => {
    e.preventDefault()

    const { pword1, pword2 } = this.state
    if (pword1 && pword2) {
      if (pword1 !== pword2) {
        toast.warn('Passwords not matching.')
      } else {
        this.setState({ resetting: true })
        this.resetRequest().then(result => {
          if (result.success) {
            toast.success('Your password has been updated.')
            this.setState({ resetting: false })
            setTimeout(() => this.props.history.push('/login'), 1000)
          }
        })
      }
    }
  }
  checkPassword = () => {
    const password = this.state.pword1
    const hasSpecial = new RegExp(/[^A-Za-z0-9]/).test(password)
    const hasNumber = new RegExp(/[0-9]/).test(password)
    const hasMixed = new RegExp(/[a-z]/).test(password) && new RegExp(/[A-Z]/).test(password)

    let passwordStrength = 0
    if (password.length >= 5) passwordStrength += 1
    if (password.length >= 8 && hasSpecial) passwordStrength += 1
    if (password.length >= 8 && hasSpecial && (hasNumber || hasMixed)) passwordStrength += 1

    this.setState({ passwordStrength }, () => this.checkPasswordsAreEqual())
  }
  checkPasswordsAreEqual = () => {
    this.setState(prevState => {
      const passwordsEqual = (prevState.pword1 === prevState.pword2) && prevState.passwordStrength > 1
      return { passwordsEqual }
    })
  }
  updatePassword = e => {
    const { target } = e
    this.setState({ [target.name]: target.value }, () => {
      if (target.name === 'pword1') {
        this.checkPassword()
      }
      if (target.name === 'pword2') {
        this.checkPasswordsAreEqual()
      }
    })
  }

  render() {
    if (this.state.loading) {
      return <Loader/>
    }

    return (
      <Wrapper>
        <Title>
          <Link to='/'>
            <img src={vars.icons.logo} alt='Klipr logo'/>Klipr.io
          </Link>
        </Title>
        {this.state.testPassed ? (
          <InnerWrapper>
            <h3>Reset your password</h3>
            <ResetForm action='/' onSubmit={this.update}>
              <fieldset>
                <HiddenInput type='username' name='username' defaultValue={this.state.username}/>
                <PasswordMeterWrapper strength={this.state.passwordStrength}>
                  <input type='password' name='pword1' placeholder='New password' onChange={this.updatePassword} required/>
                </PasswordMeterWrapper>
                <PasswordEqualWrapper equal={this.state.passwordsEqual}>
                  <input type='password' name='pword2' placeholder='Confirm new password' onChange={this.updatePassword} required/>
                </PasswordEqualWrapper>
                <p><em>Your password must contain at least 8 characters and at least a character that is not a letter or a number.</em></p>
                {this.state.resetting ? (
                  <button type='button'><Loader small/></button>
                ) : (
                  <button type='submit' disabled={!this.state.pword1 || !this.state.pword2}>Update password</button>
                )}
              </fieldset>
            </ResetForm>
            <Back to='/login'>Back to login screen</Back>
          </InnerWrapper>
        ) : (
          <InnerWrapper>
            <h3>Your password reset link failed due to one of the following reasons:</h3>
            <Reasons>
              <li><p>The link has expired or is invalid</p></li>
              <li><p>The account has been deleted</p></li>
            </Reasons>
            <Contact>Please do not hesitate to contact us if the problem persists <a href='mailto=contact@klipr.io'>contact@klipr.io</a></Contact>
            <Back to='/password-reset'>Request a new reset link</Back>
          </InnerWrapper>
        )}
      </Wrapper>
    )
  }
}

export default withRouter(PasswordReset)
