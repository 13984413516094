import React from 'react'
import { withRouter } from 'react-router'
import AddCampaign from '../pages/addCampaign'

class NewCampaign extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      companies: null,
      domainAsUrl: false,
      campaign: {
        company: this.props.match.params.company ? +this.props.match.params.company : '',
        id: this.props.match.params.campaign ? +this.props.match.params.campaign : '',
        title: '',
        url: '',
        keywords: '',
        loading: false,
        edit: this.props.location.pathname.includes('edit-campaign'),
        fetch_type: 'prefix',
        date_started: new Date().toISOString().split('T')[0],
        follow_link_kpi_target: '',
        buzzstream_id: undefined,
        buzzstream_name: undefined,
        type: undefined,
        states: {
          inactive: false,
          manual: false,
          auto: true
        },
        stateCopy: {
          inactive: 'You can still input URLs manually, but Klipr will not update any values against the URLs.',
          manual: 'The campaign will only be populated with manually added URLs. The Domain Rating and Shared Counts will be automatically updated against the URLs.',
          auto: 'Similar to the Manual mode, except Klipr can automatically populate this campaign with URLs that reference your campaign.'
        }
      }
    }
  }

  componentDidMount() {
    this.getCompanies()
  }

  getCompanies = () => {
    if (this.props.companies) {
      this.setState({ companies: this.props.companies }, () => {
        if (this.props.match.params.campaign) {
          this.getCampaign(+this.props.match.params.campaign)
        } else {
          this.props.setLoading(false)
        }
      })
    } else {
      this.props.callAPI('companies').then(result => {
        this.setState({ companies: result }, () => {
          if (this.props.match.params.campaign) {
            this.getCampaign(+this.props.match.params.campaign)
          } else {
            this.props.setLoading(false)
          }
        })
      })
    }
  }
  getCampaign = campaignId => {
    this.props.callAPI(`campaigns/${campaignId}`).then(result => {
      this.setState(prevState => ({
        campaign: {
          ...prevState.campaign,
          title: result.name,
          url: result.url,
          keywords: result.keywords,
          fetch_type: result.url_fetch_type,
          date_started: result.date_started ? result.date_started.split(' ')[0] : new Date().toISOString().split('T')[0],
          follow_link_kpi_target: result.follow_link_kpi_target,
          states: {
            inactive: !result.is_active,
            manual: result.is_active && !result.auto_populate,
            auto: result.is_active && result.auto_populate
          },
          buzzstream_id: result.buzzstream_id,
          buzzstream_name: result.buzzstream_name,
          type: result.type
        }
      }), () => this.props.setLoading(false))
    })
  }
  checkIfDomainAsUrl = e => {
    e.preventDefault()
    if (this.props.buzzstreamEnabled && !this.state.campaign.buzzstream_id) {
      this.props.toast.error('BuzzStream project is mandatory')
      return
    }
    if (!this.state.campaign.type) {
      this.props.toast.error('Project type is mandatory')
      return
    }
    const parsedUrl = new URL(this.state.campaign.url)
    const domainAsUrl = parsedUrl.pathname.length <= 1
    this.setState({ domainAsUrl })

    if (!domainAsUrl) {
      this.addCampaign()
    }
  }
  approveUrl = () => {
    this.setState({ domainAsUrl: false })
    this.addCampaign()
  }
  unsetApprovalModal = () => {
    this.setState({ domainAsUrl: false })
  }
  addCampaign = () => {
    const { campaign } = this.state
    campaign.loading = true
    this.setState({ campaign })

    const payload = {
      name: campaign.title,
      url: campaign.url.slice(-2).includes('#') ? campaign.url.split('#')[0] : campaign.url,
      company: campaign.company,
      keywords: campaign.keywords,
      is_active: !campaign.states.inactive,
      date_started: `${campaign.date_started} 00:00:00`,
      follow_link_kpi_target: campaign.follow_link_kpi_target,
      auto_populate: campaign.states.auto,
      url_fetch_type: campaign.fetch_type,
      buzzstream_id: campaign.buzzstream_id,
      buzzstream_name: campaign.buzzstream_name,
      type: campaign.type
    }
    const endpoint = campaign.edit ? 'campaigns/' : 'campaigns/new'
    const method = campaign.edit ? 'put' : 'post'
    const id = campaign.edit ? campaign.id : undefined

    this.props.callAPI(endpoint, id, method, JSON.stringify(payload)).then(result => {
      campaign.loading = false
      this.setState({ campaign })

      if (result.success) {
        const campaignId = campaign.edit ? id : result.campaign_id
        if (result.job_batch_id) {
          localStorage.setItem('klipr_job_batch_details', JSON.stringify({ jobId: result.job_batch_id, campaignId }))
        }
        this.props.toast.success(`Campaign ${this.state.campaign.title} was successfully ${campaign.edit ? 'updated' : 'created'}.`)
        this.props.history.push(`/campaign/${this.state.campaign.company}/${campaignId}`)
      }
    })
  }
  changeCampaign = e => {
    const field = e.target.name
    const { campaign } = this.state
    campaign[field] = e.target.value
    this.setState({ campaign })
  }
  changeBuzzstreamCampaign = e => {
    if (!e) return
    const { campaign } = this.state
    campaign.buzzstream_id = e.value
    campaign.buzzstream_name = e.label
    this.setState({ campaign: { ...campaign } })
  }
  changeCampaignType = e => {
    if (!e) return
    const { campaign } = this.state
    campaign.type = e.value
    this.setState({ campaign: { ...campaign } })
  }
  updateCampaignState = state => {
    const states = {
      inactive: false,
      manual: false,
      auto: false
    }
    states[state] = true
    const { campaign } = this.state
    campaign.states = states
    this.setState({ campaign })
  }

  render() {
    return <AddCampaign onSubmit={this.checkIfDomainAsUrl} onChange={this.changeCampaign} approveUrl={this.approveUrl} domainAsUrl={this.state.domainAsUrl} unsetApprovalModal={this.unsetApprovalModal} campaign={this.state.campaign} companies={this.state.companies} history={this.props.history} updateCampaignState={this.updateCampaignState} buzzstreamEnabled={this.props.buzzstreamEnabled} onBuzzstreamChange={this.changeBuzzstreamCampaign} onTypeChange={this.changeCampaignType} callApi={this.props.callAPI}/>
  }
}

export default withRouter(NewCampaign)
