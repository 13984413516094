import React from 'react'
import styled from 'styled-components'
import * as vars from '../assets/vars'

const Input = styled.input`
  width: auto;
  min-width: 200px;
  margin: 0;
  padding: 10px 35px 10px 15px;
  color: ${vars.colors.teal};
  background-image: url(${vars.icons.search});
  background-repeat: no-repeat;
  background-position: calc(100% - 8px);
  background-color: ${vars.colors.offWhite};
  background-size: 16px;
  border: none;
  outline: none;
  box-shadow: 0px 3px 6px ${vars.colors.darkShadow};
  will-change: box-shadow;
  transition: box-shadow .3s ease-out;

  &:hover {
    @media (hover: hover) {
      box-shadow: 0px 1px 3px ${vars.colors.darkShadow};
    }
  }
`

const SearchInput = props => (
  <Input name={props.name} value={props.value} onChange={props.onChange} placeholder={props.placeholder}/>
)

export default SearchInput
