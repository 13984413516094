/* eslint-disable */

import React from 'react'
import { Link } from 'react-router-dom'
import { Column, SortDirection, SortIndicator, defaultTableRowRenderer } from 'react-virtualized'
import styled from 'styled-components'
import moment from 'moment'
import { CSVLink, CSVDownload } from 'react-csv'
import VirtualizedTable from '../components/virtualizedTable'
import OverviewStats from '../components/overviewStats'
import SearchInput from '../components/searchInput'
// import Paginator from '../components/paginator'
import Loader from '../components/loader'
import ApprovalModal from '../components/approvalModal'
import ShareModal from '../components/shareModal'
import * as vars from '../assets/vars'

const AddLink = styled(Link)`
  position: relative;
  padding-left: 25px !important;

  &:before {
    content: '';
    position: absolute;
    top: 50%; left: 0;
    width: 20px; height: 20px;
    transform: translateY(-50%);
    background-image: url(${vars.icons.add});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    will-change: transform;
    transition: transform .3s ease-out;
  }
  &:hover:before {
    @media (hover: hover) {
      transform: translateY(-50%) scale(1.2);
    }
  }
`
const FilterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0 25px;
  font-size: 0.9em;

  @media (max-width: 767px) {
    justify-content: center;
  }

  > a {
    padding: 0;
    font-weight: 600;

    &:hover span {
      @media (hover: hover) {
        transform: scale(1.15);
      }
    }
  }
  > div a {
    margin-right: 30px;
  }
  a {
    text-decoration: none;
  }
`
const NoResults = styled.div`
  margin-top: 50px;
`

const CsvWrapper = styled.div`
  display: flex;
`

const URLCopyBox = styled.div`
  position: relative;
  flex: 0 1 auto;
  display: inline-block;
  margin: 0 15px;
  vertical-align: middle;
  overflow: hidden;

  input {
    position: absolute;
    top: -1000px;
  }
`
const NumberCell = styled.div`
  font-weight: 600;
  color: ${({ color }) => color};
`
const ActionButton = styled.button`
  display: flex;
  right: 30px !important;
  padding: 7px !important;
`
const CompanyUrlButton = styled.button`
  height: 40px;
  width: 160px;
  padding: 5px 15px !important;

  &:disabled {
    pointer-events: none;
  }
  > div {
    height: 100%;
  }
`
const TableHeader = styled.div`
  position: relative;
`
const ScoreInfo = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  right: -10px;
  padding: 0 !important;
  width: 16px; height: 16px;
  background-image: url(${vars.icons.info});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &:after {
    content: 'Klipr score aims to measure the SEO value of a piece of coverage.';
    position: absolute;
    padding: 7px;
    top: -12px; left: -320px;
    width: 300px; height: 28px;
    border-radius: 3px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
    pointer-events: none;
    opacity: 0;
    background-color: ${vars.colors.offWhite};
    will-change: opacity;
    transition: opacity .3s ease-out;
  }
  &:hover:after {
    @media (hover: hover) {
      opacity: 1;
    }
  }
`
const RowLinkWrapper = styled(Link)`
  display: block;
  width: 100%;
  height: ${props => props.style.height}px;

  > div:first-of-type {
    top: auto !important;
  }
`
const CampaignCell = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`
const CampaignType = styled.div`
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 8px;
  background-color: ${props => (props.type === 'auto' ? vars.colors.green : props.type === 'manual' ? vars.colors.amber : vars.colors.red)};

  &:after {
    content: '${props => props.type} campaign';
    position: absolute;
    z-index: 1;
    top: calc(50% - 15px); left: 8px;
    width: 140px; height: 20px;
    line-height: 20px;
    text-align: center;
    line-height: 20px;
    background-color: ${props => (props.type === 'auto' ? vars.colors.green : props.type === 'manual' ? vars.colors.amber : vars.colors.red)};
    color: ${vars.colors.white};
    padding: 5px;
    text-transform: capitalize;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    transition: opacity .3s ease-out;
  }
  &:hover:after {
    @media (hover: hover) {
      pointer-events: auto;
      opacity: 1;
    }
  }
`
const TableSettingsButton = styled.div`
  position: relative;
  width: 20px; height: 20px;
  background-image: url(${vars.icons.cog});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 25px;
  cursor: pointer;
  will-change: transform;
  transition: transform .3s ease-out;

  &:after {
    content: 'Table Settings';
    position: absolute;
    padding: 7px;
    top: -12px; left: -120px;
    width: 100px; height: 30px;
    line-height: 30px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    pointer-events: none;
    background-color: ${vars.colors.offWhite};
    opacity: 0;
    will-change: opacity;
    transition: opacity .3s ease-out;
  }
  &:hover {
    @media (hover: hover) {
      transform: scale(1.1);

      &:after {
        opacity: 1;
      }
    }
  }
`
const NewCoverage = styled.div`
  flex: none !important;
  position: relative;
  top: -1px; left: 5px;
  color: ${vars.colors.white};

  p {
    margin: 0;
    background-color: ${vars.colors.green};
    color: ${vars.colors.white} !important;
    padding: 2px 10px;
    border-radius: 20px;
  }
  span {
    position: absolute;
    top: 50%; left: 0;
    width: 310px;
    text-align: center;
    background-color: ${vars.colors.green};
    color: ${vars.colors.white} !important;
    font-weight: 300 !important;
    font-size: 1em !important;
    text-transform: none !important;
    margin: 0 !important;
    padding: 2px 10px;
    border-radius: 20px;
    opacity: 0;
    will-change: opacity;
    transition: opacity .1s ease-out;
    pointer-events: none;
    transform: translateY(-50%);

    &:hover {
      @media (hover: hover) {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &:hover span {
    @media (hover: hover) {
      opacity: 1;
      pointer-events: auto;
    }
  }
`

class Campaigns extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      companyId: null,
      companyName: null,
      companyUrl: null,
      generatingLink: false,
      showShareModal: false,
      currentPath: null,
      noCampaigns: false,
      campaigns: null,
      csvData: null,
      allCoverageCsvData: null,
      showApproveModal: false,
      rows: null,
      campaignTotals: null,
      search: '',
      // page: 1,
      perPage: 10,
      scrollToIndex: undefined,
      sortBy: 'newest_link_datetime',
      sortDirection: SortDirection.DESC,
      prevSortBy: null,
      newLinksSince: null,
      loadingCsv: false,
      tableSettings: {
        companySettings: props.user.is_client,
        show: false,
        loading: false,
        saving: false,
        initialSort: null,
        defaultSort: null,
        columnLabels: ['Column'],
        columns: null
      }
    }
  }

  componentDidMount() {
    if (this.props.match.params.company) {
      this.getCompanyDetails(+this.props.match.params.company)
    } else {
      this.getTableStructure()
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.company !== nextProps.match.params.company) {
      if (nextProps.match.params.company) {
        this.getCompanyDetails(+nextProps.match.params.company)
      } else {
        this.getTableStructure()
      }
      this.setState({ generatingLink: false, companyUrl: null })
    }
    const { deleteDetails } = nextProps
    if (!deleteDetails.deleting && deleteDetails.success) {
      this.softDelete(deleteDetails.id)
    }
  }
  componentWillUnmount() {
    localStorage.removeItem('klipr_preshare_path')
  }

  getCompanyDetails = companyId => {
    this.props.callAPI(`companies/${companyId}`).then(result => {
      this.setState({ companyName: result.name, companyId }, () => this.getTableStructure())
    })
  }
  getTableStructure = () => {
    const { tableSettings } = this.state
    this.props.callAPI('dataset/campaign_list').then(structure => {
      const fields = structure.fields.filter(field => !field.hidden)
      tableSettings.fields = fields
      tableSettings.columnLabels = [...tableSettings.columnLabels, ...Object.values(structure.settings_labels)]
      this.setState({ tableSettings }, () => this.getTableSettings())
    })
  }
  getTableSettings = () => {
    const { tableSettings, companyId } = this.state
    const payload = (companyId && tableSettings.companySettings) ? { company_id: companyId } : {}
    // exclude legacy metrics:
    const filterColumns = ['avg_trust_flow', 'avg_citation_flow', 'total_shared_count', 'market_country', 'trust_flow', 'citation_flow', 'primary_topical']
    tableSettings.fields = tableSettings.fields.filter(field => !filterColumns.includes(field.field_id))
    const { fields } = tableSettings
    tableSettings.loading = true
    this.setState({ tableSettings })

    this.props.callAPI('dataset/campaign_list/settings', '', 'post', JSON.stringify(payload)).then(settings => {
      for (const setting of filterColumns) {
        delete settings[setting]
      }

      Object.keys(settings).forEach(setting => {
        fields.find(field => field.field_id === setting).settings = settings[setting]
      })
      const defaultField = fields.find(field => field.settings.is_default_sort === 1)
      const sortBy = defaultField ? defaultField.field_id : fields[0].field_id
      tableSettings.initialSort = sortBy
      tableSettings.defaultSort = sortBy

      tableSettings.columns = Object.fromEntries(fields.map(field => (
        [field.field_id, {
          label: field.label,
          show: field.settings.is_hidden ? field.settings.is_hidden === 0 : true,
          sticky: field.settings.is_hidden === undefined
        }]
      )))
      tableSettings.loading = false
      this.setState({ tableSettings, sortBy }, () => this.getCampaigns())
    })
  }
  toggleTableSettings = () => {
    const { tableSettings } = this.state
    tableSettings.show = !tableSettings.show
    this.setState({ tableSettings })
  }
  switchTableSettings = type => {
    const { tableSettings } = this.state
    tableSettings.companySettings = type === 'company'
    this.setState({ tableSettings }, () => this.getTableSettings())
  }
  updateTableSettings = e => {
    const name = e.target.getAttribute('name')
    const { tableSettings } = this.state
    if (e.target.getAttribute('type') === 'checkbox' && !tableSettings.columns[name].sticky) {
      tableSettings.columns[name].show = !this.state.tableSettings.columns[name].show
      tableSettings.defaultSort = (!tableSettings.columns[name].show && tableSettings.defaultSort === name) ? tableSettings.initialSort : tableSettings.defaultSort
    } else {
      tableSettings.defaultSort = name
      this.sort({ sortBy: name, sortDirection: SortDirection.ASC })
    }
    this.setState({ tableSettings }, () => this.updateCsv(this.state.rows))
  }
  saveTableSettings = save => {
    const { tableSettings, companyId } = this.state
    if (save) {
      tableSettings.saving = true
      this.setState({ tableSettings })

      const scope = (companyId && tableSettings.companySettings) ? { company_id: companyId } : {}
      const settings = JSON.parse(JSON.stringify(tableSettings.columns)) // make a deepcopy of settings
      Object.keys(settings).forEach(key => {
        if (!settings[key].sticky) {
          settings[key].is_hidden = settings[key].show ? 0 : 1
        }
        settings[key].is_default_sort = tableSettings.defaultSort === key ? 1 : 0
        delete settings[key].label
        delete settings[key].show
        delete settings[key].sticky
      })
      const payload = { scope, settings }
      this.props.callAPI('dataset/campaign_list/settings', '', 'put', JSON.stringify(payload)).then(response => {
        tableSettings.saving = false
        tableSettings.show = false
        this.setState({ tableSettings })
        if (response.success) {
          this.props.toast.success('Table settings have been updated.')
        }
      })
    } else {
      tableSettings.saving = false
      tableSettings.show = false
      this.setState({ tableSettings })
    }
  }
  getCampaigns = () => {
    const { companyId } = this.state
    if (companyId) {
      this.props.callAPI(`companies/${companyId}/campaigns?domain_rating_filter=${this.props.defaultFilter}`).then(result => {
        if (result.length) {
          this.setData(result)
        } else {
          this.setState({ noCampaigns: true }, () => this.props.setLoading(false))
        }
      })
    } else {
      this.props.callAPI(`campaigns?domain_rating_filter=${this.props.defaultFilter}`).then(result => {
        if (result.length) {
          this.setData(result)
        } else {
          this.setState({ noCampaigns: true }, () => this.props.setLoading(false))
        }
      })
    }
  }
  setData = campaigns => {
    this.props.callAPI('settings/application').then(settings => {
      const rangeSetting = settings.find(x => x.setting_id === 'new_link_day_range')
      const dayOffset = rangeSetting ? rangeSetting.value : 0
      const newLinksSince = dayOffset > 0 ? moment().subtract(dayOffset, 'day').format('MMMM Do YYYY') : null

      const rows = campaigns.map(campaign => ({
        ...campaign,
        campaign_name: campaign.campaign_name,
        id: campaign.id,
        date_started: campaign.date_started ? campaign.date_started.split(' ')[0] : '0',
        newest_link_datetime: campaign.newest_link_datetime ? campaign.newest_link_datetime.split(' ')[0] : '0',
        average_domain_rating: campaign.total_links_count > 0 && (campaign.average_domain_rating === 0 || campaign.average_domain_rating > 0) ? campaign.average_domain_rating : 'TBC',
        total_links_count: campaign.total_links_count,
        new_links_count: campaign.new_links_count,
        follow_links_count: campaign.follow_links_count,
        nofollow_links_count: campaign.nofollow_links_count,
        mention_links_count: campaign.mention_links_count,
        syndicated_links_count: campaign.syndicated_links_count,
        follow_nofollow_links_count: campaign.follow_nofollow_links_count,
        follow_nofollow_syndicated_links_count: campaign.follow_nofollow_syndicated_links_count,
        other_links_count: campaign.other_links_count,
        klipr_score: campaign.total_links_count > 0 && (campaign.klipr_score === 0 || campaign.klipr_score > 0) ? campaign.klipr_score : 'TBC',
        total_shared_count: campaign.total_shared_count === 0 || campaign.total_shared_count > 0 ? campaign.total_shared_count : 'TBC',
        ga_total_sessions_count: campaign.ga_total_sessions_count > 0 ? campaign.ga_total_sessions_count : '–',
        kpi_pct: campaign.follow_link_kpi_pct ? campaign.follow_link_kpi_pct : '–',
        avg_trust_flow: campaign.avg_trust_flow ? campaign.avg_trust_flow.toFixed(2) : '0',
        avg_citation_flow: campaign.avg_citation_flow ? campaign.avg_citation_flow.toFixed(2) : '0',
        type: campaign.type
      }))

      this.setState({ campaigns: rows, rows, newLinksSince }, () => {
        this.sort({ sortBy: this.state.sortBy, sortDirection: SortDirection.DESC })
      })
      this.getCampaignTotals(rows)
    })
  }
  getCampaignTotals = (campaigns, allowLoad = true) => {
    let TBCs = 0
    const total = campaigns.map(c => c.total_links_count).reduce((acc, curr) => acc + curr, 0)
    const newTotal = campaigns.map(c => c.new_links_count).reduce((acc, curr) => acc + curr, 0)
    const follow = campaigns.map(c => c.follow_links_count).reduce((acc, curr) => acc + curr, 0)
    const nofollow = campaigns.map(c => c.nofollow_links_count).reduce((acc, curr) => acc + curr, 0)
    const mention = campaigns.map(c => c.mention_links_count).reduce((acc, curr) => acc + curr, 0)
    const syndicated = campaigns.map(c => c.syndicated_links_count).reduce((acc, curr) => acc + curr, 0)
    const followNofollow = campaigns.map(c => c.follow_nofollow_links_count).reduce((acc, curr) => acc + curr, 0)
    const followNofollowSyndicated = campaigns.map(c => c.follow_nofollow_syndicated_links_count).reduce((acc, curr) => acc + curr, 0)
    const other = campaigns.map(c => c.other_links_count).reduce((acc, curr) => acc + curr, 0)
    const shares = campaigns.map(c => c.total_shared_count).reduce((acc, curr) => acc + (curr !== 'TBC' ? curr : 0), 0)
    const kpiPct = campaigns.filter(c => c.follow_link_kpi_pct).length > 0 ? Math.round(campaigns.map(c => c.follow_link_kpi_pct).reduce((acc, curr) => acc + Math.min(Math.max(curr, 0), 100)) / campaigns.filter(c => c.follow_link_kpi_pct).length) : null
    const kliprScore = campaigns.map(c => c.klipr_score).reduce((acc, curr) => acc + (curr !== 'TBC' ? curr : 0), 0)
    const totalTraffic = campaigns.map(c => c.ga_total_sessions_count).reduce((acc, curr) => acc + (curr !== '–' ? curr : 0), 0)
    const ratingsSum = campaigns.map(c => {
      if (c.average_domain_rating === 'TBC') {
        TBCs += 1
        return 0
      }
      return c.average_domain_rating
    }).reduce((acc, curr) => acc + curr, 0)
    const cAvgTrustFlow = campaigns.map(c => {
      if (!c.avg_trust_flow) {
        return 0.0
      }

      return parseFloat(c.avg_trust_flow)
    }).reduce((acc, curr) => acc + curr, 0.0)
    const cAvgCitationFlow = campaigns.map(c => {
      if (!c.avg_citation_flow) {
        return 0.0
      }

      return parseFloat(c.avg_citation_flow)
    }).reduce((acc, curr) => acc + curr, 0.0)

    // old avgRating was calculating the average of DR averages:
    // const avgRating = Math.round(ratingsSum / campaigns.length - TBCs)
    // it has been change to the avg DR of all the individual campaigns (weighted average):
    const avgRating = campaigns.reduce((currRating, campaign) => {
      if (campaign.average_domain_rating === 'TBC') return currRating
      return currRating + ((campaign.average_domain_rating || 0) * ((campaign.total_links_count || 0) / total))
    }, 0)

    const avgTrustFlow = campaigns.map(c => c.avg_trust_flow).reduce((acc, curr) => acc + curr, 0)
    const avgCitationFlow = campaigns.map(c => c.avg_citation_flow).reduce((acc, curr) => acc + curr, 0)
    const companyAvgTrustFlow = (cAvgTrustFlow / campaigns.length).toFixed(2)
    const companyAvgCitationFlow = (cAvgCitationFlow / campaigns.length).toFixed(2)

    this.updateCsv(campaigns)
    this.setState({
      campaignTotals: {
        total,
        newTotal,
        follow,
        nofollow,
        mention,
        syndicated,
        followNofollow,
        followNofollowSyndicated,
        other,
        avgRating: avgRating > 0 ? avgRating : 'TBC',
        shares,
        kpiPct,
        kliprScore,
        totalTraffic,
        avgTrustFlow,
        avgCitationFlow,
        companyAvgTrustFlow,
        companyAvgCitationFlow
      }
    }, () => {
      if (allowLoad) {
        this.props.setLoading(false)
      }
    })
  }
  updateCsv = data => {
    let csvData = null
    const { tableSettings } = this.state
    if (tableSettings.columns) {
      const columns = Object.keys(tableSettings.columns).filter(column => tableSettings.columns[column].show)
      csvData = data ? data.map(x => {
        const obj = {}
        columns.forEach(column => {
          obj[tableSettings.columns[column].label] = x[column]
        })
        return obj
      }) : null
    }
    this.setState({ csvData })
  }
  setCompanyUrl = () => {
    if (this.state.companyUrl) {
      this.setState({
        showShareModal: true,
        currentPath: this.props.location.pathname
      })
      localStorage.setItem('klipr_preshare_path', this.props.location.pathname)
      window.history.replaceState(window.history.state, '', this.state.companyUrl)
    } else {
      const shortlink = localStorage.getItem('klipr_shortlink') ? localStorage.getItem('klipr_shortlink') : null

      if (shortlink) {
        this.setState({
          generatingLink: false,
          showShareModal: true,
          currentPath: this.props.location.pathname,
          companyUrl: `${__BASEURL__}share/${shortlink}`
        })
        localStorage.setItem('klipr_preshare_path', this.props.location.pathname)
        window.history.replaceState(window.history.state, '', `/share/${shortlink}`)
      } else if (this.state.companyId && !this.props.user.is_client) {
        this.setState({ generatingLink: true })
        this.props.callAPI(`companies/${this.state.companyId}/generateshortlink`).then(result => {
          if (result.shortlink) {
            setTimeout(() => {
              this.setState({
                generatingLink: false,
                showShareModal: true,
                currentPath: this.props.location.pathname,
                companyUrl: `${__BASEURL__}share/${result.shortlink}`
              })
              localStorage.setItem('klipr_preshare_path', this.props.location.pathname)
              window.history.replaceState(window.history.state, '', `/share/${result.shortlink}`)
            }, 500)
          } else {
            this.setState({ generatingLink: false })
          }
        })
      }
    }
  }
  copyCompanyUrl = () => {
    if (this.state.companyUrl) {
      const companyUrl = document.getElementById('companyUrl')
      const copyUrl = document.querySelector('#copyUrl')
      companyUrl.value = this.state.companyUrl
      companyUrl.select()

      try {
        const successful = document.execCommand('copy')
        copyUrl.innerHTML = successful ? 'URL Copied' : 'Try again'
        setTimeout(() => {
          copyUrl.innerHTML = 'Copy URL'
        }, 1500)
      } catch (err) {
        this.props.toast.warn('Unable to copy URL.')
      }
    }
  }
  closeShareModal = () => {
    this.setState({ showShareModal: false })

    if (this.state.currentPath) {
      window.history.replaceState(window.history.state, '', this.state.currentPath)
    }
    localStorage.removeItem('klipr_preshare_path')
  }
  softDelete = id => {
    this.setState(prevState => {
      const campaigns = prevState.campaigns.filter(x => x.id !== id)
      const rows = prevState.rows.filter(x => x.id !== id)
      this.getCampaignTotals(campaigns, false)
      return ({ campaigns, rows })
    })
  }
  sort = ({ sortBy, sortDirection }) => {
    let sortDir = sortDirection
    let sortOptions = ['newest_link_datetime', 'campaign_name', 'total_links_count', 'average_domain_rating']
    sortOptions = sortOptions.filter(item => item !== sortBy)
    sortOptions.unshift(sortBy)

    const prevSort = this.state.prevSortBy
    if (sortBy !== 'campaign_name' && (!prevSort || (prevSort && prevSort !== sortBy))) {
      sortDir = 'DESC'
    }
    const applySort = options => {
      const fields = options.map(option => {
        const cmp = (a, b) => {
          if (a == b) return 0 // eslint-disable-line
          return a < b ? -1 : 1
        }
        const invert = sortDir === SortDirection.DESC || (option !== sortBy && option !== 'campaign_name')
        const cmpFunc = invert ? (a, b) => -1 * cmp(a, b) : cmp
        return { option, cmpFunc }
      })

      return (A, B) => {
        let a, b, name, cmp, result
        for (let i = 0; i < fields.length; i += 1) {
          result = 0
          name = fields[i].option
          a = (A[name] === 'TBC' || A[name] === '–') ? -1 : typeof A[name] === 'string' ? A[name].toLowerCase() : A[name]
          b = (B[name] === 'TBC' || B[name] === '–') ? -1 : typeof B[name] === 'string' ? B[name].toLowerCase() : B[name]
          cmp = fields[i].cmpFunc
          result = cmp(a, b)
          if (result !== 0) break
        }
        return result
      }
    }

    this.setState(prevState => ({
      sortBy,
      sortDirection: sortDir,
      prevSortBy: sortBy,
      rows: [...prevState.rows].sort(applySort(sortOptions))
    }), () => this.updateCsv(this.state.rows))
  }
  updateSearch = e => {
    const search = e.target.value
    this.setState(prevState => ({
      search,
      rows: prevState.campaigns.filter(campaign => campaign.campaign_name.toLowerCase().includes(search.toLowerCase()))
    }))
  }
  handleRowsScroll = ({ stopIndex }) => {
    this.setState(prevState => {
      const page = Math.ceil(stopIndex / prevState.perPage)
      return { page, scrollToIndex: undefined }
    })
  }
  checkUserType = e => {
    if (this.props.currentTeam?.type === 'free') {
      e.preventDefault()
      e.stopPropagation()
      this.setState({ showApproveModal: true })
      return false
    }
    return true
  }
  // handlePageChange = page => {
  //   this.setState(prevState => {
  //     const scrollToIndex = (page - 1) * prevState.perPage
  //     return { page, scrollToIndex }
  //   })
  // }

  headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => (
    <TableHeader>
      {label}{sortBy === dataKey && <SortIndicator sortDirection={sortDirection}/>}
      {label === 'Klipr Score' && <ScoreInfo/>}
    </TableHeader>
  )
  tableSettingsRenderer = () => (
    <TableSettingsButton onClick={this.toggleTableSettings}/>
  )
  campaignCellRenderer = props => (
    <CampaignCell>
      <CampaignType type={props.rowData.is_active ? props.rowData.auto_populate ? 'auto' : 'manual' : 'inactive'}/>
      <p>{props.cellData}</p>
      {props.rowData.new_links_count > 0 && (
        <NewCoverage>
          <p>+{props.rowData.new_links_count}</p>
          {this.state.newLinksSince &&
            <span>{props.rowData.new_links_count} new URL{props.rowData.new_links_count > 1 ? 's' : ''} since {this.state.newLinksSince}</span>
          }
        </NewCoverage>
      )}
    </CampaignCell>
  )
  numberCellRenderer = ({ cellData }) => (
    <NumberCell>{typeof cellData === 'number' ? cellData.toLocaleString() : cellData}</NumberCell>
  )
  colorPrcCellRenderer = ({ cellData }) => (
    <NumberCell color={typeof cellData === 'number' ? (cellData < 50 ? vars.colors.red : cellData < 100 ? vars.colors.amber : vars.colors.green) : vars.colors.teal}>
      {typeof cellData === 'number' ? `${cellData.toLocaleString()}%` : cellData}
    </NumberCell>
  )
  dateCellRenderer = ({ cellData }) => (
    <>
      {cellData !== '0' ? moment(cellData).format('DD MMMM YYYY') : 'N/A'}
    </>
  )
  actionCellRenderer = props => (
    <ActionButton type='button' onClick={e => this.props.delete('campaign', props.cellData, props.rowData.campaign_name, e)}>
      <img src={vars.icons.remove} alt='Delete campaign'/>
    </ActionButton>
  )
  rowRenderer = props => (
    <RowLinkWrapper key={props.rowData.id} style={props.style} to={`/campaign/${props.rowData.company_id}/${props.rowData.id}`}>
      {React.cloneElement(
        defaultTableRowRenderer(props), {
          'data-id': props.rowData.id,
          'data-companyid': props.rowData.company_id
        }
      )}
    </RowLinkWrapper>
  )

  downloadAllCoverage = (e) => {
    if (!this.checkUserType(e)) return
    this.setState({
      loadingCsv: true
    })
    this.props.callAPI(`companies/${this.state.companyId}/links?domain_rating_filter=${this.props.defaultFilter}`).then(result => {
      this.setState({
        loadingCsv: false,
        allCoverageCsvData: result
      })
    }).catch(() => {
      this.setState({
        loadingCsv: false
      })
    })
    this.setState({allCoverageCsvData: null})
  }


  render() {
    const { user, currentTeam } = this.props
    const { rows, csvData, perPage, campaignTotals, newLinksSince, noCampaigns, tableSettings, companyId, companyName, companyUrl, generatingLink, search, scrollToIndex, sortBy, sortDirection, showApproveModal, showShareModal } = this.state
    const headerHeight = 60
    const rowHeight = 70
    const rowCount = rows ? rows.length : 0
    const height = rowHeight * Math.min(perPage, rowCount) + headerHeight
    // const pageCount = Math.ceil(rowCount / perPage)
    const overviewStats = campaignTotals ? [{
      label: 'Total coverage',
      custom: true,
      total: campaignTotals.total,
      newTotal: campaignTotals.newTotal,
      date: newLinksSince
    }, {
      label: 'Follow links',
      value: campaignTotals.follow.toLocaleString()
    }, {
      label: 'Nofollow links',
      value: campaignTotals.nofollow.toLocaleString()
    }, {
      label: 'Mentions',
      value: campaignTotals.mention.toLocaleString(),
      hide: campaignTotals.mention <= 0
    }, {
      label: 'Syndicated Links',
      value: campaignTotals.syndicated.toLocaleString(),
      hide: campaignTotals.syndicated <= 0
    }, {
      label: 'Total Links (F/NF)',
      value: campaignTotals.followNofollow.toLocaleString(),
      hide: campaignTotals.followNofollow <= 0
    }, {
      label: 'Total Links (F/NF/S)',
      value: campaignTotals.followNofollowSyndicated.toLocaleString(),
      hide: campaignTotals.followNofollowSyndicated <= 0
    }, {
      label: 'Other links',
      value: campaignTotals.other.toLocaleString(),
      hide: campaignTotals.other <= 0
    }, {
      label: 'Avg. domain rating',
      value: campaignTotals.avgRating.toLocaleString()
    },
    //   {
    //   label: 'Total shares',
    //   value: typeof campaignTotals.shares === 'number' ? campaignTotals.shares.toLocaleString() : 'TBC'
    // },
    //   {
    //   label: 'Overall KPI Achieved %',
    //   value: `${campaignTotals.kpiPct?.toLocaleString()}%`,
    //   hide: !campaignTotals.kpiPct || user.is_client,
    //   color: campaignTotals.kpiPct < 50 ? vars.colors.red : campaignTotals.kpiPct < 100 ? vars.colors.amber : vars.colors.green
    // },
      {
      label: 'Total Klipr score',
      value: campaignTotals.kliprScore.toLocaleString()
    }, {
      label: 'Total Traffic',
      value: campaignTotals.totalTraffic.toLocaleString(),
      hide: campaignTotals.totalTraffic <= 0
    },
    //   {
    //   label: 'Avg. trust flow',
    //   value: campaignTotals.companyAvgTrustFlow.toLocaleString()
    // },
    //   {
    //   label: 'Avg. citation flow',
    //   value: campaignTotals.companyAvgCitationFlow.toLocaleString()
    // }
    ] : []
    const approveModalContent = '<p>CSV exports are only available to paying customers.</p>'

    return (
      <div>
        <div className='panelHead'>
          <h2>
            {companyId && companyName ? companyName : 'All'} Campaigns
            {companyId && !user.is_client && (
              <Link to={`/company/${companyId}`} className='link'/>
            )}
          </h2>

          {companyId && (
            <URLCopyBox>
              <CompanyUrlButton type='button' className='button' disabled={generatingLink} onClick={this.setCompanyUrl}>
                {generatingLink ? <Loader small/> : (
                  <span>Share & Bookmark</span>
                )}
              </CompanyUrlButton>
              <input id='companyUrl' type='text' defaultValue=''/>
            </URLCopyBox>
          )}

          {!user.is_client && (
            <Link to='/companies' className='link'>
              <img src={vars.icons.arrow} alt='Back to all comapnies'/> all companies
            </Link>
          )}
        </div>
        {campaignTotals && (
            <OverviewStats stats={overviewStats}/>
        )}
        <FilterBar>
          <div>
            {!user.is_client && currentTeam && (!currentTeam.campaignLimitReached || !currentTeam.planEnded) &&
              <AddLink to={`/add-campaign/${!companyId ? '' : companyId}`} className='link addCampaign'>campaign</AddLink>
            }
            {!noCampaigns && (
              <SearchInput name='campSearch' value={search} onChange={this.updateSearch} placeholder='Search campaigns'/>
            )}
          </div>
          <CsvWrapper>
            {csvData &&
              <CSVLink data={csvData} filename={`Klipr_${companyId && companyName ? companyName : 'All'}_Campaigns_${moment(new Date()).format('DD.MM.YYYY')}.csv`} className='button amber' target='_blank' onClick={this.checkUserType}>Export Campaigns</CSVLink>
            }
            {/* <CSVLink */}
            {/*   data={this.state.allCoverageCsvData} */}
            {/*   filename={`Klipr_${companyId && companyName ? companyName : 'All'}_Campaigns_Coverage_${moment(new Date()).format('DD.MM.YYYY')}.csv`} */}
            {/*   className='button amber' */}
            {/*   asyncOnClick={true} */}
            {/*   onClick={this.downloadAllCoverage} */}
            {/* > */}
            {/*   {this.state.loadingCsv ? 'Loading...' : 'Export Coverage'} */}
            {/* </CSVLink> */}
            <button
              className='button amber'
              onClick={this.downloadAllCoverage}
            >
              {this.state.loadingCsv ? 'Loading...' : 'Export Coverage'}
            </button>
            {this.state.allCoverageCsvData &&
              <CSVDownload
                data={this.state.allCoverageCsvData}
                filename={`Klipr_${companyId && companyName ? companyName : 'All'}_Campaigns_Coverage_${moment(new Date()).format('DD.MM.YYYY')}.csv`}
                target='_blank'
              />}
          </CsvWrapper>
          {/* {pageCount > 3 &&
            <Paginator pageCount={pageCount} currentPage={page} onPageChange={this.handlePageChange}/>
          } */}
        </FilterBar>

        {(!noCampaigns && tableSettings.columns) ? (
          <VirtualizedTable
            rowHeight={rowHeight} headerHeight={headerHeight} height={height}
            rowCount={rowCount} rows={rows} onRowsRendered={this.handleRowsScroll}
            clickable scrollToIndex={scrollToIndex} scrollToAlignment='start'
            sort={this.sort} sortBy={sortBy} sortDirection={sortDirection}
            rowRenderer={this.rowRenderer} tableSettings={tableSettings} updateTableSettings={this.updateTableSettings} saveTableSettings={this.saveTableSettings}
            switchTableSettings={this.switchTableSettings} user={user}
            teamName={currentTeam ? currentTeam.name : ''} companyName={companyName}
          >
            <Column label='Campaign' dataKey='campaign_name' width={250} flexGrow={1} headerRenderer={this.headerRenderer} cellRenderer={this.campaignCellRenderer}/>
            {tableSettings.columns.date_started.show &&
              <Column label='Date Started' dataKey='date_started' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.dateCellRenderer}/>
            }
            {tableSettings.columns.type.show &&
              <Column label='Type' dataKey='type' width={70} headerRenderer={this.headerRenderer} />
            }
            {tableSettings.columns.newest_link_datetime.show &&
              <Column label='Date Updated' dataKey='newest_link_datetime' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.dateCellRenderer}/>
            }
            {tableSettings.columns.total_links_count.show &&
              <Column label='Coverage' dataKey='total_links_count' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {tableSettings.columns.follow_links_count.show &&
              <Column label='Follow Links' dataKey='follow_links_count' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {tableSettings.columns.nofollow_links_count.show &&
              <Column label='Nofollow Links' dataKey='nofollow_links_count' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {tableSettings.columns.mention_links_count.show &&
              <Column label='Mentions' dataKey='mention_links_count' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {tableSettings.columns.syndicated_links_count.show &&
              <Column label='Syndicated Links' dataKey='syndicated_links_count' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {tableSettings.columns.follow_nofollow_links_count.show &&
              <Column label='Total Links (F/NF)' dataKey='follow_nofollow_links_count' width={80} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {tableSettings.columns.follow_nofollow_syndicated_links_count.show &&
              <Column label='Total Links (F/NF/S)' dataKey='follow_nofollow_syndicated_links_count' width={80} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {/* {tableSettings.columns.total_shared_count.show && */}
            {/*   <Column label='Total Shares' dataKey='total_shared_count' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/> */}
            {/* } */}
            {tableSettings.columns.average_domain_rating.show &&
              <Column label='Avg. Domain Rating' dataKey='average_domain_rating' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {tableSettings.columns.klipr_score.show &&
              <Column label='Klipr Score' dataKey='klipr_score' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {tableSettings.columns.ga_total_sessions_count.show &&
              <Column label='Google Analytics Traffic' dataKey='ga_total_sessions_count' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            }
            {/* {tableSettings.columns.follow_link_kpi_pct.show && !user.is_client && */}
            {/*   <Column label='KPI Achieved %' dataKey='kpi_pct' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.colorPrcCellRenderer}/> */}
            {/* } */}
            {/* {tableSettings.columns.avg_trust_flow.show && */}
            {/*   <Column label='Avg. Trust Flow' dataKey='avg_trust_flow' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/> */}
            {/* } */}
            {/* {tableSettings.columns.avg_citation_flow.show && */}
            {/*   <Column label='Avg. Citation Flow' dataKey='avg_citation_flow' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/> */}
            {/* } */}
            {!user.is_client &&
              <Column label='' dataKey='id' width={70} disableSort headerRenderer={this.tableSettingsRenderer} cellRenderer={this.actionCellRenderer}/>
            }
          </VirtualizedTable>
        ) : (
          <NoResults>There are currently no campaigns</NoResults>
        )}
        <ApprovalModal show={showApproveModal} content={approveModalContent} approveAction={() => window.open('https://klipr.io/pricing/', '_blank')} closeAction={() => this.setState({ showApproveModal: false })} approveButtonText='Upgrade Now' closeButtonText='Close'/>
        <ShareModal url={companyUrl} show={showShareModal} copy={this.copyCompanyUrl} close={this.closeShareModal}/>
      </div>
    )
  }
}

export default Campaigns
