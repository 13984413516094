import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  text-align: center;
  flex-direction: column;

  h3 {
    font-size: 2em;
  }
  > a {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    max-width: 390px;
    margin: 32px auto 0;
    padding: 0 15px;
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-size: 1.3em;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:active {
      top: 2px;
    }
    &:focus {
      outline: 0;
    }
    &:disabled {
      cursor: auto;
    }
  }
`
const NotFound = ({ setLoading }) => {
  useEffect(() => setLoading(false), [setLoading])

  return (
    <Wrapper>
      <h3>Page not found</h3>
      <p>Sorry, this page does not exist.</p>
      <Link to='/'>Home</Link>
    </Wrapper>
  )
}

export default NotFound
