import React, { useState, useEffect } from 'react'
import { Table, AutoSizer } from 'react-virtualized'
import styled from 'styled-components'
import { lighten, darken } from 'polished'
import posed from 'react-pose'
import Loader from './loader'
import Toggle from './toggle'
import * as vars from '../assets/vars'

const TableWrapper = styled.div`
  .ReactVirtualized__Table {
  }
  .ReactVirtualized__Table__Grid {
    outline: none;
  }
  .ReactVirtualized__Table__headerColumn {
    outline: none;
    margin-right: 10px;
    will-change: color;
    transition: color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background: transparent;
        text-decoration: underline;
      }
    }
    &:first-of-type {
      margin-left: 10px;
    }
    &[aria-sort='ascending'], &[aria-sort='descending'] {
      color: ${vars.colors.green};
    }
  }
  .ReactVirtualized__Table__headerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 !important;
    line-height: 1;
    text-align: ${({ leftAligned }) => (leftAligned ? 'left' : 'center')};
    color: ${vars.colors.grey};
    border-top: none !important;
    border-bottom: 1px solid ${vars.colors.lightGrey};

    &:hover {
      @media (hover: hover) {
        background: transparent;
      }
    }
  }
  .ReactVirtualized__Table__sortableHeaderColumn {
    cursor: pointer;
    user-select: none;
    padding: 0 !important;
    text-align: ${({ leftAligned }) => (leftAligned ? 'left' : 'center')};
    outline: none;

    &:first-of-type {
      text-align: left;
    }
  }
  .ReactVirtualized__Table__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: ${props => (props.clickable ? 'pointer' : 'auto')};
    outline: none;
    justify-content: space-around;
    padding-right: 35px;
    text-decoration: none;
    will-change: background-color;
    transition: background-color .3s ease-out;
    position: relative;
    color: ${vars.colors.teal};

    &.new {
      background-color: ${lighten(0.5, vars.colors.green)};
    }
    &:hover {
      @media (hover: hover) {
        background-color: ${vars.colors.offWhite};
      }
    }

    @media (min-width: 768px) {
      flex-wrap: nowrap;
      padding-right: 65px;
    }

    p {
      margin: 0;
    }
    button {
      position: absolute;
      top: 50%;
      right: 10px;
      cursor: pointer;
      transform: translateY(-50%);
      padding: 0;
      border: 0;
      outline: 0;
      background-color: transparent;
      will-change: transform;
      transition: transform .3s ease-out;

      &:hover {
        @media (hover: hover) {
          transform: translateY(-50%) scale(1.1);
        }
      }
      img {
        width: 18px;
      }
    }
  }
  .ReactVirtualized__Table__rowColumn {
    margin-right: 10px;
    min-width: 0px;
    padding: 0 !important;
    text-align: ${({ leftAligned }) => (leftAligned ? 'left' : 'center')};
    outline: none;
    overflow: visible !important;

    &:first-of-type {
      text-align: left;
      margin-left: 10px;
    }
    a {
      line-height: 1.1;
    }
  }
  .ReactVirtualized__Table__sortableHeaderColumn {
  }
  .ReactVirtualized__Table__sortableHeaderIcon {
    position: absolute;
    flex: 0 0 24px;
    height: 1em; width: 1em;
    fill: currentColor;
    margin-left: -2px;
  }
`
const AnimatedWrapper = posed.div({
  show: { opacity: 1 },
  hide: { opacity: 0 }
})
const TableSettings = styled(AnimatedWrapper)`
  width: 100%; height: 100%;
  position: fixed;
  top: 0; left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => (props.pose === 'show' ? 'auto' : 'none !important')};

  * {
    pointer-events: ${props => (props.pose === 'show' ? 'auto' : 'none !important')};
  }
`
const SettingsOverlay = styled.div`
  background-color: ${vars.colors.darkShadow};
  width: 100%; height: 100%;
  position: absolute;
  top: 0; left: 0;
  z-index: -1;
`
const SettingsWrapper = styled.div`
  position: relative;
  width: 80%;
  max-width: 300px;
  background-color: ${vars.colors.teal};
  color: ${vars.colors.white};
  padding: 30px 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
  }
`
const SettingsHeader = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;

  &:after {
    content: '';
    width: 100%; height: 2px;
    position: absolute;
    left: 0; bottom: -8px;
    background-color: ${vars.colors.green};
  }
  h3 {
    font-weight: 300;
    margin: 0;
    padding-right: 25px;
    position: relative;

    &:after {
      content: '';
      display: ${props => (props.showDrop ? 'block' : 'none')};
      position: absolute;
      top: 50%; right: 3px;
      width: 20px; height: 20px;
      background-image: url(${vars.icons.downChevron});
      background-repeat: no-repeat;
      background-position: center;
      background-size: 14px;
      transform: translateY(-50%);
      will-change: transform;
      transition: transform .3s ease-out;
    }

    &:hover:after {
      transform: translateY(-50%) scale(1.2);
    }
  }
`
const HeaderExplainer = styled.span`
  width: 100%;
  font-size: 14px;
  margin-bottom: 20px;
`
const SettingsDropdown = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;

  div {
    position: absolute;
    z-index: 1;
    width: calc(100% - 20px);
    top: calc(100% + 6px);
    left: 10px;
    opacity: ${props => (props.open ? 1 : 0)};
    pointer-events: ${props => (props.open ? 'auto' : 'none')};
    will-change: opacity;
    transition: opacity .3s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    span {
      width: 100%;
      padding: 10px;
      will-change: color, background-color;
      transition: color .3s ease-out, background-color .3s ease-out;
      pointer-events: ${props => (props.open ? 'auto' : 'none')};

      &:first-of-type {
        background-color: ${props => (!props.companyActive ? `${vars.colors.green} !important` : vars.colors.white)};
        color: ${props => (!props.companyActive ? `${vars.colors.white} !important` : vars.colors.teal)};
      }
      &:last-of-type {
        background-color: ${props => (props.companyActive ? `${vars.colors.green} !important` : vars.colors.white)};
        color: ${props => (props.companyActive ? `${vars.colors.white} !important` : vars.colors.teal)};
      }

      &:hover {
        @media (hover: hover) {
          background-color: ${vars.colors.offWhite};
        }
      }
    }
  }
`
const SettingsColumnHeaders = styled.div`
display: flex;
justify-content: center;
align-items: center;

p {
  line-height: 18px;
  width: 60px; height: 36px;
  margin: 0;
  text-align: center;

  &:first-of-type {
    width: calc(100% - 120px);
    text-align: left;
  }
}
`
const SettingsColumnsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
`
const SettingsColumn = styled.ul`
  text-align: ${props => (props.center ? 'center' : 'left')};
  width: ${props => (props.wide ? 'calc(100% - 120px)' : '60px')};
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: ${props => (props.center ? 'flex' : 'block')};
    justify-content: center;
    font-size: 14px;
    line-height: 16px;
    height: 16px;
    margin: 7px 0;

    input {
      cursor: pointer;
    }
  }
`
const SettingsButton = styled.div`
  cursor: pointer;
  border-radius: 30px;
  height: 30px;
  padding: 5px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${vars.colors.green};
  will-change: background-color;
  transition: background-color .3s ease-out;

  > div {
    height: 100%;
  }
  &:hover {
    @media (hover: hover) {
      background-color: ${darken(0.1, vars.colors.green)};
    }
  }
`
const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  p {
    width: 100%;
    margin: 0 0 0 10px;
    font-size: 14px;
    line-height: 13px;
    position: relative;
    opacity: ${props => (props.disabled ? 0.5 : 1)};
  }
  span {
    display: ${props => (props.disabled ? 'block' : 'none')};
    position: absolute;
    bottom: -20px; left: 31px;
    font-size: 13px;
    line-height: 13px;
  }
`
const SettingsLoader = styled.div`
  position: absolute;
  top: 0; left: 0;
  width: 100%; height: 100%;
  background-color: ${vars.colors.darkShadow};
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  will-change: opacity;
  transition: opacity .3s ease-out;

  * {
    pointer-events: ${props => (props.show ? 'auto' : 'none !important')};
  }
`

const VirtualizedTable = ({ rows, ...props }) => {
  const { user, tableSettings, companyName, teamName, leftAligned } = props
  const [dropdownOpen, setDropdown] = useState(false)
  const [storeSettings, setStoreSettings] = useState(false)

  if (tableSettings) {
    useEffect(() => {
      setStoreSettings(false)
    }, [tableSettings.companySettings])
  }
  const saveSettings = save => {
    setDropdown(false)
    props.saveTableSettings(storeSettings && save)
  }

  return (
    <TableWrapper clickable={props.clickable || props.onRowClick} leftAligned={leftAligned}>
      <AutoSizer disableHeight>
        {({ width }) => (
          <Table width={width} overscanRowCount={10} rowGetter={({ index }) => rows[index]} {...props}/>
        )}
      </AutoSizer>
      {tableSettings && (
        <TableSettings pose={(tableSettings?.show) ? 'show' : 'hide'}>
          <SettingsOverlay onClick={() => saveSettings(false)}/>
          <SettingsWrapper>
            <SettingsHeader showDrop={companyName}>
              {companyName ? (
                <SettingsDropdown onClick={() => setDropdown(!dropdownOpen)} open={dropdownOpen} companyActive={tableSettings.companySettings}>
                  <h3>{tableSettings.companySettings ? companyName : teamName} Table Settings</h3>
                  <div>
                    <span onClick={() => props.switchTableSettings('team')}>{teamName} Table Settings</span>
                    <span onClick={() => props.switchTableSettings('company')}>{companyName} Table Settings</span>
                  </div>
                </SettingsDropdown>
              ) : (
                <h3>{teamName} Table Settings</h3>
              )}
            </SettingsHeader>
            <HeaderExplainer>{(tableSettings.companySettings && companyName) ? (
              `*Table settings seen by ${companyName} on their sharing link.`
            ) : (
              `*Default table settings for ${teamName} users.`
            )}
            </HeaderExplainer>
            <SettingsColumnHeaders>
              {tableSettings.columnLabels.map(label => <p key={label}>{label}</p>)}
            </SettingsColumnHeaders>
            <SettingsColumnsWrapper>
              <SettingsColumn wide>
                {Object.values(tableSettings.columns).map(col => (
                  <li key={col.label}>{col.label}</li>
                ))}
              </SettingsColumn>
              <SettingsColumn center>
                {Object.entries(tableSettings.columns).map(entry => (
                  <li key={entry[0]}>
                    <Toggle small id={`${entry[0]}TableSetting`} name={entry[0]} onChange={props.updateTableSettings} isChecked={tableSettings.columns[entry[0]].show} disabled={entry[1].sticky}/>
                  </li>
                ))}
              </SettingsColumn>
              <SettingsColumn center>
                {Object.entries(tableSettings.columns).map(entry => (
                  <li key={entry[0]}>
                    <input type='radio' name={entry[0]} checked={tableSettings.defaultSort === entry[0]} onChange={props.updateTableSettings}/>
                  </li>
                ))}
              </SettingsColumn>
            </SettingsColumnsWrapper>
            <SettingsButton onClick={() => saveSettings(true)}>
              {tableSettings.saving ? <Loader small/> : `${storeSettings ? 'Save' : 'OK'}`}
            </SettingsButton>
            <ToggleWrapper disabled={!user.is_team_admin}>
              <Toggle small id='settingsToggle' name='settingsToggle' onChange={() => setStoreSettings(!storeSettings)} on={storeSettings} disabled={!user.is_team_admin}/>
              <p>Save {(tableSettings.companySettings && companyName) ? companyName : teamName} table settings.</p>
              <span>* Only team admins can save table settings.</span>
            </ToggleWrapper>
            <SettingsLoader show={tableSettings.loading}>
              <Loader small/>
            </SettingsLoader>
          </SettingsWrapper>
        </TableSettings>
      )}
    </TableWrapper>
  )
}

export default VirtualizedTable
