import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import { toast } from 'react-toastify'
import Loader from '../components/loader'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 100px);
    justify-content: center;
    align-items: center;
  }
  button {
    display: block;
    width: 80%;
    max-width: 400px;
    height: 60px;
    line-height: 60px;
    margin-top: 20px;
    padding: 0 15px;
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-size: 1.3em;
    font-weight: 600;
    background-color: ${vars.colors.green};
    text-align: center;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    will-change: background-color, color;
    transition: background-color .3s ease-out, color .3s ease-out;

    &:disabled {
      background-color: ${vars.colors.lightGrey};
      color: ${vars.colors.darkGrey};
      cursor: auto;
    }
    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
  }
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100px;
  margin: 0;
  font-weight: 600;
  padding: 20px 0px;
  background-color: ${vars.colors.teal};

  a {
    color: ${vars.colors.white};
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;
    transition: transform .3s ease-out;

    &:hover {
      @media (hover: hover) {
        transform: scale(1.05);
      }
    }
  }
  span {
    font-weight: 300;
  }
  img {
    width: 55px;
    margin-right: 14px;
  }
`

const NewAccount = ({ match, history }) => {
  const [loading, setLoading] = useState(false)
  const [accountCreated, setAccountCreated] = useState(false)

  const createAccount = () => {
    localStorage.removeItem('klipr_new-account-data')
    setLoading(true)
    const payload = {
      method: 'get',
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    }
    fetch(`${__API__}shortlinks/${match.params.shortlink}`)
      .then(handleResponse)
      .then(shortlinkResult => {
        if (shortlinkResult.type === 'onboarding' && shortlinkResult.data) {
          localStorage.setItem('klipr_new-account-data', JSON.stringify(shortlinkResult.data))
          fetch(`${__API__}onboarding/continue/${match.params.shortlink}`, payload)
            .then(handleResponse)
            .then(result => {
              if (result.success && result.token) {
                localStorage.setItem('klipr_auth_token', result.token)
                localStorage.removeItem('klipr_new-account-data')
                toast.success('Account successfully created')
                setAccountCreated(true)
                setTimeout(() => history.push('/'), 1000)
              } else {
                toast.error(result.message.split('ERROR: ')[1])
                setTimeout(() => history.push('/free-trial'), 1000)
              }
            })
            .catch(error => {
              console.error(error)
              setTimeout(() => history.push('/free-trial'), 1000)
              return error
            })
        }
      })
      .catch(error => {
        console.error(error)
        setTimeout(() => history.push('/free-trial'), 1000)
        return error
      })
  }
  const handleResponse = response => {
    if (!response.ok) {
      const error = new Error(response.statusText)
      toast.error(`There was an error while processing your request. ${response.status === 500 ? '' : `${response.statusText}.`}`)
      error.response = response
      throw error
    }
    const contentType = response.headers.get('content-type')
    if (contentType.includes('application/json')) {
      return response.json().then(json => {
        if (response.ok) return json
        return Promise.reject({
          ...json,
          status: response.status,
          statusText: response.statusText
        })
      })
    }
    if (contentType.includes('text/html')) {
      return response.text().then(text => {
        if (response.ok) return text
        return Promise.reject({
          status: response.status,
          statusText: response.statusText
        })
      })
    }
  }

  return (
    <Wrapper>
      <Title>
        <Link to='/'>
          <img src={vars.icons.logo} alt='Klipr logo'/>Klipr.io
        </Link>
      </Title>
      <div>
        {accountCreated ? (
          <>
            <Loader small/>
            <p>We are just logging you into your new account...</p>
          </>
        ) : (
          <>
            <p>Thanks for verifying your email address!</p>
            <p>Your Klipr Dashboard is nearly ready - just click the link below to finish creating your account.</p>
            <button type='button' onClick={createAccount} disabled={loading}>
              {loading ? (
                <Loader small/>
              ) : (
                <span>Create Account</span>
              )}
            </button>
          </>
        )}
      </div>
    </Wrapper>
  )
}

export default withRouter(NewAccount)
