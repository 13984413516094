import React, { useEffect } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const GApiAuth = ({ location }) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const queryState = JSON.parse(params.get('state'))
    const paramCode = params.has('code') ? params.get('code') : null
    const env = queryState?.environment ? queryState.environment : null

    if (env === 'dev') {
      window.location = `http://dashboard.klipr.test/google-api/auth?code=${paramCode}`
      return
    }
    if (env === 'staging') {
      window.location = `https://dashboard.staging.klipr.io/google-api/auth?code=${paramCode}`
      return
    }
    if (window.opener) {
      window.opener.postMessage({ kliprGoogleAuthCode: paramCode })
      setTimeout(() => window.close(), 1000)
    }
  }, [])

  return (
    <Wrapper><span>One moment...</span></Wrapper>
  )
}
export default GApiAuth
