import React from 'react'
import { Redirect, withRouter } from 'react-router'
import Loader from '../components/loader'

class CompanyLogin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errorMsg: null,
      loggedIn: false,
      companyId: null,
      campaignId: null
    }
  }

  componentDidMount() {
    if (localStorage.getItem('klipr_preshare_path')) {
      this.props.history.push(localStorage.getItem('klipr_preshare_path'))
    } else {
      this.companyLogin()
    }
  }

  handleResponse = response => {
    if (!response.ok) {
      const errorMsg = `There was an error while processing your request. ${response.statusText}.`
      this.setState({ errorMsg })
      const error = new Error(response.statusText)
      error.response = response
      throw error
    }

    return response.json().then(json => {
      if (response.ok) {
        return json
      }
      return Promise.reject({
        ...json,
        status: response.status,
        statusText: response.statusText
      })
    })
  }
  callUnAuthAPI = path => fetch(__API__ + path, { method: 'get' })
    .then(this.handleResponse)
    .then(result => result)
    .catch(error => {
      console.error(error)
      return error
    })
  companyLogin = () => {
    this.callUnAuthAPI(`shortlinks/${this.props.match.params.shortlink}`).then(result => {
      if (result.token.access_token) {
        localStorage.setItem('klipr_auth_token', result.token.access_token)
        localStorage.setItem('klipr_user_level', 'company')
        localStorage.setItem('klipr_shortlink', this.props.match.params.shortlink)

        this.setState({
          ...{ loggedIn: true },
          ...(result.data?.company_id) && { companyId: result.data.company_id },
          ...(result.data?.campaign_id) && { campaignId: result.data.campaign_id }
        })
      } else {
        const errorMsg = 'Sorry, the link you used is invalid. Please request a new link from your account manager.'
        this.setState({ errorMsg })
      }
    })
  }

  render() {
    if (this.state.errorMsg) {
      return <Redirect to={{ pathname: '/login', state: { error: this.state.errorMsg } }}/>
    }
    if (!this.state.loggedIn) {
      return <Loader/>
    }
    if (this.props.location.pathname.includes('share')) {
      return <Redirect to={{ pathname: `/campaigns/${this.state.companyId}` }}/>
    }
    return <Redirect to={{ pathname: `/lookbook/${this.state.companyId}/${this.state.campaignId}` }}/>
  }
}

export default withRouter(CompanyLogin)
