import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Column, SortDirection, SortIndicator, defaultTableRowRenderer } from 'react-virtualized'
import styled from 'styled-components'
import VirtualizedTable from '../components/virtualizedTable'
import SearchInput from '../components/searchInput'
// import Paginator from '../components/paginator'
import Avatar from '../components/avatar'
import * as vars from '../assets/vars'

const AddLink = styled(Link)`
  position: relative;
  padding-left: 25px !important;

  &:before {
    content: '';
    position: absolute;
    top: 50%; left: 0;
    width: 20px; height: 20px;
    transform: translateY(-50%);
    background-image: url(${vars.icons.add});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    will-change: transform;
    transition: transform .3s ease-out;
  }
  &:hover:before {
    @media (hover: hover) {
      transform: translateY(-50%) scale(1.2);
    }
  }
`
const FilterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0 25px;
  font-size: 0.9em;

  @media (max-width: 767px) {
    justify-content: center;
  }

  > a {
    padding: 0;
    font-weight: 600;

    &:hover span {
      @media (hover: hover) {
        transform: scale(1.15);
      }
    }
  }
  > div a {
    margin-right: 30px;
  }
  a {
    text-decoration: none;
  }
`
const NoResults = styled.div`
  margin-top: 50px;
`
const NameContainer = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-right: 10px !important;
  }
`
const NumberCell = styled.div`
  font-weight: 600;
`
const ActionButton = styled.button`
  display: flex;
  right: 30px !important;
  padding: 7px !important;
`
const RowLinkWrapper = styled(Link)`
  display: block;
  width: 100%;
  height: ${props => props.style.height}px;

  > div:first-of-type {
    top: auto !important;
  }
`

class Companies extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      noCompanies: false,
      companies: null,
      rows: null,
      search: '',
      // page: 1,
      perPage: 10,
      scrollToIndex: undefined,
      sortBy: 'name',
      sortDirection: SortDirection.ASC,
      prevSortBy: null
    }
  }

  componentDidMount() {
    this.getCompanies()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { deleteDetails } = nextProps
    if (!deleteDetails.deleting && deleteDetails.success) {
      this.softDelete(deleteDetails.id)
    }
  }

  getCompanies = () => {
    if (this.props.companies?.length > 0) {
      const rows = this.props.companies.map(company => ({ ...company, totalCampaigns: company.campaigns_count }))
      this.setState({ companies: rows, rows }, () => {
        this.sort({ sortBy: 'name', sortDirection: SortDirection.ASC })
        this.props.setLoading(false)
      })
    } else {
      this.props.callAPI('companies').then(result => {
        if (result.length > 0) {
          const rows = result.map(company => ({ ...company, totalCampaigns: company.campaigns_count }))
          this.setState({ companies: rows, rows }, () => {
            this.sort({ sortBy: 'name', sortDirection: SortDirection.ASC })
            this.props.setLoading(false)
          })
        } else {
          this.setState({ companies: null, rows: null, noCompanies: true }, () => {
            this.props.setLoading(false)
          })
        }
      })
    }
  }
  updateSearch = e => {
    const search = e.target.value
    this.setState(prevState => ({
      search,
      rows: prevState.companies.filter(company => company.name.toLowerCase().includes(search.toLowerCase()))
    }))
  }
  softDelete = id => {
    this.setState(prevState => ({
      companies: prevState.companies.filter(x => x.id !== id),
      rows: prevState.rows.filter(x => x.id !== id)
    }))
  }
  sort = ({ sortBy, sortDirection }) => {
    let sortDir = sortDirection
    let sortOptions = ['name']
    sortOptions = sortOptions.filter(item => item !== sortBy)
    sortOptions.unshift(sortBy)

    const prevSort = this.state.prevSortBy
    if (sortBy !== 'name' && (!prevSort || (prevSort && prevSort !== sortBy))) {
      sortDir = 'DESC'
    }
    const applySort = options => {
      const fields = options.map(option => {
        const cmp = (a, b) => {
          if (a == b) return 0 // eslint-disable-line
          return a < b ? -1 : 1
        }
        const invert = sortDir === SortDirection.DESC || (option !== sortBy && option !== 'name')
        const cmpFunc = invert ? (a, b) => -1 * cmp(a, b) : cmp
        return { option, cmpFunc }
      })

      return (A, B) => {
        let a, b, name, cmp, result
        for (let i = 0; i < fields.length; i += 1) {
          result = 0
          name = fields[i].option
          a = A[name] === 'TBC' ? -1 : typeof A[name] === 'string' ? A[name].toLowerCase() : A[name]
          b = B[name] === 'TBC' ? -1 : typeof B[name] === 'string' ? B[name].toLowerCase() : B[name]
          cmp = fields[i].cmpFunc
          result = cmp(a, b)
          if (result !== 0) break
        }
        return result
      }
    }

    this.setState(prevState => ({
      sortBy,
      sortDirection: sortDir,
      prevSortBy: sortBy,
      rows: [...prevState.rows].sort(applySort(sortOptions))
    }))
  }
  handleRowsScroll = ({ stopIndex }) => {
    this.setState(prevState => {
      const page = Math.ceil(stopIndex / prevState.perPage)
      return { page, scrollToIndex: undefined }
    })
  }
  // handlePageChange = page => {
  //   this.setState(prevState => {
  //     const scrollToIndex = (page - 1) * prevState.perPage
  //     return { page, scrollToIndex }
  //   })
  // }

  headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => (
    <>
      {label}{sortBy === dataKey && <SortIndicator sortDirection={sortDirection}/>}
    </>
  )
  nameCellRenderer = ({ cellData, rowData }) => (
    <NameContainer>
      <Avatar image={rowData.image} name={rowData.name}/>
      {cellData}
    </NameContainer>
  )
  numberCellRenderer = ({ cellData }) => (
    <NumberCell>{typeof cellData === 'number' ? cellData.toLocaleString() : cellData}</NumberCell>
  )
  actionCellRenderer = props => (
    <ActionButton type='button' onClick={e => this.props.delete('company', props.cellData, props.rowData.name, e)}>
      <img src={vars.icons.remove} alt='Delete company'/>
    </ActionButton>
  )
  rowRenderer = props => (
    <RowLinkWrapper key={props.rowData.id} style={props.style} to={`/campaigns/${props.rowData.id}`}>
      {React.cloneElement(
        defaultTableRowRenderer(props), {
          'data-id': props.rowData.id,
          'data-teamid': props.rowData.team_id
        }
      )}
    </RowLinkWrapper>
  )

  render() {
    const headerHeight = 55
    const rowHeight = 70
    const rowCount = this.state.rows ? this.state.rows.length : 0
    const height = rowHeight * Math.min(this.state.perPage, rowCount) + headerHeight
    // const pageCount = Math.ceil(rowCount / this.state.perPage)

    return (
      <div>
        <div className='panelHead'>
          <h2>All Companies</h2>
          {!this.props.user.is_client && (!this.props.currentTeam?.companyLimitReached || !this.props.currentTeam?.planEnded) &&
            <AddLink to='/add-company' className='link addCompany'>company</AddLink>
          }
        </div>

        <FilterBar>
          {!this.state.noCompanies &&
            <SearchInput name='companySearch' value={this.state.search} onChange={this.updateSearch} placeholder='Search companies'/>
          }
          {/* {pageCount > 3 &&
            <Paginator pageCount={pageCount} currentPage={this.state.page} onPageChange={this.handlePageChange}/>
          } */}
        </FilterBar>

        {!this.state.noCompanies ? (
          <VirtualizedTable
            rowHeight={rowHeight} headerHeight={headerHeight} height={height}
            rowCount={rowCount} rows={this.state.rows} rowRenderer={this.rowRenderer}
            clickable scrollToIndex={this.state.scrollToIndex} scrollToAlignment='start'
            sort={this.sort} sortBy={this.state.sortBy} sortDirection={this.state.sortDirection}
            // onRowsRendered={this.handleRowsScroll}
          >
            <Column label='Name' dataKey='name' width={250} flexGrow={1} headerRenderer={this.headerRenderer} cellRenderer={this.nameCellRenderer}/>
            {/* <Column label='ID' dataKey='id' width={170} headerRenderer={this.headerRenderer}/> */}
            <Column label='Campaigns' dataKey='totalCampaigns' width={100} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
            {!this.props.user.is_client &&
              <Column label='' dataKey='id' width={70} disableSort cellRenderer={this.actionCellRenderer}/>
            }
          </VirtualizedTable>
        ) : (
          <NoResults>There are currently no companies</NoResults>
        )}
      </div>
    )
  }
}

export default withRouter(Companies)
