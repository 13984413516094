/* eslint-disable */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Column } from 'react-virtualized'
import VirtualizedTable from '../components/virtualizedTable'
import Select from 'react-select'
import * as vars from '../assets/vars'

const StyledContainer = styled.div`
  .main-container {

    button {
      width: 100%;
      border-radius: 20px;
      color: white;
      font-weight: bold;
      font-size: 0.9em;
    }

    form {
      max-width: 421px;
      display: flex;
      gap: 25px;
      flex-direction: column;

      input {
        background: #F5F5F5;
        border: none;
        padding: 8px;
        outline: none;
      }

      label {
        color: #FC425D;
        font-weight: 600;
        font-size: .85em;
      }

      .input-wrapper {
        display: flex;
        gap: 10px;
        flex-direction: column;
      }

      .inline-wrapper {
        display: flex;
        gap: 15px;

      }

      .buttons-wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: center;
        width: 140px;

        button[type='submit'] {
          background: #2869CA;
          padding: 10px 12px;
        }

        .download-csv-button {
          background: #FDB002;
          margin-bottom: 10px;
          padding: 10px 15px;
        }

      }
    }

    .table-wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .warning-too-much-rows {
        color: #FC425D;
        font-weight: 600;
        font-size: .85em;
      }

      .table-grid {
        max-height: 600px;
        overflow: auto !important;

        .table-column {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden !important;
        }
      }
    }
  }
`

const MAX_ROWS = 300

const typeOptions = [
  {
    label: 'Follow',
    value: 'follow'
  },
  {
    label: 'No Follow',
    value: 'nofollow'
  },
  {
    label: 'Syndicated',
    value: 'syndicated'
  },
  {
    label: 'Mention',
    value: 'mention'
  },
  {
    label: 'UGC',
    value: 'ugc'
  },
  {
    label: 'Sponsored',
    value: 'sponsored'
  }
]

const SearchDatabase = (props) => {
  const {
    setLoading,
    callAPI,
    toast
  } = props
  useEffect(() => setLoading(false), [setLoading])

  const [startDate, setStartDate] = useState(moment()
    .subtract(3, 'month')
    .format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment()
    .format('YYYY-MM-DD'))
  const [keyword, setKeyword] = useState('')
  const [linkTypes, setLinkTypes] = useState()
  const [minDomainRating, setMinDomainRating] = useState(0)

  const [data, setData] = useState([])
  const [tableProps, setTableProps] = useState({
    perPage: 100,
    headerHeight: 55,
    rowHeight: 70,
    rowCount: 0,
    height: 55
  })

  const headerRenderer = e => e.label

  const onDownloadClicked = () => {
    setLoading(true)
    return callAPI(`search/keywords?keyword=${encodeURIComponent(keyword)}&minDomainRating=${minDomainRating}&startDate=${startDate}&endDate=${endDate}${linkTypes?.length ? `&linkTypes=${linkTypes.map(type => type.value).join(',')}` : ''}`, undefined, 'get')
      .then(blob => {
        if (blob.count === 0) return
        const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/csv' }))
        window.location.assign(url)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onSearchClicked = (e) => {
    e.preventDefault()
    setLoading(true)
    setData([])
    const body = {
      keyword,
      minDomainRating,
      startDate,
      endDate,
      linkTypes: linkTypes?.map(type => type.value),
    }
    return callAPI('search/keywords', undefined, 'post', JSON.stringify(body))
      .then(response => {
        if (!response.results?.length) {
          toast.error('Coverage not found')
        }
        setTableProps({
          ...tableProps,
          rowCount: response.results?.length || 0,
          height: tableProps.rowHeight * Math.min(tableProps.perPage, response.results?.length || 0) + tableProps.headerHeight
        })
        setData(response.results)
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <StyledContainer>
      <div className="main-container">
        <h1>Search Coverage</h1>
        <form onSubmit={onSearchClicked}>

          <div className="input-wrapper">
            <label htmlFor="keyword">Keyword</label>
            <input type="text" required name="keyword" value={keyword} onChange={e => {
              setKeyword(e.target.value)
            }}/>
          </div>

          <div className="inline-wrapper">
            <label htmlFor="startDate">From</label>
            <input type="date"
                   name="startDate"
                   className="date-picker"
                   value={startDate}
                   required
                   onChange={e => {
                     setStartDate(moment(e.target.value)
                       .format('YYYY-MM-DD'))
                   }}
            />
            <label htmlFor="startDate">To</label>
            <input type="date"
                   name="endDate"
                   className="date-picker"
                   value={endDate}
                   required
                   onChange={e => {
                     setEndDate(moment(e.target.value)
                       .format('YYYY-MM-DD'))
                   }}
            />
          </div>
          <div className="inline-wrapper">
            <label htmlFor="domainRating">Min. domain rating</label>
            <input type="number"
                   name="domainRating"
                   className="dr-input"
                   min={0}
                   max={100}
                   width={50}
                   value={minDomainRating}
                   onChange={e => setMinDomainRating(parseInt(e.target.value))}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="link-types">Link types</label>
            <Select id="link-types"
                    name="link-types"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: 'none',
                        color: `${vars.colors.teal}`,
                        backgroundColor: `#F5F5F5`,
                        boxShadow: `none`,
                        padding: '3px',
                      }),
                      multiValue: base => ({
                        ...base,
                        backgroundColor: '#d0e4ff'
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#707070'
                      }),
                      input: base => ({
                        ...base,
                        boxShadow: 'none'
                      })
                    }}
                    isMulti
                    onChange={types => setLinkTypes(types)}
                    value={linkTypes}
                    options={
                      typeOptions
                    }
                    placeholder='All types'
            />

          </div>
          <div className="buttons-wrapper">
            <button type="submit">Search</button>
            or
            <button className="download-csv-button" onClick={onDownloadClicked}>Download CSV</button>
          </div>
        </form>

        {!!data?.length && <div className="table-wrapper">
          {(tableProps?.rowCount > MAX_ROWS) &&
            <div className="warning-too-much-rows">
              {`Showing only ${MAX_ROWS} out of ${tableProps.rowCount} total results`}
              <br/>
              To view all coverage please download the data
            </div>
          }

          <VirtualizedTable rows={data} {...tableProps}
                            rowCount={tableProps.rowCount > MAX_ROWS ? MAX_ROWS : tableProps.rowCount}
                            gridClassName="table-grid"
          >
            <Column label="ID" dataKey="campaign_link_id" width={70} headerRenderer={headerRenderer}
                    className="table-column"/>
            <Column label="Date Created" dataKey="date_created" width={100} headerRenderer={headerRenderer}
                    className="table-column"/>
            <Column label="URL" dataKey="url" width={250} flexGrow={1} headerRenderer={headerRenderer}
                    cellRenderer={data => <a href={data.cellData} target='_blank' rel='noopener noreferrer'>{data.cellData}</a>} className="table-column"/>
            <Column label="Domain Rating" dataKey="domain_rating" width={100} headerRenderer={headerRenderer}
                    className="table-column"/>
            <Column label="Page Title" dataKey="page_title" width={100} headerRenderer={headerRenderer}
                    className="table-column"/>
            <Column label="Link Type" dataKey="link_type" width={100} headerRenderer={headerRenderer}
                    className="table-column"/>
            <Column label="Date Published" dataKey="date_published" width={100} headerRenderer={headerRenderer}
                    className="table-column"/>
            <Column label="Company" dataKey="company" width={100} headerRenderer={headerRenderer}
                    className="table-column"/>
            <Column label="Campaign" dataKey="campaign" width={100} headerRenderer={headerRenderer}
                    className="table-column"/>
            <Column label="Team" dataKey="team" width={100} headerRenderer={headerRenderer} className="table-column"/>
          </VirtualizedTable>
        </div>}
      </div>
    </StyledContainer>
  )
}

export default SearchDatabase
