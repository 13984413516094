import React from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import Loader from './loader'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  width: 100%;

  a {
    display: block;
    color: ${vars.colors.white};
    font-size: 0.9em;
    text-align: center;
  }
`
const ErrorMsg = styled.p`
  margin: 15px auto;
  width: 80%;
  max-width: 400px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${vars.colors.red};
  color: ${vars.colors.white};
`

const Form = styled.form`
  margin: 0 auto 32px;
  max-width: 390px;

  input {
    display: block;
    width: 100%;
    padding: 26px;
    border: 0;
    outline: 0;
    font-size: 1.2em;
    background-color: ${vars.colors.white};
    box-sizing: border-box;

    &:last-of-type {
      margin-top: 20px;
    }
  }

  button {
    display: block;
    width: 100%; height: 60px;
    max-width: 390px;
    margin: 32px auto 0;
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-size: 1.3em;
    font-weight: 600;
    background-color: ${vars.colors.green};
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:focus {
      outline: 0;
    }
    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
  }
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1.4em;
  font-weight: 600;
  color: ${vars.colors.white};
  padding: 20px 0px;
  background-color: ${vars.colors.teal};

  span {
    font-weight: 300;
  }
  img {
    width: 55px;
    margin-right: 14px;
  }
`
const Forgot = styled(Link)`
  color: ${vars.colors.black} !important;
  display: table !important;
  font-size: 18px !important;
  font-weight: 600;
  margin: 0 auto;
  will-change: color;
  transition: color .3s ease-out;

  &:hover {
    @media (hover: hover) {
      color: ${vars.colors.blue} !important;
    }
  }
`

const LoginForm = ({ onSubmit, onChange, error, user }) => (
  <Wrapper>
    <Title>
      <img src={vars.icons.logo} alt='Klipr logo'/>Klipr.io
    </Title>

    <Form action='/' onSubmit={onSubmit}>
      <fieldset>
        <input type='text' name='username' onChange={onChange} value={user.username} placeholder='Username or Email' required/>
        <input type='password' name='password' onChange={onChange} value={user.password} placeholder='Password' required/>
      </fieldset>
      {user.loading ? (
        <button type='button' disabled><Loader small/></button>
      ) : (
        <button type='submit'>Log in</button>
      )}
    </Form>

    {error && <ErrorMsg>{error}</ErrorMsg> }

    <Forgot to='/forgot-password'>Forgot password?</Forgot>
    {user.loggedIn && <Redirect to='/'/>}
  </Wrapper>
)

export default LoginForm
