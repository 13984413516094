import React from 'react'
import styled from 'styled-components'
import posed from 'react-pose'
import * as vars from '../assets/vars'

const AnimatedWrapper = posed.div({
  show: { opacity: 1 },
  hide: { opacity: 0 }
})
const Wrapper = styled(AnimatedWrapper)`
  width: 100%;
  padding: 30px;
  width: 100%;
  pointer-events: ${props => (props.pose === 'show' ? 'auto' : 'none !important')};

  * {
    pointer-events: ${props => (props.pose === 'show' ? 'auto' : 'none !important')};
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`
const ProgressBar = styled.div`
  width: 80%;
  height: 15px;
  padding: 5px;
  position: relative;
  border-radius: 20px;
  background-color: ${vars.colors.teal};
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    width: ${props => props.width}%;
    height: 100%;
    border-radius: 20px;
    background-color: ${vars.colors.green};
    will-change: width;
    transition: width .3s ease-out;
  }
`

const LinkPolling = ({ isPolling, total, remaining }) => (
  <Wrapper pose={isPolling ? 'show' : 'hide'}>
    <div>
      <p>We are fetching links information for this campaign - feel free to navigate away from this page while it loads.</p>
      {total &&
        <ProgressBar width={100 - ((remaining / total) * 100)}/>
      }
    </div>
  </Wrapper>
)

export default LinkPolling
