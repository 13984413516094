/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Toggle from './toggle'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  position: relative;
  margin: 0 10px 0 20px;
`
const Trigger = styled.button`
  padding: 0;
  margin-right: 15px;
  cursor: pointer;

  span {
    width: 10px;
    height: 10px;
    margin-left: 5px;
    display: inline-block;
    background-image: url(${vars.icons.arrow});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    will-change: transform;
    transition: transform .3s ease-out;
    transform: rotate(${({ isOpen }) => (isOpen ? -180 : 0)}deg);
  }
`
const Filters = styled.div`
  padding: 10px;
  background-color: ${vars.colors.white};
  box-shadow: 0px 3px 6px ${vars.colors.darkShadow};
  position: absolute;
  z-index: 1;
  top: 22px; left: -50px;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  will-change: opacity;
  transition: opacity .3s ease-out;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 5px;

    &:last-of-type {
      margin-bottom: 0;
    }
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  p {
    margin: 0 10px;
  }
  select {
    margin: 5px;
    min-width: 100px;
  }
  input {
    max-width: 150px;
    margin: 5px;
  }
`

const LinksFilter = ({ filterData, defaultFilter }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [filters, setFilters] = useState({
    coverageType: {
      enabled: false,
      value: 'follow'
    },
    domainRating: {
      enabled: true,
      value: ['above', defaultFilter || 10]
    },
    dateAddedRange: {
      enabled: false,
      value: ['', '']
    }
  })
  const filterRef = useRef()
  const handleOffClick = e => {
    if (!filterRef.current?.contains(e.target)) {
      setIsOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleOffClick)
    return () => document.removeEventListener('click', handleOffClick)
  })
  useEffect(() => {
    const filterFns = {
      coverageType: x => x.type === filters.coverageType.value,
      domainRating: x => {
        if (filters.domainRating.value[0] === 'above') {
          return x.domain_rating > filters.domainRating.value[1]
        }
        return x.domain_rating < filters.domainRating.value[1]
      },
      dateAddedRange: x => {
        if (filters.dateAddedRange.value[0] && filters.dateAddedRange.value[1]) {
          return filters.dateAddedRange.value[0] <= x.date_created < filters.dateAddedRange.value[1]
        }
        if (filters.dateAddedRange.value[0]) {
          return filters.dateAddedRange.value[0] <= x.date_created
        }
        if (filters.dateAddedRange.value[1]) {
          return x.date_created < filters.dateAddedRange.value[1]
        }
        return true
      }
    }
    filterData([...Object.keys(filterFns).filter(key => filters[key].enabled)].map(fn => filterFns[fn]))
  }, [filters])

  const toggleFilters = e => {
    setFilters({
      ...filters,
      [e.target.name]: {
        ...filters[e.target.name],
        enabled: !filters[e.target.name].enabled
      }
    })
  }
  const updateCoverageType = e => {
    if (filters.coverageType.enabled) {
      setFilters({
        ...filters,
        coverageType: {
          ...filters.coverageType,
          value: e.target.value
        }
      })
    }
  }
  const drSelectRef = useRef()
  const drInputRef = useRef()
  const updateDomainRating = () => {
    if (filters.domainRating.enabled) {
      setFilters({
        ...filters,
        domainRating: {
          ...filters.domainRating,
          value: [drSelectRef.current.value, +drInputRef.current.value]
        }
      })
    }
  }
  const darFromRef = useRef()
  const darToRef = useRef()
  const updatePublishedDateRange = () => {
    if (filters.dateAddedRange.enabled) {
      setFilters({
        ...filters,
        dateAddedRange: {
          ...filters.dateAddedRange,
          value: [darFromRef.current.value, darToRef.current.value]
        }
      })
    }
  }

  return (
    <Wrapper ref={filterRef}>
      <div style={{display: 'flex'}}>
        <Trigger type='button' isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
          Filter<span/>
        </Trigger>
        {Object.values(filters).filter(filterObj => filterObj.enabled).length ? <div style={{
          fontWeight: 'bold',
          color: 'red'
        }}>You have a filter on!</div> : <></>}
        <Filters isOpen={isOpen}>
          <ul>
            <li>
              <div>
                <Toggle id='coverageType' name='coverageType' onChange={toggleFilters} on={filters.coverageType.enabled} small/>
                <p>Coverage Type:</p>
              </div>
              <select value={filters.coverageType.value} onChange={updateCoverageType} disabled={!filters.coverageType.enabled}>
                <option value='follow'>follow</option>
                <option value='mention'>mention</option>
                <option value='nofollow'>nofollow</option>
                <option value='syndicated'>syndicated</option>
                <option value='ugc'>UGC</option>
                <option value='sponsored'>sponsored</option>
              </select>
            </li>
            <li>
              <div>
                <Toggle id='domainRating' name='domainRating' onChange={toggleFilters} on={filters.domainRating.enabled} isChecked={filters.domainRating.enabled} small/>
                <p>Domain Rating:</p>
              </div>
              <div>
                <select ref={drSelectRef} value={filters.domainRating.value[0]} onChange={updateDomainRating} disabled={!filters.domainRating.enabled}>
                  <option value='below'>below</option>
                  <option value='above'>above</option>
                </select>
                <input ref={drInputRef} type='number' min={0} max={100} value={filters.domainRating.value[1]} onChange={updateDomainRating} disabled={!filters.domainRating.enabled}/>
              </div>
            </li>
            <li>
              <div>
                <Toggle id='dateAddedRange' name='dateAddedRange' onChange={toggleFilters} on={filters.dateAddedRange.enabled} small/>
                <p>Date Added Range:</p>
              </div>
              <div>
                <input ref={darFromRef} type='date' value={filters.dateAddedRange.value[0]} max={filters.dateAddedRange.value[1] ? filters.dateAddedRange.value[1] : ''} onChange={updatePublishedDateRange} disabled={!filters.dateAddedRange.enabled}/>
                <input ref={darToRef} type='date' value={filters.dateAddedRange.value[1]} min={filters.dateAddedRange.value[0] ? filters.dateAddedRange.value[0] : ''} onChange={updatePublishedDateRange} disabled={!filters.dateAddedRange.enabled}/>
              </div>
            </li>
          </ul>
        </Filters>
      </div>
    </Wrapper>
  )
}

export default LinksFilter
