import React from 'react'
import { Column, SortDirection, SortIndicator, defaultTableRowRenderer } from 'react-virtualized'
import styled from 'styled-components'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import VirtualizedTable from '../components/virtualizedTable'
import OverviewStats from '../components/overviewStats'
import ApprovalModal from '../components/approvalModal'
import SearchInput from '../components/searchInput'
// import Paginator from '../components/paginator'
import * as vars from '../assets/vars'

const NoResults = styled.div`
  margin-top: 50px;
`
const FilterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0 25px;
  font-size: 0.9em;

  @media (max-width: 767px) {
    justify-content: center;
  }

  > a {
    padding: 0;
    font-weight: 600;

    &:hover span {
      @media (hover: hover) {
        transform: scale(1.15);
      }
    }
  }
  > div a {
    margin-right: 30px;
  }
  a {
    text-decoration: none;
  }
`
const LinkWrapper = styled.div`
  padding: 0 20px 0 0 !important;

  a {
    margin-left: 8px;
    line-height: 1.2em;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${vars.colors.teal};
    will-change: color;
    transition: color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        color: ${vars.colors.blue};
      }
    }
  }
`
const LinkType = styled.div`
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 8px;
  background-color: ${props => (props.type === 'auto' ? vars.colors.green : vars.colors.blue)};

  &:after {
    content: '${props => props.type} URL';
    position: absolute;
    z-index: 1;
    top: calc(50% - 15px); left: 8px;
    width: 100px; height: 20px;
    line-height: 20px;
    text-align: center;
    line-height: 20px;
    background-color: ${props => (props.type === 'auto' ? vars.colors.green : vars.colors.blue)};
    color: ${vars.colors.white};
    padding: 5px;
    text-transform: capitalize;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    transition: opacity .3s ease-out;
  }
  &:hover:after {
    @media (hover: hover) {
      pointer-events: auto;
      opacity: 1;
    }
  }
`
const NumberCell = styled.div`
  font-weight: 600;
`
const ActionButton = styled.button`
  display: flex;
  right: 30px !important;
  padding: 7px !important;
`
const AnchorText = styled.div`
  p {
    margin: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    position: absolute;
    opacity: ${props => (props.empty ? '0 !important' : 0)};
    pointer-events: ${props => (props.empty ? 'none !important' : 'none')};
    top: 50%; right: 80px;
    transform: translateY(-50%);
    padding: 10px 20px;
    border-radius: 3px;
    color: ${vars.colors.darkGrey};
    background-color: ${vars.colors.offWhite};
    font-weight: 600;
    text-transform: none !important;
    will-change: opacity;
    transition: opacity .3s ease-out;

    &:hover {
      @media (hover: hover) {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  &:hover {
    @media (hover: hover) {
      span {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`
const TableSettingsButton = styled.div`
  position: relative;
  width: 20px; height: 20px;
  background-image: url(${vars.icons.cog});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 25px;
  cursor: pointer;
  will-change: transform;
  transition: transform .3s ease-out;

  &:after {
    content: 'Table Settings';
    position: absolute;
    padding: 7px;
    top: -12px; left: -120px;
    width: 100px; height: 30px;
    line-height: 30px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    pointer-events: none;
    background-color: ${vars.colors.offWhite};
    opacity: 0;
    will-change: opacity;
    transition: opacity .3s ease-out;
  }
  &:hover {
    @media (hover: hover) {
      transform: scale(1.1);

      &:after {
        opacity: 1;
      }
    }
  }
`

class Coverage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      noLinks: false,
      links: false,
      rows: false,
      csvData: null,
      showApproveModal: false,
      shares: 0,
      followLinks: 0,
      nofollowLinks: 0,
      mentionLinks: 0,
      otherLinks: 0,
      kliprScore: 0,
      search: '',
      // page: 1,
      perPage: 10,
      scrollToIndex: undefined,
      sortBy: 'domain_rating',
      sortDirection: SortDirection.DESC,
      prevSortBy: null,
      tableSettings: {
        show: false,
        loading: false,
        initialSort: null,
        defaultSort: null,
        columnLabels: ['Column'],
        columns: null
      }
    }
  }
  componentDidMount() {
    this.getTableSettings()
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { deleteDetails } = nextProps
    if (!deleteDetails.deleting && deleteDetails.success) {
      this.getTableSettings()
      // this.softDelete(this.props.deleteDetails.id)
    }
  }

  getTableSettings = () => {
    this.props.callAPI('dataset/link_list').then(structure => {
      const fields = structure.fields.filter(field => !field.hidden)
      this.props.callAPI('dataset/link_list/settings', '', 'post', JSON.stringify({})).then(settings => {
        Object.keys(settings).forEach(setting => {
          fields.find(field => field.field_id === setting).settings = settings[setting]
        })

        const { tableSettings } = this.state
        const defaultField = fields.find(field => field.settings.is_default_sort === 1)
        const sortBy = defaultField ? defaultField.field_id : fields[0].field_id
        tableSettings.initialSort = sortBy
        tableSettings.defaultSort = sortBy
        tableSettings.columnLabels = [...tableSettings.columnLabels, ...Object.values(structure.settings_labels)]
        tableSettings.columns = Object.fromEntries(fields.map(field => (
          [field.field_id, {
            label: field.label,
            show: field.settings.is_hidden ? field.settings.is_hidden === 0 : true,
            sticky: field.settings.is_hidden === undefined
          }]
        )))
        this.setState({ tableSettings, sortBy }, () => this.getLinks())
      })
    })
  }
  toggleTableSettings = () => {
    const { tableSettings } = this.state
    tableSettings.show = !tableSettings.show
    this.setState({ tableSettings })
  }
  updateTableSettings = e => {
    const name = e.target.getAttribute('name')
    const { tableSettings } = this.state
    if (e.target.getAttribute('type') === 'checkbox' && !tableSettings.columns[name].sticky) {
      tableSettings.columns[name].show = !this.state.tableSettings.columns[name].show
      tableSettings.defaultSort = (!tableSettings.columns[name].show && tableSettings.defaultSort === name) ? tableSettings.initialSort : tableSettings.defaultSort
    } else {
      tableSettings.defaultSort = name
      this.sort({ sortBy: name, sortDirection: SortDirection.ASC })
    }
    this.setState({ tableSettings }, () => this.updateCsv(this.state.rows))
  }
  saveTableSettings = save => {
    const { tableSettings } = this.state
    if (save) {
      tableSettings.loading = true
      this.setState({ tableSettings })

      const scope = {}
      const settings = JSON.parse(JSON.stringify(tableSettings.columns)) // make a deepcopy of settings
      Object.keys(settings).forEach(key => {
        if (!settings[key].sticky) {
          settings[key].is_hidden = settings[key].show ? 0 : 1
        }
        settings[key].is_default_sort = tableSettings.defaultSort === key ? 1 : 0
        delete settings[key].label
        delete settings[key].show
        delete settings[key].sticky
      })
      const payload = { scope, settings }
      this.props.callAPI('dataset/link_list/settings', '', 'put', JSON.stringify(payload)).then(response => {
        tableSettings.loading = false
        tableSettings.show = false
        this.setState({ tableSettings })
        if (response.success) {
          this.props.toast.success('Table settings have been updated.')
        }
      })
    } else {
      tableSettings.loading = false
      tableSettings.show = false
      this.setState({ tableSettings })
    }
  }
  getLinks = () => {
    this.props.callAPI('links').then(result => {
      if (result.length > 0) {
        const followLinks = result.reduce((acc, curr) => acc + (curr.type === 'follow' ? 1 : 0), 0)
        const nofollowLinks = result.reduce((acc, curr) => acc + (curr.type === 'nofollow' ? 1 : 0), 0)
        const mentionLinks = result.reduce((acc, curr) => acc + (curr.type === 'mention' ? 1 : 0), 0)
        const otherLinks = result.reduce((acc, curr) => acc + ((curr.type === 'syndicated' || curr.type === 'ugc' || curr.type === 'sponsored') ? 1 : 0), 0)
        const shares = result.reduce((acc, curr) => acc + (curr.shares ? curr.shares : 0), 0)
        const kliprScore = result.reduce((acc, curr) => acc + (curr.klipr_score ? curr.klipr_score : 0), 0)

        const rows = result.map(link => ({
          url: link.url,
          campaign_name: link.campaign_name,
          company_name: link.company_name,
          date_created: link.date_created.split(' ')[0],
          date_published: link.date_published?.split(' ')[0],
          id: link.id,
          campaign_id: link.campaign_id,
          type: link.type ? link.type : 'TBC',
          campaign_type: link.campaign_type,
          is_manual: link.is_manual,
          ga_sessions: link.ga_sessions ? link.ga_sessions : '–',
          anchor_term: link.anchor_term ? link.anchor_term : 'N/A',
          domain_rating: link.domain_rating === 0 || link.domain_rating > 0 ? link.domain_rating : 'TBC',
          shares: link.shares === 0 || link.shares > 0 ? link.shares : 'TBC',
          klipr_score: (link.type && (link.domain_rating === 0 || link.domain_rating > 0)) ? link.klipr_score : 'TBC'
        }))

        this.updateCsv(rows)
        this.setState({ followLinks, nofollowLinks, mentionLinks, otherLinks, shares, kliprScore, rows, links: rows }, () => {
          this.sort({ sortBy: this.state.sortBy, sortDirection: SortDirection.DESC })
          this.props.setLoading(false)
        })
      } else {
        this.setState({ noLinks: true }, () => this.props.setLoading(false))
      }
    })
  }
  updateCsv = data => {
    let csvData = null
    const { tableSettings } = this.state
    if (tableSettings.columns) {
      const columns = Object.keys(tableSettings.columns).filter(column => tableSettings.columns[column].show)
      csvData = data ? data.map(x => {
        const obj = {}
        columns.forEach(column => {
          obj[tableSettings.columns[column].label] = x[column]
          obj['Campaign ID'] = x.campaign_id
        })
        return obj
      }) : null
    }
    this.setState({ csvData })
  }
  // softDelete = id => {
  //   const link = this.state.links.filter(x => x.id === id)[0]
  //   const links = this.state.links.filter(x => x.id !== id)
  //   const rows = this.state.rows.filter(x => x.id !== id)
  //   const shares = this.state.shares - link.shares
  //   const followLinks = link.type === 'follow' ? this.state.followLinks - 1 : this.state.followLinks
  //   const nofollowLinks = link.type === 'nofollow' ? this.state.nofollowLinks - 1 : this.state.nofollowLinks
  //   const mentionLinks = link.type === 'mention' ? this.state.mentionLinks - 1 : this.state.mentionLinks
  //   const otherLinks = link.type === 'syndicated' || link.type === 'ugc' || link.type === 'sponsored' ? this.state.otherLinks - 1 : this.state.otherLinks
  //   this.setState({ links, rows, shares, followLinks, otherLinks, nofollowLinks, mentionLinks })
  // }
  sort = ({ sortBy, sortDirection }) => {
    let sortDir = sortDirection
    let sortOptions = ['domain_rating', 'type', 'date_created', 'url']
    sortOptions = sortOptions.filter(item => item !== sortBy)
    sortOptions.unshift(sortBy)

    const prevSort = this.state.prevSortBy
    if (sortBy !== 'link' && (!prevSort || (prevSort && prevSort !== sortBy))) {
      sortDir = 'DESC'
    }
    const applySort = options => {
      const fields = options.map(option => {
        const cmp = (a, b) => {
          if (a == b) return 0 //eslint-disable-line
          return a < b ? -1 : 1
        }
        const invert = sortDir === SortDirection.DESC || (option !== sortBy && option !== 'link')
        const cmpFunc = invert ? (a, b) => -1 * cmp(a, b) : cmp
        return { option, cmpFunc }
      })

      return (A, B) => {
        let a, b, name, cmp, result
        for (let i = 0; i < fields.length; i += 1) {
          result = 0
          name = fields[i].option
          if (name === 'type') {
            a = A[name] === 'follow' ? 6 : A[name] === 'nofollow' ? 5 : A[name] === 'syndicated' ? 4 : A[name] === 'mention' ? 3 : A[name] === 'ugc' ? 2 : A[name] === 'sponsored' ? 1 : 0
            b = B[name] === 'follow' ? 6 : B[name] === 'nofollow' ? 5 : B[name] === 'syndicated' ? 4 : B[name] === 'mention' ? 3 : B[name] === 'ugc' ? 2 : B[name] === 'sponsored' ? 1 : 0
          } else {
            a = A[name] === 'TBC' ? -1 : typeof A[name] === 'string' ? A[name].toLowerCase()?.replace('https://', '')?.replace('http://', '')?.replace('www.', '') : A[name]
            b = B[name] === 'TBC' ? -1 : typeof B[name] === 'string' ? B[name].toLowerCase()?.replace('https://', '')?.replace('http://', '')?.replace('www.', '') : B[name]
          }
          cmp = fields[i].cmpFunc
          result = cmp(a, b)
          if (result !== 0) break
        }
        return result
      }
    }

    this.setState(prevState => ({
      sortBy,
      sortDirection: sortDir,
      prevSortBy: sortBy,
      rows: [...prevState.rows].sort(applySort(sortOptions))
    }), () => this.updateCsv(this.state.rows))
  }
  updateSearch = e => {
    const search = e.target.value
    this.setState(prevState => ({
      search,
      rows: prevState.links.filter(link => link.url?.toLowerCase().includes(search?.toLowerCase()))
    }))
  }
  handleRowsScroll = ({ stopIndex }) => {
    this.setState(prevState => {
      const page = Math.ceil(stopIndex / prevState.perPage)
      return { page, scrollToIndex: undefined }
    })
  }
  checkUserType = e => {
    if (this.props.currentTeam?.type === 'free') {
      e.preventDefault()
      e.stopPropagation()
      this.setState({ showApproveModal: true })
    }
  }
  // handlePageChange = page => {
  //   this.setState(prevState => {
  //     const scrollToIndex = (page - 1) * prevState.perPage
  //     return { page, scrollToIndex }
  //   })
  // }

  headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => (
    <div>
      {label}{sortBy === dataKey && <SortIndicator sortDirection={sortDirection}/>}
    </div>
  )
  tableSettingsRenderer = () => (
    <TableSettingsButton onClick={this.toggleTableSettings}/>
  )
  linkCellRenderer = props => (
    <LinkWrapper>
      <LinkType type={props.rowData.is_manual ? 'manual' : 'auto'}/>
      <a href={props.cellData} target='_blank' rel='noopener noreferrer'>{props.cellData.replace('https://', '').replace('http://', '').replace('www.', '')}</a>
    </LinkWrapper>
  )
  dateCellRenderer = ({ cellData }) => (
    <div>
      {cellData ? moment(cellData).format('DD MMMM YYYY') : '–'}
    </div>
  )
  numberCellRenderer = ({ cellData }) => (
    <NumberCell>{typeof cellData === 'number' ? cellData.toLocaleString() : cellData}</NumberCell>
  )
  actionCellRenderer = props => (
    <ActionButton type='button' onClick={e => this.props.delete('URL', props.cellData, props.rowData.url.replace('https://', '').replace('http://', '').replace('www.', ''), e)}>
      <img src={vars.icons.remove} alt='Delete URL'/>
    </ActionButton>
  )
  anchorCellRenderer = ({ cellData }) => (
    <AnchorText empty={!cellData.length || cellData.length < 8 || cellData === 'N/A'}>
      <p>{cellData}</p>
      <span>{cellData}</span>
    </AnchorText>
  )
  rowRenderer = props => (
    React.cloneElement(
      defaultTableRowRenderer(props), {
        'data-id': props.rowData.id,
        'data-campaignid': props.rowData.campaign_id
      }
    )
  )

  render() {
    const { rows, csvData, perPage, followLinks, nofollowLinks, mentionLinks, otherLinks, shares, kliprScore, noLinks, search, tableSettings, scrollToIndex, sortBy, sortDirection, showApproveModal } = this.state
    const headerHeight = 55
    const rowHeight = 70
    const rowCount = rows ? rows.length : 0
    const height = rowHeight * Math.min(perPage, rowCount) + headerHeight
    // const pageCount = Math.ceil(rowCount / perPage)
    const overviewStats = [{
      label: 'Follow links',
      value: followLinks.toLocaleString()
    }, {
      label: 'Nofollow links',
      value: nofollowLinks.toLocaleString()
    }, {
      label: 'Mentions',
      value: mentionLinks.toLocaleString(),
      hide: mentionLinks <= 0
    }, {
      label: 'Other links',
      value: otherLinks.toLocaleString(),
      hide: otherLinks <= 0
    }, {
      label: 'Total shares',
      value: typeof shares === 'number' ? shares.toLocaleString() : 'TBC'
    }, {
      label: 'Total Klipr score',
      value: kliprScore.toLocaleString()
    }]
    const approveModalContent = '<p>CSV exports are only available to paying customers.</p>'

    return (
      <div>
        <div className='panelHead'>
          <h2>Coverage Database</h2>
        </div>
        <OverviewStats stats={overviewStats}/>
        <div>
          <FilterBar>
            {!noLinks &&
              <SearchInput name='urlSearch' value={search} onChange={this.updateSearch} placeholder='Search URLs'/>
            }
            {csvData &&
              <CSVLink data={csvData} filename={`Klipr_Coverage Database_${moment(new Date()).format('DD.MM.YYYY')}.csv`} className='button amber' target='_blank' onClick={this.checkUserType}>Download CSV</CSVLink>
            }
            {/* {pageCount > 3 &&
              <Paginator pageCount={pageCount} currentPage={page} onPageChange={this.handlePageChange}/>
            } */}
          </FilterBar>

          {(!noLinks && tableSettings.columns) ? (
            <VirtualizedTable
              rowHeight={rowHeight} headerHeight={headerHeight} height={height}
              rowCount={rowCount} rows={rows} rowRenderer={this.rowRenderer}
              scrollToIndex={scrollToIndex} scrollToAlignment='start'
              sort={this.sort} sortBy={sortBy} sortDirection={sortDirection}
              tableSettings={tableSettings} updateTableSettings={this.updateTableSettings} saveTableSettings={this.saveTableSettings} user={this.props.user}
              teamName={this.props.currentTeam.name}
            >
              <Column label='URL' dataKey='url' width={250} flexGrow={1} headerRenderer={this.headerRenderer} cellRenderer={this.linkCellRenderer}/>
              {tableSettings.columns.campaign_name.show &&
                <Column label='Campaign Name' dataKey='campaign_name' width={170} headerRenderer={this.headerRenderer}/>
              }
              {tableSettings.columns.company_name.show &&
                <Column label='Company Name' dataKey='company_name' width={170} headerRenderer={this.headerRenderer}/>
              }
              {tableSettings.columns.date_created.show &&
                <Column label='Date Added' dataKey='date_created' width={170} headerRenderer={this.headerRenderer} cellRenderer={this.dateCellRenderer}/>
              }
              {tableSettings.columns.date_published.show &&
                <Column label='Date Published' dataKey='date_published' width={170} headerRenderer={this.headerRenderer} cellRenderer={this.dateCellRenderer}/>
              }
              {tableSettings.columns.type.show &&
                <Column label='Coverage Type' dataKey='type' width={100} headerRenderer={this.headerRenderer}/>
              }
              {tableSettings.columns.domain_rating.show &&
                <Column label='Domain Rating' dataKey='domain_rating' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
              }
              {tableSettings.columns.shares.show &&
                <Column label='Shares' dataKey='shares' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
              }
              {tableSettings.columns.klipr_score.show &&
                <Column label='Klipr Score' dataKey='klipr_score' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
              }
              {tableSettings.columns.campaign_type.show &&
                <Column label='Campaign Type' dataKey='campaign_type' width={120} headerRenderer={this.headerRenderer} cellRenderer={this.textCellRenderer}/>
              }
              {tableSettings.columns.ga_sessions.show &&
                <Column label='Google Analytics Sessions' dataKey='ga_sessions' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
              }
              {tableSettings.columns.anchor_term.show &&
                <Column label='Anchor Text' dataKey='anchor_term' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.anchorCellRenderer}/>
              }
              {!this.props.user.is_client &&
                <Column label='' dataKey='id' width={70} disableSort headerRenderer={this.tableSettingsRenderer} cellRenderer={this.actionCellRenderer}/>
              }
            </VirtualizedTable>
          ) : (
            <NoResults>There are currently no URLs</NoResults>
          )}
          <ApprovalModal show={showApproveModal} content={approveModalContent} approveAction={() => window.open('https://klipr.io/pricing/', '_blank')} closeAction={() => this.setState({ showApproveModal: false })} approveButtonText='Upgrade Now' closeButtonText='Close'/>
        </div>
      </div>
    )
  }
}

export default Coverage
