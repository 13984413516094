import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Avatar from '../components/avatar'
import Loader from '../components/loader'
import Form from '../components/form'
import Toggle from '../components/toggle'
import * as vars from '../assets/vars'

const AllowanceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const AddTeam = ({ onSubmit, onChange, preview, team }) => (
  <div className='narrow'>
    <div className='panelHead'>
      <h2>{team.name}</h2>
      <Link to='/teams' className='link'>
        <img src={vars.icons.arrow} alt='Back to all teams'/> all teams
      </Link>
    </div>

    <Form action='/' onSubmit={onSubmit}>
      <fieldset className='halfWidth'>
        <label>Team Name*</label>
        <input name='name' type='text' onChange={onChange} value={team.name} required/>
      </fieldset>
      <fieldset className='halfWidth'>
        <label>Email*</label>
        <input name='email' type='email' onChange={onChange} value={team.email} required/>
      </fieldset>
      <fieldset className='halfWidth'>
        <label htmlFor='teamImg'>Profile Image (png or jpg)</label>
        <input id='teamImg' name='logo' type='file' accept='image/png, image/jpeg' onChange={preview}/>
      </fieldset>
      <fieldset className='halfWidth'>
        <Avatar image={team.logo} name={team.name} big/>
      </fieldset>
      <fieldset className='halfWidth'>
        <label>Max Companies*</label>
        <input name='max_clients' type='number' onChange={onChange} value={team.max_clients} required/>
      </fieldset>
      <fieldset className='halfWidth'>
        <label>Max Campaigns*</label>
        <input name='max_campaigns' type='number' onChange={onChange} value={team.max_campaigns} required/>
      </fieldset>
      <fieldset className='fullWidth'>
        <label>Team Plan Type</label>
        <select name='type' onChange={onChange} value={team.type}>
          <option value='free'>Free plan</option>
          <option value='paid'>Paid plan</option>
          <option value='7-day-trial'>7 day trial</option>
        </select>
      </fieldset>
      <fieldset className='halfWidth'>
        <label>Allow Access to Journalist Email Finder</label>
        <Toggle id='tool_access_journalist_finder' name='tool_access_journalist_finder' onChange={onChange} isChecked={team.tool_access_journalist_finder}/>
      </fieldset>
      <fieldset className='halfWidth'>
        <label>Limit Usage</label>
        <Toggle id='allowance_unlimited' name='allowance_unlimited' onChange={onChange} isChecked={!team.allowance_unlimited}/>
      </fieldset>
      {!team.allowance_unlimited && (
        <AllowanceWrapper>
          <fieldset className='halfWidth'>
            <label>Max Klips*</label>
            <input name='max_urls' type='number' min={1} onChange={onChange} value={team.max_urls} required/>
          </fieldset>
          <fieldset className='halfWidth'>
            <label>Allowance Duration*</label>
            <select name='allowance_period_duration' onChange={onChange} value={team.allowance_period_duration} required>
              <option value='' disabled>Select duration</option>
              <option value='daily'>Daily</option>
              <option value='weekly'>Weekly</option>
              <option value='monthly'>Monthly</option>
              <option value='yearly'>Yearly</option>
            </select>
          </fieldset>
          <fieldset className='halfWidth'>
            <label>Allowance Start Date*</label>
            <input name='allowance_date_start' type='date' onChange={onChange} value={team.allowance_date_start} max={team.allowance_date_end ? team.allowance_date_end : ''} required/>
          </fieldset>
          <fieldset className='halfWidth'>
            <label>Allowance End Date</label>
            <input name='allowance_date_end' type='date' onChange={onChange} value={team.allowance_date_end} min={team.allowance_date_start ? team.allowance_date_start : ''}/>
          </fieldset>
        </AllowanceWrapper>
      )}
      {team.loading ? (
        <button type='button' disabled><Loader small/></button>
      ) : (
        <button type='submit'>{team.id ? 'Update Team' : 'Add Team'}</button>
      )}
    </Form>
  </div>
)

export default AddTeam
