/* eslint-disable */

import React, { useMemo } from 'react'
import Select from 'react-select'
import { Link } from 'react-router-dom'
import Loader from '../components/loader'
import Form from '../components/form'
import * as vars from '../assets/vars'

const AddUrls = ({ onSubmit, onChange, urls, loading, match, assignedUserId, onUserChanged, users }) => {
  const userOptions = useMemo(() => {
    return users
      ?.map((user, index) => ({
        label: user.name,
        value: user.id
      }))
  },[users])
  return (
    <div className="narrow">
      <div className="panelHead">
        <h2>Bulk Add URLs</h2>
        <Link to={`/campaign/${match.params.company}/${match.params.campaign}`} className="link">
          <img src={vars.icons.arrow} alt="Back to all company campaigns"/> campaign
        </Link>
      </div>

      <Form action="/" onSubmit={onSubmit}>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
          <fieldset>
            <label>URLs (one per line)*</label>
            <textarea name="urls" onChange={onChange} value={urls} rows="8" required/>
          </fieldset>
          <fieldset style={{ maxWidth: 300 }}>
            <label>Assigned user</label>
            <Select id="assign-user"
                    name="assign-user"
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        border: 'none',
                        color: `${vars.colors.teal}`,
                        backgroundColor: `${vars.colors.white}`,
                        boxShadow: `0px 3px 6px ${vars.colors.lightShadow}`,
                        willChange: 'box-shadow',
                        transition: 'box-shadow .3s ease-out',
                        '&:hover': {
                          boxShadow: `0px 1px 3px ${vars.colors.lightShadow}`
                        },
                        padding: '10px 15px'
                      }),
                      placeholder: (base) => ({
                        ...base,
                        color: '#707070'
                      }),
                      input: base => ({
                        ...base,
                        boxShadow: 'none'
                      })
                    }}
                    onChange={onUserChanged}
                    value={userOptions?.find(user => user.value === assignedUserId)}
                    required // not working
                    options={
                      userOptions
                    }
                    isLoading={!userOptions}
                    isSearchable
              // placeholder={campaign.buzzstream_name || 'Folder → Project Name'}
            />
          </fieldset>
          {loading ? (
            <button type="button" disabled><Loader small/></button>
          ) : (
            <button type="submit">Add URLs</button>
          )}
        </div>
      </Form>
    </div>
  )
}

export default AddUrls
