import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import ShareModal from '../components/shareModal'
import Loader from '../components/loader'
import * as vars from '../assets/vars'

const CompanyUrlButton = styled.button`
  height: 40px;
  width: 160px;
  padding: 5px 15px !important;

  &:disabled {
    pointer-events: none;
  }
  > div {
    height: 100%;
  }
`
const URLCopyBox = styled.div`
  position: relative;
  flex: 0 1 auto;
  display: inline-block;
  margin: 0 15px;
  vertical-align: middle;
  overflow: hidden;

  input {
    position: absolute;
    top: -1000px;
  }
`
const OgImg = styled.div`
  display: flex;
  align-items: center;
  max-height: 650px;
  overflow: hidden;

  > div {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`
const NotableCoverage = styled.div`
  padding: 0 0 35px;

  h3 {
    margin: 0 0 1.5em;
    font-size: 1.8em;
    text-align: center;

    &:before {
      content: '';
      display: block;
      width: 90%;
      max-width: 185px;
      height: 2px;
      margin: 0 auto 1.5em;
      background-color: ${vars.colors.blue};
    }
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: calc(158px * 5);
    margin: 0 auto;
  }
  img {
    width: 60px;
    margin: 30px 15px 0;

    &[src=""] {
      visibility: hidden;
    }
  }
`
const CoverageInsights = styled.div`
  width: 100%;

  h3 {
    margin: 0 0 1.5em;
    font-size: 1.8em;
    text-align: center;

    &:before {
      content: '';
      display: block;
      width: 90%;
      max-width: 185px;
      height: 2px;
      margin: 0 auto 1.5em;
      background-color: ${vars.colors.blue};
    }
  }
`
const CoverageBlocks = styled.ul`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 30px 0 10px;
  padding: 0;
  list-style: none;

  li {
    flex: 1 0 calc(25% - 90px / 4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 160px;
    margin: 0 10px 20px;
    padding: 15px;
    color: ${vars.colors.darkGrey};
    font-size: 2em;
    font-weight: 600;
    text-align: center;
    background: ${vars.colors.offWhite};
    background-position: 10px 12px;
    background-repeat: no-repeat;
    background-size: 32px;
    box-shadow: 2px 3px 4px ${vars.colors.lightShadow};
    box-sizing: border-box;

    &:nth-child(4n) {
      @media (min-width: 768px) {
        margin-right: 0;
      }
    }
    &:first-child, &:nth-child(5) {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &.scroll {
      background: none;
      box-shadow: none;

      > span {
        margin-bottom: 15px;
      }
    }
    span:last-of-type {
      display: block;
      margin: 10px 0 0;
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
    }
    > img {
      width: 40px;
      height: auto;
    }
  }
`
const CoveragePod = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 35px;
  padding: 0 0 35px;
  border-bottom: 1px solid ${vars.colors.lightGrey};

  &:last-child {
    border: 0;
  }
  h4 {
    flex: 1 0 100%;
    font-weight: 600;
    font-size: 1.4em;
  }
`
const CoverageImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 70%;
  position: relative;

  a {
    width: 100%;
  }
  img {
    display: block;
    width: 100%;
    border: 1px solid ${vars.colors.lightGrey};
    box-shadow: 2px 3px 4px ${vars.colors.lightShadow};
  }
`
const LoadingWrapper = styled.div`
  width: 100%;

  p {
    text-align: center;
    color: ${vars.colors.amber};
  }
`
const Stats = styled.div`
  flex: 1 1 auto;

  @media (min-width: 1024px) {
    padding-left: 35px;
  }

  ul {
    @media (min-width: 1024px) {
      display: block;
      margin: 0;
    }
  }
  li {
    min-height: 125px;

    @media (min-width: 1024px) {
      margin: 0 0 20px;
    }
  }
  a {
    display: block;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    color: ${vars.colors.white};
    font-weight: 600;
    font-size: 0.95em;
    border-radius: 3px;
    background-image: url(${vars.icons.newWindow}) calc(100% - 20px) center no-repeat;
    background-size: 14px;
    background-color: ${vars.colors.blue};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.blue)};
      }
    }
    @media (min-width: 1024px) {
      margin: 50px 0 0;
    }
  }
`

class Lookbook extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      campaign: false,
      campaignId: null,
      links: false,
      topLinks: false,
      totalShares: 0,
      ogimg: '',
      bookUrl: null,
      generatingLink: false,
      showShareModal: false,
      currentPath: null
    }
  }
  componentDidMount() {
    this.getCampaignDetails(this.props.match.params.campaign)
  }
  componentWillUnmount() {
    if (this.state.currentPath) {
      window.history.replaceState(window.history.state, '', this.state.currentPath)
    }
    localStorage.removeItem('klipr_preshare_path')
  }

  getCampaignDetails = campaignId => {
    this.setState({ campaignId })
    this.props.callAPI(`campaigns/${campaignId}`).then(result => {
      this.getCompanyDetails(result)
    })
  }
  getCompanyDetails = campaign => {
    this.props.callAPI(`companies/${campaign.company_id}`).then(result => {
      this.getCampaignLinks({ ...campaign, companyName: result.name, hasLicence: result.has_screenshot_licence })
    })
  }
  getCampaignLinks = campaign => {
    this.props.callAPI(`campaigns/${campaign.id}/links`).then(result => {
      const notableLink = result.filter(link => link.domain_rating !== 'TBC' && link.domain_rating > 0)
      this.getCampaignMetrics({ ...campaign, links: notableLink })
    })
  }
  getCampaignMetrics = campaign => {
    this.props.callAPI(`campaigns/${campaign.id}/dataset`).then(result => {
      this.setState({
        campaign: {
          ...campaign,
          link_total: result.total_links_count,
          follow: result.follow_links_count,
          nofollow: result.nofollow_links_count,
          mention: result.mention_links_count,
          other: result.other_links_count
        },
        totalShares: result.total_shared_count ? result.total_shared_count : 'TBC',
        avgRating: result.average_domain_rating === 0 || result.average_domain_rating > 0 ? result.average_domain_rating : 'TBC',
        links: campaign.links
      }, () => this.props.setLoading(false))
      this.getOG()
      this.getTopCoverage()
      this.getCoverageScreenShots()
    })
  }
  getOG = () => {
    this.props.callAPI(`campaigns/${this.state.campaign.id}/image/opengraph`).then(response => {
      this.setState({ ogimg: response })
    })
  }
  getTopCoverage = () => {
    const links = this.state.links.filter(link => link.domain_rating >= 50).slice(0, 10)
    links.sort((a, b) => {
      const a1 = typeof a.domain_rating
      const b1 = typeof b.domain_rating
      return a1 < b1 ? -1
        : a1 > b1 ? 1
          : a.domain_rating < b.domain_rating ? 1
            : a.domain_rating > b.domain_rating ? -1 : 0
    })

    let count = 0
    const linksWithLogos = [...links]
    linksWithLogos.forEach((link, i) => {
      this.props.callAPI(`links/${link.id}/image/favicon`).then(response => {
        linksWithLogos[i].logo = response
        count += 1

        if (count === links.length) {
          const topLinks = linksWithLogos.filter(x => x.logo?.length)
          this.setState({ topLinks })
        }
      }).catch(() => { count += 1 })
    })
  }
  getCoverageScreenShots = () => {
    const { links } = this.state
    let count = 0
    const linksWithImages = [...links]
    linksWithImages.forEach((link, i) => {
      this.props.callAPI(`links/${link.id}/image/${this.state.campaign.hasLicence ? 'screenshot' : 'opengraph'}`).then(response => {
        linksWithImages[i].image = response
        count += 1

        if (count === links.length) {
          this.setState({ links: linksWithImages })
        }
      }).catch(() => { count += 1 })
    })
  }
  setBookUrl = () => {
    if (this.state.bookUrl) {
      this.setState({
        showShareModal: true,
        currentPath: this.props.location.pathname
      })
      localStorage.setItem('klipr_preshare_path', this.props.location.pathname)
      window.history.replaceState(window.history.state, '', this.state.bookUrl)
    } else {
      const shortlink = localStorage.getItem('klipr_shortlink') ? localStorage.getItem('klipr_shortlink') : null

      if (shortlink) {
        this.setState({
          generatingLink: false,
          showShareModal: true,
          currentPath: this.props.location.pathname,
          bookUrl: `${__BASEURL__}book/${shortlink}`
        })
        localStorage.setItem('klipr_preshare_path', this.props.location.pathname)
        window.history.replaceState(window.history.state, '', `/book/${shortlink}`)
      } else if (this.state.campaignId && !this.props.user.is_client) {
        this.setState({ generatingLink: true })
        this.props.callAPI(`campaigns/${this.state.campaignId}/generateshortlink`).then(result => {
          if (result.shortlink) {
            setTimeout(() => {
              this.setState({
                generatingLink: false,
                showShareModal: true,
                currentPath: this.props.location.pathname,
                bookUrl: `${__BASEURL__}book/${result.shortlink}`
              })
              localStorage.setItem('klipr_preshare_path', this.props.location.pathname)
              window.history.replaceState(window.history.state, '', `/book/${result.shortlink}`)
            }, 500)
          } else {
            this.setState({ generatingLink: false })
          }
        })
      }
    }
  }
  copyBookUrl = () => {
    if (this.state.bookUrl) {
      const bookUrl = document.getElementById('bookUrl')
      const copyUrl = document.querySelector('#copyUrl')
      bookUrl.value = this.state.bookUrl
      bookUrl.select()

      try {
        const successful = document.execCommand('copy')
        copyUrl.innerHTML = successful ? 'URL Copied' : 'Try again'
        setTimeout(() => { copyUrl.innerHTML = 'Copy URL' }, 1500)
      } catch (err) {
        this.props.error('Unable to copy URL.')
      }
    } else {
      this.props.error('Unable to copy URL.')
    }
  }
  closeShareModal = () => {
    this.setState({ showShareModal: false })

    if (this.state.currentPath) {
      window.history.replaceState(window.history.state, '', this.state.currentPath)
    }
    localStorage.removeItem('klipr_preshare_path')
  }

  render() {
    const { campaign } = this.state

    return (
      <div>
        {campaign && (
          <>
            <div className='panelHead'>
              <h2>
                {campaign.name}
                {/* <span>{campaign.companyName}&nbsp;-</span>&nbsp;{campaign.name}&nbsp;<span>Lookbook</span> */}
              </h2>

              <div>
                <Link to={`/campaign/${this.props.match.params.company}/${this.props.match.params.campaign}`} className='link'><img src={vars.icons.arrow} alt='Back to campaign'/>back to campaign</Link>
                <URLCopyBox>
                  <CompanyUrlButton type='button' className='button' disabled={this.state.generatingLink} onClick={this.setBookUrl}>
                    {this.state.generatingLink ? <Loader small/> : (
                      <span>Share & Bookmark</span>
                    )}
                  </CompanyUrlButton>
                  <input id='bookUrl' type='text' defaultValue=''/>
                </URLCopyBox>
              </div>
            </div>

            <OgImg>
              {this.state.ogimg === '' &&
                <Loader small/>
              }

              {this.state.ogimg !== 'empty' && this.state.ogimg !== '' && (
                <img src={this.state.ogimg} alt='Campaign opengraph' onError={e => {
                  e.target.onerror = null
                  e.target.style.display = 'none'
                }}
                />
              )}
            </OgImg>

            <CoverageBlocks>
              <li>
                <span>{campaign.link_total}</span>
                <span>Total coverage</span>
              </li>
              {campaign.follow > 0 && (
                <li>
                  <span>{campaign.follow}</span>
                  <span>Follow links</span>
                </li>
              )}
              {campaign.nofollow > 0 && (
                <li>
                  <span>{campaign.nofollow}</span>
                  <span>Nofollow links</span>
                </li>
              )}
              {campaign.mention > 0 && (
                <li>
                  <span>{campaign.mention}</span>
                  <span>Mentions</span>
                </li>
              )}
              {campaign.other > 0 && (
                <li>
                  <span>{campaign.other}</span>
                  <span>Other links</span>
                </li>
              )}
              <li>
                <span>{this.state.avgRating}</span>
                <span>Avg. domain rating</span>
              </li>
              <li>
                <span>{this.state.totalShares}</span>
                <span>Total shares</span>
              </li>
            </CoverageBlocks>

            {!!this.state.topLinks.length && (
              <NotableCoverage>
                <h3>Notable Coverage</h3>
                <div>
                  {this.state.topLinks.map(link => (
                    <img key={link.url} src={link.logo} alt={link.url} title={new URL(link.url).hostname}/>
                  ))}
                </div>
              </NotableCoverage>
            )}

            <CoverageInsights>
              <h3>Coverage Insight</h3>
              {this.state.links.length ?
                this.state.links.filter((s1, pos, arr) => arr.findIndex(s2 => s2.url.split('/')[2].replace('www.', '') === s1.url.split('/')[2].replace('www.', '')) === pos).map(link => (
                  <CoveragePod key={link.url}>
                    <h4>{link.url.split('/')[2].replace('www.', '')}</h4>

                    <CoverageImage>
                      {link.image === undefined ? (
                        <Loader small/>
                      ) : (
                        <a href={link.url}><img src={link.image} alt='Coverage screenshot'
                          onError={e => {
                            e.target.onerror = null
                            e.target.src = vars.images.placeholder
                          }}
                        />
                        </a>
                      )}
                    </CoverageImage>

                    <Stats>
                      <CoverageBlocks>
                        <li>
                          <span>{link.domain_rating}</span>
                          <span>Domain rating</span>
                        </li>
                        <li>
                          <span>{link.type}</span>
                          <span>Coverage type</span>
                        </li>
                        <li>
                          <span>
                            {typeof link.shares === 'number' ? link.shares.toLocaleString() : 0}
                          </span>
                          <span>Shares</span>
                        </li>
                      </CoverageBlocks>
                      <a href={link.url} target='_blank' rel='noopener noreferrer'>View coverage</a>
                    </Stats>
                  </CoveragePod>
                )) : (
                  <LoadingWrapper>
                    <p>Collecting data</p>
                    <Loader small/>
                  </LoadingWrapper>
                )
              }
            </CoverageInsights>
            <ShareModal url={this.state.bookUrl} show={this.state.showShareModal} copy={this.copyBookUrl} close={this.closeShareModal}/>
          </>
        )}
      </div>
    )
  }
}

export default Lookbook
