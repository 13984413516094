/* eslint-disable */

import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import posed from 'react-pose'
import Loader from '../components/loader'
import Form from '../components/form'
import ApprovalModal from '../components/approvalModal'
import MultiToggle from '../components/multiToggle'
import * as vars from '../assets/vars'
import Select from 'react-select'

const AnimatedWrapper = posed.div({
  show: { opacity: 1 },
  hide: { opacity: 0 }
})
const StateCopy = styled.p`
  font-size: 14px;
  font-style: italic;
  line-height: 16px;
  min-height: 32px;
  margin: 0 auto;
  width: 80%;
`
const SelectFieldset = styled.fieldset`
  input {
    box-shadow: none;
  }
`
const UrlWrapper = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: center;

  input {
    width: calc(100% - 110px);
  }
`
const FetchTypeSelect = styled.div`
  width: 110px;
  height: 100%;
  position: absolute;
  top: 0; right: 0;
  cursor: pointer;
  background: url(${vars.icons.arrow}) no-repeat 95% center transparent;
  background-size: 14px;
  color: ${vars.colors.teal};
  background-color: ${vars.colors.white};
  box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
  will-change: box-shadow;
  transition: box-shadow .3s ease-out;

  &:hover {
    @media (hover: hover) {
      box-shadow: 0px 1px 3px ${vars.colors.lightShadow};
    }
  }

  > div:first-of-type {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 100%;
    text-transform: capitalize;
  }
`
const TypeList = styled(AnimatedWrapper)`
  display: inline-block;
  position: relative;
  z-index: 1;
  width: 190px;
  margin-left: -80px;
  cursor: pointer;
  pointer-events: ${props => (props.pose === 'show' ? 'auto' : 'none')};

  div {
    padding: 5px;
    box-shadow: 0px 1px 3px ${vars.colors.lightShadow};
    will-change: background-color, color;
    transition: background-color .3s ease-out, color .3s ease-out;

    &:first-of-type {
      background-color: ${props => (props.type === 'prefix' ? `${vars.colors.green} !important` : vars.colors.white)};
      color: ${props => (props.type === 'prefix' ? `${vars.colors.white} !important` : vars.colors.teal)};
    }
    &:last-of-type {
      background-color: ${props => (props.type === 'exact' ? `${vars.colors.green} !important` : vars.colors.white)};
      color: ${props => (props.type === 'exact' ? `${vars.colors.white} !important` : vars.colors.teal)};
    }
    &:hover {
      @media (hover: hover) {
        background-color: ${vars.colors.offWhite};
      }
    }
  }
  p {
    margin: 0 0 5px;
    font-weight: 600;
  }
  span {
    display: block;
    font-size: 14px;

    &:last-of-type {
      font-style: italic;
    }
  }
`

const TYPES = ['Blog', 'Content', 'Reactive', 'Other']

const AddCampaign = ({ onSubmit, approveUrl, domainAsUrl, unsetApprovalModal, onChange, campaign, companies, history, updateCampaignState, buzzstreamEnabled, onBuzzstreamChange, onTypeChange, callApi }) => {
  const [typeSelectExpanded, toggleTypeSelect] = useState(false)
  const [fetchType, setFetchType] = useState(campaign.fetch_type)
  const [buzzstreamProjects, setBuzzstreamProjects] = useState(null)
  const typeSelectRef = useRef()
  const handleOffClick = e => {
    if (!typeSelectRef.current?.contains(e.target)) {
      toggleTypeSelect(false)
    }
  }
  const handleTypeSelectClick = type => {
    setFetchType(type)
    const fakeEvent = { target: { name: 'fetch_type', value: type } }
    onChange(fakeEvent)
  }
  useEffect(() => {
    if (buzzstreamEnabled) {
      callApi('buzzstream/projects').then(response => {
        setBuzzstreamProjects(response.projects || [])
      })
    }
  }, [])
  useEffect(() => {
    setFetchType(campaign.fetch_type)
  }, [campaign])
  useEffect(() => {
    document.addEventListener('click', handleOffClick)

    return () => {
      document.removeEventListener('click', handleOffClick)
    }
  })
  const approveModalContent = `<p>Are you sure you want to set the campaign URL to a homepage (<span>${campaign.url}</span>)?</p><p>This is likely to pick up unrelated URLs and use up your coverage limit more quickly.</p>`
  return (
    <div className='narrow'>
      <div className='panelHead'>
        <h2>{campaign.edit ? 'Edit Campaign' : 'Add Campaign'}</h2>
        {campaign.edit ? (
          <button type='button' className='link' onClick={e => { e.preventDefault(); history.goBack() }}>
            <img src={vars.icons.arrow} alt='Back to campaign'/> back
          </button>
        ) : (
          <Link to={`/campaigns/${campaign.company}`} className='link'>
            <img src={vars.icons.arrow} alt='Back to all company campaigns'/> all campaigns
          </Link>
        )}
      </div>

      <Form action='/' onSubmit={onSubmit}>
        <fieldset className='companyTitle'>
          <label htmlFor='title'>Campaign Title*</label>
          <input id='title' name='title' type='text' onChange={onChange} value={campaign.title} required/>
        </fieldset>
        <fieldset className='halfWidth'>
          <label htmlFor='company'>Company*</label>
          {companies && (
            <select id='company' style={{padding: '18px 24px'}} value={campaign.company} name='company' onChange={onChange} required>
              <option value='' disabled>Select Company</option>
              {companies.map(cl => (
                <option key={cl.id} value={cl.id}>{cl.name}</option>
              ))}
            </select>
          )}
        </fieldset>
        <fieldset className='halfWidth'>
          <label>Campaign Start Date*</label>
          <input name='date_started' type='date' onChange={onChange} value={campaign.date_started} required/>
        </fieldset>
        <fieldset className='companyUrl' >
          <label htmlFor='url'>Campaign URL*</label>
          <UrlWrapper>
            <input id='url' name='url' type='url' onChange={onChange} value={campaign.url} disabled={campaign.edit} required/>
            <FetchTypeSelect ref={typeSelectRef} className='urlPrefix' onClick={() => toggleTypeSelect(!typeSelectExpanded)}>
              <div>{fetchType}</div>
              <TypeList pose={typeSelectExpanded ? 'show' : 'hide'} type={fetchType}>
                <div onClick={() => handleTypeSelectClick('prefix')}>
                  <p>Prefix (Recommended)</p>
                  <span>All pages beginning with</span>
                  <span>example.com/path/*</span>
                </div>
                <div onClick={() => handleTypeSelectClick('exact')}>
                  <p>Exact URL</p>
                  <span>This URL only</span>
                  <span>example.com/path/</span>
                </div>
              </TypeList>
            </FetchTypeSelect>
          </UrlWrapper>
          {campaign.edit &&
            <p><em>You cannot edit a campaign URL once created.</em></p>
          }
        </fieldset>
        <fieldset className='halfWidth'>
          <label>Link KPI*</label>
          <input name='follow_link_kpi_target' required type='number' onChange={onChange} value={campaign.follow_link_kpi_target} min={0}/>
        </fieldset>
        <fieldset className='halfWidth'>
          <label htmlFor="type">Campaign Type*</label>
          <Select id="type"
                  name="type"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: 'none',
                      color: `${vars.colors.teal}`,
                      backgroundColor: `${vars.colors.white}`,
                      boxShadow: `0px 3px 6px ${vars.colors.lightShadow}`,
                      willChange: 'box-shadow',
                      transition: 'box-shadow .3s ease-out',
                      '&:hover': {
                        boxShadow: `0px 1px 3px ${vars.colors.lightShadow}`
                      },
                      padding: '0 15px'
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#707070'
                    }),
                    input: base => ({
                      ...base,
                      boxShadow: 'none'
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      paddingTop: 0,
                      paddingBottom: 0
                    })
                  }}
                  onChange={onTypeChange}
                  value={campaign.type}
                  options={
                    TYPES.map((type) => ({
                        label: type,
                        value: type
                      }))
                  }
                  isSearchable={false}
                  placeholder={campaign.type || 'Select...'}
          />
        </fieldset>

        {
        buzzstreamEnabled &&
        <SelectFieldset>
          {buzzstreamProjects && buzzstreamProjects.length === 0 && <div style={{ color: 'red', marginBottom: 5 }}>Error: couldn't fetch the projects, please try again later</div>}
          <label htmlFor="buzzstream_name">Project Name on BuzzStream*</label>
          <Select id="buzzstream_name"
                  name="buzzstream_name"
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      border: 'none',
                      color: `${vars.colors.teal}`,
                      backgroundColor: `${vars.colors.white}`,
                      boxShadow: `0px 3px 6px ${vars.colors.lightShadow}`,
                      willChange: 'box-shadow',
                      transition: 'box-shadow .3s ease-out',
                      '&:hover': {
                        boxShadow: `0px 1px 3px ${vars.colors.lightShadow}`
                      },
                      padding: '10px 15px'
                    }),
                    placeholder: (base) => ({
                      ...base,
                      color: '#707070'
                    }),
                    input: base => ({
                      ...base,
                      boxShadow: 'none'
                    }),
                  }}
                  onChange={onBuzzstreamChange}
                  value={campaign.buzzstream_id}
                  required // not working
                  options={
                    buzzstreamProjects
                      ?.map((project, index) => ({
                        label: renderProjectName(project.name),
                        value: project.id
                      }))
                  }
                  isLoading={buzzstreamProjects === null}
                  isClearable
                  isSearchable
                  placeholder={campaign.buzzstream_name || 'Folder → Project Name'}
          />
          <div style={{marginTop: 10}}>Still don't have one? <a href={`https://app.buzzstream.com/`} target='_blank' style={{color: 'blue'}}>create</a></div>
        </SelectFieldset>
        }
        <fieldset>
          <label htmlFor='keywords'>Keywords</label>
          <textarea id='keywords' name='keywords' onChange={onChange} value={campaign.keywords}/>
          <p><em>Keywords are used to identify campaign coverage that includes a backlink to the domain, but not the specific content campaign. Enter terms you believe may be used as anchor text for this specific campaign on publications - e.g. the campaign name or unique parts of the campaign name.</em></p>
          <p><em>Entering in 1-word phrases such as the brand name or a generic term could result in non-related campaign URLs being added.</em></p>
        </fieldset>

        {campaign.edit && (
          <fieldset>
            <label htmlFor='update_status'>Update campaign status</label>
            <MultiToggle id='update_status' name='update_status' onChange={updateCampaignState} states={campaign.states} status={campaign.status}/>
            <StateCopy>{campaign.stateCopy[Object.keys(campaign.states).find(state => campaign.states[state])]}</StateCopy>
          </fieldset>
        )}

        {campaign.loading ? (
          <button type='button' disabled><Loader small/></button>
        ) : (
          <button type='submit'>{campaign.edit ? 'Update campaign' : 'Add campaign'}</button>
        )}
      </Form>
      <ApprovalModal show={domainAsUrl} content={approveModalContent} approveAction={approveUrl} closeAction={unsetApprovalModal} approveButtonText={campaign.edit ? 'Update' : 'Add'} closeButtonText='Cancel'/>
    </div>
  )
}

export default AddCampaign


function renderProjectName(name) {
  if (!name) return ''
  if (name.includes(`\\`)) {
    const chunks = name.split(`\\`)
    let finalName = chunks[0] || ''
    for (let i=1; i<chunks.length; i++) {
      finalName += ` → ${chunks[i]}`
    }
    return finalName
  }
  return name
}
