import add from './icons/add.svg'
import arrow from './icons/arrow.svg'
import cog from './icons/cog.svg'
import cross from './icons/cross.svg'
import crossRed from './icons/crossRed.svg'
import edit from './icons/edit.svg'
import info from './icons/info.svg'
import logo from './icons/logo.svg'
import plus from './icons/plus.svg'
import padlock from './icons/padlock.svg'
import download from './icons/download.svg'
import remove from './icons/remove.svg'
import search from './icons/search.svg'
import searchWhite from './icons/searchWhite.svg'
import placeholder from './placeholder.jpg'
import downChevron from './icons/chevronDown.svg'
import rightChevron from './icons/chevronRight.svg'
import newWindow from './icons/newWindow.svg'
import emailFinder from './icons/emailFinder.svg'
import google from './icons/google.svg'
import email from './icons/email.svg'
import tick from './icons/tick.svg'
import clock from './icons/clock.svg'
import pattern from './icons/pattern.svg'
import warning from './icons/warning.svg'
import etoro from './icons/etoro.svg'
import lastminute from './icons/lastminute.svg'
import msm from './icons/msm.svg'
import npower from './icons/npower.svg'
import openInNew from './icons/open-in-new.svg'

import img0 from './loader/img_0.png'
import img1 from './loader/img_1.png'
import img2 from './loader/img_2.png'
import img3 from './loader/img_3.png'
import img4 from './loader/img_4.png'
import img5 from './loader/img_5.png'
import img6 from './loader/img_6.png'
import img7 from './loader/img_7.png'
import img8 from './loader/img_8.png'
import img9 from './loader/img_9.png'
import img10 from './loader/img_10.png'
import img11 from './loader/img_11.png'
import img12 from './loader/img_12.png'
import img13 from './loader/img_13.png'
import img14 from './loader/img_14.png'
import img15 from './loader/img_15.png'
import img16 from './loader/img_16.png'
import img17 from './loader/img_17.png'
import img18 from './loader/img_18.png'
import img19 from './loader/img_19.png'
import img20 from './loader/img_20.png'
import img21 from './loader/img_21.png'
import img22 from './loader/img_22.png'
import img23 from './loader/img_23.png'
import img24 from './loader/img_24.png'
import img25 from './loader/img_25.png'
import img26 from './loader/img_26.png'
import img27 from './loader/img_27.png'
import img28 from './loader/img_28.png'
import img29 from './loader/img_29.png'
import img30 from './loader/img_30.png'
import img31 from './loader/img_31.png'
import img32 from './loader/img_32.png'
import img33 from './loader/img_33.png'
import img34 from './loader/img_34.png'
import img35 from './loader/img_35.png'
import img36 from './loader/img_36.png'
import img37 from './loader/img_37.png'
import img38 from './loader/img_38.png'
import img39 from './loader/img_39.png'
import img40 from './loader/img_40.png'
import img41 from './loader/img_41.png'
import img42 from './loader/img_42.png'
import img43 from './loader/img_43.png'
import img44 from './loader/img_44.png'
import img45 from './loader/img_45.png'
import img46 from './loader/img_46.png'

const icons = { add, arrow, cog, cross, crossRed, edit, info, logo, plus, padlock, download, remove, search, searchWhite, downChevron, rightChevron, newWindow, emailFinder, google, email, tick, clock, pattern, warning, etoro, lastminute, msm, npower, openInNew }
const images = { placeholder }
const loader = { img0, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21, img22, img23, img24, img25, img26, img27, img28, img29, img30, img31, img32, img33, img34, img35, img36, img37, img38, img39, img40, img41, img42, img43, img44, img45, img46 }
const colors = {
  white: '#FFFFFF',
  offWhite: '#F5F5F5',
  black: '#000000',
  teal: '#465E69',
  grey: '#707070',
  lightGrey: '#C2C2C2',
  darkGrey: '#484848',
  lightShadow: '#00000030',
  darkShadow: '#00000070',
  green: '#01B88E',
  red: '#FC425D',
  amber: '#FDB002',
  blue: '#2869CA'
}
const sizes = {
  mobileS: '(min-width: 320px)',
  mobileM: '(min-width: 375px)',
  mobileL: '(min-width: 425px)',
  tablet: '(min-width: 768px)',
  laptop: '(min-width: 1024px)',
  laptopL: '(min-width: 1440px)',
  desktop: '(min-width: 2560px)'
}

export { icons, images, loader, colors, sizes }
