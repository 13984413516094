import React from 'react'
import styled from 'styled-components'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 30px;
`
const Toggle = styled.div`
  display: flex;
  width: calc(100% / 3);
  margin: 10px;

  input {
    display: none;

    &::selection {
      background: none;
    }
    &:checked + label {
      &.inactive {
        color: ${vars.colors.red};
      }
      &.manual {
        color: ${vars.colors.amber};
      }
      &.auto {
        color: ${vars.colors.green};
      }
    }
  }

  label {
    outline: 0;
    display: block;
    width: 100%;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding: 5px;
    text-align: center;
    text-transform: capitalize;
    color: ${vars.colors.grey};
    font-weight: 600;
    will-change: color;
    transition: color .3s ease-out;

    &:before, &:after {
      content: '';
      background-color: ${vars.colors.grey};
      border-radius: 50%;
      width: 30px; height: 30px;
      position: absolute;
      top: 37px; left: calc(50% - 15px);
      cursor: pointer;
      z-index: 1;
    }
    &:after {
      width: 24px; height: 24px;
      top: 40px; left: calc(50% - 12px);
      background-color: ${props => (props.level === 'high' ? vars.colors.green : props.level === 'mid' ? vars.colors.amber : vars.colors.red)};
      will-change: background-color;
      transition: background-color .3s ease-out;
    }
    &.manual {
      &:after {
        display: ${props => ((props.level === 'high' || props.level === 'mid') ? 'block' : 'none')};
      }
    }
    &.auto {
      &:after {
        display: ${props => (props.level === 'high' ? 'block' : 'none')};
      }
    }
  }
`
const StatusBar = styled.div`
  background-color: ${vars.colors.grey};
  width: 68%;
  height: 20px;
  display: block;
  position: absolute;
  top: 82px; left: 16%;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px; left: 5px;
    height: 12px;
    width: ${props => (props.level === 'high' ? 'calc(100% - 10px)' : props.level === 'mid' ? 'calc(50% - 5px)' : '0')};
    background-color: ${props => (props.level === 'high' ? vars.colors.green : props.level === 'mid' ? vars.colors.amber : vars.colors.red)};
    will-change: width, background-color;
    transition: width .3s ease-out, background-color .3s ease-out;
  }
`

const MultiToggle = props => (
  <Wrapper>
    {Object.keys(props.states).map(state => (
      <Toggle key={state} level={props.states.auto ? 'high' : props.states.manual ? 'mid' : 'low'}>
        <input id={state} name={state} type='radio' onChange={() => props.onChange(state)} checked={props.states[state]}/>
        <label htmlFor={state} className={state}>{state}</label>
      </Toggle>
    ))}
    <StatusBar level={props.states.auto ? 'high' : props.states.manual ? 'mid' : 'low'}/>
  </Wrapper>
)

export default MultiToggle
