/* eslint-disable */
import React from 'react'
import { withRouter } from 'react-router'
import isURL from 'validator/es/lib/isURL'
import AddURLs from '../pages/addUrls'

class BulkUrls extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      urls: '',
      users: null,
      assignedUserId: null
    }
  }

  componentDidMount() {
    this.props.setLoading(false)
    this.getUsers()
  }

  getUsers = () => {
    this.props.callAPI('users').then(result => {
      if (result.length > 0) {
        const users = result.filter(user => !user.is_client)
          .map(user => ({ ...user, name: `${user.first_name} ${user.last_name}` }))
          .sort((u1, u2) => u1.name.localeCompare(u2.name))
        this.setState({ ...this.state, users, assignedUserId: this.props.user.id })
      } else {
        this.setState({ users: null })
      }
    })
  }

  onUserChanged = (event) => {
    this.setState({assignedUserId: event.value})
  }

  addUrls = e => {
    e.preventDefault()

    const urls = this.state.urls.split(/\r?\n/g).filter(url => url.length > 0)
    if (urls.length < 1) {
      this.props.toast.warn('Please provide at least one URL.')
    } else {
      const matchParams = this.props.match.params
      const urlsToProcess = urls.filter(url => url.trim() !== '')
      const invalidUrls = urls.find(url => !isURL(url, {
        protocols: ['http', 'https'],
        require_protocol: true,
        allow_underscores: true
      }))

      if (invalidUrls) {
        this.props.toast.warn('Please ensure all URLs include http:// or https:// protocols.')
      } else {
        this.setState({ loading: true })
        const payload = {
          urls: urlsToProcess,
          campaign: matchParams.campaign,
          userId: this.state.assignedUserId
        }

        this.props.callAPI('links/new/multiple', undefined, 'post', JSON.stringify(payload)).then(result => {
          this.setState({ loading: false })
          if (result.success) {
            if (result.batch_id) {
              localStorage.setItem('klipr_job_batch_details', JSON.stringify({ jobId: result.batch_id, campaignId: matchParams.campaign }))
            }
            this.props.toast.success(`URL${urlsToProcess.length > 1 ? 's' : ''} successfully added.`)
            this.props.history.push(`/campaign/${matchParams.company}/${matchParams.campaign}`)
          }
        })
      }
    }
  }

  changeUrls = e => {
    const urls = e.target.value.replace(/ /g, '')
    this.setState({ urls })
  }

  render() {
    return <AddURLs onSubmit={this.addUrls} onChange={this.changeUrls} urls={this.state.urls} loading={this.state.loading} match={this.props.match} assignedUserId={this.state.assignedUserId} onUserChanged={this.onUserChanged} users={this.state.users}/>
  }
}

export default withRouter(BulkUrls)
