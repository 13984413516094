import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import * as vars from '../assets/vars'

const OutterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 900px;
  margin: auto;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center
  }
`
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${vars.colors.offWhite};
`
const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${({ smallPadding }) => (smallPadding ? '10px 30px' : '30px')};
`
const InnerWrapper = styled.div`
  width: 80%;
  padding-right: 20px;
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 5px 0px;
  width: 100%;

  h2 {
    color: ${vars.colors.teal};
    margin: 0px;
    font-weight: 300;
  }
`
const StyledLink = styled(Link)`
  width: 20%;
  text-decoration: underline;
  color: black;
  text-align: right;
`
const UsageBar = styled.div`
  position: relative;
  height: 15px;
  width: 100%;
  border-radius: 40px;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    background-color: ${vars.colors.offWhite};
  }
  &:after {
    content: '';
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: ${({ usage }) => usage}%;
    border-radius: 30px;
    background-color: ${({ usage }) => (usage >= 100 ? vars.colors.red : usage >= 50 ? vars.colors.amber : vars.colors.green)};
    will-change: background-color, width;
    transition: background-color .3s ease-out, width .3s ease-out;
  }
`
const ResetInfo = styled.div`
  span {
    font-weight: 600;
    color: ${vars.colors.red};
  }
`
const LimitReachedInfo = styled.div`
  p {
    color: ${vars.colors.red};
    margin: 0;
  }
`
const ManageButton = styled.a`
  color: ${vars.colors.black};
  will-change: color;
  transition: color .3s ease-out;

  &:hover {
    @media (hover: hover) {
      color: ${vars.colors.blue};
    }
  }
`
const SUInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  width: 100%;
  position: relative;

  &:before {
    content: '';
    height: 1px;
    background-color: ${vars.colors.lightGrey};
    width: calc(100% + 60px);
    position: absolute;
    top: -20px; left: -30px;
  }
  h3 {
    color: ${vars.colors.blue};
    font-weight: 300;
  }
`
const LinksWrapper = styled.div`
  p {
    margin-top: 10px;
    font-weight: 600;
    font-size: 36px;
  }
`
const CostsWrapper = styled.div`
  text-align: right;
`
const Costs = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  p {
    margin: 0;
  }
  span {
    margin-left: 15px;
    margin-right: 30px;
    font-weight: 600;

    &:last-of-type {
      margin-right: 0;
    }
  }
`
const TotalCost = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 24px;
  margin-top: 10px;

  p {
    margin: 0;
  }
  span {
    margin-left: 20px;
    font-weight: 600;
  }
`

class Plan extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      companiesNo: null,
      campaignsNo: null,
      linksMax: null,
      companiesMax: null,
      campaignsMax: null,
      periodLinksUsage: null,
      periodDuration: null,
      resetDate: null,
      unlimitedUsage: false,
      costs: null
    }
  }
  componentDidMount() {
    if (this.props.currentTeam && this.props.currentTeam.usages) {
      this.setData(this.props.currentTeam)
    } else {
      const teamId = (this.props.user.is_super_user && this.props.currentTeam) ? this.props.currentTeam.id : this.props.user.team_id
      this.getData(teamId)
    }
  }

  getData = teamId => {
    this.props.callAPI(`teams/${teamId}`).then(result => {
      this.getUsagePeriods(result)
    })
  }
  getUsagePeriods = teamData => {
    const withinAllowanceDatePeriod = moment().isAfter(teamData.allowance_date_start) && moment().isBefore(teamData.allowance_date_end)
    if (teamData.allowance_unlimited || !withinAllowanceDatePeriod) {
      const periodData = {
        date_start: moment().subtract(1, 'month').format('YYYY-MM-DD hh:mm:ss'),
        date_end: moment().format('YYYY-MM-DD hh:mm:ss')
      }
      this.getUsage(teamData, periodData)
    } else {
      this.props.callAPI(`teams/${teamData.id}/usageperiod`).then(result => {
        const periodData = {
          date_start: result.date_start,
          date_end: result.date_end
        }
        this.getUsage(teamData, periodData)
      })
    }
  }
  getUsage = (team, periodData) => {
    this.props.callAPI(`teams/${team.id}/usages`, '', 'post', JSON.stringify(periodData)).then(result => {
      const teamData = {
        ...this.props.currentTeam,
        usages: result,
        periodData
      }
      this.setData(teamData)
    })
  }
  setData = teamData => {
    const newLinks = teamData.usages.length ? teamData.usages.reduce((acc, curr) => acc + curr.new_links, 0) : 0

    const ahrefsCost = teamData.usages.length ? teamData.usages.reduce((acc, curr) => acc + curr.ahrefs_cost, 0) : null
    const urlboxCost = teamData.usages.length ? teamData.usages.reduce((acc, curr) => acc + curr.urlbox_cost, 0) : null
    const sharedcount = teamData.usages.length ? teamData.usages.reduce((acc, curr) => acc + curr.shareinfo_cost, 0) : null
    const totalCost = teamData.usages.length ? teamData.usages.reduce((acc, curr) => acc + curr.total_cost, 0) : null
    const costs = teamData.usages.length ? {
      ahrefs: ahrefsCost.toFixed(2),
      urlbox: urlboxCost.toFixed(2),
      sharedcount: sharedcount.toFixed(2),
      total: totalCost.toFixed(2)
    } : null

    this.setState({
      companiesNo: teamData.companies_count,
      campaignsNo: teamData.campaign_count,
      linksMax: teamData.url_max,
      companiesMax: teamData.companies_max,
      campaignsMax: teamData.campaign_max,
      periodLinksUsage: newLinks,
      periodDuration: teamData.allowance_period_duration,
      unlimitedUsage: teamData.allowance_unlimited,
      resetDate: moment(teamData.periodData.date_end).format('MMMM Do YYYY'),
      costs
    }, () => this.props.setLoading(false))
  }

  render() {
    const { companiesNo, campaignsNo, companiesMax, campaignsMax, linksMax, periodLinksUsage, periodDuration, resetDate, unlimitedUsage, costs } = this.state
    const { name, linkLimitReached, companyLimitReached, campaignLimitReached, planEnded, planNotStarted } = this.props.currentTeam
    const { user } = this.props
    const freePlan = this.props.currentTeam.type === 'free'
    const allowanceDateEnd = this.props.currentTeam.allowance_date_start

    return (
      <div>
        <OutterWrapper>
          <div>
            <h1>Your Plan</h1>
            {user.is_team_admin &&
              <ManageButton href={freePlan ? 'https://klipr.io/pricing/' : `mailto:contact@klipr.io?subject=${name}%20team%20upgrade%20enquiry`} target='_blank' rel='noopener noreferrer'>{freePlan ? 'Upgrade Plan' : 'Manage Subscription'}</ManageButton>
            }
          </div>
          <Divider/>
          {(linkLimitReached || companyLimitReached || campaignLimitReached) && !planEnded && !planNotStarted && (
            <Section>
              <LimitReachedInfo>
                <p>You have reached an account limit. Please email us at <a href={`mailto:contact@klipr.io?subject=${name}%20team%20upgrade%20enquiry`}>contact@klipr.io</a> to upgrade your account.</p>
              </LimitReachedInfo>
            </Section>
          )}
          {planEnded && (
            <Section>
              <LimitReachedInfo>
                <p>Your plan has now ended. You will still be able to view your collected coverage and share it with your companies, but to continue collecting coverage, <a href='https://klipr.io/pricing/'>upgrade to a paid plan</a>.</p>
              </LimitReachedInfo>
            </Section>
          )}
          {planNotStarted && (
            <Section>
              <LimitReachedInfo>
                <p>Your plan is set to start on {moment(allowanceDateEnd).format('MMMM Do YYYY')}. No coverage will be collected or updated until then. Please email us at <a href={`mailto:contact@klipr.io?subject=${name}%20team%20upgrade%20enquiry`}>contact@klipr.io</a> to update your plan start date.</p>
              </LimitReachedInfo>
            </Section>
          )}
          <Divider/>
          <Section>
            <InnerWrapper>
              <TitleWrapper>
                <h2>Companies Usage</h2>
                {companiesMax > 0 ? (
                  <span>{`${companiesNo.toLocaleString()}/${companiesMax.toLocaleString()}`}<b> ({companyLimitReached ? 'Limit reached' : `${Math.round((companiesNo / companiesMax) * 100)}%`})</b></span>
                ) : (
                  <span>Companies limit not set</span>
                )}
              </TitleWrapper>
              {companiesMax > 0 &&
                <UsageBar usage={Math.round((companiesNo / companiesMax) * 100)}/>
              }
            </InnerWrapper>
            <StyledLink to='/companies'>View all companies</StyledLink>
          </Section>
          <Divider/>
          <Section>
            <InnerWrapper>
              <TitleWrapper>
                <h2>Campaigns Usage</h2>
                {campaignsMax > 0 ? (
                  <span>{`${campaignsNo.toLocaleString()}/${campaignsMax.toLocaleString()}`}<b> ({campaignLimitReached ? 'Limit reached' : `${Math.round((campaignsNo / campaignsMax) * 100)}%`})</b></span>
                ) : (
                  <span>Campaigns limit not set</span>
                )}
              </TitleWrapper>
              {campaignsMax > 0 &&
                <UsageBar usage={Math.round((campaignsNo / campaignsMax) * 100)}/>
              }
            </InnerWrapper>
            <StyledLink to='/campaigns'>View all campaigns</StyledLink>
          </Section>
          <Divider/>
          <Section>
            <InnerWrapper>
              <TitleWrapper>
                <h2>Klips Used</h2>
                {!unlimitedUsage && periodLinksUsage !== null ? (
                  <span>{`${periodLinksUsage.toLocaleString()}/${linksMax.toLocaleString()}`}<b> ({linkLimitReached ? 'Limit reached' : `${Math.round((periodLinksUsage / linksMax) * 100)}%`})</b></span>
                ) : (
                  <span>Unlimited klips usage</span>
                )}
              </TitleWrapper>
              {!unlimitedUsage &&
                <UsageBar usage={Math.round((periodLinksUsage / linksMax) * 100)}/>
              }
            </InnerWrapper>
            <StyledLink to='/links'>View all coverage</StyledLink>
          </Section>
          {(!unlimitedUsage && !planEnded) && (
            <Section smallPadding>
              <ResetInfo>Your <span>{periodDuration}</span> klips allowance will reset on <span>{resetDate}</span>.</ResetInfo>
            </Section>
          )}
          <Section>
            <SUInfo>
              <LinksWrapper>
                <h3>Klips discovered in the last {periodDuration ? periodDuration === 'daily' ? 'day' : periodDuration.slice(0, -2) : 'month'}</h3>
                <p>{periodLinksUsage}</p>
              </LinksWrapper>
              {user.is_super_user && costs && (
                <CostsWrapper>
                  <h3>Costs over the last {periodDuration ? periodDuration === 'daily' ? 'day' : periodDuration.slice(0, -2) : 'month'}</h3>
                  <Costs>
                    <p>Ahrefs</p>
                    <span>${costs.ahrefs}</span>
                    <p>Urlbox</p>
                    <span>${costs.urlbox}</span>
                    <p>SharedCount</p>
                    <span>${costs.sharedcount}</span>
                  </Costs>
                  <TotalCost>
                    <p>Total</p>
                    <span>${costs.total}</span>
                  </TotalCost>
                </CostsWrapper>
              )}
            </SUInfo>
          </Section>
        </OutterWrapper>
      </div>
    )
  }
}

export default Plan
