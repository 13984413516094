import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import posed from 'react-pose'
import Loader from '../components/loader'
import Form from '../components/form'
import Avatar from '../components/avatar'
import Toggle from '../components/toggle'
import ApiSettings from '../components/apiSettings'
import * as vars from '../assets/vars'

const AnimatedWrapper = posed.div({
  show: { opacity: 1 },
  hide: { opacity: 0 }
})
const HiddenWrapper = styled.div`
  width: 100%;
  height: ${props => (props.show ? 'auto' : 0)};
  overflow: ${props => (props.show ? 'visible' : 'hidden')};
`
const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  div {
    width: 150px;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    text-align: center;
    cursor: pointer;
    background-color: ${vars.colors.lightGrey};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:first-of-type {
      margin-right: 2px;
    }
    &.active {
      background-color: ${vars.colors.green};
      color: ${vars.colors.white};
      font-weight: 600;
    }
    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.lightGrey)};

        &.active {
          background-color: ${darken(0.1, vars.colors.green)};
        }
      }
    }
  }
`

const AddCompany = ({ onSubmit, onChange, callAPI, preview, user, company, match, tourOpen, toast }) => {
  const [showApiSettings, setShowApiSettings] = useState(false)

  return (
    <div className='narrow'>
      <div className='panelHead'>
        {match.params.company ? (
          <>
            <h2>{company.name}</h2>
            <Link to={`/campaigns/${match.params.company}`} className='link'><img src={vars.icons.arrow} alt='Back to company'/> company page</Link>
          </>
        ) : (
          <>
            <h2>Add Company</h2>
            <Link to='/companies' className='link'><img src={vars.icons.arrow} alt='Back to all companies'/> all companies</Link>
          </>
        )}
      </div>

      <div>
        {(user.is_team_admin && match.params.company) && (
          <Tabs>
            <div onClick={() => setShowApiSettings(false)} className={showApiSettings ? '' : 'active'}>
              Company Details
            </div>
            <div onClick={() => setShowApiSettings(true)} className={`apiSettingsTab ${showApiSettings ? 'active' : ''}`}>
              API Settings
            </div>
          </Tabs>
        )}
        <AnimatedWrapper pose={showApiSettings ? 'show' : 'hide'}>
          <HiddenWrapper show={showApiSettings}>
            <ApiSettings callAPI={callAPI} isVisible={showApiSettings} user={user} companyId={match.params.company} prevTourOpen={tourOpen} toast={toast}/>
          </HiddenWrapper>
        </AnimatedWrapper>
        <AnimatedWrapper pose={!showApiSettings ? 'show' : 'hide'}>
          <HiddenWrapper show={!showApiSettings}>
            <Form action='/' onSubmit={onSubmit}>
              <fieldset>
                <label>Company Name*</label>
                <input name='name' type='text' onChange={onChange} value={company.name} required/>
              </fieldset>
              <fieldset className='halfWidth'>
                <label>Contact Number</label>
                <input name='phone' type='tel' onChange={onChange} value={company.phone}/>
              </fieldset>
              <fieldset className='halfWidth'>
                <label>Contact Email</label>
                <input name='email' type='email' onChange={onChange} value={company.email}/>
              </fieldset>
              <fieldset className='halfWidth'>
                <label htmlFor='companyImg'>Company Icon (png or jpg)</label>
                <input id='companyImg' name='avatar' type='file' accept='image/png, image/jpeg' onChange={preview}/>
              </fieldset>
              <fieldset className='halfWidth'>
                <Avatar image={company.avatar} name={company.name} big/>
              </fieldset>
              <fieldset>
                <label>Allow coverage screenshots in lookbook?</label>
                <Toggle id='screenshotsAllowed' name='screenshotsAllowed' onChange={onChange} isChecked={company.screenshotsAllowed}/>
                <p><em>By enabling screenshots in the Lookbook feature, you agree that you hold the requisite licences for viewing and sharing screenshots of content from publications represented by a publisher-owned rights licensing and database business.</em></p>
              </fieldset>
              {company.loading ? (
                <button type='button' disabled><Loader small/></button>
              ) : (
                <button type='submit'>{match.params.company ? 'Update company' : 'Add company'}</button>
              )}
            </Form>
          </HiddenWrapper>
        </AnimatedWrapper>
      </div>
    </div>
  )
}

export default AddCompany
