import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import { toast } from 'react-toastify'
import Loader from '../components/loader'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  width: 100%;
  text-align: center;

  span {
    color: ${vars.colors.green};
    font-weight: 600;
  }
`
const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1.4em;
  font-weight: 600;
  padding: 20px 0px;
  background-color: ${vars.colors.teal};

  a {
    color: ${vars.colors.white};
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    will-change: transform;
    transition: transform .3s ease-out;

    &:hover {
      @media (hover: hover) {
        transform: scale(1.05);
      }
    }
  }
  span {
    font-weight: 300;
  }
  img {
    width: 55px;
    margin-right: 14px;
  }
`
const ResetForm = styled.form`
  margin-bottom: 30px;

  input {
    display: block;
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    padding: 26px;
    outline: none;
    border: 0;
    font-size: 1.2em;
    background-color: ${vars.colors.white};
    box-sizing: border-box;
  }

  button {
    display: block;
    width: 80%;
    max-width: 400px;
    height: 60px;
    line-height: 60px;
    margin: 32px auto 0;
    padding: 0 15px;
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-size: 1.3em;
    font-weight: 600;
    background-color: ${vars.colors.green};
    text-align: center;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    will-change: background-color, color;
    transition: background-color .3s ease-out, color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:disabled {
      background-color: ${vars.colors.lightGrey};
      color: ${vars.colors.darkGrey};
      cursor: auto;
    }

    > div {
      height: 80%;
    }
  }
`
const Back = styled(Link)`
  display: table;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 600;
  color: ${vars.colors.black};
  will-change: color;
  transition: color .3s ease-out;

  &:hover {
    @media (hover: hover) {
      color: ${vars.colors.blue};
    }
  }
`

class PasswordRequest extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      email: localStorage.getItem('klipr_user_email') ? localStorage.getItem('klipr_user_email') : '',
      loading: false
    }
  }

  handleResponse = response => {
    if (!response.ok) {
      this.setState({ loading: false })
      const error = new Error(response.statusText)
      toast.error('There was an error while resetting your password. Please try again.')
      error.response = response
      throw error
    }

    return response.json().then(json => {
      if (response.ok) {
        return json
      }
      return Promise.reject({
        ...json,
        status: response.status,
        statusText: response.statusText
      })
    })
  }
  resetRequest = () => {
    const payload = {
      method: 'post',
      body: JSON.stringify({ email: this.state.email }),
      headers: { 'Content-Type': 'application/json; charset=UTF-8' }
    }

    return fetch(`${__API__}forgotten-password/request`, payload)
      .then(this.handleResponse)
      .then(result => result)
      .catch(error => {
        console.error(error)
        return error
      })
  }
  reset = e => {
    e.preventDefault()

    this.setState({ loading: true })
    this.resetRequest().then(result => {
      if (result.success) {
        toast.success(`A password reset email has been sent to ${this.state.email}.`)
        this.setState({ loading: false })
        localStorage.removeItem('klipr_user_email')
        setTimeout(() => this.props.history.push('/login'), 1000)
      }
    })
  }
  updateEmail = e => {
    this.setState({ email: e.target.value })
  }

  render() {
    return (
      <Wrapper>
        <Title>
          <Link to='/'>
            <img src={vars.icons.logo} alt='Klipr logo'/>Klipr.io
          </Link>
        </Title>

        <h3>Forgot your password?</h3>
        <ResetForm action='/' onSubmit={this.reset}>
          <fieldset>
            <input type='email' placeholder='Enter your email address' onChange={this.updateEmail} value={this.state.email} required/>
            {this.state.loading ? (
              <button type='button'><Loader small/></button>
            ) : (
              <button type='submit' disabled={!this.state.email}>Reset password</button>
            )}
          </fieldset>
        </ResetForm>
        <p>If your email is registered and verified, you will receive password reset instructions.</p>
        <p><span>Please check your spam folder</span> if you have not received the reset instructions email.</p>
        <Back to='/login'>Back to login screen</Back>
      </Wrapper>
    )
  }
}

export default withRouter(PasswordRequest)
