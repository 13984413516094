import React from 'react'
import ReactDOM from 'react-dom'
import { Switch, Route } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import 'react-app-polyfill/ie11'
import styled from 'styled-components'
import WebFont from 'webfontloader'
import { ToastContainer, Slide } from 'react-toastify'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import Authenticate from './api/authRoute'
import Login from './api/login'
import CompanyLogin from './api/companyLogin'
import ForgotPassword from './api/passwordRequest'
import PasswordReset from './api/passwordReset'
import FreeTrial from './api/freeTrial'
import NewAccount from './api/newAccount'
import GApiAuth from './api/gApiAuth'
import ApiConnect from './api/apiConnect'
import MainWrapper from './components/wrapper'
import * as vars from './assets/vars'

WebFont.load({ typekit: { id: 'fxn6cah' } })
ReactGA.initialize('UA-62297915-4')
ReactGA.pageview(window.location.pathname + window.location.search)
Sentry.init({ dsn: 'https://c2542447b3af48dbac453bbca39bbdda@sentry.io/2328507' })

const Wrapper = styled.div`
  select {
    padding: 0 15px 0 0;
    background: url(${vars.icons.arrow}) no-repeat 100% center transparent;
    background-size: 14px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

// eslint-disable-next-line
const _cs = ['\x6b\x6c\x69', '\x73\x74', '\x70\x72\x2e', '\x74\x65', '\x61\x62\x73', '\x69\x6f', '\x42\x79']
if (document.location.hostname.indexOf(_cs[0] + _cs[2] + _cs[5]) > -1 || document.location.hostname.indexOf(_cs[0] + _cs[2] + _cs[3] + _cs[1]) > -1) {
  ReactDOM.render(
    <Wrapper>
      <Router>
        <Switch>
          <Route exact path='/login' component={Login}/>
          <Route exact path='/forgot-password' component={ForgotPassword}/>
          <Route exact path='/reset/:token' component={PasswordReset}/>
          <Route exact path='/free-trial' component={FreeTrial}/>
          <Route exact path='/new-account/:shortlink' component={NewAccount}/>
          <Route exact path='/share/:shortlink' component={CompanyLogin}/>
          <Route exact path='/book/:shortlink' component={CompanyLogin}/>
          <Route exact path='/google-api/auth' component={GApiAuth}/>
          <Route exact path='/apiconnect/:shortlink' component={ApiConnect}/>
          <Route component={Authenticate(MainWrapper)}/>
        </Switch>
      </Router>
      <ToastContainer transition={Slide} pauseOnFocusLoss={false} newestOnTop style={{ width: 370 }}/>
    </Wrapper>,
    document.getElementById('app')
  )
} else {
  console.error('Unauthorised hostname')
  failHere() // eslint-disable-line
}
