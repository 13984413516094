import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import Tour from 'reactour'
import * as vars from '../assets/vars'

const StyledTour = styled(Tour)`
  > div {
    button {
      &:first-of-type {
        display: ${props => (props.steps.length === 1 ? 'none' : 'block')};
      }
      &:last-of-type {
        margin-left: ${props => (props.steps.length === 1 ? 0 : '24px')};
      }
    }
  }
`
const TourButton = styled.div`
  background-color: ${props => (props.red ? vars.colors.red : vars.colors.green)};
  color: ${vars.colors.white};
  padding: ${props => (props.red ? '9px 15px' : '9px 8px')};
  font-size: 14px;
  font-weight: 600;
  border-radius: 20px;
  will-change: background-color, padding;
  transition: background-color .3s ease-out, padding .1s ease-out;

  img {
    transform: ${props => (props.next ? 'rotate(-90deg)' : 'rotate(90deg)')};
    height: 10px;
  }

  span & {
    button:not([disabled]) & {
      cursor: pointer;
      &:hover {
        @media (hover: hover) {
          background-color: ${props => (props.red ? darken(0.3, vars.colors.red) : darken(0.3, vars.colors.green))};
        }
      }
    }
    button:disabled & {
      opacity: 0.5;
    }
  }
`

const TourComponent = ({ steps, isOpen, endTour }) => (
  <StyledTour steps={steps} isOpen={isOpen} accentColor={vars.colors.red}
    closeWithMask={false} showNavigationNumber={false} showNavigation={false}
    showNumber={false} disableDotsNavigation={false} disableKeyboardNavigation={false}
    prevButton={(
      <TourButton><img src={vars.icons.downChevron} alt='arrow'/></TourButton>
    )}
    nextButton={(
      <TourButton next><img src={vars.icons.downChevron} alt='arrow'/></TourButton>
    )}
    lastStepNextButton={<TourButton red alone={steps.length === 1}>OK</TourButton>}
    onRequestClose={endTour}
    onAfterOpen={t => disableBodyScroll(t)} onBeforeClose={t => enableBodyScroll(t)}
  />
)

export default TourComponent
