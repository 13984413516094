import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { darken } from 'polished'
import { toast } from 'react-toastify'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import Loader from '../components/loader'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`
const LogoWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  will-change: transform;
  transition: transform .3s ease-out;

  &:hover {
    @media (hover: hover) {
      transform: scale(1.05);
    }
  }
  h1 {
    position: absolute;
    text-indent: 100%;
    width: 0;
    white-space: nowrap;
    overflow: hidden;
    pointer-events: none;
  }
`
const Logo = styled.div`
  background-image: url(${vars.icons.logo});
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  background-size: contain;
`
const LogoText = styled.div`
  height: 25px;
  margin-left: 10px;
  max-width: 120px;

  svg {
    height: 100%;
  }
`
const InnerWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  position: relative;
  z-index: 1;
`
const FormWrapper = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-height: 100vh;
  padding: 30px 40px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
    min-height: auto;
  }

  h2 {
    width: 100%;
    text-align: left;
    border-bottom: solid 2px ${vars.colors.green};
    padding-bottom: 20px;

    @media (max-width: 768px) {
      padding-top: 20px;
    }
  }
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0;
  }
  fieldset {
    width: 100%;

    &.halfWidth {
      @media (min-width: 768px) {
        width: calc(50% - 10px);
      }
    }
  }
  label {
    font-weight: 600;
    font-size: 14px;
  }
  input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    background-color: ${vars.colors.white};
    margin-top: 5px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 15px;
    appearance: none;

    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
  p {
    font-size: 14px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    z-index: 1;
    background: linear-gradient(0deg, rgba(245,247,247,0) 0%, rgba(245,247,247,1) 50%);
  }
  &:after {
    content: '';
    background-color: transparent;
    background-image: url(${vars.icons.pattern});
    background-size: 1200px;
    position: absolute;
    width: 200vh; height: 200vh;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`
const PasswordMeterWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  input {
    margin-bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0; left: 0;
    height: 4px;
    width: ${props => `${props.strength * (100 / 3)}%`};
    background-color: ${props => (props.strength > 2 ? vars.colors.green : props.strength > 1 ? vars.colors.amber : vars.colors.red)};
    will-change: width, color;
    transition: width .3s ease-out, color .3s ease-out;
  }
`
const PasswordEqualWrapper = styled.div`
  position: relative;
  width: 100%;

  input {
    margin-bottom: 0;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0; left: 0;
    width: 100%; height: 4px;
    opacity: ${props => (props.showStatus ? 1 : 0)};
    background-color: ${props => (props.equal ? vars.colors.green : vars.colors.red)};
    will-change: color, opacity;
    transition: color .3s ease-out, opacity .3s ease-out;
  }
`
const ConsentWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  p {
    font-weight: 600;
  }
  button {
    display: block;
    width: 80%;
    max-width: 400px;
    height: 50px;
    margin: 10px auto;
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-weight: 600;
    background-color: ${vars.colors.green};
    text-align: center;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    will-change: background-color, color;
    transition: background-color .3s ease-out, color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:disabled {
      background-color: ${vars.colors.lightGrey};
      color: ${vars.colors.darkGrey};
      cursor: auto;
    }

    > div {
      height: 80%;
    }
  }
`
const SaleWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  min-height: 100vh;
  padding: 50px;
  color: ${vars.colors.white};
  background-color: ${vars.colors.teal};

  &:before {
    content: '';
    position: absolute;
    width: 100vh;
    height: 100vh;
    top: 0; left: 0;
    background: radial-gradient(circle at bottom right, rgba(0,0,0,0.13) 50%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.05) 70%, rgba(0,0,0,0) 70%);
  }
  @media (max-width: 768px) {
    width: 100%;
    min-height: auto;
  }
`
const Testimonial = styled.div`
  width: 100%;
  max-width: 600px;

  @media (max-width: 768px) {
    padding-bottom: 30px;
  }

  p {
    font-style: italic;
    font-size: 18px;
    margin-bottom: 10px;
  }
  span {
    display: block;
    font-size: 14px;

    &:first-of-type {
      font-weight: 600;
    }
  }
`
const BrandsWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 500px) {
    position: static;
  }

  span {
    font-weight: 600;
    text-transform: uppercase;

    @media (max-width: 500px) {
      margin: 10px 0;
    }
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 500px) {
      flex-wrap: wrap;
    }

    img {
      margin: 10px;
      width: 25%;

      @media (max-width: 900px) {
        width: 20%;
      }
      @media (max-width: 500px) {
        width: 30%;
      }
    }
  }
`

const FreeTrial = ({ location }) => {
  useEffect(() => {
    loadReCaptcha('6LdBReAUAAAAAOS2EaaK_7H8fbJIZaiNjBO3DIKk')
    if (location.search.length > 0 && location.search.includes('email=')) {
      setFormData({
        ...formData,
        email: location.search.split('email=')[1].split('&')[0]
      })
    } else if (localStorage.getItem('klipr_new-account-data')) {
      const storedData = JSON.parse(localStorage.getItem('klipr_new-account-data'))
      setFormData({
        ...formData,
        email: storedData.email,
        first_name: storedData.firstName,
        last_name: storedData.lastName,
        phone_number: storedData.phoneNumber,
        company_name: storedData.companyName
      })
    }
  }, [])
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    phone_number: '',
    pword1: '',
    pword2: ''
  })
  const [token, setToken] = useState(null)
  const [passwordStrength, setPasswordStrength] = useState(0)
  const [passwordsEqual, setPasswordsEqual] = useState(false)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    checkPassword()
  }, [formData.pword1])
  useEffect(() => {
    checkPasswordsAreEqual()
  }, [formData.pword2])
  useEffect(() => {
    checkPasswordsAreEqual()
  }, [passwordStrength])

  const updateFormData = e => {
    const { name, value }  = e.currentTarget
    setFormData({
      ...formData,
      [name]: value
    })
  }
  const checkPassword = () => {
    const password = formData.pword1
    const hasSpecial = new RegExp(/[^A-Za-z0-9]/).test(password)
    const hasNumber = new RegExp(/[0-9]/).test(password)
    const hasMixed = new RegExp(/[a-z]/).test(password) && new RegExp(/[A-Z]/).test(password)

    let strength = 0
    if (password.length >= 5) strength += 1
    if (password.length >= 8 && hasSpecial) strength += 1
    if (password.length >= 8 && hasSpecial && (hasNumber || hasMixed)) strength += 1
    setPasswordStrength(strength)
  }
  const checkPasswordsAreEqual = () => {
    setPasswordsEqual(formData.pword1 === formData.pword2)
  }
  const handleResponse = response => {
    if (!response.ok) {
      const error = new Error(response.statusText)
      toast.error(`There was an error while processing your request. ${response.status === 500 ? '' : `${response.statusText}.`}`)
      error.response = response
      throw error
    }
    const contentType = response.headers.get('content-type')
    if (contentType.includes('application/json')) {
      return response.json().then(json => {
        if (response.ok) return json
        return Promise.reject({
          ...json,
          status: response.status,
          statusText: response.statusText
        })
      })
    }
    if (contentType.includes('text/html')) {
      return response.text().then(text => {
        if (response.ok) return text
        return Promise.reject({
          status: response.status,
          statusText: response.statusText
        })
      })
    }
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (passwordStrength < 3) {
      toast.warn('Password does not meet requirements.')
    } else if (!passwordsEqual) {
      toast.warn('Passwords not matching.')
    } else if (token) {
      setLoading(true)
      const { first_name, last_name, email, company_name, phone_number, pword1 } = formData
      const payload = {
        method: 'post',
        body: JSON.stringify({ first_name, last_name, email, company_name, phone_number, password: pword1, token }),
        headers: { 'Content-Type': 'application/json; charset=UTF-8' }
      }

      fetch(`${__API__}onboarding/new`, payload)
        .then(handleResponse)
        .then(result => {
          if (result.success) {
            toast.success('Verification email sent')
            setLoading(false)
            if (process.env.NODE_ENV !== 'development') {
              setTimeout(() => {
                window.location.href = 'https://klipr.io/thank-you/'
              }, 2000)
            }
          } else {
            toast.error(result.message.split('ERROR: ')[1])
            setLoading(false)
          }
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          return error
        })
    } else {
      toast.warn('Google reCAPTCHA verification failed.')
    }
  }

  return (
    <Wrapper>
      <FormWrapper>
        <ReCaptcha sitekey='6LdBReAUAAAAAOS2EaaK_7H8fbJIZaiNjBO3DIKk' verifyCallback={tk => setToken(tk)}/>
        <InnerWrapper>
          <LogoWrapper to='/'>
            <Logo/>
            <LogoText>
              <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 105.909 30.1'><path d='M20.898 30.1L8.514 15.437 20.514 0h-4.862L4.085 14.921V0H0v30.1h4.085V16.211L15.781 30.1zm10.406-4V0h-4.085v30.1h15.222v-4zm17.627 4h4.085V0h-4.085zM69.918 0h-7.4v30.1h4.085V19.092h3.311c6.622 0 10.234-4.257 10.234-9.546C80.152 4.816 76.54 0 69.918 0zm-.172 15.093h-3.139V4h3.139c3.784 0 6.235 2.408 6.235 5.547 0 3.396-2.45 5.546-6.235 5.546zM101.265 30.1h4.644l-7.31-12.126a8.9 8.9 0 006.106-8.686c0-4.773-3.612-9.288-9.761-9.288h-8.17v30.1h4.085V18.533h3.784zM90.859 4h3.913c3.44 0 5.805 2.15 5.805 5.289 0 3.1-2.365 5.289-5.805 5.289h-3.913z'/></svg>
            </LogoText>
            <h1>Klipr</h1>
          </LogoWrapper>
          <h2>Create Your Account</h2>
          <form onSubmit={handleSubmit}>
            <fieldset className='halfWidth'>
              <label htmlFor='first_name'>First Name*</label>
              <input type='text' name='first_name' value={formData.first_name} onChange={updateFormData} required/>
            </fieldset>
            <fieldset className='halfWidth'>
              <label htmlFor='last_name'>Last Name*</label>
              <input type='text' name='last_name' value={formData.last_name} onChange={updateFormData} required/>
            </fieldset>
            <label htmlFor='email'>Email Address*</label>
            <input type='text' name='email' value={formData.email} onChange={updateFormData} required/>
            <fieldset className='halfWidth'>
              <label htmlFor='company_name'>Company Name*</label>
              <input type='text' name='company_name' value={formData.company_name} onChange={updateFormData} required/>
            </fieldset>
            <fieldset className='halfWidth'>
              <label htmlFor='phone_number'>Phone Number*</label>
              <input type='number' name='phone_number' value={formData.phone_number} onChange={updateFormData} required/>
            </fieldset>
            <fieldset className='halfWidth'>
              <PasswordMeterWrapper strength={passwordStrength}>
                <label htmlFor='pword1'>Password*</label>
                <input type='password' name='pword1' onChange={updateFormData} required/>
              </PasswordMeterWrapper>
            </fieldset>
            <fieldset className='halfWidth'>
              <PasswordEqualWrapper showStatus={formData.pword2.length > 0} equal={passwordsEqual}>
                <label htmlFor='pword2'>Confirm Password*</label>
                <input type='password' name='pword2' onChange={updateFormData} required/>
              </PasswordEqualWrapper>
            </fieldset>
            <p><em>Your password must contain at least 8 characters and at least a character that is not a letter or a number.</em></p>
            <ConsentWrapper>
              <p>By clicking “Create your account”, you agree to Klipr’s <a href='https://klipr.io/terms-conditions/' target='_blank' rel='noopener noreferrer'>Terms of Service</a> and <a href='https://klipr.io/privacy-policy/' target='_blank' rel='noopener noreferrer'>Privacy Policy</a></p>
              {loading ? (
                <button type='button' disabled><Loader small/></button>
              ) : (
                <button type='submit'>Create your account</button>
              )}
              <p>Already have an account? <Link to='/'>Login</Link></p>
            </ConsentWrapper>
          </form>
        </InnerWrapper>
      </FormWrapper>
      <SaleWrapper>
        <Testimonial>
          <p>“Klipr allows our team to create collaborative, real time reports for our clients - putting their minds at ease during the outreach process.”</p>
          <span>Jessica Fairfax</span>
          <span>Senior Outreach Manager, Artefact</span>
        </Testimonial>
        <BrandsWrapper>
          <span>Used by the world’s leading brands</span>
          <div>
            <img src={vars.icons.etoro} alt='etoro'/>
            <img src={vars.icons.lastminute} alt='lastminute'/>
            <img src={vars.icons.msm} alt='msm'/>
            <img src={vars.icons.npower} alt='npower'/>
          </div>
        </BrandsWrapper>
      </SaleWrapper>
    </Wrapper>
  )
}

export default withRouter(FreeTrial)
