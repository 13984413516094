import React from 'react'
import styled from 'styled-components'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-top: 20px;
  background: ${vars.colors.offWhite};

  > div {
    color: ${vars.colors.grey};
    box-sizing: border-box;
    flex: 1 0 50%;
    margin: 0 0 20px;
    padding: 0 15px;
    text-align: center;

    @media (min-width: 768px) {
      flex: 1 0 auto;
    }

    span {
      display: block;
      color: ${vars.colors.red};
      font-weight: 600;
      margin: 0 0 5px;
      font-size: 0.85em;
    }
  }
`
const Value = styled.div`
  color: ${({ color }) => color};
`
const TotalFlexWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  p {
    margin: 0;
  }
`
const NewCoverage = styled.div`
  flex: none !important;
  position: relative;
  top: -1px; left: 5px;
  color: ${vars.colors.white};

  p {
    margin: 0;
    background-color: ${vars.colors.green};
    color: ${vars.colors.white} !important;
    padding: 2px 10px;
    border-radius: 20px;
  }
  span {
    position: absolute;
    top: 50%; left: 0;
    width: 310px;
    text-align: center;
    background-color: ${vars.colors.green};
    color: ${vars.colors.white} !important;
    font-weight: 300 !important;
    font-size: 1em !important;
    text-transform: none !important;
    margin: 0 !important;
    padding: 2px 10px;
    border-radius: 20px;
    opacity: 0;
    will-change: opacity;
    transition: opacity .1s ease-out;
    pointer-events: none;
    transform: translateY(-50%);

    &:hover {
      @media (hover: hover) {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &:hover {
    @media (hover: hover) {
      span {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`

const OverviewStats = ({ stats }) => (
  <Wrapper>
    <>
      {stats.filter(stat => stat.custom).map(stat => (
        <div key={stat.label}>
          <span>{stat.label}</span>
          <TotalFlexWrapper>
            <p>{stat.total.toLocaleString()}</p>
            {stat.newTotal > 0 && (
              <NewCoverage>
                <p>+{stat.newTotal}</p>
                {stat.date &&
                  <span>{stat.newTotal} new URL{stat.newTotal > 1 ? 's' : ''} since {stat.date}</span>
                }
              </NewCoverage>
            )}
          </TotalFlexWrapper>
        </div>
      ))}
      {stats.filter(stat => !stat.hide).filter(stat => !stat.custom).map(stat => (
        <div key={stat.label}>
          <span>{stat.label}</span>
          <Value color={stat.color}>{stat.value}</Value>
        </div>
      ))}
    </>
  </Wrapper>
)

export default OverviewStats
