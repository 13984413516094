/* eslint-disable */

import React from 'react'
import { Redirect, withRouter } from 'react-router'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import LogRocket from 'logrocket'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import Loader from '../components/loader'
import Form from '../components/form'
import * as vars from '../assets/vars'

const VerificationWrapper = styled.div`
  position: fixed;
  z-index: 9;
  top: 0; left: 0;
  width: 100%; height: 100vh;
  background-color: ${vars.colors.darkShadow};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  > div {
    width: 80%;
    max-width: 550px;
    padding: 30px;
    border-radius: 6px;
    background-color: ${vars.colors.offWhite};

    p {
      width: 100%;
      margin: 10px auto;
    }
    input {
      width: 400px;
      margin: 0 auto;
    }
  }
`
const VerifyButton = styled.button`
  padding: 10px 15px !important;
  margin: 10px auto 0 !important;
  width: 150px !important;
  height: 45px;
  line-height: 25px !important;
`

window.startTracking = () => {
  if (!LogRocket._isInitialized) { // eslint-disable-line
    LogRocket.init('uip0zs/klipr-dashboard')
    LogRocket.getSessionURL(sessionURL => {
      console.log(sessionURL) // eslint-disable-line
      Sentry.configureScope(scope => scope.setExtra('sessionURL', sessionURL))
      ReactGA.event({ category: 'LogRocket', action: sessionURL })
    })
    LogRocket.identify(process.env.NODE_ENV, { name: 'Manual Tracking' })
  }
}

const DOMAIN_RATING_FILTER = 10

export default ChildComponent => {
  class AuthenticatedComponent extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: true,
        verifying: false,
        ready: false,
        loggedOut: false,
        token: null,
        user: null,
        teams: null,
        companies: null,
        currentTeamId: null,
        currentTeam: null,
        deleteDetails: {
          type: null,
          name: null,
          id: null,
          allowed: true,
          deleting: false,
          success: false
        }
      }
    }

    componentDidMount() {
      const token = localStorage.getItem('klipr_auth_token')
      this.setState({ token })

      if (token) {
        document.body.classList.add('loggedin', 'locked')
        this.getData()
        const redirect = localStorage.getItem('klipr_login_redirect') || localStorage.getItem('klipr_preshare_path')
        if (redirect) {
          this.props.history.push(redirect)
        }
      } else {
        this.setState({ token: null, user: null })

        const redirect = this.props.location.pathname
        if (redirect?.length > 1 && !redirect?.includes('login') && !redirect?.includes('reset')) {
          localStorage.setItem('klipr_login_redirect', redirect)
        }
      }

      this.resetCurrentTeamId(this.props.location.pathname)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.location.pathname !== nextProps.location.pathname) {
        this.setLoading(true)
        this.getData()
        this.resetCurrentTeamId(nextProps.location.pathname)
      }
    }

    getData = () => {
      this.setState({ ready: false })

      if (!this.state.user) {
        this.getUserData()
      } else if (this.state.user.is_super_user) {
        this.getTeams()
      } else {
        this.getCompanies(this.state.user.is_client ? this.state.user.company_id : null)
      }
    }
    getUserData = () => {
      this.callAPI('users/current').then(result => {
        if (result.email) {
          const user = result
          this.setState({ user }, () => {
            if (user.is_super_user) {
              this.getTeams()
              console.log(`Bearer token: ${localStorage.getItem('klipr_auth_token')}`) //eslint-disable-line
            } else {
              this.getCompanies(user.is_client ? user.company_id : null)
            }
          })
        }
      })
    }
    getTeams = () => {
      this.callAPI('teams').then(result => {
        if (result.length) {
          this.setState({ teams: result.filter(team => team.id !== 1) }, () => this.getCurrentTeam())
        }
      })
    }
    getCompanies = id => {
      this.callAPI(`companies${id ? `/${id}` : ''}`).then(result => {
        const companies = Array.isArray(result) ? result : [...[], result]
        this.setState({ companies }, () => this.getCurrentTeam())
      })
    }
    getCurrentTeam = () => {
      const { user } = this.state
      const teamId = user.is_super_user ? this.state.currentTeamId ? this.state.currentTeamId : null : user.team_id

      if (teamId) {
        this.callAPI(`teams/${teamId}`).then(result => {
          if (!user.is_client) {
            this.getUsageData(result)
          } else {
            this.setState({ ready: true })
          }
          if ((user.id === 27 || !user.is_super_user) && __BASEURL__ === 'https://dashboard.klipr.io/') {
            this.initLogrocket(result)
          }
        })
      } else {
        this.setState({ ready: true })
        if ((user.id === 27 || !user.is_super_user) && __BASEURL__ === 'https://dashboard.klipr.io/') {
          this.initLogrocket()
        }
      }
    }
    initLogrocket = team => {
      if (!LogRocket._isInitialized) { // eslint-disable-line
        LogRocket.init('uip0zs/klipr-dashboard')
        LogRocket.getSessionURL(sessionURL => {
          Sentry.configureScope(scope => scope.setExtra('sessionURL', sessionURL))
          ReactGA.event({ category: 'LogRocket', action: sessionURL })
        })
      }
      const { user } = this.state
      const teamName = team ? team.name : 'Kaizen'
      const name = user.is_client ? `Client ${user.first_name}` : `${teamName} | ${user.first_name} ${user.last_name}`
      LogRocket.identify(user.id, {
        name,
        email: user.email,
        team: teamName,
        teamAdmin: user.is_team_admin
      })
    }
    getUsageData = teamData => {
      const dateStart = teamData.allowance_date_start ? `${teamData.allowance_date_start.split(' ')[0]} 00:00:00` : null
      const dateEnd = teamData.allowance_date_end ? `${teamData.allowance_date_end.split(' ')[0]} 23:59:59` : null
      const newTeamData = {
        ...teamData,
        allowance_date_start: dateStart,
        allowance_date_end: dateEnd
      }
      const withinAllowanceDatePeriod = moment().isAfter(dateStart) && moment().isBefore(dateEnd)
      if (teamData.allowance_unlimited || !withinAllowanceDatePeriod) {
        const periodData = {
          date_start: moment().subtract(1, 'month').format('YYYY-MM-DD hh:mm:ss'),
          date_end: moment().format('YYYY-MM-DD hh:mm:ss')
        }
        this.getUsage(newTeamData, periodData)
      } else {
        this.callAPI(`teams/${teamData.id}/usageperiod`).then(period => {
          const periodData = {
            date_start: period.date_start,
            date_end: period.date_end
          }
          this.getUsage(newTeamData, periodData)
        })
      }
    }
    getUsage = (teamData, periodData) => {
      this.callAPI(`teams/${teamData.id}/usages`, '', 'post', JSON.stringify(periodData)).then(usages => {
        const newLinks = usages.length ? usages.reduce((acc, curr) => acc + curr.new_links, 0) : 0
        const linkLimitReached = !teamData.allowance_unlimited && newLinks >= teamData.url_max
        const companyLimitReached = !teamData.allowance_unlimited && teamData.companies_max > 0 && teamData.companies_count >= teamData.companies_max
        const campaignLimitReached = !teamData.allowance_unlimited && teamData.campaign_max > 0 && teamData.campaign_count >= teamData.campaign_max
        const planEnded = !teamData.allowance_unlimited && teamData.allowance_date_end && moment().isAfter(teamData.allowance_date_end)
        const planNotStarted = !teamData.allowance_unlimited && teamData.allowance_date_start && moment().isBefore(teamData.allowance_date_start)

        this.setState({
          ready: true,
          currentTeam: {
            ...teamData,
            newLinks,
            usages,
            periodData,
            linkLimitReached,
            companyLimitReached,
            campaignLimitReached,
            planEnded,
            planNotStarted
          }
        })
      })
    }
    logout = type => {
      if (!this.state.loggedOut) {
        switch (type) {
          case 'full':
            if (!this.state.user.is_client) {
              this.callAPI('users/logout')
            }
            localStorage.removeItem('klipr_login_redirect')
            localStorage.removeItem('klipr_team_override')
            break
          case 'partial':
            localStorage.removeItem('klipr_login_redirect')
            localStorage.removeItem('klipr_team_override')
            break
          case 'soft':
            localStorage.setItem('klipr_login_redirect', this.props.location.pathname)
            break
          default:
            localStorage.setItem('klipr_login_redirect', this.props.location.pathname)
        }
        localStorage.removeItem('klipr_auth_token')
        localStorage.removeItem('klipr_user_level')
        localStorage.removeItem('klipr_shortlink')
        document.body.classList.remove('loggedin')
        this.setState({ loggedOut: true, token: null })
      }
    }
    setDeleteModal = (type = null, id = null, name = null, e = null) => {
      const { deleteDetails } = this.state
      if (e && id) {
        e.preventDefault()
        e.stopPropagation()

        deleteDetails.type = type
        deleteDetails.name = type === 'URL' && name.length > 40 ? `${name.substring(0, 40)}...` : name
        deleteDetails.id = id
        deleteDetails.allowed = type !== 'team'

        this.setState({ deleteDetails })
      } else {
        deleteDetails.id = null

        this.setState({ deleteDetails }, () => {
          setTimeout(() => {
            deleteDetails.type = null
            deleteDetails.name = null
            deleteDetails.id = null
            deleteDetails.allowed = true
            deleteDetails.deleting = false
            deleteDetails.success = false

            this.setState({ deleteDetails })
          }, 500)
        })
      }
    }
    deleteInputChange = e => {
      const { deleteDetails } = this.state
      deleteDetails.allowed = e.target.value === 'delete'
      this.setState({ deleteDetails })
    }
    deleteItem = () => {
      const { deleteDetails } = this.state
      deleteDetails.deleting = true
      this.setState({ deleteDetails })
      const endpoint = deleteDetails.type === 'URL' ? 'links' : deleteDetails.type === 'company' ? 'companies' : `${deleteDetails.type}s`

      this.callAPI(endpoint, `/${deleteDetails.id}`, 'delete').then(result => {
        if (result.success) {
          toast.success(`Successfully deleted ${deleteDetails.type} ${deleteDetails.name}.`)
          deleteDetails.deleting = false
          deleteDetails.success = true
          this.setState({ deleteDetails }, () => {
            setTimeout(() => {
              this.setDeleteModal()
            }, 1000)
          })
        } else {
          deleteDetails.deleting = false
          deleteDetails.success = false
          this.setState({ deleteDetails }, () => {
            setTimeout(() => {
              this.setDeleteModal()
            }, 1000)
          })
        }
      })
    }
    setCurrentTeamId = teamId => {
      this.setState({ currentTeamId: teamId })
      localStorage.setItem('klipr_team_override', teamId)
    }
    resetCurrentTeamId = pathname => {
      if (pathname === '/teams') {
        localStorage.removeItem('klipr_team_override')
        this.setState({ currentTeam: null, currentTeamId: null })
      } else {
        const currentTeamId = localStorage.getItem('klipr_team_override')
        this.setState({ currentTeamId })
      }
    }

    // API wrapper
    handleResponse = response => {
      const contentType = response.headers.get('content-type')
      if (contentType.includes('application/json')) {
        return response.json().then(json => {
          if (response.ok) {
            return json
          }
          this.handleError(response, json)
          return Promise.reject({
            ...json,
            status: response.status,
            statusText: response.statusText
          })
        })
      }
      if (contentType.includes('text/html')) {
        return response.text().then(text => {
          if (response.ok) {
            return text
          }
          this.handleError(response)
          return Promise.reject({
            status: response.status,
            statusText: response.statusText
          })
        })
      }
      if (contentType.includes('image/')) {
        return response.blob().then(blob => {
          if (response.ok) {
            return URL.createObjectURL(blob)
          }
          this.handleError(response)
          return Promise.reject({
            status: response.status,
            statusText: response.statusText
          })
        })
      }
      const error = `Sorry, content-type ${contentType} not supported.`
      toast.error(error)
      throw Error(error)
    }
    handleError = (response, json = null) => {
      let error = `There was an error while processing your request. ${response.status === 500 ? '' : `${response.statusText}.`}`
      if (response.status === 401) {
        if (json?.error_description.includes('OAuth2 authentication required')) {
          error = 'Authentication required.'
          this.logout('soft')
        } else {
          error = 'Your session has expired, please log in again.'
          this.logout('partial')
        }
      } else if (response.status === 403) {
        error = 'Your session has expired, please log in again.'
        this.logout('full')
      } else if (!response.url.includes('settings/user/')) {
        if (!json?.success && json?.message) {
          toast.error(<div>{json.message.replace(/ERROR: /g, '').split('\n').map((text, key) => <p key={key}>{text}</p>)}</div>) // eslint-disable-line
        } else if (!response.url.includes('/image/') && response.status !== 422) {
          toast.error(error)
        }
        const responseError = new Error(response.statusText)
        responseError.response = response
        throw responseError
      }
    }
    callAPI = (path, id = '', method = 'get', body = '', token = localStorage.getItem('klipr_auth_token')) => {
      let request
      if (method === 'get' || method === 'delete') {
        const bearer = `Bearer ${token}`
        const headers = {
          ...{ Authorization: bearer },
          ...this.state.currentTeamId && { 'X-superuser-override-team-id': this.state.currentTeamId }
        }
        request = { method, headers }
      } else {
        const bearer = `Bearer ${token}`
        const headers = {
          ...{
            Authorization: bearer,
            'Content-Type': 'application/json; charset=UTF-8',
            Accept: 'application/json'
          },
          ...this.state.currentTeamId && { 'X-superuser-override-team-id': this.state.currentTeamId }
        }
        request = { method, headers, body }
      }

      return fetch(__API__ + path + id, request)
        .then(this.handleResponse)
        .then(result => result)
        .catch(error => {
          console.error(error)
          return error
        })
    }

    verify = e => {
      e.preventDefault()

      this.setState({ verifying: true })
      const payload = {
        first_name: this.state.user.first_name,
        last_name: this.state.user.last_name,
        email: this.state.user.email,
        is_team_admin: this.state.user.is_team_admin,
        image: this.state.user.image,
        is_active: this.state.user.is_active,
        is_email_verified: true
      }

      this.callAPI('users/', this.state.user.id, 'put', JSON.stringify(payload)).then(result => {
        if (result.success) {
          this.callAPI('users/current').then(currentUser => {
            if (currentUser.email) {
              this.setState({ verifying: false })
              toast.success('Email verified.')
              this.setState({ user: currentUser })
            }
          })
        } else {
          this.setState({ verifying: false })
        }
      })
    }
    updateEmail = e => {
      this.setState(state => ({ user: { ...state.user, email: e.target.value } }))
    }
    setLoading = loading => {
      this.setState({ loading }, () => {
        setTimeout(() => document.body.classList.remove('locked'), 150)
      })
    }

    render() {
      if (this.props.location.pathname.includes('/share/') || this.props.location.pathname.includes('/book/')) {
        return (
          <Redirect to={{ pathname: '/login', state: { error: 'Sorry, the link you used is invalid. Please request a new link from your account manager.' } }}/>
        )
      }
      if (!localStorage.getItem('klipr_auth_token') || this.state.loggedOut) {
        return (
          <Redirect to={{ pathname: '/login', state: { error: 'Your session has expired, please log in again.' } }}/>
        )
      }
      return (
        <>
          {(!this.state.token || this.state.loading || !this.state.user || !this.state.ready) &&
            <Loader/>
          }
          {this.state.token && this.state.user && this.state.ready && (
            <>
              {!this.state.user.is_email_verified && !this.state.user.is_client ? (
                <VerificationWrapper>
                  <div>
                    <Form action='/' onSubmit={this.verify}>
                      <p>Is this email address correct for your account?</p>
                      <input type='email' defaultValue={this.state.user.email} onChange={this.updateEmail} required/>
                      <p>Once verified, this will be what you use to log in with.</p>
                      {this.state.verifying ? (
                        <VerifyButton disabled><Loader small/></VerifyButton>
                      ) : (
                        <VerifyButton type='submit'>Save</VerifyButton>
                      )}
                    </Form>
                  </div>
                </VerificationWrapper>
              ) : (
                <ChildComponent {...this.props} logout={this.logout} loading={this.state.loading} setLoading={this.setLoading} user={this.state.user} companies={this.state.companies} teams={this.state.teams} deleteDetails={this.state.deleteDetails} setDeleteModal={this.setDeleteModal} deleteInputChange={this.deleteInputChange}
                                deleteItem={this.deleteItem} callAPI={this.callAPI} currentTeam={this.state.currentTeam} setCurrentTeamId={this.setCurrentTeamId} toast={toast} defaultFilter={DOMAIN_RATING_FILTER}
                />
              )}
            </>
          )}
        </>
      )
    }
  }

  return withRouter(AuthenticatedComponent)
}
