import React, { useState, useEffect, createRef } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import Form from './form'
import Loader from './loader'
import TourComponent from './tour'
import tours from '../assets/tours'
import * as vars from '../assets/vars'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  background-color: ${vars.colors.white};
`
const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const AddButton = styled.button`
  position: relative;
  color: ${vars.colors.darkGrey};
  padding-left: 25px !important;

  &:before {
    content: '';
    position: absolute;
    top: 50%; left: 0;
    width: 20px; height: 20px;
    transform: translateY(-50%);
    background-image: url(${vars.icons.add});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    will-change: transform;
    transition: transform .3s ease-out;
  }
  &:hover:before {
    @media (hover: hover) {
      transform: translateY(-50%) scale(1.2);
    }
  }
`
const BackButton = styled.div`
  display: flex;
  white-space: nowrap;
  color: ${vars.colors.teal};
  cursor: pointer;

  &:hover {
    @media (hover: hover) {
      img {
        transform: translateX(-3px) rotate(90deg);
      }
    }
  }
  img {
    max-width: 12px;
    margin-right: 5px;
    vertical-align: middle;
    transform: rotate(90deg);
    will-change: transform;
    transition: transform .3s ease-out;
  }
`
const ApiList = styled.div`
  width: 100%;

  .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${vars.colors.teal};
    color: ${vars.colors.white};
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
  }
  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:not(.wide) {
      cursor: pointer;
      &:hover {
        @media (hover: hover) {
          background-color: ${vars.colors.lightShadow};
        }
      }
    }
  }
  span {
    padding: 10px;

    &:nth-of-type(1) {
      &:not(.wide) {
        width: calc(20% - 25px);
      }
    }
    &:nth-of-type(2) {
      width: calc(30% - 25px);
    }
    &:nth-of-type(3) {
      width: calc(50% - 25px);
    }
    &:nth-of-type(4) {
      width: 80px;
      text-align: center;

      img {
        width: 20px;
      }
    }
    &.capitalize {
      text-transform: capitalize;
    }
  }
`
const ApiSpecs = styled.div`
  width: 100%;

  form {
    width: 100%;

    fieldset {
      &:first-of-type {
        width: calc(30% - 20px);
      }
      &:last-of-type {
        width: calc(70% - 20px);
      }
    }
    input::placeholder {
      color: ${vars.colors.lightShadow};
    }
  }
`
const ApiSetupOptions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 250px;
      padding: 0;

      > div {
        height: 100%;
      }
    }
  }
  button {
    margin: 10px;
  }
`
const ApiDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const ApiStatus = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  margin-bottom: 30px;
  background-color: ${vars.colors.white};
  box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
  will-change: box-shadow;
  transition: box-shadow .3s ease-out;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    margin: 0 20px 0 0;
    width: 30px;
  }
  p {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  span {
    color: ${vars.colors.red};
    font-weight: 600;
  }
  button {
    font-weight: 600;
    color: ${vars.colors.red};
    will-change: transform;
    transition: transform .3s ease-out;

    &:hover {
      @media (hover: hover) {
        transform: scale(1.1);
      }
    }
  }

  &:hover {
    @media (hover: hover) {
      box-shadow: 0px 1px 3px ${vars.colors.lightShadow};
    }
  }
`
const EmailStatus = styled(ApiStatus)`
  span {
    display: flex;
    background-color: ${vars.colors.offWhite};
    color: ${vars.colors.teal};
    font-weight: 300;
    border-radius: 50%;
    padding: 10px;
    margin-right: 20px;
  }
  img {
    margin: 0;
    width: 25px;
  }
`
const ApiConnections = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;

  > div {
    width: calc(100% / 3);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
    will-change: box-shadow;
    transition: box-shadow .3s ease-out;

    &:hover {
      @media (hover: hover) {
        box-shadow: 0px 1px 3px ${vars.colors.lightShadow};
      }
    }
    &:nth-last-of-type(2) {
      margin: 0 10px;
    }
    > span {
      width: calc(100% - 40px);
      padding: 15px 0;
      margin-bottom: 10px;
      box-sizing: border-box;
      border-bottom: solid 2px ${vars.colors.green};
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 400px;
      overflow: auto;
    }
    li {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      min-height: 65px;
      padding: 10px 20px;
      box-sizing: border-box;

      &.locked {
        pointer-events: none;
        background-color: ${vars.colors.white} !important;
      }
      &.account {
        padding-right: 40px;
      }
      &:not(.disabled), &:not(.active) {
        cursor: pointer;
        will-change: background-color;
        transition: background-color .3s ease-out;

        &:hover {
          @media (hover: hover) {
            background-color: ${vars.colors.lightShadow};
          }
        }
      }

      &.disabled {
        pointer-events: none;
        padding: 0 20px;

        > div {
          height: 60px;
        }
      }
      &.active {
        pointer-events: none;
        background-color: ${vars.colors.green};
        color: ${vars.colors.white};
      }
      p {
        margin: 0 0 5px 0;
        text-align: left;
      }
      span {
        font-size: 14px;
        font-weight: 600;
      }
      img {
        position: absolute;
        right: 20px;
        width: 12px;
        cursor: default;
        pointer-events: auto;
      }
    }
  }
`
const AccountLockPop = styled.div`
  position: absolute;
  z-index: 1;
  pointer-events: none;
  border-radius: 5px;
  padding: 10px;
  width: 450px;
  top: ${props => props.position.y}px;
  left: ${props => props.position.x}px;
  background-color: ${vars.colors.white};
  opacity: ${props => (props.show ? 1 : 0)};
  box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
  will-change: opacity;
  transition: opacity .3s ease-out;
  transform: translateY(-110%);
`
const ApiButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => (props.alignEnd ? 'flex-end' : 'space-between')};
  align-items: center;
  margin: 20px 0;

  button {
    width: 150px;
  }
`
const ApiButton = styled.button`
  height: 45px;
  line-height: 25px;
  padding: 0 20px;
  border-radius: 40px;
  color: ${vars.colors.white};
  font-size: 0.9em;
  font-weight: 300;
  text-align: center;
  background-color: ${props => (props.delete ? vars.colors.red : props.disabled ? vars.colors.lightGrey : vars.colors.green)};
  cursor: ${props => (props.disabled ? 'auto' : 'pointer')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  will-change: background-color;
  transition: background-color .3s ease-out;

  &:hover {
    @media (hover: hover) {
      background-color: ${props => (props.delete ? darken(0.1, vars.colors.red) : darken(0.1, vars.colors.green))};
    }
  }
`
const Modal = styled.div`
  position: fixed;
  z-index: 9;
  top: 0; left: 0;
  width: 100%; height: 100vh;
  background-color: ${vars.colors.darkShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  will-change: opacity;
  transition: opacity .3s ease-out;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none !important')};

  * {
    pointer-events: ${props => (props.show ? 'auto' : 'none !important')};
  }
  a, button {
    display: block;
    width: 100%;
    height: 60px;
    line-height: 60px;
    max-width: 390px;
    margin: 32px auto 0;
    padding: 0 15px;
    border-radius: 50px;
    border: 0;
    color: ${vars.colors.white};
    font-size: 1.3em;
    font-weight: 300;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:focus {
      outline: 0;
    }
    &:disabled {
      cursor: auto;
    }
  }
`
const ModalInner = styled.div`
  width: 80%;
  min-height: 100px;
  max-width: ${props => (props.narrow ? '400px' : '550px')};
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: ${vars.colors.offWhite};

  p {
    width: 80%;
    margin: 0 auto 20px;
    font-size: 18px;
    line-height: 22px;

    &.success {
      margin: 0;
    }
  }
  span {
    font-weight: 600;
    color: ${vars.colors.red};

    &.title {
      color: ${vars.colors.teal};
      margin: 10px 0 20px;
    }
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 20px;
    max-height: 60vh;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      cursor: pointer;
      padding: 20px 30px;
      margin: 5px 0;
      background-color: ${vars.colors.white};
      color: ${vars.colors.teal};
      box-sizing: border-box;
      box-shadow: 0px 2px 4px ${vars.colors.lightShadow};
      will-change: background-color, color, box-shadow;
      transition: background-color .3s ease-out, color .3s ease-out, box-shadow .3s ease-out;

      &:hover {
        @media (hover: hover) {
          background-color: ${vars.colors.green};
          color: ${vars.colors.white};
          box-shadow: 0px 1px 3px ${vars.colors.lightShadow};

          span {
            color: ${vars.colors.white};
          }
          svg {
            stroke: ${vars.colors.white};
            transform: translateX(3px) rotate(-90deg);
          }
        }
      }
      span {
        max-width: calc(100% - 30px);
        text-align: left;
        color: ${vars.colors.teal};
        font-weight: 300;
        will-change: color;
        transition: color .3s ease-out;
      }
      svg {
        stroke: ${vars.colors.teal};
        max-width: 16px;
        vertical-align: middle;
        transform: rotate(-90deg);
        will-change: transform, stroke;
        transition: transform .3s ease-out, stroke .3s ease-out;
      }
    }
  }
  label {
    text-align: left;
  }
`
const ModalLoader = styled.div`
  width: 70%; height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  > div:first-of-type {
    height: 100%;
  }
`
const ModalButtons = styled.div`
  width: 70%; height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;

  button {
    width: 40%; height: 40px;
    line-height: 40px;
    max-width: 150px;
    margin: 0;
    padding: 0 8px;
    font-weight: 300;
    font-size: 18px;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
    &:first-of-type {
      background-color: ${vars.colors.red};

      &:hover {
        @media (hover: hover) {
          background-color: ${darken(0.3, vars.colors.red)};
        }
      }
    }
  }
`

const ApiSettings = ({ callAPI, isVisible, user, companyId, prevTourOpen, toast }) => {
  const initialSettings = {
    type: 'google_analytics',
    domain: '',
    existing_accounts: null,
    api_setting_id: null,
    google_account_id: null,
    google_account_name: null,
    google_account_connect_token: null,
    loading_accounts: true,
    accounts: null,
    analytics_account_id: null,
    analytics_account_name: null,
    loading_properties: true,
    properties: null,
    analytics_property_id: null,
    analytics_property_name: null,
    loading_profiles: true,
    profiles: null,
    analytics_profile_id: null,
    analytics_profile_name: null,
    saving_settings: false,
    deleting_settings: false,
    deleting_successful: false,
    save_enabled: false,
    variant_string: '',
    invitation_name: '',
    invitation_email: '',
    access_revoked: false
  }
  const [apiState, setApiState] = useState(initialSettings)
  const [loadingDetails, setLoadingDetails] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [apiList, setApiList] = useState(null)
  const [showApiList, setApiListVisibility] = useState(true)
  const [loadingList, setLoadingList] = useState(false)
  const [showApiSpecs, setApiSpecsVisibility] = useState(false)
  const [showApiSetupOptions, setApiSetupOptionsVisibility] = useState(false)
  const [showApiDetails, setApiDetailsVisibility] = useState(false)
  const [showApiStatus, setApiStatusVisibility] = useState(false)
  const [showInvitationList, setInvitationListVisibility] = useState(false)
  const [loadingInvitation, setLoadingInvitation] = useState(false)
  const [showDeleteButton, setDeleteButtonVisibility] = useState(false)
  const [showSaveButton, setSaveButtonVisibility] = useState(false)
  const [showAccountLockPop, setAccountLockPopVisibility] = useState(false)
  const [accountPopPosition, setAccountPopPosition] = useState({ x: 0, y: 0 })
  const [connectingToExisting, setConnectToExisting] = useState(false)
  const [connectingToNew, setConnectToNew] = useState(false)
  const [accountRefs, setAccountRefs] = useState(null)
  const [propertyRefs, setPropertyRefs] = useState(null)
  const [profileRefs, setProfileRefs] = useState(null)
  const [modalType, setModalType] = useState(null)
  const [tourOpen, setTourOpen] = useState(false)
  const [tourSteps, setTourSteps] = useState([])
  const [completedTours, setCompletedTours] = useState([])
  const popupInterval = createRef(null)

  useEffect(() => {
    getApiSettings()
  }, [])
  useEffect(() => {
    if (isVisible) {
      if (prevTourOpen) {
        if (showApiList) {
          setCompletedTours(completedTours.filter(x => x !== 'add-api'))
        } else {
          setCompletedTours(completedTours.filter(x => x !== 'api-steps'))
        }
      } else if (completedTours.includes('add-api')) {
        setTimeout(() => startTour(true), 500)
      } else {
        setTimeout(() => startTour(false), 500)
      }
    }
  }, [isVisible, prevTourOpen])
  useEffect(() => {
    const exp = new RegExp(/^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/)
    setFormValid(apiState.type && apiState.domain.match(exp))
  }, [apiState])

  const startTour = startSecondTour => {
    if (!user.is_super_user) {
      let page = null
      if (!startSecondTour) {
        if (!completedTours.includes('add-api')) {
          page = 'add-api'
        }
      } else if (!completedTours.includes('api-steps')) {
        page = 'api-steps'
      }
      const tour = tours.find(x => x.page === page)
      if (tour) {
        if (completedTours.length < 1) {
          callAPI('settings/user/completed_tours').then(result => {
            if (result.value) {
              setCompletedTours(result.value)
              if (result.value.length < 1 || !result.value.includes(page)) {
                setTourSteps(tour.steps)
                setTourOpen(true)
              }
            } else {
              setTourSteps(tour.steps)
              setTourOpen(true)
            }
          }).catch(() => {
            setTourSteps(tour.steps)
            setTourOpen(true)
          })
        } else if (!completedTours.includes(page)) {
          setTourSteps(tour.steps)
          setTourOpen(true)
        }
      }
    }
  }
  const endTour = () => {
    let payload = []
    if (completedTours.includes('add-api')) {
      payload = [...new Set([...completedTours, 'api-steps'])]
    } else {
      payload = [...new Set([...completedTours, 'add-api'])]
    }
    setCompletedTours(payload)
    callAPI('settings/user/completed_tours', '', 'post', JSON.stringify(payload))
    setTourOpen(false)
  }
  const updateForm = e => {
    const { value, name } = e.currentTarget
    setApiState({
      ...apiState,
      [name]: name === 'domain' ? value.replace('https://', '').replace('http://', '').split('/')[0] : value
    })
  }
  const goToAddApi = () => {
    setApiListVisibility(false)
    setApiSpecsVisibility(true)
    setApiSetupOptionsVisibility(true)
    setTimeout(() => startTour(true), 500)
  }
  const connectToNew = () => {
    setConnectToNew(true)
    callAPI('google-api/authentication-url').then(result => {
      showGoogleAuthPopup(result)
      setConnectToNew(false)
    })
  }
  const testExisting = () => {
    setConnectToExisting(true)
    if (apiState.google_account_id && !apiState.access_revoked) {
      callAPI(`google-api/${apiState.google_account_id}/testrevoked`).then(testResult => {
        if (testResult.success) {
          connectToExisting()
        }
      })
    } else {
      connectToExisting()
    }
  }
  const connectToExisting = () => {
    callAPI('apisettings/existinggoogleaccounts').then(result => {
      if (result.length > 0) {
        setApiState({
          ...apiState,
          existing_accounts: result
        })
        setModalType('existing accounts')
      } else {
        setModalType('no accounts')
      }
      setConnectToExisting(false)
    })
  }
  const setCurrentGoogleConnection = data => {
    setApiState({
      ...apiState,
      loading_accounts: true,
      google_account_connect_token: data.connect_token,
      google_account_id: data.id,
      google_account_name: data.name,
      save_enabled: false
    })
    setModalType(null)
    setApiSetupOptionsVisibility(false)
    setApiStatusVisibility(true)
    setApiDetailsVisibility(true)
    setSaveButtonVisibility(true)
    const payload = { google_account_connect_token: data.connect_token }
    callAPI('google-api/analytics/accounts', '', 'post', JSON.stringify(payload)).then(accounts => {
      setApiState({
        ...apiState,
        accounts: accounts.sort((a, b) => ((a.name > b.name) ? 1 : -1)),
        loading_accounts: false,
        google_account_connect_token: data.connect_token,
        google_account_id: data.id,
        google_account_name: data.name
      })
    })
  }
  const showEmailModal = () => {
    setModalType('email')
  }
  const closeEmailModal = () => {
    setApiState({
      ...apiState,
      invitation_name: '',
      invitation_email: ''
    })
    setModalType('')
  }
  const handleEmailSubmit = e => {
    e.preventDefault()
    setModalType(null)
    setApiSetupOptionsVisibility(false)
    setInvitationListVisibility(true)
    setApiState({ ...apiState, save_enabled: true })
    setSaveButtonVisibility(true)
  }
  const showGoogleAuthPopup = url => {
    let windowObjectReference = null
    let previousUrl = null
    setLoadingDetails(true)
    setApiSetupOptionsVisibility(false)
    setApiDetailsVisibility(true)
    setSaveButtonVisibility(false)
    setDeleteButtonVisibility(false)
    window.removeEventListener('message', handleGoogleMessage)

    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, 'Google', strWindowFeatures)
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, 'Google', strWindowFeatures)
      windowObjectReference.focus()
    } else {
      windowObjectReference.focus()
    }

    window.addEventListener('message', handleGoogleMessage, false)
    previousUrl = url

    popupInterval.current = setInterval(() => {
      if (windowObjectReference.closed) {
        window.removeEventListener('message', handleGoogleMessage)
        setLoadingDetails(false)
        setApiSetupOptionsVisibility(true)
        setApiDetailsVisibility(false)
        if (apiState.api_setting_id) {
          setDeleteButtonVisibility(true)
        }
        clearInterval(popupInterval.current)
      }
    }, 1000)
  }
  const handleGoogleMessage = e => {
    if (`${e.origin}/` !== __BASEURL__) {
      setLoadingDetails(false)
      return
    }
    if (e.data?.kliprGoogleAuthCode) {
      clearInterval(popupInterval.current)
      window.removeEventListener('message', handleGoogleMessage)
      const payload = { access_code: e.data.kliprGoogleAuthCode }
      callAPI('google-api/authenticate', '', 'post', JSON.stringify(payload)).then(result => {
        const { google_account_connect_token, google_account_id, google_account_name } = result
        if (apiState.api_setting_id && (google_account_id === apiState.google_account_id)) {
          callAPI(`apisettings/${apiState.api_setting_id}`).then(apiData => {
            if (apiData.status === 'ok') {
              getConnectionDetails(apiData)
            }
          })
        } else {
          setApiState({
            ...apiState,
            loading_accounts: true,
            google_account_connect_token,
            google_account_id,
            google_account_name
          })

          const connectPayload = { google_account_connect_token }
          callAPI('google-api/analytics/accounts', '', 'post', JSON.stringify(connectPayload)).then(accounts => {
            setApiState({
              ...apiState,
              accounts: accounts.sort((a, b) => ((a.name > b.name) ? 1 : -1)),
              loading_accounts: false,
              google_account_connect_token,
              google_account_id,
              google_account_name
            })
            setLoadingDetails(false)
            setSaveButtonVisibility(true)
          })
            .catch(() => {
              setApiDetailsVisibility(false)
              setApiStatusVisibility(false)
              setLoadingDetails(false)
              goToAddApi()
            })
        }
        setApiStatusVisibility(true)
        setLoadingDetails(true)
      })
    }
  }
  const getConnectionDetails = (apiData, callback) => {
    const accountsPayload = { google_account_connect_token: apiData.setting_data.google_account_connect_token }
    callAPI('google-api/analytics/accounts', '', 'post', JSON.stringify(accountsPayload)).then(accountsResult => {
      const accounts = accountsResult.sort((a, b) => ((a.name > b.name) ? 1 : -1))
      let accRefs = null
      accountsResult.forEach(acc => {
        accRefs = { ...accRefs, ...{ [acc.id]: createRef() } }
      })
      setAccountRefs(accRefs)

      const propertiesPayload = {
        account_id: apiData.setting_data.analytics_account_id,
        google_account_connect_token: apiData.setting_data.google_account_connect_token
      }
      callAPI('google-api/analytics/properties', '', 'post', JSON.stringify(propertiesPayload)).then(propertiesResult => {
        const properties = propertiesResult.sort((a, b) => ((a.name > b.name) ? 1 : -1))
        let propRefs = null
        propertiesResult.forEach(acc => {
          propRefs = { ...propRefs, ...{ [acc.id]: createRef() } }
        })
        setPropertyRefs(propRefs)

        const profilesPayload = {
          account_id: apiData.setting_data.analytics_account_id,
          property_id: apiData.setting_data.analytics_property_id,
          google_account_connect_token: apiData.setting_data.google_account_connect_token
        }
        callAPI('google-api/analytics/profiles', '', 'post', JSON.stringify(profilesPayload)).then(profilesResult => {
          const profiles = profilesResult.sort((a, b) => ((a.name > b.name) ? 1 : -1))
          let profRefs = null
          profilesResult.forEach(acc => {
            profRefs = { ...profRefs, ...{ [acc.id]: createRef() } }
          })
          setProfileRefs(profRefs)

          const accountName = accounts.find(x => x.id === apiData.setting_data.analytics_account_id).name
          const propertyName = properties.find(x => x.id === apiData.setting_data.analytics_property_id).name
          const profileName = profiles.find(x => x.id === apiData.setting_data.analytics_profile_id).name

          setApiState({
            ...apiState,
            type: apiData.type,
            domain: apiData.domain,
            accounts,
            loading_accounts: false,
            properties,
            loading_properties: false,
            profiles,
            loading_profiles: false,
            api_setting_id: apiData.id,
            google_account_name: apiData.setting_data.google_account_info.name,
            google_account_id: apiData.setting_data.google_account_id,
            google_account_connect_token: apiData.setting_data.google_account_connect_token,
            analytics_account_id: apiData.setting_data.analytics_account_id,
            analytics_account_name: accountName,
            analytics_property_id: apiData.setting_data.analytics_property_id,
            analytics_property_name: propertyName,
            analytics_profile_id: apiData.setting_data.analytics_profile_id,
            analytics_profile_name: profileName,
            variant_string: apiData.variant_string,
            save_enabled: true,
            access_revoked: false
          })
          setApiSpecsVisibility(true)
          setDeleteButtonVisibility(true)
          setSaveButtonVisibility(true)
          setApiStatusVisibility(true)
          setApiDetailsVisibility(true)
          setLoadingDetails(false)

          if (accounts.length > 5) {
            accRefs[apiData.setting_data.analytics_account_id].current.scrollIntoView()
          }
          if (properties.length > 5) {
            propRefs[apiData.setting_data.analytics_property_id].current.scrollIntoView()
          }
          if (profiles.length > 5) {
            profRefs[apiData.setting_data.analytics_profile_id].current.scrollIntoView()
          }
        }).catch(callback)
      }).catch(callback)
    }).catch(callback)
  }
  const setCurrentGoogleAccount = e => {
    const id = e.currentTarget.getAttribute('data-id')
    const name = e.currentTarget.getAttribute('data-name')
    setApiState({
      ...apiState,
      analytics_account_id: id,
      analytics_account_name: name,
      loading_properties: true
    })
    const payload = {
      account_id: id,
      google_account_connect_token: apiState.google_account_connect_token
    }
    callAPI('google-api/analytics/properties', '', 'post', JSON.stringify(payload)).then(properties => {
      setApiState({
        ...apiState,
        properties: properties.sort((a, b) => ((a.name > b.name) ? 1 : -1)),
        analytics_account_id: id,
        analytics_account_name: name,
        loading_properties: false
      })
    })
  }
  const setCurrentGoogleProperty = e => {
    const id = e.currentTarget.getAttribute('data-id')
    const name = e.currentTarget.getAttribute('data-name')
    setApiState({
      ...apiState,
      analytics_property_id: id,
      analytics_property_name: name,
      loading_profiles: true
    })
    const payload = {
      account_id: apiState.analytics_account_id,
      property_id: id,
      google_account_connect_token: apiState.google_account_connect_token
    }
    callAPI('google-api/analytics/profiles', '', 'post', JSON.stringify(payload)).then(profiles => {
      setApiState({
        ...apiState,
        profiles: profiles.sort((a, b) => ((a.name > b.name) ? 1 : -1)),
        analytics_property_id: id,
        analytics_property_name: name,
        loading_profiles: false
      })
    })
  }
  const setCurrentGoogleProfile = e => {
    const id = e.currentTarget.getAttribute('data-id')
    const name = e.currentTarget.getAttribute('data-name')
    setApiState({
      ...apiState,
      analytics_profile_id: id,
      analytics_profile_name: name,
      save_enabled: true
    })
  }
  const getApiSettings = () => {
    if (companyId) {
      setApiListVisibility(true)
      setLoadingList(true)
      setApiSpecsVisibility(false)
      setApiSetupOptionsVisibility(false)
      setApiStatusVisibility(false)
      setApiDetailsVisibility(false)
      setInvitationListVisibility(false)
      setDeleteButtonVisibility(false)
      setSaveButtonVisibility(false)
      callAPI(`companies/${companyId}/apisettings`).then(result => {
        setApiState({ ...apiState, deleting_successful: false })
        setApiList(result)
        setLoadingList(false)
        setApiState(initialSettings)
      })
    }
  }
  const getApiDetails = apiData => {
    setApiListVisibility(false)
    if (apiData.status === 'google_account_awaiting_connection') {
      setLoadingInvitation(true)
      setInvitationListVisibility(true)
    } else {
      setLoadingDetails(true)
      setApiStatusVisibility(false)
      setApiDetailsVisibility(true)
    }
    callAPI(`apisettings/${apiData.id}`).then(result => {
      if (result.status === 'ok' || result.status === 'google_account_access_revoked') {
        if (result.email_request_date_sent) {
          setApiState({
            ...apiState,
            type: result.type,
            domain: result.domain,
            api_setting_id: apiData.id,
            google_account_name: result.setting_data.google_account_info.name,
            access_revoked: result.setting_data.google_account_info.is_access_revoked,
            google_account_id: result.setting_data.google_account_id,
            variant_string: result.variant_string,
            save_enabled: true
          })

          setInvitationListVisibility(true)
          setApiDetailsVisibility(false)
          setDeleteButtonVisibility(true)
          setLoadingDetails(false)
        } else {
          getConnectionDetails(result, () => {
            setApiState({
              ...apiState,
              type: result.type,
              domain: result.domain,
              api_setting_id: apiData.id,
              google_account_name: result.setting_data.google_account_info.name,
              google_account_id: result.setting_data.google_account_id,
              google_account_connect_token: result.setting_data.google_account_connect_token,
              access_revoked: true
            })
            setLoadingDetails(false)
            setApiSpecsVisibility(true)
            setApiStatusVisibility(true)
            setApiDetailsVisibility(false)
            setDeleteButtonVisibility(true)
            setApiSetupOptionsVisibility(true)
          })
        }
      } else {
        setApiState({
          ...apiState,
          type: result.type,
          domain: result.domain,
          api_setting_id: result.id,
          invitation_name: result.email_request_to_name ? result.email_request_to_name : '',
          invitation_email: result.email_request_to_email ? result.email_request_to_email : '',
          variant_string: result.variant_string,
          access_revoked: false
        })
        if (apiData.status === 'google_account_awaiting_connection') {
          setApiState({
            ...apiState,
            type: result.type,
            domain: result.domain,
            api_setting_id: apiData.id,
            invitation_name: result.email_request_to_name,
            invitation_email: result.email_request_to_email,
            variant_string: result.variant_string,
            save_enabled: true
          })
          setLoadingInvitation(false)
          setApiSpecsVisibility(true)
          setSaveButtonVisibility(true)
          setDeleteButtonVisibility(true)
        } else {
          setApiStatusVisibility(false)
          setApiDetailsVisibility(false)
          setLoadingDetails(false)
          setDeleteButtonVisibility(true)
          goToAddApi()
        }
      }
    })
  }
  const handleSave = () => {
    setApiState({ ...apiState, saving_settings: true })
    let payload
    if (apiState.invitation_name.length > 0) {
      payload = {
        company_id: companyId,
        type: apiState.type,
        domain: apiState.domain,
        email_request_to_name: apiState.invitation_name,
        email_request_to_email: apiState.invitation_email,
        variant_string: `Invite sent to ${apiState.invitation_name}`
      }
    } else {
      const accountName = apiState.analytics_account_name.length > 63 ? `${apiState.analytics_account_name.substring(1, 63)}…` : apiState.analytics_account_name
      const propertyName = apiState.analytics_property_name.length > 63 ? `${apiState.analytics_property_name.substring(1, 63)}…` : apiState.analytics_property_name
      const profileName = apiState.analytics_profile_name.length > 63 ? `${apiState.analytics_profile_name.substring(1, 63)}…` : apiState.analytics_profile_name
      const variant_string = `${accountName} → ${propertyName} → ${profileName}`
      payload = {
        company_id: companyId,
        type: apiState.type,
        domain: apiState.domain,
        variant_string,
        setting_data: {
          google_account_id: apiState.google_account_id,
          google_account_connect_token: apiState.google_account_connect_token,
          analytics_account_id: apiState.analytics_account_id,
          analytics_property_id: apiState.analytics_property_id,
          analytics_profile_id: apiState.analytics_profile_id
        }
      }
    }
    const testPayload = {
      google_account_connect_token: apiState.google_account_connect_token,
      profile_id: apiState.analytics_profile_id
    }
    if (apiState.invitation_name.length > 0) {
      if (apiState.api_setting_id) {
        callAPI(`apisettings/${apiState.api_setting_id}`, '', 'put', JSON.stringify(payload)).then(result => {
          if (result.success) {
            toast.success('Api settings successfully saved.')
            setApiState({
              ...apiState,
              saving_settings: false
            })
            getApiSettings()
          }
        })
      } else {
        callAPI('apisettings/new', '', 'post', JSON.stringify(payload)).then(result => {
          if (result.success) {
            toast.success('Invitation email successfully sent.')
            setApiState({
              ...apiState,
              saving_settings: false
            })
            getApiSettings()
          }
        })
      }
    } else if (apiState.api_setting_id) {
      callAPI('google-api/analytics/test', '', 'post', JSON.stringify(testPayload)).then(testResult => {
        if (testResult.success) {
          callAPI(`apisettings/${apiState.api_setting_id}`, '', 'put', JSON.stringify(payload)).then(result => {
            if (result.success) {
              toast.success('Api settings successfully saved.')
              setApiState({
                ...apiState,
                saving_settings: false
              })
              getApiSettings()
            }
          })
        }
      })
    } else {
      callAPI('google-api/analytics/test', '', 'post', JSON.stringify(testPayload)).then(testResult => {
        if (testResult.success) {
          callAPI('apisettings/new', '', 'post', JSON.stringify(payload)).then(result => {
            if (result.success) {
              toast.success('New Api settings successfully saved.')
              setApiState({
                ...apiState,
                saving_settings: false
              })
              getApiSettings()
            }
          })
        }
      })
    }
  }
  const handleAccountLockHover = e => {
    const rect = e.target.getBoundingClientRect()
    const offset = document.documentElement.scrollTop || document.body.scrollTop
    setAccountPopPosition({ x: rect.x - 20, y: rect.y + offset })
    setAccountLockPopVisibility(true)
  }
  const handleDelete = type => {
    setApiState({ ...apiState, deleting_settings: true })
    if (type === 'delete') {
      callAPI(`apisettings/${apiState.api_setting_id}`, '', 'delete').then(result => {
        if (result.success) {
          toast.success('Google Analytics API settings have been deleted.')
          setApiState({ ...apiState, deleting_successful: true })
          setTimeout(() => setApiState({ ...apiState, deleting_successful: false }), 500)
          setModalType(null)
          getApiSettings()
        }
      })
    } else {
      setDeleteButtonVisibility(false)
      setSaveButtonVisibility(false)
      const payload = { google_account_connect_token: apiState.google_account_connect_token }
      callAPI('google-api/remove', '', 'post', JSON.stringify(payload)).then(result => {
        if (result.success) {
          const savePayload = {
            company_id: apiState.company_id,
            type: apiState.type,
            domain: apiState.domain,
            variant_string: 'Google Analytics View not set',
            setting_data: {}
          }
          if (apiState.api_setting_id) {
            callAPI(`apisettings/${apiState.api_setting_id}`, '', 'put', JSON.stringify(savePayload)).then(saveResult => {
              if (saveResult.success) {
                toast.success(`${apiState.google_account_name} Google Analytics connection was removed.`)
                setApiState({
                  ...apiState,
                  deleting_settings: false,
                  deleting_successful: true
                })
                setTimeout(() => setApiState({ ...apiState, deleting_successful: false }), 500)
                setModalType(null)
                setApiStatusVisibility(false)
                setApiDetailsVisibility(false)
                setLoadingDetails(false)
                setDeleteButtonVisibility(true)
                goToAddApi()
              }
            })
          } else {
            toast.success(`${apiState.google_account_name} Google Analytics connection was removed.`)
            setApiState({
              ...apiState,
              deleting_settings: false,
              deleting_successful: true
            })
            setTimeout(() => setApiState({ ...apiState, deleting_successful: false }), 500)
            setModalType(null)
            getApiSettings()
          }
        }
      })
    }
  }

  return (
    <Wrapper>
      <Header>
        <h3>API Settings</h3>
        {showApiList ? (
          <AddButton className='addApiButton' onClick={goToAddApi}>API</AddButton>
        ) : (
          <>
            {!loadingDetails && (
              <BackButton onClick={getApiSettings}>
                <img src={vars.icons.arrow} alt='Back to API List'/> Back to API List
              </BackButton>
            )}
          </>
        )}
      </Header>
      {showApiList && (
        <ApiList>
          {loadingList ? (
            <Loader small/>
          ) : (
            <>
              <div className='head'>
                <span>Type</span>
                <span>Domain</span>
                <span/>
                <span>Verified</span>
              </div>
              <ul>
                {(apiList?.length > 0) ? (
                  apiList.map(api => (
                    <li key={api.id} data-id={api.id} onClick={() => getApiDetails(api)}>
                      <span className='capitalize'>{api.type.replace(/_/g, ' ')}</span>
                      <span>{api.domain}</span>
                      <span>{api.variant_string}</span>
                      <span>
                        <img alt='API veritication status'
                          src={api.status === 'ok' ? vars.icons.tick : api.status === 'google_account_awaiting_connection' ? vars.icons.clock : vars.icons.crossRed}
                          title={api.status === 'ok' ? 'Verified' : api.status === 'google_account_awaiting_connection' ? 'Awaiting verification' : 'Google Account not connected'}
                        />
                      </span>
                    </li>
                  ))
                ) : (
                  <li className='wide'><span className='wide'>There are currently no APIs</span></li>
                )}
              </ul>
            </>
          )}
        </ApiList>
      )}
      {showApiSpecs && (
        <ApiSpecs>
          <Form onSubmit={e => e.preventDefault()}>
            <fieldset className='halfWidth'>
              <label htmlFor='type'>Type*</label>
              <select id='type' className='apiTypeDropdown' value={apiState.type} name='type' disabled onChange={updateForm} required>
                <option value='google_analytics'>Google Analytics</option>
              </select>
            </fieldset>
            <fieldset className='halfWidth'>
              <label htmlFor='domain'>Domain*</label>
              <input id='domain' className='apiDomainInput' name='domain' type='text' placeholder='example.com' onChange={updateForm} value={apiState.domain} required/>
            </fieldset>
          </Form>
        </ApiSpecs>
      )}
      {showApiStatus && (
        <ApiStatus>
          <div>
            <img src={apiState.access_revoked ? vars.icons.crossRed : vars.icons.google} alt='API type'/>
            <p>{apiState.google_account_name}</p>
          </div>
          {apiState.access_revoked ? (
            <span>Google Account access revoked</span>
          ) : (
            <>
              {apiState.google_account_connect_token &&
                <button type='button' onClick={() => setModalType('remove')}>Remove</button>
              }
            </>
          )}
        </ApiStatus>
      )}
      {showApiSetupOptions && (
        <ApiSetupOptions>
          <div>
            <ApiButton className='apiExistingConnectionButton' disabled={(connectingToExisting || connectingToNew || !formValid)} onClick={testExisting}>
              {connectingToExisting ? <Loader small/> : 'Use Linked Google Account'}
            </ApiButton>
            <ApiButton className='apiNewConnectionButton' disabled={(connectingToNew || connectingToExisting || !formValid)} onClick={connectToNew}>
              {connectingToNew ? <Loader small/> : 'Link New Google Account'}
            </ApiButton>
          </div>
          <ApiButton className='apiEmailConnectionButton' disabled={(connectingToNew || connectingToExisting || !formValid)} onClick={showEmailModal}>Request Access via Email</ApiButton>
        </ApiSetupOptions>
      )}
      {showApiDetails && (
        <ApiDetails>
          {loadingDetails ? (
            <Loader small/>
          ) : (
            <ApiConnections>
              <div>
                <span>Analytics Accounts</span>
                <ul>
                  {(apiState.loading_accounts || !apiState.accounts.length) ? (
                    <li className='disabled'><Loader small/></li>
                  ) : (
                    apiState.accounts.map(account => {
                      const perms = account.effective_permissions
                      const unlocked = perms?.length > 0 && perms?.find(x => x === 'READ_AND_ANALYZE')
                      return (
                        <li key={account.id} onClick={unlocked ? setCurrentGoogleAccount : null}
                          className={account.id === apiState.analytics_account_id ? unlocked ? 'active account' : 'active account locked' : unlocked ? 'account' : 'account locked'}
                          data-id={account.id} data-name={account.name}
                          ref={accountRefs ? accountRefs[account.id] : null}
                        >
                          <p>{account.name}</p>
                          <span>ID: {account.id}</span>
                          {!unlocked &&
                            <img src={vars.icons.padlock} alt='account lock' onMouseEnter={handleAccountLockHover} onMouseLeave={() => setAccountLockPopVisibility(false)}/>
                          }
                        </li>
                      )
                    })
                  )}
                </ul>
                <AccountLockPop show={showAccountLockPop} position={accountPopPosition}>Your access to this Analytics profile does not permit Klipr to use its data. In order to connect the account, please instead use the “Request Access via Email” option on the API List setting.</AccountLockPop>
              </div>
              <div>
                <span>Properties & Apps</span>
                {apiState.analytics_account_id ? (
                  <ul>
                    {(apiState.loading_properties || !apiState.properties.length) ? (
                      <li className='disabled'><Loader small/></li>
                    ) : (
                      apiState.properties.map(property => (
                        <li key={property.id} onClick={setCurrentGoogleProperty}
                          className={property.id === apiState.analytics_property_id ? 'active' : ''}
                          data-id={property.id} data-name={property.name}
                          ref={propertyRefs ? propertyRefs[property.id] : null}
                        >
                          <p>{property.name}</p>
                          <span>{property.id}</span>
                        </li>
                      ))
                    )}
                  </ul>
                ) : (
                  <ul><li className='disabled'>Select an account</li></ul>
                )}
              </div>
              <div>
                <span>Views</span>
                {apiState.analytics_property_id ? (
                  <ul>
                    {(apiState.loading_profiles || !apiState.profiles.length) ? (
                      <li className='disabled'><Loader small/></li>
                    ) : (
                      apiState.profiles.map(profile => (
                        <li key={profile.id} onClick={setCurrentGoogleProfile}
                          className={profile.id === apiState.analytics_profile_id ? 'active' : ''}
                          data-id={profile.id} data-name={profile.name}
                          ref={profileRefs ? profileRefs[profile.id] : null}
                        >
                          <p>{profile.name}</p>
                          <span>{profile.id}</span>
                        </li>
                      ))
                    )}
                  </ul>
                ) : (
                  <ul><li className='disabled'>Select a property</li></ul>
                )}
              </div>
            </ApiConnections>
          )}
        </ApiDetails>
      )}
      {showInvitationList && (
        <EmailStatus>
          {loadingInvitation ? (
            <Loader small/>
          ) : (
            <div>
              <span><img src={vars.icons.email} alt='Email invitation'/></span>
              <p>{apiState.variant_string ? apiState.variant_string : `Send invitation email to ${apiState.invitation_name}...`}</p>
            </div>
          )}
        </EmailStatus>
      )}
      <ApiButtonsWrapper alignEnd={!showDeleteButton}>
        {showDeleteButton && (
          <ApiButton delete onClick={() => setModalType('delete')}>Delete</ApiButton>
        )}
        {showSaveButton && (
          <ApiButton disabled={apiState.saving_settings || !apiState.save_enabled} onClick={handleSave}>
            {apiState.saving_settings ? (
              <Loader small/>
            ) : (apiState.invitation_name.length > 0 && !apiState.api_setting_id) ? 'Save and Send' : 'Save'}
          </ApiButton>
        )}
      </ApiButtonsWrapper>
      <Modal show={modalType}>
        <ModalInner narrow={modalType === 'existing accounts'}>
          {(modalType === 'delete' || modalType === 'remove') ? (
            <>
              {apiState.deleting_settings ? (
                <p>{modalType === 'delete' ? 'Deleting' : 'Removing'} <span>{apiState.google_account_name}</span> API {modalType === 'delete' ? 'settings' : 'connection'}</p>
              ) : apiState.deleting_successful ? (
                <p className='success'>Successfully {modalType === 'delete' ? 'deleted' : 'removed'} <span>{apiState.google_account_name}</span> API {modalType === 'delete' ? 'settings' : 'connection'}</p>
              ) : (
                <div>
                  <p>Are you sure you want to {modalType} <span>{apiState.google_account_name}</span> API {modalType === 'delete' ? 'settings' : 'connection'}?</p>
                </div>
              )}
              {apiState.deleting_settings ? (
                <ModalLoader><Loader small/></ModalLoader>
              ) : apiState.deleting_successful ? (
                <span/>
              ) : (
                <ModalButtons>
                  <button type='button' onClick={() => handleDelete(modalType)}>{modalType === 'delete' ? 'Delete' : 'Remove'}</button>
                  <button type='button' onClick={() => setModalType(null)}>Cancel</button>
                </ModalButtons>
              )}
            </>
          ) : modalType === 'no accounts' ? (
            <>
              <span className='title'>No existing Google Accounts. Please connect a new account.</span>
              <ModalButtons>
                <button type='button' onClick={() => setModalType(null)}>OK</button>
              </ModalButtons>
            </>
          ) : modalType === 'existing accounts' ? (
            <>
              <span className='title'>Select one of the following Google Accounts</span>
              <ul>
                {apiState.existing_accounts.map(account => (
                  <li key={account.id} onClick={() => setCurrentGoogleConnection(account)}>
                    <span>{account.name}</span>
                    <svg xmlns='http://www.w3.org/2000/svg' width='25.' height='15' viewBox='0 0 25 15'> <path fill='none' strokeLinecap='round' strokeLinejoin='round' strokeWidth='3' d='M2.119 2.12l10.5 11.481 6.239-6.715 4.428-4.766'/></svg>
                  </li>
                ))}
              </ul>
              <ModalButtons>
                <button type='button' onClick={() => setModalType(null)}>Cancel</button>
              </ModalButtons>
            </>
          ) : modalType === 'email' ? (
            <>
              <span className='title left'>Send Email Invitation</span>
              <Form onSubmit={handleEmailSubmit}>
                <fieldset>
                  <label htmlFor='invitation_name'>Name*</label>
                  <input id='invitation_name' name='invitation_name' type='text' onChange={updateForm} value={apiState.invitation_name} required/>
                </fieldset>
                <fieldset>
                  <label htmlFor='invitation_email'>Email*</label>
                  <input id='invitation_email' name='invitation_email' type='email' onChange={updateForm} value={apiState.invitation_email} required/>
                </fieldset>
                <ModalButtons>
                  <button type='button' onClick={closeEmailModal}>Cancel</button>
                  <button type='submit'>OK</button>
                </ModalButtons>
              </Form>
            </>
          ) : null}
        </ModalInner>
      </Modal>
      <TourComponent steps={tourSteps} isOpen={tourOpen} endTour={endTour}/>
    </Wrapper>
  )
}

export default ApiSettings
