import React from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import posed from 'react-pose'
import * as vars from '../assets/vars'

const AnimatedWrapper = posed.div({
  show: { opacity: 1 },
  hide: { opacity: 0 }
})
const Wrapper = styled(AnimatedWrapper)`
  position: fixed;
  z-index: 9;
  top: 0; left: 0;
  width: 100%; height: 100vh;
  background-color: ${vars.colors.darkShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: ${props => (props.pose === 'show' ? 'auto' : 'none')};
`
const InnerWrapper = styled.div`
  position: relative;
  width: 80%;
  max-width: 550px;
  padding: 20px;
  border-radius: 3px;
  background-color: ${vars.colors.offWhite};

  h2 {
    width: calc(100% - 40px);
    margin: 0;
    color: ${vars.colors.green};
  }
  p {
    width: 100%;
  }
  span {
    display: block;
    width: calc(100% - 30px);
    font-weight: 600;
    color: ${vars.colors.teal};
    background-color: ${vars.colors.white};
    padding: 5px 15px;
    border-radius: 3px;
    margin-bottom: 20px;
    word-break: break-all;
  }
`
const Close = styled.div`
  position: absolute;
  top: 15px; right: 20px;
  width: 18px; height: 18px;
  background-image: url(${vars.icons.cross});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  will-change: transform;
  transition: transform .3s ease-out;

  &:hover {
    @media (hover: hover) {
      transform: scale(1.2);
    }
  }
`
const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  a, button {
    width: 40%; height: 40px;
    line-height: 40px;
    max-width: 150px;
    padding: 0 8px;
    margin: 0 20px 0 0;
    border: 0;
    outline: 0;
    border-radius: 20px;
    color: ${vars.colors.white};
    font-size: 18px;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    background-color: ${vars.colors.green};
    will-change: background-color;
    transition: background-color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.1, vars.colors.green)};
      }
    }
  }
  a {
    background-color: ${vars.colors.red};

    &:hover {
      @media (hover: hover) {
        background-color: ${darken(0.3, vars.colors.red)};
      }
    }
  }
`

const ShareModal = ({ url, show, copy, close }) => (
  <Wrapper pose={show ? 'show' : 'hide'}>
    <InnerWrapper>
      <Close onClick={close}/>
      <h2>Share Read-Only URL</h2>
      <p>This URL can also be added to your bookmarks</p>
      <span>{url}</span>

      <ButtonsWrapper>
        <button type='button' id='copyUrl' onClick={copy}>Copy URL</button>
        <a href={`mailto:?subject=Your%20Klipr%20Campaigns%20Dashboard%20URL&body=Please%20find%20your%20Klipr%20Campaigns%20Dashboard%20at%20the%20following%20link:%0d%0a%0d%0a${url}`} target='_blank' rel='noopener noreferrer'>Email URL</a>
      </ButtonsWrapper>
    </InnerWrapper>
  </Wrapper>
)

export default ShareModal
