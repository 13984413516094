import React from 'react'
import { withRouter } from 'react-router'
import AddCompany from '../pages/addCompany'

class NewCompany extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      company: {
        name: '',
        phone: '',
        email: '',
        avatar: '',
        screenshotsAllowed: false,
        loading: !!this.props.match.params.company,
        teamId: ''
      }
    }
  }

  componentDidMount() {
    if (this.props.match.params.company) {
      this.getCompany(+this.props.match.params.company)
    } else {
      this.props.setLoading(false)
    }
  }

  getCompany = companyId => {
    this.props.callAPI(`companies/${companyId}`).then(result => {
      this.setState({
        company: {
          name: result.name,
          phone: result.contact_number ? result.contact_number : '',
          email: result.contact_email ? result.contact_email : '',
          avatar: result.image,
          screenshotsAllowed: result.has_screenshot_licence,
          teamId: result.team_id,
          loading: false
        }
      }, () => this.props.setLoading(false))
    })
  }
  addCompany = e => {
    e.preventDefault()

    const { company } = this.state
    company.loading = true
    this.setState({ company })

    const phone = company.phone ? company.phone : null
    const email = company.email ? company.email : null

    const payload = {
      ...{
        name: company.name,
        image: company.avatar,
        team: company.teamId,
        has_screenshot_licence: company.screenshotsAllowed,
        isActive: true
      },
      ...email && { email },
      ...phone && { phoneNumber: phone }
    }

    const path = this.props.match.params.company ? 'companies/' : 'companies/new'
    const method = this.props.match.params.company ? 'put' : 'post'
    const id = this.props.match.params.company

    this.props.callAPI(path, id, method, JSON.stringify(payload)).then(result => {
      company.loading = false
      this.setState({ company })

      if (result.success) {
        if (path.includes('/new')) {
          this.props.toast.success(`Company ${company.name} was successfully added.`)
          this.setState({ company })
          this.props.history.push(`/campaigns/${result.company_id}`)
        } else {
          this.props.toast.success(`Company ${company.name} was updated.`)
          this.setState({ company })
          this.props.history.push(`/campaigns/${id}`)
        }
      }
    })
  }
  changeCompany = e => {
    const field = e.target.name
    const { company } = this.state
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
    company[field] = value
    this.setState({ company })
  }
  previewFile = e => {
    const reader = new FileReader()
    const { company } = this.state
    const file = e.target.files[0]

    if (file) {
      const size = ((file.size / 1024) / 1024).toFixed(4)
      if (size < 0.5) {
        reader.addEventListener('load', () => {
          company.avatar = reader.result.replace('data:image/jpeg;base64,', '').replace('data:image/png;base64,', '')
          this.setState({ company })
        }, false)
        reader.readAsDataURL(file)
      } else {
        this.props.toast.error('The file is too big, please try again with an image smaller than 500kb.')
      }
    }
  }

  render() {
    return <AddCompany onSubmit={this.addCompany} onChange={this.changeCompany} callAPI={this.props.callAPI} preview={this.previewFile} user={this.props.user} company={this.state.company} match={this.props.match} tourOpen={this.props.tourOpen} toast={this.props.toast}/>
  }
}

export default withRouter(NewCompany)
