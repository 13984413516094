import React from 'react'
import styled from 'styled-components'
import * as vars from '../assets/vars'

const Wrapper = styled.span`
  position: relative;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  top: ${props => (props.big ? '10px' : 'auto')};
  font-size: ${props => (props.big ? '28px' : '20px')};
  flex: 0 0 auto;
  width: ${props => (props.big ? '80px' : '40px')};
  height: ${props => (props.big ? '80px' : '40px')};
  border-radius: 50%;
  color: ${vars.colors.teal};
  background-image: ${props => (props.image ? `url(data:image/png;base64,${props.image})` : 'none')};
  background-color: ${vars.colors.white};
  background-size: cover;
  background-position: center;
  box-shadow: 0px 3px 6px ${vars.colors.lightShadow};
`

const Avatar = props => {
  let initials = null
  if (props.name.replace(/\s/g, '').length) {
    const split = props.name.includes(' ') ? ' ' : props.name.includes('-') ? '-' : props.name.includes('&') ? '&' : null
    initials = split ? `${props.name.split(split)[0][0]}${props.name.split(split)[1].length ? props.name.split(split)[1][0] : ''}` : props.name[0]
  }

  return (
    <Wrapper image={props.image} big={props.big}>
      {!props.image && initials?.toUpperCase()}
    </Wrapper>
  )
}

export default Avatar
