import React from 'react'

export default [{
  page: 'companies',
  steps: [{
    selector: '.addCompany',
    content: 'Before you can start tracking campaigns, you need to set up a Company. Set up your first Company now.'
  }]
}, {
  page: 'add-company',
  steps: [{
    selector: '',
    content: 'Your Company pages are how you group campaigns for easy tracking and reporting. You can share read-only access to whole Company pages including all the campaigns, and track overall results on the Company Dashboard.'
  }]
}, {
  page: 'company',
  adminsOnly: true,
  steps: [{
    selector: '.apiSettingsTab',
    content: 'You can add Google Analytics if you have access to it! This will provide Referral Traffic data (“Sessions”), as well as improve the efficiency of finding new backlinks.',
    stepInteraction: false
  }]
}, {
  page: 'add-api',
  steps: [{
    selector: '.addApiButton',
    content: 'To pull accurate results, you need to have a relevant account or profile linked to the Company.',
    stepInteraction: false
  }]
}, {
  page: 'api-steps',
  steps: [{
    selector: '.apiTypeDropdown',
    content: 'You can select what API integration to link. Currently, only Google Analytics is supported.',
    stepInteraction: false
  }, {
    selector: '.apiDomainInput',
    content: 'Input the domain you’re trying to find information for. This should match the exact domain - the easiest way is to copy and paste your campaign’s URL into this field.',
    stepInteraction: false
  }, {
    selector: '.apiNewConnectionButton',
    content: 'If you have direct access to the Google Analytics account, use this button to connect it.',
    stepInteraction: false
  }, {
    selector: '.apiExistingConnectionButton',
    content: 'If you’ve already linked a Google Account and just want to set up a new profile, use this button.',
    stepInteraction: false
  }, {
    selector: '.apiEmailConnectionButton',
    content: 'If you need to request Google Analytics access from a client, use this button to send an email invitation. This allows them to securely link their account at their end.',
    stepInteraction: false
  }]
}, {
  page: 'campaigns',
  steps: [{
    selector: '.addCampaign',
    content: 'Campaigns are your monitors for coverage. Get started with your first Campaign tracker.'
  }]
}, {
  page: 'add-campaign',
  steps: [{
    selector: '',
    content: 'Your Campaign pages where you’ll track coverage. You’ll be able to see details on individual links and mentions, as well as campaign-wide results.'
  }, {
    selector: '.companyTitle',
    content: 'This will be how your Campaign appears in your Company Dashboard.',
    stepInteraction: false
  }, {
    selector: '.companyUrl',
    content: () => (
      <div>This should be your landing page for the content. Make sure you include the protocol (<i>http://</i> or <i>https://</i>)!</div>
    ),
    stepInteraction: false
  }, {
    selector: '.urlPrefix',
    content: () => (
      <div>This lets you select how you want to track coverage. If it’s set to Prefix, coverage will be found for all the subfolders too - so a Campaign URL of <i>site.com/content</i> will also find coverage for <i>site.com/content/page1</i> and <i>site.com/content/page2</i>. If it’s set to Exact URL, it’ll only find coverage for site.com/content.</div>
    ),
    stepInteraction: false
  }]
}]
