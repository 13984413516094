/* eslint-disable */
import React from 'react'
import { Link } from 'react-router-dom'
import { Column, SortDirection, SortIndicator, defaultTableRowRenderer } from 'react-virtualized'
import styled from 'styled-components'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import VirtualizedTable from '../components/virtualizedTable'
import OverviewStats from '../components/overviewStats'
import ApprovalModal from '../components/approvalModal'
import SearchInput from '../components/searchInput'
import LinkPolling from '../components/linkPolling'
import LinksFilter from '../components/linksFilter'
// import Paginator from '../components/paginator'
import Loader from '../components/loader'
import * as vars from '../assets/vars'

const Title = styled.div`
  width: calc(100% - 280px);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  h2 {
    width: calc(100% - 50px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    
    &:after {
      content: ${({ campaignType }) => campaignType ? `'${campaignType}'` : ''};
      border-radius: 20px;
      padding: 5px 15px;
      background: #01B88E;
      color: white;
      position: relative;
      font-size: 16px;
      cursor: default;
      font-weight: normal;
      top: -3px;
      left: 20px;
    }

    & + a {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding: 0;
      margin: 0 0 4px 12px;

      &:after {
        content: '';
        position: absolute;
        top: 50%; left: 0;
        width: 16px; height: 16px;
        transform: translateY(-50%);
        background-image: url(${vars.icons.edit});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        will-change: transform;
        transition: transform .3s ease-out;
      }
      &:hover:after {
        @media (hover: hover) {
          transform: translateY(-50%) scale(1.2);
        }
      }
    }
  }
`
const AddLink = styled(Link)`
  position: relative;
  color: ${vars.colors.darkGrey};
  padding-left: 25px !important;

  &:before {
    content: '';
    position: absolute;
    top: 50%; left: 0;
    width: 20px; height: 20px;
    transform: translateY(-50%);
    background-image: url(${vars.icons.add});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    will-change: transform;
    transition: transform .3s ease-out;
  }
  &:hover:before {
    @media (hover: hover) {
      transform: translateY(-50%) scale(1.2);
    }
  }
`
const FilterBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 0 25px;
  font-size: 0.9em;

  @media (max-width: 767px) {
    justify-content: center;
  }

  > a {
    padding: 0;
    font-weight: 600;

    &:hover span {
      @media (hover: hover) {
        transform: scale(1.15);
      }
    }
  }
  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    a {
      margin-right: 30px;
    }
  }
  a {
    text-decoration: none;
  }
`
const NoResults = styled.div`
  margin-top: 50px;
`
const LinkWrapper = styled.div`
  padding: 0 20px 0 0 !important;

  a {
    margin-left: 8px;
    line-height: 1.2em;
    text-decoration: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: ${vars.colors.teal};
    will-change: color;
    transition: color .3s ease-out;

    &:hover {
      @media (hover: hover) {
        color: ${vars.colors.blue};
      }
    }
  }
`
const LinkType = styled.div`
  position: absolute;
  top: 0; left: 0;
  height: 100%; width: 8px;
  background-color: ${props => (props.type === 'auto' ? vars.colors.green : vars.colors.blue)};

  &:after {
    content: '${props => props.type} URL';
    position: absolute;
    z-index: 1;
    top: calc(50% - 15px); left: 8px;
    width: 100px; height: 20px;
    line-height: 20px;
    text-align: center;
    line-height: 20px;
    background-color: ${props => (props.type === 'auto' ? vars.colors.green : vars.colors.blue)};
    color: ${vars.colors.white};
    padding: 5px;
    text-transform: capitalize;
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    transition: opacity .3s ease-out;
  }
  &:hover:after {
    @media (hover: hover) {
      pointer-events: auto;
      opacity: 1;
    }
  }
`

const BuzzstreamFieldset = styled.fieldset`
  border: 1px solid #d9d9d9;
  padding: 20px 17px 25px 17px;
  background: #F5F5F5;
  margin-bottom: 20px;

  span {
    color: ${vars.colors.red};
    font-weight: 600;
    font-size: 13.6px;
    margin-right: 5px;
    display: block;
    padding-bottom: 5px;
  }

  legend {
    padding: 0 10px;
    font-weight: bold;
  }

  .open-on-buzzstream {
    display: flex;
    gap: 5px;
    margin-top: 15px;
  }
`

const DropdownRenderer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  select {
    cursor: pointer;
    color: ${vars.colors.teal};
    font-size: 16px;
    text-align: center;
    text-align-last: center;
  }
  > div {
    height: 50px;
    width: 100%;
  }
`
const SelectDrop = styled.div`
  position: fixed;
  top: 0; left: 0;
  width: 100%; height: 100%;
`
const NumberCell = styled.div`
  font-weight: 600;
`
const TextCell = styled.div`
  width: 100%;
`
const ActionButton = styled.button`
  display: flex;
  right: 30px !important;
  padding: 7px !important;
`
const TableHeader = styled.div`
  position: relative;
`
const ScoreInfo = styled.div`
  position: absolute;
  top: calc(50% - 8px);
  right: -10px;
  padding: 0 !important;
  width: 16px; height: 16px;
  background-image: url(${vars.icons.info});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  &:after {
    content: 'Klipr score aims to measure the SEO value of a piece of coverage.';
    position: absolute;
    padding: 7px;
    top: -12px; left: -320px;
    width: 300px; height: 28px;
    border-radius: 3px;
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
    pointer-events: none;
    opacity: 0;
    background-color: ${vars.colors.offWhite};
    will-change: opacity;
    transition: opacity .3s ease-out;
  }
  &:hover:after {
    @media (hover: hover) {
      opacity: 1;
    }
  }
`
const AnchorText = styled.div`
  p {
    margin: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    position: absolute;
    opacity: ${props => (props.empty ? '0 !important' : 0)};
    pointer-events: ${props => (props.empty ? 'none !important' : 'none')};
    top: 50%; right: 80px;
    transform: translateY(-50%);
    padding: 10px 20px;
    border-radius: 3px;
    color: ${vars.colors.darkGrey};
    background-color: ${vars.colors.offWhite};
    font-weight: 600;
    text-transform: none !important;
    will-change: opacity;
    transition: opacity .3s ease-out;

    &:hover {
      @media (hover: hover) {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
  &:hover {
    @media (hover: hover) {
      span {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`
const NewCoverage = styled.div`
  flex: none !important;
  position: relative;
  top: -1px; left: 5px;
  color: ${vars.colors.white};

  p {
    margin: 0;
    background-color: ${vars.colors.green};
    color: ${vars.colors.white} !important;
    padding: 2px 10px;
    border-radius: 20px;
  }
  span {
    position: absolute;
    top: 50%; left: 0;
    width: 310px;
    text-align: center;
    background-color: ${vars.colors.green};
    color: ${vars.colors.white} !important;
    font-weight: 300 !important;
    font-size: 1em !important;
    text-transform: none !important;
    margin: 0 !important;
    padding: 2px 10px;
    border-radius: 20px;
    opacity: 0;
    will-change: opacity;
    transition: opacity .1s ease-out;
    pointer-events: none;
    transform: translateY(-50%);

    &:hover {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &:hover {
    span {
      opacity: 1;
      pointer-events: auto;
    }
  }
`
const TopRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`
const TotalCoverage = styled.div`
  display: flex;

  > span {
    margin-right: 5px;
  }
`
const CampaignUrl = styled.div`
  display: flex;
  max-width: 70%;

  span {
    color: ${vars.colors.red};
    font-weight: 600;
    margin-right: 5px;
  }
  a {
    text-decoration: none;
    color: ${vars.colors.teal};
    will-change: color;
    transition: color .3s ease-out;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      @media (hover: hover) {
        color: ${vars.colors.blue};
      }
    }
  }
`
const TableSettingsButton = styled.div`
  position: relative;
  width: 20px; height: 20px;
  background-image: url(${vars.icons.cog});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 25px;
  cursor: pointer;
  will-change: transform;
  transition: transform .3s ease-out;

  &:after {
    content: 'Table Settings';
    position: absolute;
    padding: 7px;
    top: -12px; left: -120px;
    width: 100px; height: 30px;
    line-height: 30px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
    pointer-events: none;
    background-color: ${vars.colors.offWhite};
    opacity: 0;
    will-change: opacity;
    transition: opacity .3s ease-out;
  }
  &:hover {
    @media (hover: hover) {
      transform: scale(1.1);

      &:after {
        opacity: 1;
      }
    }
  }
`

let forcePollEnd = false
class CampaignScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      companyId: +this.props.match.params.company,
      campaignId: +this.props.match.params.campaign,
      campaign: null,
      links: null,
      rows: null,
      users: null,
      csvData: null,
      showApproveModal: false,
      shares: 'N/A',
      noLinks: false,
      avgRating: 0,
      kliprScore: 0,
      avgTrustFlow: 0,
      avgCitationFlow: 0,
      search: '',
      appSettings: null,
      linkEditing: false,
      ownerEditing: false,
      datePublishedEditing: false,
      linkPrevType: null,
      currentTypeSelect: null,
      showSelectDrop: false,
      currentEditLinkIds: [],
      currentOwnerIds: [],
      currentDatePublishedIds: [],
      // page: 1,
      perPage: 10,
      scrollToIndex: undefined,
      sortBy: 'domain_rating',
      sortDirection: SortDirection.DESC,
      prevSortBy: null,
      newLinksSince: null,
      tableSettings: {
        companySettings: props.user.is_client,
        show: false,
        loading: false,
        saving: false,
        initialSort: null,
        defaultSort: null,
        columnLabels: ['Column'],
        columns: null
      },
      jobId: null,
      polling: {
        isPolling: false,
        total: 0,
        remaining: 0
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.rows && prevState.rows.length !== this.state.rows.length) {
      let avgRating = 0
      avgRating = this.state.rows.filter(row => typeof row.domain_rating == 'number').map(row => row.domain_rating)
      if (avgRating.length) {
        avgRating = avgRating.reduce((a,b) => a+b, 0) /  avgRating.length
      } else {
        avgRating = 'TBC'
      }


      let avgTrustFlow = this.state.rows.filter(row => row.trust_flow).map(row => row.trust_flow)
      avgTrustFlow = avgTrustFlow.reduce((a,b) => a+b, 0) /  avgTrustFlow.length
      let avgCitationFlow = this.state.rows.filter(row => row.citation_flow).map(row => row.citation_flow)
      avgCitationFlow = avgCitationFlow.reduce((a,b) => a+b, 0) /  avgCitationFlow.length

      const followLinks = this.state.rows.filter(row => row.type === 'follow').length || 0
      const mentionLinks = this.state.rows.filter(row => row.type === 'mention').length || 0
      const syndicatedLinks = this.state.rows.filter(row => row.type === 'syndicated').length || 0
      const nofollowLinks = this.state.rows.filter(row => row.type === 'nofollow').length || 0
      const totalLinks = this.state.rows.length
      const newLinks = this.state.rows.filter(row => row.is_new).length || 0
      this.setState({
        campaign: {
          ...prevState.campaign,
          linkTotal: totalLinks,
          newLinks,
          follow: followLinks,
          mention: mentionLinks,
          syndicated: syndicatedLinks,
          nofollow: nofollowLinks,
          other: totalLinks - mentionLinks - nofollowLinks - followLinks - syndicatedLinks
        },
        kliprScore: this.state.rows.reduce((partialSum, row) => partialSum + (row.klipr_score === 'TBC' ? 0 : row.klipr_score), 0),
        shares: this.state.rows.reduce((partialSum, row) => partialSum + (row.shares === 'TBC' ? 0 : row.shares), 0),
        avgRating,
        avgTrustFlow,
        avgCitationFlow
      })
    }
  }

  componentDidMount() {
    forcePollEnd = false
    this.getCampaignDetails()
    window.addEventListener('blur', this.blurHandler)
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.getCampaignDetails()
    }
    const { deleteDetails } = nextProps
    if (!deleteDetails.deleting && deleteDetails.success) {
      this.getCampaignDetails()
    }
  }
  componentWillUnmount() {
    forcePollEnd = true
    window.removeEventListener('blur', this.blurHandler)
  }

  getUsers = () => {
    this.props.callAPI('users').then(result => {
      if (result.length > 0) {
        const users = result.filter(user => !user.is_client)
          .map(user => ({ ...user, name: `${user.first_name} ${user.last_name}` }))
          .sort((u1, u2) => u1.name.localeCompare(u2.name))
        this.setState({ users })
      } else {
        this.setState({ users: null })
      }
    })
  }
  getCampaignDetails = () => {
    const jobDetails = JSON.parse(localStorage.getItem('klipr_job_batch_details'))
    if (+jobDetails?.campaignId === this.state.campaignId) {
      localStorage.removeItem('klipr_job_batch_details')
    }
    this.props.callAPI(`campaigns/${this.state.campaignId}`).then(result => {
      this.setState({
        campaign: result,
        totalTraffic: (result.additional_metrics && result.additional_metrics.ga_sessions_total) ? result.additional_metrics.ga_sessions_total : 0,
        jobId: result.active_job_batch_id ? result.active_job_batch_id : jobDetails?.jobId ? jobDetails.jobId : null
      }, () => this.getTableStructure())
    })
  }
  getTableStructure = () => {
    const { tableSettings } = this.state
    this.props.callAPI('dataset/link_list').then(structure => {
      let fields = structure.fields.filter(field => !field.hidden)
      tableSettings.fields = fields
      tableSettings.columnLabels = [...tableSettings.columnLabels, ...Object.values(structure.settings_labels)]
      this.setState({ tableSettings }, () => this.getTableSettings())
    })
  }
  getTableSettings = () => {
    const { tableSettings, companyId } = this.state
    const payload = (companyId && tableSettings.companySettings) ? { company_id: companyId } : {}
      // exclude metrics:
    const filterColumns = ['primary_topical', 'coverage_link_notes', 'user_id', 'shares', 'trust_flow', 'citation_flow', 'company_name', 'campaign_name', 'campaign_type']
    tableSettings.fields = tableSettings.fields.filter(field => !filterColumns.includes(field.field_id))
    const { fields } = tableSettings
    tableSettings.loading = true
    this.setState({ tableSettings })

    this.props.callAPI('dataset/link_list/settings', '', 'post', JSON.stringify(payload)).then(settings => {
      for (const setting of filterColumns) {
        delete settings[setting]
      }

      Object.keys(settings).forEach(setting => {
        fields.find(field => field.field_id === setting).settings = settings[setting]
      })
      const defaultField = fields.find(field => field.settings.is_default_sort === 1)
      const sortBy = defaultField ? defaultField.field_id : fields[0].field_id
      tableSettings.initialSort = sortBy
      tableSettings.defaultSort = sortBy

      tableSettings.columns = Object.fromEntries(fields.map(field => (
        [field.field_id, {
          label: field.label,
          show: field.settings.is_hidden ? field.settings.is_hidden === 0 : true,
          sticky: field.settings.is_hidden === undefined
        }]
      )))
      tableSettings.loading = false
      this.setState({ tableSettings, sortBy }, () => {
        if (this.state.jobId) {
          this.startPolling(this.state.jobId)
        } else {
          this.getCampaignMetrics()
        }
      })
    })
  }
  toggleTableSettings = () => {
    const { tableSettings } = this.state
    tableSettings.show = !tableSettings.show
    this.setState({ tableSettings })
  }
  switchTableSettings = type => {
    const { tableSettings } = this.state
    tableSettings.companySettings = type === 'company'
    this.setState({ tableSettings }, () => this.getTableSettings())
  }
  updateTableSettings = e => {
    const name = e.target.getAttribute('name')
    const { tableSettings } = this.state
    if (e.target.getAttribute('type') === 'checkbox' && !tableSettings.columns[name].sticky) {
      tableSettings.columns[name].show = !this.state.tableSettings.columns[name].show
      tableSettings.defaultSort = (!tableSettings.columns[name].show && tableSettings.defaultSort === name) ? tableSettings.initialSort : tableSettings.defaultSort
    } else {
      tableSettings.defaultSort = name
      this.sort({ sortBy: name, sortDirection: SortDirection.ASC })
    }
    this.setState({ tableSettings }, () => this.updateCsv(this.state.rows))
  }
  saveTableSettings = save => {
    const { tableSettings, companyId } = this.state
    if (save) {
      tableSettings.saving = true
      this.setState({ tableSettings })

      const scope = (companyId && tableSettings.companySettings) ? { company_id: companyId } : {}
      const settings = JSON.parse(JSON.stringify(tableSettings.columns)) // make a deepcopy of settings
      Object.keys(settings).forEach(key => {
        if (!settings[key].sticky) {
          settings[key].is_hidden = settings[key].show ? 0 : 1
        }
        settings[key].is_default_sort = tableSettings.defaultSort === key ? 1 : 0
        delete settings[key].label
        delete settings[key].show
        delete settings[key].sticky
      })
      const payload = { scope, settings }
      this.props.callAPI('dataset/link_list/settings', '', 'put', JSON.stringify(payload)).then(response => {
        tableSettings.saving = false
        tableSettings.show = false
        this.setState({ tableSettings })
        if (response.success) {
          this.props.toast.success('Table settings have been updated.')
        }
      })
    } else {
      tableSettings.saving = false
      tableSettings.show = false
      this.setState({ tableSettings })
    }
  }
  getCampaignMetrics = (getLinks = true, fromPoll = false) => {
    this.props.callAPI(`campaigns/${this.state.campaignId}/dataset`).then(result => {
      if (result.total_links_count > 0) {
        this.props.callAPI('settings/application').then(settings => {
          const rangeSetting = settings.find(x => x.setting_id === 'new_link_day_range')
          const dayOffset = rangeSetting ? rangeSetting.value : 0
          const newLinksSince = dayOffset > 0 ? moment().subtract(dayOffset, 'day').format('MMMM Do YYYY') : null

          if (this.state.linkEditing) {
            this.props.toast.success('Coverage type has been updated.')
          }

          if (this.state.ownerEditing) {
            this.props.toast.success('Owner has been updated.')
          }

          if (this.state.datePublishedEditing) {
            this.props.toast.success('Date Published has been updated.')
          }

          this.setState(prevState => ({
            campaign: {
              ...prevState.campaign,
              linkTotal: result.total_links_count,
              newLinks: result.new_links_count,
              follow: result.follow_links_count,
              mention: result.mention_links_count,
              syndicated: result.syndicated_links_count,
              nofollow: result.nofollow_links_count,
              other: result.other_links_count
            },
            appSettings: settings,
            kliprScore: result.klipr_score,
            shares: result.total_shared_count,
            newLinksSince,
            avgRating: result.average_domain_rating === 0 || result.average_domain_rating > 0 ? result.average_domain_rating : 'TBC',
            linkEditing: false,
            ownerEditing: false,
            datePublishedEditing: false,
            avgTrustFlow: result.avg_trust_flow,
            avgCitationFlow: result.avg_citation_flow
          }), () => {
            if (getLinks) {
              this.getCampaignLinks(fromPoll)
            } else {
              this.props.setLoading(false)
            }
          })
        })
      } else {
        this.setState({ noLinks: true, linkEditing: false, ownerEditing: false, datePublishedEditing: false }, () => {
          this.resetPolling()
          this.props.setLoading(false)
        })
      }
    })
  }
  getCampaignLinks = fromPoll => {
    this.props.callAPI(`campaigns/${this.state.campaignId}/links`).then(result => {
      const rows = result.map(link => ({
        url: link.url,
        date_created: link.date_created.split(' ')[0],
        date_published: link.date_published ? link.date_published.split(' ')[0] : '–',
        id: link.id,
        type: link.type ? link.type : 'TBC',
        type_is_manual: link.type_is_manual,
        is_manual: link.is_manual,
        domain_rating: link.domain_rating === 0 || link.domain_rating > 0 ? link.domain_rating : 'TBC',
        shares: link.shares ? link.shares : link.shares === 0 ? 0 : 'TBC',
        klipr_score: (link.type && (link.domain_rating === 0 || link.domain_rating > 0)) ? link.klipr_score : 'TBC',
        ga_sessions: link.ga_sessions ? link.ga_sessions : '–',
        anchor_term: link.anchor_term ? link.anchor_term : 'N/A',
        is_new: link.is_new,
        trust_flow: link.trust_flow,
        citation_flow: link.citation_flow,
        primary_topical: link.primary_topical,
        user_name: link.user_name,
        user_id: link.user_id,
        user_email: link.user_email,
        page_title: link.page_title
      }))
      this.updateCsv(rows)
      this.setState({ links: rows, rows, noLinks: rows.length < 1, linkEditing: false, ownerEditing: false, datePublishedEditing: false }, () => {
        this.sort({ sortBy: this.state.sortBy, sortDirection: SortDirection.ASC })
        this.props.setLoading(false)

        if (fromPoll) {
          const { polling } = this.state
          polling.remaining = 0
          this.setState({ polling })
          this.props.toast.success('Links information successfully fetched.')
          setTimeout(() => this.resetPolling(false), 500)
        }
        if (!this.props.user.is_client) {
          this.getUsers()
        }
      })
    })
  }
  updateCsv = data => {
    let csvData = null
    const { tableSettings } = this.state
    if (tableSettings.columns) {
      const columns = Object.keys(tableSettings.columns).filter(column => tableSettings.columns[column].show)
      csvData = data ? data.map(x => {
        const obj = {}
        columns.forEach(column => {
          obj[tableSettings.columns[column].label] = x[column]
        })
        return obj
      }) : null
    }
    this.setState({ csvData })
  }
  sort = ({ sortBy, sortDirection }) => {
    let sortDir = sortDirection
    let sortOptions = ['domain_rating', 'type', 'date_created', 'url']
    sortOptions = sortOptions.filter(item => item !== sortBy)
    sortOptions.unshift(sortBy)

    const prevSort = this.state.prevSortBy
    if (sortBy !== 'link' && (!prevSort || (prevSort && prevSort !== sortBy))) {
      sortDir = 'DESC'
    }
    const applySort = options => {
      const fields = options.map(option => {
        const cmp = (a, b) => {
          if (a == b) return 0 // eslint-disable-line
          return a < b ? -1 : 1
        }
        const invert = sortDir === SortDirection.DESC || (option !== sortBy && option !== 'link')
        const cmpFunc = invert ? (a, b) => -1 * cmp(a, b) : cmp
        return { option, cmpFunc }
      })

      return (A, B) => {
        let a, b, name, cmp, result
        for (let i = 0; i < fields.length; i += 1) {
          result = 0
          name = fields[i].option
          if (name === 'type') {
            a = A[name] === 'follow' ? 6 : A[name] === 'nofollow' ? 5 : A[name] === 'syndicated' ? 4 : A[name] === 'mention' ? 3 : A[name] === 'ugc' ? 2 : A[name] === 'sponsored' ? 1 : 0
            b = B[name] === 'follow' ? 6 : B[name] === 'nofollow' ? 5 : B[name] === 'syndicated' ? 4 : B[name] === 'mention' ? 3 : B[name] === 'ugc' ? 2 : B[name] === 'sponsored' ? 1 : 0
          } else {
            a = (A[name] === 'TBC' || A[name] === '–') ? -1 : typeof A[name] === 'string' ? A[name]?.toLowerCase()?.replace('https://', '')?.replace('http://', '')?.replace('www.', '') : A[name]
            b = (B[name] === 'TBC' || B[name] === '–') ? -1 : typeof B[name] === 'string' ? B[name]?.toLowerCase()?.replace('https://', '')?.replace('http://', '')?.replace('www.', '') : B[name]
          }
          cmp = fields[i].cmpFunc
          result = cmp(a, b)
          if (result !== 0) break
        }
        return result
      }
    }

    this.setState(prevState => ({
      sortBy,
      sortDirection: sortDir,
      prevSortBy: sortBy,
      rows: [...prevState.rows].sort(applySort(sortOptions))
    }), () => this.updateCsv(this.state.rows))
  }
  filterData = filterFns => {
    if (this.state.rows) {
      this.setState(prevState => ({ rows: prevState.links.filter(x => filterFns.every(f => f(x))) }), () => this.sort({ sortBy: this.state.sortBy, sortDirection: this.state.sortDirection }))
    }
  }
  updateSearch = e => {
    const search = e.target.value
    this.setState(prevState => ({
      search,
      rows: prevState.links.filter(link => link.url?.toLowerCase().includes(search?.toLowerCase()))
    }), () => this.sort({ sortBy: this.state.sortBy, sortDirection: this.state.sortDirection }))
  }
  handleRowsScroll = ({ stopIndex }) => {
    this.setState(prevState => {
      const page = Math.ceil(stopIndex / prevState.perPage)
      return { page, scrollToIndex: undefined }
    })
  }
  // handlePageChange = page => {
  //   this.setState(prevState => {
  //     const scrollToIndex = (page - 1) * prevState.perPage
  //     return { page, scrollToIndex }
  //   })
  // }

  updateOwner = (e, rowData) => {
    e.preventDefault()
    this.setState(prevState => ({
      ownerEditing: true,
      currentOwnerIds: [...new Set([...prevState.currentOwnerIds, rowData.id])]
    }))
    const type = rowData.type === 'TBC' ? null : rowData.type
    const userId = e.target.value
    const payload = {
      url: rowData.url,
      campaign: this.state.campaignId,
      type,
      userId
    }
    this.props.callAPI('links/', rowData.id, 'put', JSON.stringify(payload)).then(result => {
      if (result.success) {
        this.getCampaignMetrics(false)

        this.setState(prevState => {
          const rows = [...prevState.rows]
          const target = rows.findIndex(r => r.id === rowData.id)

          if (userId && userId !== 'Unassigned') {
            const userData = prevState.users?.find(u => parseInt(u.id) === parseInt(userId))
            rows[target].user_name = userData.name
            rows[target].user_email = userData.email
            rows[target].user_id = userData.id
          } else {
            rows[target].user_name = null
            rows[target].user_email = null
            rows[target].user_id = null
          }
          const currentOwnerIds = [...prevState.currentOwnerIds].filter(x => x !== rowData.id)

          return ({
            rows,
            links: rows,
            currentOwnerIds
          })
        })
      } else {
          this.setState({ linkEditing: false })
      }
    })
  }

  updateDatePublished = (e, rowData) => {
    e.preventDefault()
    this.setState(prevState => ({
      datePublishedEditing: true,
      currentDatePublishedIds: [...new Set([...prevState.currentDatePublishedIds, rowData.id])]
    }))
    const newPublishedDate = moment(e.target.value).format('YYYY-MM-DD')
    if (newPublishedDate === 'Invalid date') {
      this.props.toast.error(`Date published can't be set to empty`)
      this.setState(prevState => ({
        datePublishedEditing: false,
      }))
      return
    }
    const type = rowData.type === 'TBC' ? null : rowData.type
    const payload = {
      url: rowData.url,
      campaign: this.state.campaignId,
      type,
      datePublished: newPublishedDate || null
    }
    this.props.callAPI('links/', rowData.id, 'put', JSON.stringify(payload)).then(result => {
      if (result.success) {
        this.getCampaignMetrics(false)

        this.setState(prevState => {
          const rows = [...prevState.rows]
          const target = rows.findIndex(r => r.id === rowData.id)

          if (newPublishedDate) {
            rows[target].date_published = newPublishedDate
          }

          const currentDatePublishedIds = [...prevState.currentDatePublishedIds].filter(x => x !== rowData.id)

          return ({
            rows,
            links: rows,
            currentDatePublishedIds
          })
        })
      } else {
          this.setState({ linkEditing: false })
      }
    })
  }

  editType = (e, id, url) => {
    e.preventDefault()

    this.setState(prevState => ({
      linkEditing: true,
      currentEditLinkIds: [...new Set([...prevState.currentEditLinkIds, id])]
    }))
    this.revertLinkTypeOption(e)
    const value = e.target.value === 'TBC' ? null : e.target.value
    const payload = {
      url,
      campaign: this.state.campaignId,
      type: value
    }
    this.props.callAPI('links/', id, 'put', JSON.stringify(payload)).then(result => {
      if (result.success) {
        this.getCampaignMetrics(false)

        this.setState(prevState => {
          const rows = [...prevState.rows]
          const index = rows.findIndex(x => x.id === id)
          rows[index].type = value === 'auto' ? 'TBC' : value
          rows[index].type_is_manual = value !== 'auto'
          const linkWeight = prevState.appSettings.find(x => x.setting_id === `link_weighting_${value}`)
          rows[index].klipr_score = (value === 'auto' || rows[index].domain_rating === 'TBC') ? 'TBC' : Math.round(linkWeight.value * rows[index].domain_rating)
          const currentEditLinkIds  = [...prevState.currentEditLinkIds].filter(x => x !== id)

          return ({ rows, links: rows, currentEditLinkIds })
        })
      } else {
        this.setState({ linkEditing: false })
      }
    })
  }
  updateLinkTypeOption = (e, linkData) => {
    if (e.target.value === 'auto') {
      const originalType = linkData.type === 'auto' ? 'TBC' : linkData.type
      e.target.firstChild.text = `Auto (${originalType})`
      this.setState({ linkPrevType: originalType, currentTypeSelect: e.target }, () => {
        if (navigator.platform.includes('Mac')) {
          setTimeout(() => this.setState({ showSelectDrop: true }), 1)
        }
      })
    }
  }
  revertLinkTypeOption = e => {
    if (e.target.value === 'auto' && this.state.linkPrevType) {
      e.target.firstChild.text = this.state.linkPrevType ? this.state.linkPrevType : 'auto'
    }
  }
  blurHandler = () => {
    if (this.state.currentTypeSelect && navigator.platform.includes('Mac')) {
      this.state.currentTypeSelect.firstChild.text = this.state.linkPrevType
      this.state.currentTypeSelect.blur()
      this.setState({ showSelectDrop: false, currentTypeSelect: null })
    }
  }
  checkUserType = e => {
    if (this.props.currentTeam?.type === 'free') {
      e.preventDefault()
      e.stopPropagation()
      this.setState({ showApproveModal: true })
    }
  }
  startPolling = jobId => {
    this.props.callAPI(`jobs/batch/${jobId}`).then(result => {
      if (result.length > 1) {
        const polling = { isPolling: true, total: result.length, remaining: result.length }
        this.setState({ polling })
        this.poll(() => this.props.callAPI(`jobs/batch/${jobId}`), 500)
        this.props.setLoading(false)
      } else {
        this.setState({ jobId: null }, () => this.getCampaignMetrics())
      }
    })
  }
  poll = (fn, interval) => {
    const { campaign } = this.state
    const execute = () => fn()
      .then(result => {
        const ongoingJobs = result.filter(x => x.status === 'pending')
        const failedJobs = result.filter(x => x.status === 'failed')
        if (failedJobs.length > 0) {
          this.props.toast.error(`There was an error while fetching links information for ${campaign.name} campaign.`)
          console.error(failedJobs)
          this.resetPolling()
        } else if (forcePollEnd) {
          this.resetPolling()
        } else if (ongoingJobs.length > 0) {
          const { polling } = this.state
          polling.remaining = ongoingJobs.length
          setTimeout(execute, interval)
          this.setState({ polling })
        } else {
          this.getCampaignMetrics(true, true)
        }
      })
      .catch(() => {
        this.props.toast.error(`There was an error while fetching links information for ${campaign.name} campaign.`)
        this.resetPolling()
      })
    execute()
  }
  resetPolling = (noLinks = true) => {
    this.setState({
      noLinks,
      polling: {
        isPolling: false,
        total: 0,
        remaining: 0
      }
    })
  }
  headerRenderer = ({ label, dataKey, sortBy, sortDirection }) => (
    <TableHeader>
      {label}{sortBy === dataKey && <SortIndicator sortDirection={sortDirection}/>}
      {label === 'Klipr Score' && <ScoreInfo/>}
    </TableHeader>
  )
  tableSettingsRenderer = () => (
    <TableSettingsButton onClick={this.toggleTableSettings}/>
  )
  linkCellRenderer = props => (
    <LinkWrapper>
      <LinkType type={props.rowData.is_manual ? 'manual' : 'auto'}/>
      <a href={props.cellData} target='_blank' rel='noopener noreferrer'>{props.cellData?.replace('https://', '')?.replace('http://', '')?.replace('www.', '')}</a>
    </LinkWrapper>
  )
  dateCellRenderer = ({ cellData }) => (
    <>
      {cellData === '–' ? (
        '–'
      ) : (
        moment(cellData).format('DD MMMM YYYY')
      )}
    </>
  )
  datePublishedCellRenderer = ({ cellData, rowData }) => (
    this.state.datePublishedEditing && this.state.currentDatePublishedIds.includes(rowData.id) ? (
        <Loader small/>
      ) : (
        <input type={'date'} className='only-date-picker' value={cellData === '-' ? '' : moment(cellData).format('YYYY-MM-DD')} style={{border: 'none', maxWidth: 125}} onChange={(e) => {
          if (moment(e.target.value) < moment('1980-01-01', 'YYYY-MM-DD')) return
          this.updateDatePublished(e, rowData)
        }}/>
  ))
  linkTypeRenderer = ({ cellData, rowData }) => (
    <>
      {this.props.user.is_client ? (
        <>{cellData}</>
      ) : (
        <DropdownRenderer aria-label='Edit'>
          {this.state.linkEditing && this.state.currentEditLinkIds.includes(rowData.id) ? (
            <Loader small/>
          ) : (
            <select value={rowData.is_manual ? cellData : rowData.type_is_manual && cellData !== 'TBC' ? cellData : 'auto'} onChange={e => this.editType(e, rowData.id, rowData.url)} onFocus={e => this.updateLinkTypeOption(e, rowData)} onBlur={this.revertLinkTypeOption}>
              {rowData.is_manual ? (
                <option disabled value='TBC'>Select Type</option>
              ) : (
                <option value='auto'>{cellData === 'TBC' ? 'TBC' : rowData.type_is_manual ? 'Auto (TBC)' : cellData}</option>
              )}
              <option value='follow'>follow</option>
              <option value='mention'>mention</option>
              <option value='nofollow'>nofollow</option>
              <option value='syndicated'>syndicated</option>
              <option value='ugc'>UGC</option>
              <option value='sponsored'>sponsored</option>
            </select>
          )}
        </DropdownRenderer>
      )}
    </>
  )
  ownerRenderer = ({ cellData, rowData }) => (
    <>
      <DropdownRenderer aria-label='Edit'>
        {this.state.ownerEditing && this.state.currentOwnerIds.includes(rowData.id) ? (
          <Loader small/>
        ) : (
          <select
            value={rowData.user_id || 'Unassigned'}
            onChange={e => this.updateOwner(e, rowData)}
          >
            <option value={'Unassigned'}>Unassigned</option>
            {this.state.users && this.state.users.map(user => <option key={user.id} value={user.id}>{user.name}</option>)}
          </select>
        )}
      </DropdownRenderer>
    </>
  )
  numberCellRenderer = ({ cellData }) => (
    <NumberCell>{typeof cellData === 'number' ? cellData.toLocaleString() : cellData}</NumberCell>
  )
  textCellRenderer = ({ cellData }) => (
    <TextCell>{cellData}</TextCell>
  )
  actionCellRenderer = props => (
    <ActionButton type='button' onClick={e => this.props.delete('URL', props.cellData, props.rowData.url?.replace('https://', '')?.replace('http://', '')?.replace('www.', ''), e)}>
      <img src={vars.icons.remove} alt='Delete URL'/>
    </ActionButton>
  )
  anchorCellRenderer = ({ cellData }) => (
    <AnchorText empty={!cellData || !cellData.length || cellData.length < 8 || cellData === 'N/A'}>
      <p>{cellData}</p>
      <span>{cellData}</span>
    </AnchorText>
  )
  rowRenderer = props => (
    React.cloneElement(
      defaultTableRowRenderer(props), {
        'data-id': props.rowData.id,
        className: props.rowData.is_new ? `${props.className} new` : props.className
      }
    )
  )

  render() {
    const { campaign, companyId, rows, csvData, perPage, avgRating, shares, kliprScore, totalTraffic, campaignId, links, newLinksSince, noLinks, search, tableSettings, scrollToIndex, sortBy, sortDirection, showApproveModal, showSelectDrop, polling, avgTrustFlow, avgCitationFlow } = this.state
    const { user, currentTeam } = this.props
    const headerHeight = 55
    const rowHeight = 70
    const rowCount = rows ? rows.length : 0
    const height = rowHeight * Math.min(perPage, rowCount) + headerHeight
    // const pageCount = Math.ceil(rowCount / perPage)
    const kpiTarget = campaign?.follow_link_kpi_target ? Math.round((campaign.follow / campaign.follow_link_kpi_target) * 100) : null
    const overviewStats = campaign?.linkTotal > 0 ? [{
      label: 'Follow links',
      value: campaign.follow.toLocaleString()
    }, {
      label: 'Nofollow links',
      value: campaign.nofollow.toLocaleString()
    }, {
      label: 'Mentions',
      value: campaign.mention.toLocaleString(),
      hide: campaign.mention <= 0
    }, {
      label: 'Syndicated Links',
      value: campaign.syndicated.toLocaleString(),
      hide: campaign.syndicated <= 0
    }, {
      label: 'Other links',
      value: campaign.other.toLocaleString(),
      hide: campaign.other <= 0
    }, {
      label: 'Avg. domain rating',
      value: avgRating.toLocaleString()
    },
    //   {
    //   label: 'Total shares',
    //   value: typeof shares === 'number' ? shares.toLocaleString() : 'TBC'
    // },
      {
      label: '% of Minimum Link KPI',
      value: `${kpiTarget?.toLocaleString()}%`,
      hide: !kpiTarget || user.is_client,
      color: kpiTarget < 50 ? vars.colors.red : kpiTarget < 100 ? vars.colors.amber : vars.colors.green
    }, {
      label: 'Total Klipr score',
      value: kliprScore.toLocaleString()
    }, {
      label: 'Total Traffic',
      value: totalTraffic.toLocaleString(),
      hide: totalTraffic <= 0
    },
    //   {
    //   label: 'Avg. TrustFlow',
    //   value: avgTrustFlow ? avgTrustFlow.toFixed(2) : 0
    // },
    //   {
    //   label: 'Avg. CitationFlow',
    //   value: avgCitationFlow ? avgCitationFlow.toFixed(2) : 0
    // }
    ] : []
    const approveModalContent = '<p>CSV exports are only available to paying customers.</p>'

    return (
      <div>
        {campaign && (
          <>
            <div className='panelHead'>
              <Title campaignType={campaign.type}>
                <h2 title={campaign.name}>{campaign.name}</h2>
                {!user.is_client &&
                  <Link to={`/edit-campaign/${companyId}/${campaignId}`} className='link'/>
                }
              </Title>

              {!!links?.length && !!avgRating &&
                <Link className='button blue' to={`/lookbook/${companyId}/${campaignId}`}>View Lookbook</Link>
              }
              <Link to={`/campaigns/${companyId}`} className='link'>
                <img src={vars.icons.arrow} alt='Back to all company campaigns'/> all campaigns
              </Link>
            </div>

            <TopRow>
              {!!campaign.linkTotal && (
                <TotalCoverage>
                  <span>Total coverage:</span>{campaign.linkTotal}
                  {campaign.newLinks > 0 && (
                    <NewCoverage>
                      <p>+{campaign.newLinks}</p>
                      {newLinksSince &&
                        <span>{campaign.newLinks} new URL{campaign.newLinks > 1 ? 's' : ''} since {newLinksSince}</span>
                      }
                    </NewCoverage>
                  )}
                </TotalCoverage>
              )}
              <CampaignUrl>
                <span>URL:</span>
                <a href={campaign.url} target='_blank' rel='noopener noreferrer'>{campaign.url?.replace('https://', '')?.replace('http://', '')}</a>
              </CampaignUrl>
            </TopRow>
            {!!campaign.linkTotal &&
              <OverviewStats stats={overviewStats}/>
            }
            {
              !user.is_client && currentTeam?.buzzstream_enabled &&
              <BuzzstreamFieldset>
                <legend>BuzzStream</legend>
                { this.state.campaign.buzzstream_id ?
                  <>
                  <span>Project name</span>
                    {this.state.campaign.buzzstream_name}
                    <div className='open-on-buzzstream'>
                    <a href={`https://app.buzzstream.com/project/phase/Opportunities/project/${this.state.campaign.buzzstream_id}`} target='_blank'>Open on BuzzStream</a>
                    <img src={vars.icons.openInNew} alt='Open in new window'/>
                    </div>
                  </> :
                  <div>
                    <p>No associated project was found</p>
                    <Link to={`/edit-campaign/${companyId}/${campaignId}`} className='link'>Click here to fix</Link>
                  </div>
                }
              </BuzzstreamFieldset>
            }
            {polling.isPolling ? (
              <LinkPolling isPolling={polling.isPolling} total={polling.total} remaining={polling.remaining}/>
            ) : (
              <>
                {rows ? (<FilterBar>
                  <div>
                    {!user.is_client &&
                      <AddLink to={`/add-urls/${companyId}/${campaignId}`}>URLs</AddLink>
                    }
                    {!noLinks && (
                      <>
                        <SearchInput name="urlSearch" value={search} onChange={this.updateSearch} placeholder="Search URLs"/>
                        <LinksFilter filterData={this.filterData} defaultFilter={this.props.defaultFilter}/>
                      </>
                    )}
                  </div>
                  {csvData &&
                    <CSVLink data={csvData} filename={`Klipr_${campaign.name} Links_${moment(new Date())
                      .format('DD.MM.YYYY')}.csv`} className="button amber" target="_blank" onClick={this.checkUserType}>Download CSV</CSVLink>
                  }
                  {/* {pageCount > 3 &&
                    <Paginator pageCount={pageCount} currentPage={page} onPageChange={this.handlePageChange}/>
                  } */}
                </FilterBar>) :
                  !user.is_client &&
                  <AddLink to={`/add-urls/${companyId}/${campaignId}`}>URLs</AddLink>
                }
                {(!noLinks && tableSettings.columns) ? (
                  <VirtualizedTable
                    rowHeight={rowHeight} headerHeight={headerHeight} height={height}
                    rowCount={rowCount} rows={rows} onRowsRendered={this.handleRowsScroll}
                    scrollToIndex={scrollToIndex} scrollToAlignment='start'
                    sort={this.sort} sortBy={sortBy} sortDirection={sortDirection}
                    rowRenderer={this.rowRenderer} tableSettings={tableSettings} updateTableSettings={this.updateTableSettings} saveTableSettings={this.saveTableSettings}
                    switchTableSettings={this.switchTableSettings} user={user}
                    teamName={currentTeam ? currentTeam.name : ''} companyName={campaign.company_name}
                  >
                    <Column label='URL' dataKey='url' width={250} flexGrow={1} headerRenderer={this.headerRenderer} cellRenderer={this.linkCellRenderer}/>
                    {tableSettings.columns.date_created.show &&
                      <Column label='Date Added' dataKey='date_created' width={170} headerRenderer={this.headerRenderer} cellRenderer={this.dateCellRenderer}/>
                    }
                    {tableSettings.columns.user_name.show && !user.is_client &&
                      <Column label='Owner' dataKey='user_name' width={140} headerRenderer={this.headerRenderer} cellRenderer={this.ownerRenderer}/>
                    }
                    {tableSettings.columns.domain_rating.show &&
                      <Column label='Domain Rating' dataKey='domain_rating' width={80} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
                    }
                    {tableSettings.columns.type.show &&
                      <Column label='Coverage Type' dataKey='type' width={130} headerRenderer={this.headerRenderer} cellRenderer={this.linkTypeRenderer}/>
                    }
                    {tableSettings.columns.date_published.show &&
                      <Column label='Date Published' dataKey='date_published' width={170} headerRenderer={this.headerRenderer} cellRenderer={this.datePublishedCellRenderer}/>
                    }
                    {/* {tableSettings.columns.shares.show && */}
                    {/*   <Column label='Shares' dataKey='shares' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/> */}
                    {/* } */}
                    {tableSettings.columns.klipr_score.show &&
                      <Column label='Klipr Score' dataKey='klipr_score' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
                    }
                    {tableSettings.columns.ga_sessions.show &&
                      <Column label='Google Analytics Sessions' dataKey='ga_sessions' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/>
                    }
                    {tableSettings.columns.anchor_term.show &&
                      <Column label='Anchor Text' dataKey='anchor_term' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.anchorCellRenderer}/>
                    }
                    {tableSettings.columns.page_title.show &&
                      <Column label='Page Title' dataKey='page_title' width={100} headerRenderer={this.headerRenderer} cellRenderer={this.anchorCellRenderer}/>
                    }
                    {/* {tableSettings.columns.trust_flow.show && */}
                    {/*   <Column label='Trust Flow' dataKey='trust_flow' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/> */}
                    {/* } */}
                    {/* {tableSettings.columns.citation_flow.show && */}
                    {/*   <Column label='Citation Flow' dataKey='citation_flow' width={70} headerRenderer={this.headerRenderer} cellRenderer={this.numberCellRenderer}/> */}
                    {/* } */}
                    {tableSettings.columns.user_email.show && !user.is_client &&
                      <Column label='Owner Email' dataKey='user_email' width={150} headerRenderer={this.headerRenderer} cellRenderer={this.textCellRenderer}/>
                    }
                    {!user.is_client &&
                      <Column label='' dataKey='id' width={70} disableSort headerRenderer={this.tableSettingsRenderer} cellRenderer={this.actionCellRenderer}/>
                    }
                  </VirtualizedTable>
                ) : (
                  <NoResults>There are currently no URLs</NoResults>
                )}
              </>
            )}
            {showSelectDrop &&
              <SelectDrop onMouseMove={this.blurHandler}/>
            }
            <ApprovalModal show={showApproveModal} content={approveModalContent} approveAction={() => window.open('https://klipr.io/pricing/', '_blank')} closeAction={() => this.setState({ showApproveModal: false })} approveButtonText='Upgrade Now' closeButtonText='Close'/>
          </>
        )}
      </div>
    )
  }
}

export default CampaignScreen
