import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Avatar from '../components/avatar'
import Loader from '../components/loader'
import Form from '../components/form'
import Toggle from '../components/toggle'
import * as vars from '../assets/vars'

const SUWarning = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${vars.colors.red};
  color: ${vars.colors.white};

  p {
    padding: 20px;
    margin: 0;
  }
`
const PasswordMeterWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0; left: 0;
    height: 4px;
    width: ${props => `${props.strength * (100 / 3)}%`};
    background-color: ${props => (props.strength > 2 ? vars.colors.green : props.strength > 1 ? vars.colors.amber : vars.colors.red)};
    will-change: width, color;
    transition: width .3s ease-out, color .3s ease-out;
  }
`
const PasswordEqualWrapper = styled.div`
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0; left: 0;
    width: 100%; height: 4px;
    background-color: ${props => (props.equal ? vars.colors.green : vars.colors.red)};
    will-change: color;
    transition: color .3s ease-out;
  }
`

const AddUser = ({ onSubmit, onChange, preview, user, updateUser, currentTeam, togglePasswordFields }) => {
  const isProfile = +updateUser === +user.currentUser.id
  const showUsername = !user.emailVerified || user.title !== 'Add User'

  return (
    <div className='narrow'>
      <div className='panelHead'>
        <h2>{user.title}</h2>
        {(user.currentUser.is_super_user || user.currentUser.is_team_admin) && (
          <Link to='/users' className='link'>
            <img src={vars.icons.arrow} alt='Back to all users'/> all users
          </Link>
        )}
      </div>

      {user.currentUser.is_super_user && !currentTeam && !isProfile && !updateUser &&
        <SUWarning><p>You are adding a Super User!</p></SUWarning>
      }

      <Form action='/' onSubmit={onSubmit}>
        <fieldset className='halfWidth'>
          <label>First Name*</label>
          <input name='fname' type='text' onChange={onChange} value={user.fname} required/>
        </fieldset>
        <fieldset className='halfWidth'>
          <label>Last Name*</label>
          <input name='lname' type='text' onChange={onChange} value={user.lname} required/>
        </fieldset>
        <fieldset className={`${showUsername ? '' : 'halfWidth'}`}>
          <label>Email*</label>
          <input name='email' type='email' onChange={onChange} value={user.email} required/>
        </fieldset>
        {!showUsername && (
          <fieldset className='halfWidth'>
            <label>Username</label>
            <input name='uname' type='text' onChange={onChange} value={user.uname} disabled/>
          </fieldset>
        )}
        {((user.currentUser.is_super_user && !user.super && currentTeam && !isProfile) || (user.currentUser.is_team_admin && !user.currentUser.is_super_user && !isProfile)) && (
          <fieldset className='halfWidth'>
            <label>User Level</label>
            <select value={user.team_admin} name='team_admin' onChange={onChange}>
              <option value={false}>Team Member</option>
              <option value>Team Admin</option>
            </select>
          </fieldset>
        )}
        <fieldset className='fullWidth'>
          <fieldset className='halfWidth'>
            <label htmlFor='userImg'>Profile Image (png or jpg)</label>
            <input id='userImg' name='avatar' type='file' accept='image/png, image/jpeg' onChange={preview}/>
          </fieldset>
          <fieldset className='halfWidth'>
            <Avatar image={user.avatar} name={`${user.fname ? user.fname : ''} ${user.lname ? user.lname : ''}`} big/>
          </fieldset>
        </fieldset>
        {updateUser && (
          <fieldset className='fullWidth'>
            <label>Update password?</label>
            <Toggle id='update_password' name='update_password' onChange={togglePasswordFields} isChecked={user.updatePassword}/>
          </fieldset>
        )}
        {user.updatePassword && (
          <fieldset className='fullWidth'>
            {isProfile && (
              <fieldset className='halfWidth'>
                <label>Previous Password*</label>
                <input name='prevpword' type='password' autoComplete='off' required onChange={onChange} value={user.prevpword}/>
              </fieldset>
            )}
            <fieldset className='fullWidth'>
              <fieldset className='halfWidth'>
                <label>Password*</label>
                <PasswordMeterWrapper strength={user.passwordStrength}>
                  <input name='pword1' type='password' autoComplete='off' required onChange={onChange} value={user.pword1}/>
                </PasswordMeterWrapper>
              </fieldset>
              <fieldset className='halfWidth'>
                <label>Confirm Password*</label>
                <PasswordEqualWrapper equal={user.passwordsEqual}>
                  <input name='pword2' type='password' autoComplete='off' required onChange={onChange} value={user.pword2}/>
                </PasswordEqualWrapper>
              </fieldset>
              <p><em>Your password must contain at least 8 characters and at least a character that is not a letter or a number.</em></p>
            </fieldset>
          </fieldset>
        )}
        {user.loading ? (
          <button type='button' disabled><Loader small/></button>
        ) : (
          <button type='submit'>{isProfile ? 'Update profile' : updateUser ? 'Update user' : 'Add user'}</button>
        )}
      </Form>
    </div>
  )
}

export default AddUser
